import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const NoDataIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="NoData"
      >
        <path d="M3.66893 3.68081C4.0137 3.34954 4.55851 3.34954 4.90328 3.68081L20.9739 19.1224C21.3387 19.4729 21.3387 20.0565 20.9739 20.407C20.6291 20.7383 20.0843 20.7383 19.7396 20.407L18.5256 19.2406C17.9118 19.4705 17.244 19.5966 16.5455 19.5966H7.45455C4.44208 19.5966 2 17.2501 2 14.3556C2 12.071 3.52133 10.1278 5.64367 9.41029C5.63882 9.3123 5.63636 9.2137 5.63636 9.11455C5.63636 8.4352 5.75166 7.78173 5.96449 7.17123L3.66892 4.96544C3.30414 4.61492 3.30414 4.03133 3.66893 3.68081ZM7.45455 9.11455C7.45455 9.18571 7.4563 9.2566 7.4598 9.32719L7.5242 10.6265L6.24712 11.0583C4.804 11.5461 3.81818 12.8609 3.81818 14.3556C3.81818 16.2852 5.44624 17.8496 7.45455 17.8496H16.5455C16.7144 17.8496 16.8806 17.8385 17.0435 17.8171L7.48221 8.62992C7.46393 8.78904 7.45455 8.95074 7.45455 9.11455ZM12 3C15.5145 3 18.3636 5.73758 18.3636 9.11455C18.3636 9.2137 18.3612 9.3123 18.3564 9.41029C20.4786 10.1278 22 12.071 22 14.3556C22 15.3062 21.7366 16.1977 21.2761 16.9664L19.9192 15.6619C20.0885 15.2584 20.1818 14.8174 20.1818 14.3556C20.1818 12.4251 18.5545 10.8616 16.5455 10.8616C16.0646 10.8616 15.6055 10.9513 15.1854 11.1142L13.8283 9.81021C14.6284 9.36767 15.5562 9.11455 16.5455 9.11455C16.5455 6.70243 14.5104 4.74701 12 4.74701C11.0268 4.74701 10.1251 5.04085 9.38571 5.54122L8.08562 4.29328C9.16517 3.48291 10.5239 3 12 3Z" />
      </svg>
    );
  },
);

NoDataIcon.displayName = 'NoDataIcon';
