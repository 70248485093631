import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { IS_DEV_MODE } from 'constants/env';

const dsn = import.meta.env.VITE_SENTRY_DSN;

export function initSentry() {
  return Sentry.init({
    enabled: !IS_DEV_MODE,
    beforeSend: (event) => {
      event.extra ??= {};
      event.extra.networkStatus = navigator.onLine ? 'online' : 'offline';

      if (IS_DEV_MODE) {
        const style =
          'background-color: darkblue; color: white; border: 2px solid hotpink; font-size: 12px; padding: 4px; border-radius: 4px;';

        // eslint-disable-next-line
        console.log(
          `%cSentry message: ${event?.exception?.values?.[0].value}`,
          style,
        );

        return null; // this drops the event and nothing will be sent to sentry
      }

      return event;
    },
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        traceFetch: false,
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

export default Sentry;
