import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const FolderPlusIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="FolderPlus"
      >
        <path d="M16.57 20.99H7.21C5.56 20.99 4.73 20.99 3.96 20.6C3.28 20.25 2.73 19.71 2.39 19.03C2 18.26 2 17.43 2 15.78V6.78C2 5.67 2 5.05 2.29 4.47C2.55 3.96 2.96 3.55 3.47 3.29C4.05 3 4.66 3 5.78 3H9.22C9.94 3 10.34 3 10.77 3.15C11.15 3.28 11.5 3.5 11.78 3.77C12.1 4.09 12.28 4.44 12.6 5.09L13.35 6.6H16.57C18.22 6.6 19.05 6.6 19.82 6.99C20.5 7.34 21.05 7.88 21.39 8.56C21.78 9.33 21.78 10.16 21.78 11.81V15.77C21.78 17.42 21.78 18.25 21.39 19.02C21.04 19.7 20.5 20.25 19.82 20.59C19.05 20.98 18.22 20.98 16.57 20.98V20.99ZM3.79 8.39V15.77C3.79 17.09 3.79 17.82 3.99 18.21C4.16 18.55 4.44 18.82 4.78 19C5.16 19.2 5.89 19.2 7.22 19.2H16.58C17.9 19.2 18.63 19.2 19.02 19C19.36 18.83 19.63 18.55 19.81 18.21C20.01 17.82 20.01 17.1 20.01 15.77V11.81C20.01 10.49 20.01 9.76 19.81 9.37C19.64 9.03 19.36 8.76 19.02 8.58C18.63 8.38 17.91 8.38 16.58 8.38H3.8L3.79 8.39ZM3.79 6.59H11.33L10.98 5.88C10.75 5.42 10.61 5.15 10.51 5.04C10.42 4.95 10.3 4.88 10.17 4.83C10.03 4.78 9.72 4.78 9.21 4.78H5.77C4.99 4.78 4.47 4.78 4.28 4.88C4.11 4.97 3.97 5.1 3.89 5.27C3.8 5.45 3.79 5.9 3.79 6.58V6.59ZM11.89 17.39C11.39 17.39 10.99 16.99 10.99 16.49V14.69H9.19C8.69 14.69 8.29 14.29 8.29 13.79C8.29 13.29 8.69 12.89 9.19 12.89H10.99V11.09C10.99 10.59 11.39 10.19 11.89 10.19C12.39 10.19 12.79 10.59 12.79 11.09V12.89H14.59C15.09 12.89 15.49 13.29 15.49 13.79C15.49 14.29 15.09 14.69 14.59 14.69H12.79V16.49C12.79 16.99 12.39 17.39 11.89 17.39Z" />
      </svg>
    );
  },
);

FolderPlusIcon.displayName = 'FolderPlusIcon';
