import { ReactNode } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import Button, {
  ButtonSizes,
  ButtonVariants,
  ButtonVariantsValues,
} from '../Button';
import { Icon } from '../Icon';

export type ModalContainerProps = {
  width?: number | string; // css
  formId?: string; // to connect submit button to form
  hideActionButtons?: boolean;
  customActions?: ReactNode;
  actionText?: string;
  actionColor?: string;
  actionVariant?: ButtonVariantsValues;
  cancelText?: string;
  disableSubmit?: boolean;
  hideCancelButton?: boolean;
  onClose?: (confirm: boolean) => void; // when not provided => disable closing the modal by the user
  className?: string;
  actionAdditionalInfo?: ReactNode; // useful for adding error/helper texts near the action buttons
  dataCy?: string;
  autoFocus?: boolean;
  disableOutsideClickClose?: boolean;
} & { isHostLoading?: boolean; startIconName?: string; endIconName?: string };

const ModalContainer = ({
  width = 544,
  formId,
  hideActionButtons = false,
  customActions,
  actionText = 'Confirm',
  actionVariant = ButtonVariants.Primary,
  disableSubmit = false,
  hideCancelButton = false,
  cancelText = 'Cancel',
  onClose,
  className = 'w-[544px]',
  children,
  actionAdditionalInfo = null,
  dataCy,
  autoFocus = true,
  disableOutsideClickClose = false,
  ...actionButtonProps
}: ModalContainerProps & { children: ReactNode }) => {
  const closeIfOnCloseExists = (confirmed: boolean) => {
    if (!onClose) {
      return;
    }
    onClose(confirmed);
  };

  const onPointerDownOutside = () => {
    if (!disableOutsideClickClose) {
      closeIfOnCloseExists(false);
    }
  };

  return (
    <Dialog.Root open={true}>
      {/**
       * Using Dialog.Overlay here was removed because it's causing a focus trap that doesn't let controls inside the modal (e.g react-select component) handle scrolling.
       * See https://github.com/radix-ui/primitives/issues/2219.
       **/}
      <div className="data-[state=open]:animate-overlayShow fixed inset-0 z-modal bg-black bg-opacity-40" />
      <Dialog.Content
        onEscapeKeyDown={() => closeIfOnCloseExists(false)}
        onPointerDownOutside={onPointerDownOutside}
        onOpenAutoFocus={(e: Event) => (!autoFocus ? e.preventDefault() : null)}
        style={{ width }}
        data-cy={dataCy}
        className={`animate-contentShow fixed left-[50%] top-[50%] z-modal m-auto grid max-h-[85vh] translate-x-[-50%] translate-y-[-50%] grid-rows-[auto_1fr_auto] rounded-[12px] bg-white pt-6 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none ${className}`}
      >
        {children}

        {customActions ? (
          // If customActions is provided, hideActionButtons should not be used simultaneously.
          <div data-cy="modal-container-custom-actions" className="m-6">
            <div className="flex items-center justify-end gap-3">
              {customActions}
            </div>
          </div>
        ) : hideActionButtons ? null : (
          // If customActions is not provided and hideActionButtons is false, render the default action buttons.
          <div data-cy="modal-container-action-buttons" className="m-6">
            <div className="flex items-center justify-end gap-3">
              {actionAdditionalInfo}
              {hideCancelButton ? null : (
                <Button
                  type="button"
                  size={ButtonSizes.Large}
                  variant={ButtonVariants.Outline}
                  className="min-w-[100px]"
                  onClick={() => closeIfOnCloseExists(false)}
                >
                  {cancelText}
                </Button>
              )}
              <Button
                data-cy="modal-container-submit-button"
                type="submit"
                form={formId}
                size={ButtonSizes.Large}
                variant={actionVariant}
                className="min-w-[100px]"
                onClick={() => closeIfOnCloseExists(true)}
                disabled={disableSubmit}
                {...actionButtonProps}
              >
                {actionText}
              </Button>
            </div>
          </div>
        )}

        {!onClose ? null : (
          <Dialog.Close
            className="absolute right-[26px] top-[26px] text-text-primary"
            onClick={() => closeIfOnCloseExists(false)}
          >
            <Icon name="x-close" size={18} />
          </Dialog.Close>
        )}
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default ModalContainer;
