import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const MinusCircleIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="MinusCircle"
      >
        <path d="M12 21C7.03552 21 3 16.9645 3 12C3 7.03552 7.03552 3 12 3C16.9645 3 21 7.03552 21 12C21 16.9645 16.9645 21 12 21ZM12 4.78801C8.02442 4.78801 4.798 8.02442 4.798 11.99C4.798 15.9556 8.03441 19.192 12 19.192C15.9656 19.192 19.202 15.9556 19.202 11.99C19.202 8.02442 15.9656 4.78801 12 4.78801ZM15.2464 12.899H8.7636C8.26415 12.899 7.86459 12.4994 7.86459 12C7.86459 11.5006 8.26415 11.101 8.7636 11.101H15.2464C15.7458 11.101 16.1454 11.5006 16.1454 12C16.1454 12.4994 15.7458 12.899 15.2464 12.899Z" />
      </svg>
    );
  },
);

MinusCircleIcon.displayName = 'MinusCircleIcon';
