import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Link01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Link01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.71573C14.2876 1.42809 17.9966 1.42809 20.2843 3.71573C22.5719 6.00337 22.5719 9.71236 20.2843 12L18.9036 13.3807C18.5223 13.762 17.9041 13.762 17.5228 13.3807C17.1416 12.9994 17.1416 12.3813 17.5228 12L18.9036 10.6193C20.4286 9.09419 20.4286 6.62153 18.9036 5.09644C17.3785 3.57135 14.9058 3.57135 13.3807 5.09644L12 6.47715C11.6187 6.85843 11.0006 6.85843 10.6193 6.47715C10.238 6.09588 10.238 5.47771 10.6193 5.09644L12 3.71573ZM16.1074 7.89254C16.4887 8.27381 16.4887 8.89197 16.1074 9.27325L9.27327 16.1074C8.89199 16.4887 8.27383 16.4887 7.89256 16.1074C7.51128 15.7261 7.51128 15.108 7.89256 14.7267L14.7267 7.89254C15.108 7.51126 15.7262 7.51126 16.1074 7.89254ZM6.47715 10.6193C6.85843 11.0006 6.85843 11.6187 6.47715 12L5.09644 13.3807C3.57135 14.9058 3.57135 17.3785 5.09644 18.9036C6.62153 20.4287 9.0942 20.4287 10.6193 18.9036L12 17.5228C12.3813 17.1416 12.9994 17.1416 13.3807 17.5228C13.762 17.9041 13.762 18.5223 13.3807 18.9036L12 20.2843C9.71236 22.5719 6.00337 22.5719 3.71573 20.2843C1.42809 17.9966 1.42809 14.2876 3.71573 12L5.09644 10.6193C5.47771 10.238 6.09588 10.238 6.47715 10.6193Z"
        />
      </svg>
    );
  },
);

Link01Icon.displayName = 'Link01Icon';
