/* eslint-disable @typescript-eslint/naming-convention */
export const GCP_SUPPORTED_SERVICES = {
  compute: 'Compute',
  logging: 'Logging',
  storage: 'Storage',
  bigQuery: 'BigQuery',
  gke: 'GKE',
  sql: 'SQL',
  memorystore: 'Memorystore',
} as const;

export const GCP_SUPPORTED_TYPES = {
  LoggingBucket: 'LoggingBucket',
  BigQueryDataset: 'BigQueryDataset',
  StorageBucket: 'StorageBucket',
  ComputeDisk: 'ComputeDisk',
  ComputeInstance: 'ComputeInstance',
  ComputeInstanceGroup: 'ComputeInstanceGroup',
  ContainerCluster: 'ContainerCluster',
  SqlInstance: 'SqlInstance',
  ComputeSnapshot: 'ComputeSnapshot',
  MemoryStoreRedis: 'MemoryStoreRedis',
} as const;

export const GCP_RESOURCE_TYPE_NAMES = {
  GCPBigQueryDataset: 'BigQuery Dataset',
  GCPComputeDisk: 'Compute Disk',
  GCPComputeInstance: 'Compute Instance',
  GCPComputeInstanceGroup: 'Compute Managed Instance Group',
  GCPStorageBucket: 'Storage Bucket',
  GCPContainerCluster: 'Container Cluster',
  GCPLoggingBucket: 'Logging Bucket',
  GCPSqlInstance: 'SQL Instance',
  GCPComputeSnapshot: 'Compute Snapshot',
  GCPMemoryStoreRedis: 'Memorystore Redis',
} as const;

export const URL_SECTION_DISK_TYPES = '/diskTypes/';
export const URL_SECTION_MACHINE_TYPES = '/machineTypes/';
