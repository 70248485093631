import { ReactNode } from 'react';

import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';

import { STEPPER_TRANSITION_ANIMATION } from '../constants';
import { StepCompleteIcon } from './StepCompleteIcon';
import { StepStyles } from './types';

const StepLine = () => (
  <div
    className={`absolute top-[23px] h-[calc(100%_-_14px)] w-[2px] bg-untitled-gray-100`}
  />
);

const IndicatorBullet = ({
  isCurrent = false,
  children,
}: {
  isCurrent?: boolean;
  children: ReactNode;
}) => (
  <motion.div
    className={`flex h-5 w-5 items-center justify-center rounded-[50%] text-xs font-bold`}
    layout
    animate={{
      background: isCurrent ? '#0216C9' : '#F1F2F4',
      color: isCurrent ? '#FFFFFF' : '#B3B9C4',
    }}
    initial={false}
    transition={STEPPER_TRANSITION_ANIMATION}
  >
    {children}
  </motion.div>
);

const classForStyle = cva('', {
  variants: {
    style: {
      [StepStyles.Compact]: 'top-4',
      [StepStyles.Wide]: 'top-[28px]',
    },
  },
});

export type StepIndicatorProps = {
  stepNumber: number;
  style?: StepStyles;
  isCurrent?: boolean;
  isCompleted?: boolean;
  isLast?: boolean;
};

export const StepIndicator = ({
  stepNumber,
  isCurrent,
  isCompleted,
  isLast,
  style = StepStyles.Compact,
}: StepIndicatorProps) => (
  <motion.div
    layout
    className={`absolute ${classForStyle({
      style,
    })} left-[-34px] flex h-full justify-center`}
  >
    {!isLast && <StepLine />}
    {isCompleted ? (
      <StepCompleteIcon width={20} height={20} />
    ) : (
      <IndicatorBullet isCurrent={isCurrent}>{stepNumber}</IndicatorBullet>
    )}
  </motion.div>
);
