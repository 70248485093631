import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const ClockSnoozeIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="ClockSnooze"
      >
        <path d="M20.6364 22H16.0909C15.7273 22 15.3939 21.7778 15.2525 21.4343C15.1111 21.0909 15.1919 20.7071 15.4444 20.4444L18.4343 17.4545H16.0808C15.5758 17.4545 15.1717 17.0505 15.1717 16.5455C15.1717 16.0404 15.5758 15.6364 16.0808 15.6364H20.6263C20.9899 15.6364 21.3232 15.8586 21.4646 16.202C21.6061 16.5455 21.5253 16.9293 21.2727 17.1919L18.2828 20.1818H20.6364C21.1414 20.1818 21.5455 20.5859 21.5455 21.0909C21.5455 21.596 21.1414 22 20.6364 22ZM12 22C6.48485 22 2 17.5152 2 12C2 6.48485 6.48485 2 12 2C17.5152 2 22 6.48485 22 12C22 12.3333 21.9798 12.6768 21.9495 13C21.899 13.4949 21.4545 13.8687 20.9495 13.8081C20.4545 13.7576 20.0909 13.3131 20.1414 12.8081C20.1717 12.5354 20.1818 12.2626 20.1818 11.9899C20.1818 7.47475 16.5152 3.80808 12 3.80808C7.48485 3.80808 3.81818 7.48485 3.81818 12C3.81818 16.5152 7.48485 20.1818 12 20.1818C12.1414 20.1818 12.2727 20.1818 12.404 20.1818C12.5455 20.1818 12.6869 20.1616 12.8182 20.1515C13.3232 20.101 13.7677 20.4646 13.8081 20.9697C13.8485 21.4747 13.4949 21.9091 12.9899 21.9596C12.8283 21.9798 12.6566 21.9899 12.4848 22C12.3232 22 12.1616 22.0101 11.9899 22.0101L12 22ZM15.3939 14.6061C15.2626 14.6061 15.1212 14.5758 14.9899 14.5051L11.596 12.8081C11.2828 12.6566 11.0909 12.3434 11.0909 11.9899V6.53535C11.0909 6.0303 11.4949 5.62626 12 5.62626C12.5051 5.62626 12.9091 6.0303 12.9091 6.53535V11.4242L15.8081 12.8687C16.2525 13.0909 16.4343 13.6364 16.2121 14.0909C16.0505 14.4141 15.7273 14.596 15.3939 14.596V14.6061Z" />
      </svg>
    );
  },
);

ClockSnoozeIcon.displayName = 'ClockSnoozeIcon';
