import { ApolloError, ServerError } from '@apollo/client';
import { z } from 'zod';

import Sentry from 'services/sentry';

import {
  PF_ERRORS_SKIP_REPORTING,
  PF_ERROR_CODES,
  PfErrorCode,
} from './constants';

const PfErrorSchema = z.object({
  code: z.nativeEnum(PF_ERROR_CODES),
  message: z.string(),
  path: z.string().optional(),
  inputPath: z.string().optional(),
});

type PfErrorType = z.infer<typeof PfErrorSchema>;

const errToString = (err: unknown) => {
  try {
    return JSON.stringify(err);
  } catch {
    return 'stringify failed';
  }
};

export function parseApolloError(err: ApolloError) {
  const errName = err?.name || '-';
  const networkError = err?.networkError?.message || '-';

  const gqlErrors = (() => {
    try {
      if (typeof err.graphQLErrors !== 'object') {
        return '-';
      }

      const graphQLErrors = err.graphQLErrors;
      return graphQLErrors.map((gqlErr) => gqlErr.message).join(', ');
    } catch {
      return '-';
    }
  })();

  const errStr = errToString(err);

  const extractPfErrors = (): PfErrorType[] => {
    return err.graphQLErrors
      .map((gqlError) => {
        const pfError = gqlError.extensions?.['pf-error'];
        if (pfError) {
          const validationResult = PfErrorSchema.safeParse(pfError);
          if (validationResult.success) {
            return validationResult.data;
          } else {
            Sentry.captureException(new Error('Invalid pf-error structure'), {
              extra: {
                gqlError,
                issues: validationResult.error.issues,
              },
            });
          }
        }
        return null;
      })
      .filter((pfError): pfError is PfErrorType => !!pfError);
  };

  const pfErrors = extractPfErrors();

  const pfErrorMessage =
    pfErrors.map((pfError) => pfError.message).join(', ') || err.message;
  const errMsg = pfErrorMessage || err?.message || '-';

  return {
    errMsg,
    errName,
    gqlErrors,
    errStr,
    networkError,
    pfErrors,
    pfErrorMessage,
  };
}

type OnApolloErrorArgs = {
  error: ApolloError;
  sentryMessage: string;
  additionalData?: Record<string, unknown>;
  // Skip reporting errors to Sentry with these codes
  skipReortingErrors?: PfErrorCode[];
};

export const onApolloError = ({
  error,
  sentryMessage,
  additionalData,
  skipReortingErrors = PF_ERRORS_SKIP_REPORTING,
}: OnApolloErrorArgs) => {
  const { errMsg, errName, gqlErrors, errStr, networkError, pfErrors } =
    parseApolloError(error);

  const shouldSkipReporting = pfErrors.some((pfError) =>
    skipReortingErrors.includes(pfError.code),
  );

  if (shouldSkipReporting) {
    return;
  }

  const extra = {
    sentryMessage: `${sentryMessage} - ${errMsg}`,
    pfErrors,
    errMsg,
    errName,
    gqlErrors,
    errStr,
    networkError,
    ...additionalData,
  };

  Sentry.captureException(new Error(sentryMessage), {
    extra,
  });
};

export const interceptSessionExpiryErrors = (error: ApolloError) => {
  if (
    (error.networkError as ServerError)?.statusCode === 401 &&
    import.meta.env.MODE !== 'development'
  ) {
    window.location.href = `/login?redirectTo=${
      window.location.pathname + window.location.search
    }`;
  }
};
