import { NoUserIcon, User1Icon } from '@pointfive/assets';
import { ToolTip, ToolTipVariants } from '@pointfive/ui';

import { NO_USER_FOUND } from './constants';

export type NameButtonProps = {
  name: string;
  isOpen: boolean;
  onClick?: () => void;
};

export const NameButton = ({ name, isOpen, onClick }: NameButtonProps) => {
  const userFound = name !== NO_USER_FOUND;

  const Icon = userFound ? User1Icon : NoUserIcon;
  return (
    <ToolTip
      showCaret={true}
      message={name}
      side="bottom"
      variant={ToolTipVariants.TOP_NAV}
    >
      <div
        className={`border-[4px] ${
          userFound
            ? `cursor-pointer rounded-full transition-[padding] duration-200 hover:border-ds-navigation-top-bg-hover ${isOpen ? 'border-ds-navigation-top-bg-avatar-selected' : 'border-transparent'}`
            : 'pointer-events-none border-transparent'
        }`}
        onClick={onClick}
      >
        <div
          data-cy="name-initials-logout-button"
          id="name-initials-rounded"
          className={`text-body-1-accented flex h-7 w-7 items-center justify-center rounded-full bg-ds-navigation-icon-active uppercase`}
        >
          <Icon size="sm" color="white" />
          {/* className="text-white"  */}
        </div>
      </div>
    </ToolTip>
  );
};
