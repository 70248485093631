import { CSSProperties } from 'react';

import { ToolTipAlignment } from './types';

export enum ToolTipVariants {
  BLACK = 'black',
  WHITE = 'white',
  TOP_NAV = 'top-nav',
  SIDE_NAV = 'side-nav',
  WARNING = 'warning',
}

export const TOOLTIP_VARIANT_CLASSNAMES: Record<
  ToolTipVariants,
  {
    container: string;
    body: string;
    arrow?: string;
    arrowStyle?: CSSProperties;
    align: ToolTipAlignment;
  }
> = {
  [ToolTipVariants.BLACK]: {
    container: 'bg-neutral-900',
    body: 'text-white border-gray-900 p-3',
    arrow: 'fill-gray-900',
    align: 'center',
  },
  [ToolTipVariants.WHITE]: {
    container: 'shadow-lg bg-white',
    body: 'text-black border-white p-4',
    arrow: 'fill-white w-5 h-[10px]',
    arrowStyle: {
      filter: 'drop-shadow(rgb(9, 30, 66) 0px -12px 8px)',
      clipPath: 'inset(0 -10px -10px -10px)',
    },
    align: 'start',
  },
  [ToolTipVariants.TOP_NAV]: {
    container: 'bg-ds-tooltip-bg-default',
    body: 'text-white text-sm font-bold border-0 px-3 py-2 leading-5',
    arrow: 'fill-ds-tooltip-bg-default',
    align: 'center',
  },
  [ToolTipVariants.SIDE_NAV]: {
    container: 'shadow-lg bg-white',
    body: 'text-black text-sm font-bold border-0 px-3 py-2 leading-5',
    arrow: 'fill-white w-3 h-[6px]',
    arrowStyle: {
      filter: 'drop-shadow(rgb(9, 30, 66) 0px -12px 8px)',
      clipPath: 'inset(0 -10px -10px -10px)',
    },
    align: 'center',
  },
  [ToolTipVariants.WARNING]: {
    container: 'shadow-lg bg-[#FEC84B] rounded',
    body: 'text-black px-3 py-2 border-none',
    arrow: 'fill-[#FEC84B] w-3',
    align: 'center',
  },
};
