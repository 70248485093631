import { ReactNode, useCallback } from 'react';

import { UserRole, useAuth } from 'services/auth';

export const useRoleBaseAccessControl = ({
  allowedRoles,
}: {
  allowedRoles?: UserRole[];
}) => {
  const user = useAuth.use.user();

  const getCanAccess = useCallback(
    (roles?: UserRole[]) => {
      if (user && roles) {
        return roles.includes(user.role);
      }
      return false;
    },
    [user],
  );

  const canAccess = getCanAccess(allowedRoles);

  return {
    canAccess,
    getCanAccess,
  };
};

type RoleBasedAccessControlProps = {
  children: ReactNode;
  allowedRoles: UserRole[];
  renderOnAccessDenied?: ReactNode;
};

export const RoleBasedAccessControl = ({
  children,
  allowedRoles,
  renderOnAccessDenied = null,
}: RoleBasedAccessControlProps) => {
  const { canAccess } = useRoleBaseAccessControl({ allowedRoles });

  if (!canAccess) {
    return <>{renderOnAccessDenied}</>;
  }

  return children;
};
