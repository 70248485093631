import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Mail01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Mail01"
      >
        <path d="M7.24823 19.9372C5.58663 19.9372 4.74569 19.9372 3.97568 19.5522C3.29686 19.2178 2.74975 18.6809 2.39514 18.0122C2 17.2523 2 16.381 2 14.8105V9.13678C2 8.57953 2 8.11347 2.02026 7.70821C1.98987 7.55623 2 7.40426 2.05066 7.25228C2.10132 6.72543 2.20263 6.31003 2.40527 5.92503C2.74975 5.25633 3.29686 4.72948 3.98582 4.38501C4.75583 4 5.58663 4 7.25836 4H16.7416C18.4032 4 19.2442 4 20.0142 4.38501C20.693 4.71935 21.2401 5.25633 21.5947 5.92503C21.7974 6.31003 21.8987 6.72543 21.9493 7.25228C22 7.40426 22.0101 7.55623 21.9797 7.70821C22 8.11347 22 8.57953 22 9.13678V14.8105C22 16.3911 22 17.2624 21.6049 18.0122C21.2604 18.6809 20.7133 19.2077 20.0243 19.5522C19.2543 19.9372 18.4235 19.9372 16.7518 19.9372H7.24823ZM3.73252 9.1773V14.8105C3.73252 16.1175 3.73252 16.8267 3.93516 17.2118C4.11753 17.5562 4.39108 17.8298 4.74569 18.002C5.15096 18.2047 5.89058 18.2047 7.24823 18.2047H16.7315C18.079 18.2047 18.8288 18.2047 19.234 18.002C19.5887 17.8298 19.8723 17.5562 20.0446 17.2118C20.2472 16.8267 20.2472 16.1175 20.2472 14.8105V9.1773L14.1581 13.3212C13.4894 13.7771 13.1246 14.0304 12.6383 14.1418C12.2128 14.2432 11.767 14.2432 11.3516 14.1418C10.8754 14.0304 10.5106 13.7771 9.84194 13.3313L3.74265 9.1773H3.73252ZM3.81358 7.1307L10.8045 11.8926C11.2908 12.2269 11.5846 12.4195 11.7568 12.46C11.9088 12.5005 12.0709 12.5005 12.233 12.46C12.4053 12.4195 12.6991 12.2269 13.1753 11.8926L20.1763 7.1307C20.1459 6.96859 20.1054 6.83688 20.0547 6.73556C19.8723 6.39108 19.5988 6.12766 19.2442 5.94529C18.8389 5.74265 18.0993 5.74265 16.7416 5.74265H7.24823C5.90071 5.74265 5.15096 5.74265 4.74569 5.94529C4.39108 6.11753 4.1074 6.39108 3.93516 6.73556C3.8845 6.83688 3.84397 6.96859 3.81358 7.1307Z" />
      </svg>
    );
  },
);

Mail01Icon.displayName = 'Mail01Icon';
