import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Commitments01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Commitments01"
      >
        <path d="M9.4 3.9001H14.55C15.05 3.9001 15.45 3.5001 15.45 3.0001C15.45 2.5001 15.05 2.1001 14.55 2.1001H9.4C8.9 2.1001 8.5 2.5001 8.5 3.0001C8.5 3.5001 8.9 3.9001 9.4 3.9001Z" />
        <path d="M11.4005 17.0399C11.4037 17.0399 11.407 17.0399 11.4102 17.0399H11.3902C11.3937 17.0399 11.3971 17.0399 11.4005 17.0399Z" />
        <path d="M10.7702 16.7799C10.9379 16.9378 11.164 17.0372 11.4005 17.0399C11.6368 17.0374 11.8627 16.9475 12.0302 16.7799L15.9002 12.9099C16.2502 12.5599 16.2502 11.9899 15.9002 11.6399C15.5502 11.2899 14.9802 11.2899 14.6302 11.6399L11.4002 14.8699L10.3202 13.7899C9.97023 13.4399 9.40023 13.4399 9.05023 13.7899C8.70023 14.1399 8.70023 14.7099 9.05023 15.0599L10.7702 16.7799Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.52023 22.1999H15.0202C16.5502 22.1999 17.4002 22.1999 18.1402 21.8199C18.8002 21.4799 19.3202 20.9599 19.6602 20.2999C20.0402 19.5599 20.0402 18.7199 20.0402 17.1799V9.27994C20.0402 8.39994 20.0402 7.95994 19.9202 7.51994C19.6002 6.31994 18.6602 5.37994 17.4602 5.05994C17.0203 4.93994 16.5803 4.93994 15.7003 4.93994H8.83023C7.9503 4.93994 7.51022 4.93994 7.07023 5.05994C5.87023 5.37994 4.93023 6.31994 4.61023 7.51994C4.49023 7.95994 4.49023 8.39994 4.49023 9.27994V17.1799C4.49023 18.7099 4.49023 19.5599 4.87023 20.2999C5.21023 20.9599 5.73023 21.4799 6.39023 21.8199C7.14023 22.1999 7.98025 22.1999 9.52023 22.1999ZM8.84023 6.72994L8.83023 6.73994H15.7002C16.3902 6.73994 16.7802 6.73994 16.9902 6.79994C17.5702 6.94994 18.0302 7.39994 18.1802 7.97994C18.2402 8.18994 18.2402 8.56992 18.2402 9.2699V17.1699C18.2402 18.4299 18.2402 19.1199 18.0602 19.4799C17.9002 19.7899 17.6502 20.0499 17.3302 20.2099C16.9702 20.3899 16.2802 20.3899 15.0202 20.3899H9.52023C8.26023 20.3899 7.57023 20.3899 7.21023 20.2099C6.90023 20.0499 6.64023 19.7999 6.48023 19.4799C6.30023 19.1199 6.30023 18.4299 6.30023 17.1699V9.26994C6.30023 8.57994 6.30023 8.18994 6.36023 7.97994C6.52023 7.39994 6.97023 6.93994 7.55023 6.78994C7.76023 6.72994 8.14029 6.72994 8.84023 6.72994Z"
        />
      </svg>
    );
  },
);

Commitments01Icon.displayName = 'Commitments01Icon';
