import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as StatefulsetStsColorIcon } from './variant-svgs/statefulset-sts-color.svg';
import { ReactComponent as StatefulsetStsDisabledIcon } from './variant-svgs/statefulset-sts-disabled.svg';
import { ReactComponent as StatefulsetStsBlackIcon } from './variant-svgs/statefulset-sts-regular.svg';

const VARIANT_ICON_MAP = {
  default: StatefulsetStsColorIcon,
  black: StatefulsetStsBlackIcon,
  disabled: StatefulsetStsDisabledIcon,
};

export const StatefulsetStsIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="statefulset-sts-icon"
      />
    );
  },
);

StatefulsetStsIcon.displayName = 'StatefulsetStsIcon';
