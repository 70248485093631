import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const LoadingSkeletonImageIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="LoadingSkeletonImage"
      >
        <path d="M8.25708 20.5161H3.87109V11.6015C3.87109 11.3877 4.0444 11.2144 4.25819 11.2144H7.86999C8.08377 11.2144 8.25708 11.3877 8.25708 11.6015V20.5161Z" />
        <path d="M14.1931 20.5161H9.43597V7.3284C9.43597 7.11461 9.60928 6.9413 9.82306 6.9413H13.806C14.0198 6.9413 14.1931 7.11461 14.1931 7.3284V20.5161Z" />
        <path d="M20.1292 20.5161H15.4739V3.87098C15.4739 3.65719 15.6472 3.48389 15.861 3.48389H19.7421C19.9558 3.48389 20.1292 3.6572 20.1292 3.87098V20.5161Z" />
      </svg>
    );
  },
);

LoadingSkeletonImageIcon.displayName = 'LoadingSkeletonImageIcon';
