import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const CheckIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Check"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.4288 6.35124C20.6537 6.57621 20.78 6.88128 20.78 7.19939C20.78 7.51749 20.6537 7.82257 20.4288 8.04753L10.8316 17.6447C10.6067 17.8696 10.3016 17.9959 9.98347 17.9959C9.66537 17.9959 9.36029 17.8696 9.13533 17.6447L4.33675 12.8461C4.11823 12.6198 3.99731 12.3168 4.00005 12.0023C4.00278 11.6877 4.12894 11.3868 4.35137 11.1644C4.57379 10.942 4.87468 10.8158 5.18922 10.8131C5.50376 10.8104 5.80679 10.9313 6.03305 11.1498L9.98347 15.1002L18.7325 6.35124C18.9574 6.12634 19.2625 6 19.5806 6C19.8987 6 20.2038 6.12634 20.4288 6.35124Z"
        />
      </svg>
    );
  },
);

CheckIcon.displayName = 'CheckIcon';
