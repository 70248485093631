import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as K8sKubernetesColorIcon } from './variant-svgs/k8s-kubernetes-color.svg';
import { ReactComponent as K8sKubernetesDisabledIcon } from './variant-svgs/k8s-kubernetes-disabled.svg';
import { ReactComponent as K8sKubernetesBlackIcon } from './variant-svgs/k8s-kubernetes-regular.svg';

const VARIANT_ICON_MAP = {
  default: K8sKubernetesColorIcon,
  black: K8sKubernetesBlackIcon,
  disabled: K8sKubernetesDisabledIcon,
};

export const K8sKubernetesIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="k8s-kubernetes-icon"
      />
    );
  },
);

K8sKubernetesIcon.displayName = 'K8sKubernetesIcon';
