import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { WIDE_SCREEN_MIN_WIDTH } from 'pages/constants';

type SideNavState = {
  collapsed: boolean;
  toggleCollapsed: () => void;
};

export const useSideNav = create(
  persist<SideNavState>(
    (set, get) => ({
      collapsed: window.innerWidth < WIDE_SCREEN_MIN_WIDTH,
      toggleCollapsed: () => set({ collapsed: !get().collapsed }),
    }),
    {
      name: 'sidenav-state',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
