import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const NoUserIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="NoUser"
      >
        <path d="M7.59764 5.279C6.79616 6.17853 6.30914 7.36436 6.30914 8.66395C6.30914 11.4752 8.58812 13.7542 11.3994 13.7542C12.6155 13.7542 13.7321 13.3277 14.6076 12.6161L13.3224 11.2709C12.7846 11.6683 12.1194 11.9032 11.3994 11.9032C9.61039 11.9032 8.16014 10.4529 8.16014 8.66395C8.16014 7.89088 8.43094 7.18107 8.88287 6.6242L7.59764 5.279Z" />
        <path d="M14.3705 9.9564C14.543 9.56047 14.6386 9.12338 14.6386 8.66395C14.6386 6.87496 13.1884 5.4247 11.3994 5.4247C10.9922 5.4247 10.6026 5.49982 10.2437 5.63696L8.89873 4.22927C9.6373 3.8119 10.4905 3.57371 11.3994 3.57371C14.2106 3.57371 16.4896 5.85269 16.4896 8.66395C16.4896 9.65578 16.2059 10.5814 15.7153 11.364L14.3705 9.9564Z" />
        <path d="M17.9834 16.1495L18.8495 17.056C19.1468 17.4665 19.3784 17.9283 19.5296 18.4266C19.7296 19.0859 19.7293 19.8615 19.7289 20.9974L19.7289 21.1582C19.7289 21.6693 19.3145 22.0837 18.8034 22.0837C18.2922 22.0837 17.8779 21.6693 17.8779 21.1582C17.8779 19.7992 17.8678 19.3249 17.7583 18.9639C17.4891 18.0766 16.7947 17.3822 15.9073 17.113C15.5463 17.0035 15.072 16.9934 13.7131 16.9934H9.08564C7.72671 16.9934 7.25242 17.0035 6.89141 17.113C6.00404 17.3822 5.30963 18.0766 5.04045 18.9639C4.93094 19.3249 4.9209 19.7992 4.9209 21.1582C4.9209 21.6693 4.50654 22.0837 3.9954 22.0837C3.48426 22.0837 3.0699 21.6693 3.0699 21.1582L3.06986 20.9974C3.06944 19.8615 3.06915 19.0859 3.26916 18.4266C3.7178 16.9477 4.87515 15.7903 6.3541 15.3417C7.01342 15.1417 7.78901 15.142 8.92484 15.1424H13.8739C15.0098 15.142 15.7853 15.1417 16.4447 15.3417C17.0122 15.5139 17.5325 15.7904 17.9834 16.1495Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69041 2.23291C4.0218 1.91385 4.5491 1.92384 4.86816 2.25523L21.5137 19.5438C21.8328 19.8752 21.8228 20.4025 21.4914 20.7216C21.16 21.0406 20.6327 21.0307 20.3136 20.6993L3.66809 3.41067C3.34902 3.07928 3.35902 2.55198 3.69041 2.23291Z"
        />
      </svg>
    );
  },
);

NoUserIcon.displayName = 'NoUserIcon';
