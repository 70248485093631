import { useMatches } from 'react-router-dom';

export const useRouteTitle = (): string => {
  const matches = useMatches();

  // We only care about the first match for now
  const [title] = matches.reduce((acc, match) => {
    const title = (match.handle as { title?: string })?.title;

    if (title) {
      acc.push(title);
    }

    return acc;
  }, [] as string[]);

  return title;
};
