import { forwardRef } from 'react';

import { ToolTip, ToolTipVariants } from '@pointfive/ui';

import { formatNumber } from 'utils/numbers';
import { formatPlural } from 'utils/strings';

import {
  BaseDropdownContentFooter,
  BaseDropdownContentFooterProps,
} from '../../components/Base';

export type MultiSelectFooterProps = BaseDropdownContentFooterProps & {
  optionsCount?: number;
  selectedCount?: number;
  showLimitWarning?: boolean;
};

const Counter = forwardRef<
  HTMLDivElement,
  {
    selectedCount: string;
    optionsCount: string;
  }
>(({ selectedCount, optionsCount }, ref) => {
  return (
    <div ref={ref} className="text-body-2 text-text-tertiary">
      {selectedCount} of {optionsCount}
    </div>
  );
});
Counter.displayName = 'Counter';

// TODO: add a proper loading state
export const MultiSelectDropdownContentFooter = ({
  onApplyClick,
  isApplyDisabled,
  applyDisabledMessage,
  optionsCount = 0,
  selectedCount = 0,
  showLimitWarning = false,
}: MultiSelectFooterProps) => {
  const formattedSelectedCount = formatNumber({
    original: selectedCount,
    shorten: true,
  });

  const formattedOptionsCount = formatNumber({
    original: optionsCount,
    shorten: true,
  });

  return (
    <BaseDropdownContentFooter
      onApplyClick={onApplyClick}
      isApplyDisabled={isApplyDisabled}
      applyDisabledMessage={applyDisabledMessage}
      leftContent={
        !showLimitWarning ? (
          <Counter
            selectedCount={formattedSelectedCount}
            optionsCount={formattedOptionsCount}
          />
        ) : (
          <ToolTip
            message={`You have reached the selection limit of ${formatPlural(optionsCount, 'item', undefined, formattedOptionsCount)}.`}
            tooltipDuration={5000}
            variant={ToolTipVariants.WARNING}
            side="top"
          >
            <Counter
              selectedCount={formattedSelectedCount}
              optionsCount={formattedOptionsCount}
            />
          </ToolTip>
        )
      }
    />
  );
};
