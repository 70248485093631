import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as DatadogColorIcon } from './variant-svgs/datadog-color.svg';
import { ReactComponent as DatadogDisabledIcon } from './variant-svgs/datadog-disabled.svg';
import { ReactComponent as DatadogBlackIcon } from './variant-svgs/datadog-regular.svg';

const VARIANT_ICON_MAP = {
  default: DatadogColorIcon,
  black: DatadogBlackIcon,
  disabled: DatadogDisabledIcon,
};

export const DatadogIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="datadog-icon"
      />
    );
  },
);

DatadogIcon.displayName = 'DatadogIcon';
