import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Cloud01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Cloud01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 6.75C9.81582 6.75 8.00164 8.34246 7.65852 10.4297C7.58471 10.8787 7.21707 11.2212 6.76398 11.2632C5.21413 11.4067 4 12.7121 4 14.3C4 15.9845 5.36553 17.35 7.05 17.35H16.95C18.6345 17.35 20 15.9845 20 14.3C20 12.7121 18.7859 11.4067 17.2361 11.2632C16.783 11.2212 16.4153 10.8787 16.3415 10.4297C15.9984 8.34246 14.1842 6.75 12 6.75ZM5.84384 9.39515C6.60702 6.71355 9.07338 4.75 12 4.75C14.9267 4.75 17.393 6.71355 18.1562 9.39515C20.3632 9.93619 22 11.9264 22 14.3C22 17.0891 19.7391 19.35 16.95 19.35H7.05C4.26097 19.35 2 17.0891 2 14.3C2 11.9264 3.63686 9.93619 5.84384 9.39515Z"
        />
      </svg>
    );
  },
);

Cloud01Icon.displayName = 'Cloud01Icon';
