import { ReactNode } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import { ButtonVariants } from '../Button';
import { Icon } from '../Icon';
import ModalContainer, { ModalContainerProps } from './ModalContainer';

export enum ConfirmationModalVariants {
  confirmation = 'confirmation',
  destructive = 'destructive',
}

const variantStyles = {
  [ConfirmationModalVariants.confirmation]: {
    titleIconColor: 'text-text-warning-subtle',
    actionVariant: ButtonVariants.Primary,
  },
  [ConfirmationModalVariants.destructive]: {
    titleIconColor: 'text-text-error-subtle',
    actionVariant: ButtonVariants.Distractive,
  },
};

export type ConfirmationModalProps = {
  variant: ConfirmationModalVariants;
  title: string;
  titleIcon?: string;
  children: ReactNode;
  iconSize?: number;
  iconClassName?: string;
} & Omit<ModalContainerProps, 'actionVariant'>;

export const ConfirmationModal = ({
  variant,
  title,
  titleIcon = 'alert-triangle',
  children,
  iconClassName,
  iconSize,
  ...rest
}: ConfirmationModalProps): JSX.Element => {
  const { titleIconColor, actionVariant } = variantStyles[variant];

  return (
    <ModalContainer actionVariant={actionVariant} {...rest}>
      <div className="flex gap-4 px-6">
        <Icon
          className={`${titleIconColor} ${iconClassName ?? ''}`}
          name={titleIcon}
          size={iconSize ?? 30}
        />
        <div className="flex flex-col gap-1">
          <Dialog.Title className="text-subtitle-1-accented text-text-primary">
            {title}
          </Dialog.Title>
          <div className="text-body-2 text-text-secondary">{children}</div>
        </div>
      </div>
    </ModalContainer>
  );
};
