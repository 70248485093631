import { useCallback, useState } from 'react';

export type UseHorizontalReturnType = {
  ref: (node: HTMLDivElement) => void;
  isHorizontalOverflow: boolean;
};

// TODO: Use this custom hook in all related places that currently implement an overflow check on their own.
// https://linear.app/pointfive/issue/PF-209/use-the-new-horizontal-hook-and-replace-all-other-places-that-try-to
export const useIsHorizontalOverflow = (): UseHorizontalReturnType => {
  const [isHorizontalOverflow, setIsHorizontalOverflow] = useState(false);

  // useCallback is being used as a ref instead of a useRef and useEffect combination as in the useRef/useEffect
  // scenario, sometimes the "is there a horizontal overflow" value would be initialized after the component
  // is already done rendering, and there would be nothing that would cause the component to rerender because of
  // useRef's behavior. (or useEffect's behavior incase the useRef would be initialized after the useEffect
  // already got triggered )
  const ref = useCallback((node: HTMLDivElement) => {
    if (!node || !(node instanceof HTMLDivElement)) {
      return;
    }
    const { offsetHeight, scrollHeight, offsetWidth, scrollWidth } = node;

    const isHorizontalOverflow = offsetWidth < scrollWidth;

    // we check for vertical overflow as well for the case of using line-clamp
    const isVerticalOverflow = offsetHeight < scrollHeight;

    setIsHorizontalOverflow(isHorizontalOverflow || isVerticalOverflow);
  }, []);

  return {
    ref,
    isHorizontalOverflow,
  };
};
