import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const AddUserIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="AddUser"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 3.99999C9.06706 3.99999 7.50006 5.56699 7.50006 7.49998C7.50006 9.43297 9.06706 11 11 11C12.933 11 14.5 9.43297 14.5 7.49998C14.5 5.56699 12.933 3.99999 11 3.99999ZM5.50007 7.49998C5.50007 4.46242 7.96249 2 11 2C14.0376 2 16.5 4.46242 16.5 7.49998C16.5 10.5375 14.0376 13 11 13C7.96249 13 5.50007 10.5375 5.50007 7.49998ZM20 14C20.5523 14 21 14.4477 21 15V16.9999H23C23.5523 16.9999 24 17.4477 24 17.9999C24 18.5522 23.5523 18.9999 23 18.9999H21V20.9999C21 21.5522 20.5523 21.9999 20 21.9999C19.4477 21.9999 19 21.5522 19 20.9999V18.9999H17C16.4477 18.9999 16 18.5522 16 17.9999C16 17.4477 16.4477 16.9999 17 16.9999H19V15C19 14.4477 19.4477 14 20 14ZM8.32632 14.4999C8.38339 14.4999 8.44129 14.5 8.50006 14.5H13C13.5523 14.5 14 14.9477 14 15.4999C14 16.0522 13.5523 16.4999 13 16.4999H8.50006C7.03175 16.4999 6.51928 16.5108 6.12921 16.6291C5.17041 16.92 4.4201 17.6703 4.12925 18.6291C4.01093 19.0191 4.00008 19.5316 4.00008 20.9999C4.00008 21.5522 3.55236 21.9999 3.00008 21.9999C2.4478 21.9999 2.00008 21.5522 2.00008 20.9999C2.00008 20.9412 2.00006 20.8833 2.00004 20.8262C1.99959 19.5989 1.99928 18.7609 2.21538 18.0485C2.70013 16.4505 3.95064 15.2 5.54864 14.7153C6.26104 14.4991 7.09906 14.4995 8.32632 14.4999Z"
        />
      </svg>
    );
  },
);

AddUserIcon.displayName = 'AddUserIcon';
