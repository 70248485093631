import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const UserEmailIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="UserEmail"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.0001 2C7.96252 2 5.50008 4.46243 5.50008 7.5C5.50008 10.5376 7.96252 13 11.0001 13C14.0376 13 16.5001 10.5376 16.5001 7.5C16.5001 4.46243 14.0376 2 11.0001 2ZM7.50008 7.5C7.50008 5.567 9.06709 4 11.0001 4C12.9331 4 14.5001 5.567 14.5001 7.5C14.5001 9.433 12.9331 11 11.0001 11C9.06709 11 7.50008 9.433 7.50008 7.5Z"
        />
        <path d="M8.32635 14.5C7.09908 14.4995 6.26106 14.4992 5.54866 14.7153C3.95065 15.2 2.70013 16.4506 2.21538 18.0486C1.99928 18.761 1.99959 19.599 2.00004 20.8263L2.00008 21C2.00008 21.5523 2.4478 22 3.00008 22C3.55237 22 4.00008 21.5523 4.00008 21C4.00008 19.5317 4.01094 19.0192 4.12926 18.6291C4.42011 17.6703 5.17042 16.92 6.12923 16.6292C6.51929 16.5109 7.03177 16.5 8.50009 16.5H12C12.5523 16.5 13 16.0523 13 15.5C13 14.9477 12.5523 14.5 12 14.5L8.32635 14.5Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.1883 13C19.5548 13 20.6991 13.4202 21.619 14.2607C22.541 15.1012 23 16.1191 23 17.3105C23 18.2509 22.718 19.0547 22.1562 19.7197C21.5943 20.3868 20.9275 20.7193 20.1558 20.7193C19.631 20.7193 19.2337 20.5194 18.9641 20.1175C18.606 20.5357 18.1759 20.7458 17.6717 20.7458C17.1078 20.7458 16.6262 20.5112 16.2249 20.0379C15.8236 19.5667 15.624 18.9445 15.624 18.1754C15.624 17.388 15.8483 16.7434 16.301 16.2436C16.7518 15.7438 17.2951 15.4929 17.929 15.4929C18.3282 15.4929 18.6966 15.6295 19.0321 15.9009C19.0321 15.9009 19.0584 15.7059 19.1164 15.5969C19.2651 15.3178 19.7374 15.3855 20.0738 15.4768C20.2904 15.5356 20.4146 15.7509 20.3871 15.9736L20.0508 18.6997C20.0282 18.9119 20.0158 19.0955 20.0158 19.2526C20.0158 19.4463 20.1064 19.5422 20.2875 19.5422C20.6229 19.5422 20.946 19.3341 21.2568 18.9139C21.5676 18.4957 21.724 17.9694 21.724 17.339C21.724 16.417 21.3844 15.652 20.7052 15.0441C20.0261 14.4361 19.1926 14.1322 18.2068 14.1322C17.1366 14.1322 16.2331 14.5035 15.5005 15.2419C14.7658 15.9825 14.3974 16.9045 14.3974 18.0102C14.3974 19.0282 14.7411 19.9155 15.4264 20.6703C16.1138 21.4272 17.0831 21.8046 18.3344 21.8046C19.1206 21.8046 19.9273 21.6291 20.7505 21.2783C21.0695 21.1436 21.4358 21.2905 21.5655 21.6087C21.691 21.9168 21.5408 22.2676 21.2321 22.3982C20.2854 22.8001 19.3202 23 18.3364 23C16.7044 23 15.4079 22.5084 14.4426 21.5251C13.4815 20.5418 13 19.3688 13 18.0041C13 16.5802 13.5062 15.3909 14.5167 14.4341C15.5272 13.4774 16.7518 13 18.1883 13ZM17.752 19.4219C18.0751 19.4219 18.3529 19.2648 18.5855 18.9547C18.818 18.6426 18.9353 18.2876 18.9374 17.8858C18.9374 17.5634 18.8427 17.3064 18.6534 17.1126C18.464 16.9188 18.2479 16.8229 18.0051 16.8229C17.7252 16.8229 17.4906 16.9535 17.3013 17.2105C17.1119 17.4676 17.0172 17.8062 17.0172 18.2264C17.0172 18.5671 17.0852 18.8507 17.221 19.0792C17.3548 19.3076 17.5318 19.4219 17.752 19.4219Z"
        />
      </svg>
    );
  },
);

UserEmailIcon.displayName = 'UserEmailIcon';
