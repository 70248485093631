import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as AmazonColorIcon } from './variant-svgs/amazon-color.svg';
import { ReactComponent as AmazonDisabledIcon } from './variant-svgs/amazon-disabled.svg';
import { ReactComponent as AmazonBlackIcon } from './variant-svgs/amazon-regular.svg';

const VARIANT_ICON_MAP = {
  default: AmazonColorIcon,
  black: AmazonBlackIcon,
  disabled: AmazonDisabledIcon,
};

export const AmazonIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="amazon-icon"
      />
    );
  },
);

AmazonIcon.displayName = 'AmazonIcon';
