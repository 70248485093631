import { ReactNode, createContext, useContext } from 'react';

type ColumnOrderContextProps = {
  columnOrder: string[];
  setColumnOrder: (columnOrder: string[]) => void;
  children: ReactNode;
};

type ColumnOrderContextType = {
  columnOrder: string[];
  setColumnOrder: (columnOrder: string[]) => void;
};

const ColumnOrderContext = createContext<ColumnOrderContextType>({
  columnOrder: [],
  setColumnOrder: () => {},
});

const ColumnOrderProvider = ({
  columnOrder,
  setColumnOrder,
  children,
}: ColumnOrderContextProps) => {
  return (
    <ColumnOrderContext.Provider value={{ columnOrder, setColumnOrder }}>
      {children}
    </ColumnOrderContext.Provider>
  );
};

const useColumnOrderContext = () => {
  return useContext(ColumnOrderContext);
};

export { ColumnOrderProvider, useColumnOrderContext };
