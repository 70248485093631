import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SparklingIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Sparkling"
      >
        <path d="M14.381 4.4375C15.663 4.4375 16.7024 3.34619 16.7024 2H17.7738C17.7738 3.34619 18.8131 4.4375 20.0952 4.4375V5.5625C18.8131 5.5625 17.7738 6.65381 17.7738 8H16.7024C16.7024 6.65381 15.663 5.5625 14.381 5.5625V4.4375ZM2 11C5.15591 11 7.71429 8.31371 7.71429 5H9.61905C9.61905 8.31371 12.1774 11 15.3333 11V13C12.1774 13 9.61905 15.6863 9.61905 19H7.71429C7.71429 15.6863 5.15591 13 2 13V11ZM17.4762 14C17.4762 15.7949 16.0904 17.25 14.381 17.25V18.75C16.0904 18.75 17.4762 20.2051 17.4762 22H18.9048C18.9048 20.2051 20.2906 18.75 22 18.75V17.25C20.2906 17.25 18.9048 15.7949 18.9048 14H17.4762Z" />
      </svg>
    );
  },
);

SparklingIcon.displayName = 'SparklingIcon';
