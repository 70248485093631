import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavDashboardIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavDashboard"
      >
        <path d="M18.1798 23.9252H5.80477C4.46977 23.9252 3.71977 23.9252 3.01477 23.5352C2.39977 23.1902 1.91976 22.6802 1.60476 22.0202C1.25976 21.3002 1.25977 20.5202 1.25977 19.1102V10.7702C1.25977 10.0352 1.25977 9.63019 1.37977 9.19519C1.48477 8.80519 1.64977 8.43019 1.87477 8.08519C2.12977 7.71019 2.45977 7.4402 2.98477 6.9902L10.2148 0.915195C10.6648 0.540195 10.9648 0.285195 11.3998 0.165195C11.7898 0.0451953 12.1948 0.0451953 12.5848 0.165195C13.0198 0.285195 13.3048 0.540195 13.7548 0.915195L20.9848 7.0052C21.5098 7.4402 21.8248 7.7102 22.0948 8.10019C22.3198 8.43019 22.4848 8.80519 22.5898 9.19519C22.7098 9.64519 22.7098 10.0502 22.7098 10.7702V19.1102C22.7098 20.5202 22.7098 21.3002 22.3648 22.0202C22.0498 22.6802 21.5698 23.1902 20.9548 23.5352C20.2498 23.9252 19.4998 23.9252 18.1648 23.9252H18.1798ZM11.9998 2.3702C11.9098 2.4302 11.8198 2.5202 11.6698 2.6402L4.43977 8.7302C4.06477 9.0452 3.83977 9.2402 3.74977 9.3752C3.67477 9.4952 3.61477 9.6302 3.56977 9.7652C3.52477 9.9302 3.52477 10.2302 3.52477 10.7852V19.1252C3.52477 20.1452 3.52477 20.8052 3.64477 21.0752C3.74977 21.2852 3.91476 21.4652 4.09476 21.5702C4.30476 21.6752 4.93477 21.6752 5.80477 21.6752H18.1798C19.0498 21.6752 19.6798 21.6752 19.8898 21.5702C20.0848 21.4652 20.2498 21.2852 20.3548 21.0752C20.4748 20.8052 20.4748 20.1452 20.4748 19.1252V10.7852C20.4748 10.2302 20.4748 9.9302 20.4298 9.7652C20.3998 9.6152 20.3398 9.4952 20.2498 9.3752C20.1598 9.2552 19.9648 9.0752 19.5598 8.7302L12.3298 2.6402C12.1948 2.5202 12.0898 2.4302 11.9998 2.3702ZM16.2598 19.3202H7.72476C7.10977 19.3202 6.59976 18.8102 6.59976 18.1952C6.59976 17.5802 7.10977 17.0702 7.72476 17.0702H16.2598C16.8748 17.0702 17.3848 17.5802 17.3848 18.1952C17.3848 18.8102 16.8748 19.3202 16.2598 19.3202Z" />
      </svg>
    );
  },
);

SideNavDashboardIcon.displayName = 'SideNavDashboardIcon';
