import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavCommitmentsIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavCommitments"
      >
        <path d="M14.8046 23.925H8.39961C6.59961 23.925 5.60961 23.925 4.72461 23.475C3.94461 23.07 3.31461 22.455 2.92461 21.675C2.47461 20.79 2.47461 19.8 2.47461 18V8.80499C2.47461 7.78499 2.47461 7.26001 2.60961 6.73501C2.98461 5.31001 4.10961 4.19999 5.51961 3.80999C6.04461 3.67499 6.55461 3.675 7.58961 3.675H15.5846C16.6196 3.675 17.1296 3.67499 17.6546 3.80999C19.0796 4.18499 20.1896 5.30999 20.5796 6.71999C20.7146 7.24499 20.7146 7.755 20.7146 8.79V17.985C20.7146 19.785 20.7146 20.775 20.2646 21.66C19.8596 22.44 19.2446 23.07 18.4646 23.46C17.5796 23.91 16.5896 23.91 14.7896 23.91L14.8046 23.925ZM7.60461 5.925C6.82461 5.925 6.35961 5.92501 6.11961 5.98501C5.47461 6.16501 4.96461 6.65999 4.79961 7.30499C4.73961 7.54499 4.73961 7.98 4.73961 8.79V17.985C4.73961 19.44 4.73961 20.235 4.94961 20.64C5.12961 21 5.41461 21.285 5.77461 21.465C6.17961 21.675 6.97461 21.675 8.42961 21.675H14.8346C16.2896 21.675 17.0846 21.675 17.4896 21.465C17.8496 21.285 18.1346 21 18.3146 20.64C18.5246 20.235 18.5246 19.44 18.5246 17.985V8.79C18.5246 7.98 18.5246 7.54499 18.4646 7.30499C18.2846 6.65999 17.7896 6.15001 17.1446 5.98501C16.9046 5.92501 16.4396 5.925 15.6596 5.925H7.60461ZM10.6046 17.925C10.3196 17.925 10.0346 17.82 9.80961 17.595L7.81461 15.6C7.37961 15.165 7.37961 14.445 7.81461 14.01C8.24961 13.575 8.96961 13.575 9.40461 14.01L10.6046 15.21L14.3096 11.505C14.7446 11.07 15.4646 11.07 15.8996 11.505C16.3346 11.94 16.3346 12.66 15.8996 13.095L11.3996 17.595C11.1746 17.82 10.8896 17.925 10.6046 17.925Z" />
        <path d="M14.2496 2.625H8.24961C7.63461 2.625 7.12461 2.115 7.12461 1.5C7.12461 0.885 7.63461 0.375 8.24961 0.375H14.2496C14.8646 0.375 15.3746 0.885 15.3746 1.5C15.3746 2.115 14.8646 2.625 14.2496 2.625Z" />
      </svg>
    );
  },
);

SideNavCommitmentsIcon.displayName = 'SideNavCommitmentsIcon';
