import {
  AWS_RESOURCE_TYPE_NAMES,
  AWS_SUPPORTED_SERVICES,
  AWS_SUPPORTED_TYPES,
} from './aws/constants';
import {
  AZURE_RESOURCE_TYPE_NAMES,
  AZURE_SUPPORTED_SERVICES,
  AZURE_SUPPORTED_TYPES,
} from './azure/constants';
import {
  GCP_RESOURCE_TYPE_NAMES,
  GCP_SUPPORTED_SERVICES,
  GCP_SUPPORTED_TYPES,
} from './gcp/constants';

export const RESOURCE_TYPE_NAMES = {
  ...AWS_RESOURCE_TYPE_NAMES,
  ...GCP_RESOURCE_TYPE_NAMES,
  ...AZURE_RESOURCE_TYPE_NAMES,
} as const;

export const SUPPORTED_SERVICES = {
  ...AWS_SUPPORTED_SERVICES,
  ...GCP_SUPPORTED_SERVICES,
  ...AZURE_SUPPORTED_SERVICES,
} as const;

export const SUPPORTED_TYPES = {
  ...AWS_SUPPORTED_TYPES,
  ...GCP_SUPPORTED_TYPES,
  ...AZURE_SUPPORTED_TYPES,
} as const;
