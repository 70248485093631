import LogRocket from 'logrocket';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { GraphQLProvider } from '@pointfive/gql/provider';
import { IconProvider } from '@pointfive/ui/components/base/Icon/IconProvider';

import { ToastOutlet } from 'components/ToastOutlet';
import { ModalOutlet } from 'components/modal';
import { IS_PROD } from 'constants/env';
import { TanstackReactQueryProvider } from 'services/TanstackReactQueryProvider';
import { AuthProvider } from 'services/auth';
import { FeatureFlagsProvider } from 'services/featureFlags';
import { initSentry } from 'services/sentry';

import { routes } from './router';
import { FullStoryProvider, initFullStory } from './services/FullStory';
import { LogRocketIdentify } from './services/LogRocketIdentity';

import './App.css';

if (IS_PROD) {
  LogRocket.init('hha9du/point5');
}
initSentry();
initFullStory();

function App() {
  return (
    <>
      <IconProvider />
      <GraphQLProvider>
        <TanstackReactQueryProvider>
          <AuthProvider>
            <FullStoryProvider />
            <LogRocketIdentify />
            <FeatureFlagsProvider>
              <RouterProvider router={createBrowserRouter(routes)} />
            </FeatureFlagsProvider>
            <ModalOutlet />
            <ToastOutlet />
          </AuthProvider>
        </TanstackReactQueryProvider>
      </GraphQLProvider>
    </>
  );
}

export default App;
