import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const InfoIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Info"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.0909 8.36364C11.0909 7.86156 11.4979 7.45455 12 7.45455H12.0091C12.5112 7.45455 12.9182 7.86156 12.9182 8.36364C12.9182 8.86571 12.5112 9.27273 12.0091 9.27273H12C11.4979 9.27273 11.0909 8.86571 11.0909 8.36364ZM12 11.0909C12.5021 11.0909 12.9091 11.4979 12.9091 12V15.6364C12.9091 16.1384 12.5021 16.5455 12 16.5455C11.4979 16.5455 11.0909 16.1384 11.0909 15.6364V12C11.0909 11.4979 11.4979 11.0909 12 11.0909Z"
        />
      </svg>
    );
  },
);

InfoIcon.displayName = 'InfoIcon';
