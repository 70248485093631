import { Link } from 'react-router-dom';

import { SideNavAutomationsIcon } from '@pointfive/assets';

import { AUTOMATIONS_PAGE_TITLE } from 'pages/Automations';
import { ROUTE_CONSTANTS } from 'router/constants';

import { TopNavButton } from './TopNavButton';

type AutomationsButtonProps = {
  isSelected: boolean;
};
export const AutomationsButton = ({ isSelected }: AutomationsButtonProps) => {
  return (
    <Link to={ROUTE_CONSTANTS.automations}>
      <TopNavButton
        label={AUTOMATIONS_PAGE_TITLE}
        isSelected={isSelected}
        icon={<SideNavAutomationsIcon size="sm" />}
        dataCy="top-bar-btn-automations"
      />
    </Link>
  );
};
