import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as LoadBalancingColorIcon } from './variant-svgs/load-balancing-color.svg';
import { ReactComponent as LoadBalancingDisabledIcon } from './variant-svgs/load-balancing-disabled.svg';
import { ReactComponent as LoadBalancingBlackIcon } from './variant-svgs/load-balancing-regular.svg';

const VARIANT_ICON_MAP = {
  default: LoadBalancingColorIcon,
  black: LoadBalancingBlackIcon,
  disabled: LoadBalancingDisabledIcon,
};

export const LoadBalancingIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="load-balancing-icon"
      />
    );
  },
);

LoadBalancingIcon.displayName = 'LoadBalancingIcon';
