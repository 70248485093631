import { gql } from '@apollo/client';

import { graphql } from '../generated';

// TODO: that's a bug on apollo, fragments not working 🤷‍♂️
// export const BASE_INTEGRATION_FIELDS = gql`
//   fragment BaseIntegrationFields on Integration {
//     id
//     name
//     type
//     status
//     updatedAt
//     createdAt
//   }
// `;

export const LIST_INTEGRATIONS = gql`
  query listIntegrations($where: IntegrationFilterInput) {
    integrations(where: $where) {
      id
      name
      type
      status
      updatedAt
      createdAt
      ... on AwsIntegration {
        accountId
      }
      ... on AwsOrgIntegration {
        accountId
        managementAccountId
        managementAccountName
        accountCount
      }
      ... on KubernetesIntegration {
        name
        status
        kubernetesLastSyncDate
        deletedAt
        cluster {
          id
          name
          region
          deletedAt
          project {
            name
            canonicalName
            id
          }
        }
      }
      ... on GcpIntegration {
        serviceAccount
      }
      ... on AzureIntegration {
        tenantId
        managementGroupId
        appId
      }
    }
  }
`;

export const INTEGRATION_SCRIPT = graphql(`
  query integrationScript($provider: IntegrationProvider!) {
    integrationScript(providerType: $provider)
  }
`);
