import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Settings04Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Settings04"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.4 6.8C16.4059 6.8 15.6 7.60589 15.6 8.6C15.6 9.59411 16.4059 10.4 17.4 10.4C18.3941 10.4 19.2 9.59411 19.2 8.6C19.2 7.60589 18.3941 6.8 17.4 6.8ZM13.9134 7.7C14.3131 6.1473 15.7225 5 17.4 5C19.3882 5 21 6.61177 21 8.6C21 10.5882 19.3882 12.2 17.4 12.2C15.7225 12.2 14.3131 11.0527 13.9134 9.5L3.9 9.5C3.40294 9.5 3 9.09706 3 8.6C3 8.10294 3.40294 7.7 3.9 7.7L13.9134 7.7ZM6.6 14C5.60589 14 4.8 14.8059 4.8 15.8C4.8 16.7941 5.60589 17.6 6.6 17.6C7.59411 17.6 8.4 16.7941 8.4 15.8C8.4 14.8059 7.59411 14 6.6 14ZM3 15.8C3 13.8118 4.61177 12.2 6.6 12.2C8.27746 12.2 9.68695 13.3473 10.0866 14.9L20.1 14.9C20.5971 14.9 21 15.3029 21 15.8C21 16.2971 20.5971 16.7 20.1 16.7L10.0866 16.7C9.68695 18.2527 8.27746 19.4 6.6 19.4C4.61177 19.4 3 17.7882 3 15.8Z"
        />
      </svg>
    );
  },
);

Settings04Icon.displayName = 'Settings04Icon';
