import { Suspense, useEffect } from 'react';

import { ErrorBoundary } from '@sentry/react';
import { toast } from 'react-toastify';

import { Toast, ToastTypes } from '@pointfive/ui';

import Sentry from 'services/sentry';

import { useModalStore } from './useModal';

export const ModalOutlet = (): JSX.Element => {
  const modal = useModalStore((state) => state.modal);

  // ensure body overflow is hidden when modal is open
  useEffect(() => {
    if (modal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [modal]);
  return (
    <ErrorBoundary
      onError={(error) => {
        let msg = '-';
        if (error instanceof Error) {
          msg = error.message;
        }

        toast(
          <Toast
            message="Something Went Wrong"
            toastIcon="alert-circle"
            type={ToastTypes.negative}
          />,
        );

        Sentry.captureException(new Error('ModalOutlet captured exception'), {
          extra: { error, msg },
        });
      }}
    >
      <Suspense>{modal}</Suspense>
    </ErrorBoundary>
  );
};
