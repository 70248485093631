import { forwardRef } from 'react';

import Button, {
  ButtonProps,
  ButtonSizeValues,
  ButtonSizes,
  ButtonVariants,
  ButtonVariantsValues,
} from './Button';

type Ref = HTMLButtonElement;

type IconButtonProps = Omit<ButtonProps, 'startIconName' | 'endIconName'> & {
  iconName: string;
};

//sizes - added ! to override current button classes (different size than default button)
const smallClassName = '!h-5 !w-5 !rounded !px-0';
const mediumClassName = '!h-8 !w-8 !px-0';
const medLargeClassName = '!h-9 w-9 !px-0';
const largeClassName = '!h-10 !w-10 !px-0';

//variants overrides
const outlineStyleOverride = 'hover:bg-transparent hover:text-gray-600';
const ghostStyleOverride = 'hover:bg-transparent hover:text-gray-600';

const variantReducer = (variant: ButtonVariantsValues) => {
  switch (variant) {
    case ButtonVariants.Outline:
      return outlineStyleOverride;
    case ButtonVariants.Ghost:
      return ghostStyleOverride;
    default:
      return '';
  }
};

const sizeReducer = (buttonSize: ButtonSizeValues) => {
  switch (buttonSize) {
    case ButtonSizes.Small:
      return smallClassName;
    case ButtonSizes.Medium:
      return mediumClassName;
    case ButtonSizes.medLarge:
      return medLargeClassName;
    case ButtonSizes.Large:
      return largeClassName;
    default:
      return '';
  }
};

export const IconButton = forwardRef<Ref, IconButtonProps>((props, ref) => {
  const {
    size = ButtonSizes.Medium,
    variant = ButtonVariants.Ghost,
    iconName,
    className,
    ...rest
  } = props;

  return (
    <Button
      compact
      {...rest}
      variant={variant}
      startIconName={iconName}
      ref={ref}
      className={`${variantReducer(variant)} ${sizeReducer(size)} ${className}`}
      size={size} // for icon size, button size is handled in classNames
    />
  );
});

IconButton.displayName = 'IconButton';
