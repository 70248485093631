import { useRef } from 'react';

import { create } from 'zustand';

import { createSelectors } from 'stores/createSelectors';

import {
  FeatureFlagType,
  FeatureFlagsStoreType,
  FlagsVariationCallCache,
  LaunchDarklyStoreType,
  fallbackFlags,
} from './types';

// TODO: remove "use" from the name as this is not a hook
export const useLaunchDarkly = create<LaunchDarklyStoreType>((set) => ({
  client: undefined,

  setClient: (client) => set({ client }),
}));

const baseFeatureFlags = create<FeatureFlagsStoreType>((set, get) => ({
  // state
  flags: undefined,
  getFlag: (flag: FeatureFlagType) => {
    const fallbackFlag = fallbackFlags[flag] || false;
    if (!get().flags) {
      return fallbackFlag;
    }

    return useLaunchDarkly
      .getState()
      .client?.variation?.(flag, fallbackFlag) as boolean;
  },
  // actions
  updateFlags: (flags) => set({ flags: { ...get().flags, ...flags } }),
}));

/** @deprecated use `useFeatureFlagsValue` instead */
export const useFeatureFlags = createSelectors(baseFeatureFlags);

export const useFeatureFlagsValue = (flag?: FeatureFlagType) => {
  // Keep state of flags that were already reported to LD in "variation" (meaning the user has evaluated the flag)
  const flagVariationCallCache = useRef<FlagsVariationCallCache>(
    {} as FlagsVariationCallCache,
  );

  const [flags, getFlag] = useFeatureFlags((state) => [
    state.flags,
    state.getFlag,
  ]);

  if (!flag) {
    return false;
  }

  if (flagVariationCallCache.current[flag]) {
    return flags?.[flag] || fallbackFlags[flag];
  }

  flagVariationCallCache.current = {
    ...flagVariationCallCache.current,
    [flag]: true,
  };
  return getFlag(flag);
};
