import { HTMLAttributes, ReactNode, memo, useMemo } from 'react';

import { cva } from 'class-variance-authority';

import Button, { ButtonSizes, ButtonVariants } from './Button';
import { CopyToClipboardIcon } from './CopyToClipboard';
import { ServiceResourceTypeIcon } from './Icon/Icons/ServiceResourceTypeIcon';
import { EllipsisToolTip, ToolTip, useIsHorizontalOverflow } from './Tooltip';
import { UseHorizontalReturnType } from './Tooltip/useIsHorizontalOverflow';

/**
 * plain text cell with ellipsis, can have multi line and copy to clipboard and secondary placeholder
 */
const commonContainerClassNames =
  'flex w-full items-center justify-start gap-1 truncate font-medium leading-4';

const mainTextStyle = cva(`group/main ${commonContainerClassNames}`, {
  variants: {
    disabled: {
      true: 'text-text-disabled',
      false: 'text-gray-900',
    },
  },
});

const secondaryTextStyle = cva(
  `group/secondary text-overline ${commonContainerClassNames}`,
  {
    variants: {
      disabled: {
        true: 'text-text-disabled',
        false: 'text-slate-500',
      },
    },
  },
);

type PlainTextTableCellProps = {
  main: string | number;
  secondaryPlaceHolder?: boolean;
  secondary?: string;
  copyToCLipboard?: boolean;
  alignRight?: boolean;
  boldMain?: boolean;
  mainClassName?: string;
  tooltip?: boolean;
  secondaryClassName?: string;
  testId?: string;
  disabled?: boolean;
};

export const PlainTextTableCell = ({
  main,
  secondary,
  secondaryPlaceHolder = false,
  copyToCLipboard = false,
  alignRight = false,
  boldMain = false,
  mainClassName,
  tooltip,
  secondaryClassName,
  testId,
  disabled,
}: PlainTextTableCellProps) => {
  const horizontalOverflow = useIsHorizontalOverflow();

  const { ref, isHorizontalOverflow } = tooltip
    ? horizontalOverflow
    : ({} as UseHorizontalReturnType);

  const secondaryHorizontalOverflow = useIsHorizontalOverflow();
  const {
    ref: secondaryRef,
    isHorizontalOverflow: secondaryIsHorizontalOverflow,
  } = tooltip ? secondaryHorizontalOverflow : ({} as UseHorizontalReturnType);

  const isMainTextAccented =
    typeof secondary === 'string' || secondaryPlaceHolder || boldMain;

  const fontsSize = isMainTextAccented
    ? 'text-overline-accented'
    : 'text-overline';

  const align = `${
    alignRight ? 'flex-row-reverse text-right' : 'flex-row text-left'
  }`;

  return (
    <div
      data-cy={testId}
      className={`flex min-h-8 w-full flex-col items-start justify-center overflow-x-hidden`}
    >
      <div className={`${mainTextStyle({ disabled })} ${align}`}>
        <ToolTip message={main} disabled={!isHorizontalOverflow} side="bottom">
          <span ref={ref} className={`truncate ${fontsSize} ${mainClassName}`}>
            {main}
          </span>
        </ToolTip>
        {copyToCLipboard ? (
          <CopyToClipboardIcon
            text={main}
            groupHoverOpacityClass="group-hover/main:opacity-100"
          />
        ) : null}
      </div>
      {secondary || secondaryPlaceHolder ? (
        <div
          className={`${secondaryTextStyle({ disabled: !!disabled })} ${align}`}
        >
          <ToolTip
            message={secondary}
            disabled={!secondaryIsHorizontalOverflow}
            side="bottom"
          >
            <span
              ref={secondaryRef}
              className={`${secondaryClassName || 'truncate'} ${align}`}
            >
              {secondary || '-'}
            </span>
          </ToolTip>
          {
            //allow copy only if secondary string was passed, we don't want to copy placeholder
            secondary && copyToCLipboard ? (
              <CopyToClipboardIcon
                text={secondary}
                groupHoverOpacityClass="group-hover/secondary:opacity-100"
              />
            ) : null
          }
        </div>
      ) : null}
    </div>
  );
};

export const BlueBadge = memo(
  ({ innerLabel, text }: { innerLabel?: string; text?: string }) => {
    if (!text && !innerLabel) {
      return null;
    }

    return (
      <div className="text-bold flex items-center justify-center gap-1">
        {text && <span className="text-xs font-bold">{text}</span>}
        {innerLabel && (
          <span className="flex items-center justify-center rounded-xl border border-solid border-[#A8B8F7] bg-transparent px-2 py-[2px] text-xs text-untitled-purple-300">
            {innerLabel}
          </span>
        )}
      </div>
    );
  },
);

BlueBadge.displayName = 'BlueBadge';

export const StatusBadgeColors = {
  GREEN: 'green',
  RED: 'red',
  YELLOW: 'yellow',
  BLUE: 'blue',
  GRAY: 'gray',
  PURPLE: 'purple',
} as const;

export type StatusBadgeColorsValues =
  (typeof StatusBadgeColors)[keyof typeof StatusBadgeColors];

export const StatusBadge = ({
  color = 'green',
  innerLabel,
  size = 'small',
  displayBorder = false,
}: {
  color: StatusBadgeColorsValues;
  innerLabel: string;
  size?: 'small' | 'medium';
  displayBorder?: boolean;
}) => {
  const badgeVariants = cva(
    `text-body-2 pointer-events-none box-border flex w-fit select-none items-center justify-center gap-1 rounded-full px-[8px]`,
    {
      variants: {
        color: {
          [StatusBadgeColors.GREEN]: 'text-green-700',
          [StatusBadgeColors.RED]: 'text-text-error',
          [StatusBadgeColors.YELLOW]: 'text-orange-700',
          [StatusBadgeColors.BLUE]: 'text-text-brand',
          [StatusBadgeColors.GRAY]: 'text-text-primary',
          [StatusBadgeColors.PURPLE]: 'text-violet-800',
        },
        size: {
          small: 'text-caption py-[3px]',
          medium: 'text-caption py-3',
        },
        displayBorder: {
          true: 'border-border-minimal border border-solid bg-transparent',
          false: '',
        },
      },
      defaultVariants: {
        color: 'green',
        size: 'small',
        displayBorder: false,
      },
    },
  );

  return (
    <div className={badgeVariants({ color, size, displayBorder })}>
      {innerLabel}
    </div>
  );
};

type StatusBadgeFilterProps = HTMLAttributes<HTMLButtonElement> & {
  color: StatusBadgeColorsValues;
  innerLabel: string;
  size?: 'small' | 'medium';
  active?: boolean;
  prefixNumber?: number;
};

export const StatusBadgeFilter = ({
  color = 'green',
  innerLabel,
  size = 'small',
  active = false,
  prefixNumber,
  ...props
}: StatusBadgeFilterProps) => {
  const badgeVariants = cva(
    'text-body-2 box-border flex w-fit items-center justify-center gap-1 rounded-full px-[6px]',
    {
      variants: {
        color: {
          [StatusBadgeColors.GREEN]:
            'text-green-700 hover:bg-green-50/40 active:bg-green-50',
          [StatusBadgeColors.RED]:
            'text-text-error hover:bg-rose-50/40 active:bg-rose-50',
          [StatusBadgeColors.YELLOW]:
            'text-orange-700 hover:bg-orange-50/40 active:bg-orange-50',
          [StatusBadgeColors.BLUE]:
            'text-text-brand hover:bg-blue-100/40 active:bg-blue-100',
          [StatusBadgeColors.GRAY]:
            'text-text-primary hover:bg-gray-50/40 active:bg-gray-50',
          [StatusBadgeColors.PURPLE]:
            'text-violet-800 hover:bg-violet-50/40 active:bg-violet-50',
        },
        active: {
          true: '',
          false: 'bg-transparent',
        },
        size: {
          small: 'text-caption py-[5px]',
          medium: 'text-caption py-3',
        },
      },
      compoundVariants: [
        {
          active: true,
          color: StatusBadgeColors.GREEN,
          className: 'bg-green-50',
        },
        {
          active: true,
          color: StatusBadgeColors.RED,
          className: 'bg-rose-50',
        },
        {
          active: true,
          color: StatusBadgeColors.YELLOW,
          className: 'bg-orange-50',
        },
        {
          active: true,
          color: StatusBadgeColors.BLUE,
          className: 'bg-blue-100',
        },
        {
          active: true,
          color: StatusBadgeColors.GRAY,
          className: 'bg-gray-50',
        },
        {
          active: true,
          color: StatusBadgeColors.PURPLE,
          className: 'bg-violet-50',
        },
      ],
      defaultVariants: {
        color: 'green',
        size: 'small',
      },
    },
  );

  const prefixNumberVariants = cva(
    'text-overline flex h-[20px] min-w-[20px] items-center justify-center rounded-xl px-[6px]',
    {
      variants: {
        color: {
          [StatusBadgeColors.GREEN]: 'bg-green-50',
          [StatusBadgeColors.RED]: 'bg-rose-50',
          [StatusBadgeColors.YELLOW]: 'bg-orange-50',
          [StatusBadgeColors.BLUE]: 'bg-blue-100',
          [StatusBadgeColors.GRAY]: 'bg-gray-50',
          [StatusBadgeColors.PURPLE]: 'bg-violet-50',
        },
      },
    },
  );

  return (
    <button className={badgeVariants({ color, size, active })} {...props}>
      {typeof prefixNumber === 'number' && (
        <div className={prefixNumberVariants({ color })}>{prefixNumber}</div>
      )}
      {innerLabel}
    </button>
  );
};

const initialsBadgeColorPresets = [
  'bg-rose-100 text-rose-700',
  'bg-yellow-100 text-yellow-700',
  'bg-orange-100 text-orange-700',
  'bg-teal-50 text-teal-700',
  'bg-sky-100 text-sky-700',
  'bg-blue-100 text-blue-500',
  'bg-indigo-100 text-indigo-700',
  'bg-violet-100 text-violet-700',
  'bg-fuchsia-100 text-fuchsia-700',
  'bg-pink-100 text-pink-700',
];

const staticColorPreset = 'bg-interactive-primary-disabled text-text-secondary';

export enum InitialsBadgeVariants {
  CONDENSED = 'condensed',
  REGULAR = 'regular',
}

type Props = {
  variant?: InitialsBadgeVariants;
  innerLabel: string;
  staticColor?: boolean;
};

const badgeVariants = {
  [InitialsBadgeVariants.CONDENSED]: 'h-6 w-6 min-h-6 min-w-6',
  [InitialsBadgeVariants.REGULAR]: 'h-8 w-8 min-h-8 min-w-8',
};

export const InitialsBadge = ({
  variant = InitialsBadgeVariants.REGULAR,
  innerLabel,
  staticColor = false,
}: Props) => {
  const { initialsLetters, selectedPreset } = useMemo(() => {
    const initialsLetters = innerLabel
      .replace(/[^A-Za-z]/g, ' ')
      .split(' ')
      .map((word) => word[0])
      .join('')
      .substring(0, 2)
      .toUpperCase();

    const selectedPreset = staticColor
      ? staticColorPreset
      : (() => {
          const alphabetSum = initialsLetters
            .split('')
            .map((char) => char.toLowerCase().charCodeAt(0) - 97 + 1)
            .reduce((a, b) => a + b, 0);

          return initialsBadgeColorPresets[
            alphabetSum % initialsBadgeColorPresets.length
          ];
        })();

    return {
      initialsLetters,
      selectedPreset,
    };
  }, [innerLabel]);

  return (
    <div
      className={`${selectedPreset} text-overline-accented flex items-center justify-center rounded-full ${badgeVariants[variant]}`}
    >
      {initialsLetters}
    </div>
  );
};

export const InteractiveServiceBadge = ({ service }: { service: string }) => {
  return (
    <button
      className={`flex h-6 max-w-[101px] items-center justify-center gap-1 rounded-xl border-0 bg-gray-100 px-2 py-1 text-xs font-semibold text-gray-600 selection:border-0 selection:outline-0 hover:border-0 active:border-0 active:outline-0 disabled:text-gray-300`}
    >
      <ServiceResourceTypeIcon service={service} />
      <span className="flex-1 truncate">{service}</span>
    </button>
  );
};

export const TableCellBadgeContainer = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={`flex h-6 w-min max-w-full items-center justify-center overflow-hidden rounded-xl bg-gray-100 px-2 py-1 text-xs font-semibold text-gray-600 ${className}`}
    >
      <EllipsisToolTip side="top" message={children}>
        <span className="truncate">{children}</span>
      </EllipsisToolTip>
    </div>
  );
};

export const OwnerCell = ({ name }: { name: string }) => {
  const capitalizedName = name[0].toUpperCase() + name.slice(1);
  return (
    <div className="inline-flex items-center justify-center gap-2 overflow-hidden">
      <div className="Avatar inline-flex h-6 w-6 flex-col items-center justify-center gap-2.5 rounded-xl bg-blue-100">
        <div className="text-center text-xs font-semibold leading-none text-untitled-purple-300">
          {capitalizedName[0]}
        </div>
      </div>
      <div className="truncate text-xs font-normal leading-tight text-sky-950">
        {capitalizedName}
      </div>
    </div>
  );
};

type Severities = 'Low' | 'Medium' | 'High';

const severityToTextMap = new Map<Severities, string>([
  ['Low', 'Low'],
  ['Medium', 'Med'],
  ['High', 'High'],
]);

export const SeverityText = ({
  severity,
  fontClassName,
  text,
}: {
  severity: Severities;
  fontClassName?: string;
  text?: string;
}) => {
  const textColor =
    severity === 'Low'
      ? 'text-severity-100'
      : severity === 'High'
        ? 'text-severity-900'
        : 'text-severity-500';

  return (
    <span
      className={`${fontClassName ?? 'text-overline-accented'} ${textColor}`}
    >
      {text ?? severityToTextMap.get(severity)}
    </span>
  );
};

const linkCellStyle = cva('max-w-full [&&]:px-0 font-normal', {
  variants: {
    disabled: {
      true: 'text-text-tertiary',
      false:
        'hover:underline [&&]:active:border-solid active:border-2 active:rounded active:border-primary-100 active:bg-transparent',
    },
  },
});

export const LinkCell = ({
  title,
  onClick,
  disabled,
}: {
  title: string;
  onClick: () => void;
  disabled?: boolean;
}) => (
  <Button
    variant={ButtonVariants.Ghost}
    type="button"
    size={ButtonSizes.Small}
    onClick={onClick}
    className={linkCellStyle({ disabled })}
    disabled={disabled}
  >
    <EllipsisToolTip side="top" message={title}>
      <div className="truncate">{title}</div>
    </EllipsisToolTip>
  </Button>
);
