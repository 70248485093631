import { create } from 'zustand';

import { createSelectors } from 'stores/createSelectors';

import { AuthContext, LoggedInUser } from './types';

export const useAuthBase = create<AuthContext>((set) => ({
  user: undefined,

  setUser: (user: LoggedInUser) => {
    set({ user });
  },

  refetchUserFunction: () => {},

  // TODO: Think about getting Apollo types in here.
  // Requires installing Apollo here. Perhaps to export them from gql?
  setRefetchUserFunction: (refetchUserFunction: (() => void) | undefined) =>
    set({ refetchUserFunction }),
}));

export const useAuth = createSelectors(useAuthBase);
