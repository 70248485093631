import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavNavbrAvatarGrayMdIcon = forwardRef<
  SVGSVGElement,
  IconProps
>(({ size = 'sm', color, onClick }, ref) => {
  const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

  return (
    <svg
      ref={ref}
      width={ICON_SIZE_OPTIONS[size]}
      height={ICON_SIZE_OPTIONS[size]}
      color={iconColor}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={iconColor}
      onClick={onClick}
      aria-label="SideNavNavbrAvatarGrayMd"
    >
      <rect width="32" height="32" rx="16" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0001 9.33341C14.3892 9.33341 13.0834 10.6393 13.0834 12.2501C13.0834 13.8609 14.3892 15.1667 16.0001 15.1667C17.6109 15.1667 18.9167 13.8609 18.9167 12.2501C18.9167 10.6393 17.6109 9.33341 16.0001 9.33341ZM11.4167 12.2501C11.4167 9.71878 13.4688 7.66675 16.0001 7.66675C18.5314 7.66675 20.5834 9.71878 20.5834 12.2501C20.5834 14.7814 18.5314 16.8334 16.0001 16.8334C13.4688 16.8334 11.4167 14.7814 11.4167 12.2501ZM13.772 18.0834C13.8195 18.0834 13.8678 18.0834 13.9167 18.0834H18.0834C18.1324 18.0834 18.1806 18.0834 18.2282 18.0834C19.2509 18.083 19.9493 18.0827 20.5429 18.2628C21.8746 18.6668 22.9167 19.7089 23.3207 21.0406C23.5007 21.6342 23.5005 22.3326 23.5001 23.3553C23.5001 23.4029 23.5001 23.4511 23.5001 23.5001C23.5001 23.9603 23.127 24.3334 22.6667 24.3334C22.2065 24.3334 21.8334 23.9603 21.8334 23.5001C21.8334 22.2765 21.8244 21.8494 21.7258 21.5244C21.4834 20.7254 20.8581 20.1001 20.0591 19.8577C19.7341 19.7591 19.307 19.7501 18.0834 19.7501H13.9167C12.6931 19.7501 12.2661 19.7591 11.941 19.8577C11.142 20.1001 10.5168 20.7254 10.2744 21.5244C10.1758 21.8494 10.1667 22.2765 10.1667 23.5001C10.1667 23.9603 9.79364 24.3334 9.3334 24.3334C8.87317 24.3334 8.50007 23.9603 8.50007 23.5001C8.50007 23.4511 8.50005 23.4029 8.50003 23.3553C8.49966 22.3326 8.4994 21.6342 8.67949 21.0406C9.08344 19.7089 10.1255 18.6668 11.4572 18.2628C12.0509 18.0827 12.7492 18.083 13.772 18.0834Z"
      />
    </svg>
  );
});

SideNavNavbrAvatarGrayMdIcon.displayName = 'SideNavNavbrAvatarGrayMdIcon';
