import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const UserLeft01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="UserLeft01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 4C8.06706 4 6.50006 5.567 6.50006 7.5C6.50006 9.433 8.06706 11 10 11C11.933 11 13.5 9.433 13.5 7.5C13.5 5.567 11.933 4 10 4ZM4.50007 7.5C4.50007 4.46243 6.96249 2 10 2C13.0376 2 15.5 4.46243 15.5 7.5C15.5 10.5376 13.0376 13 10 13C6.96249 13 4.50007 10.5376 4.50007 7.5ZM19.7071 14.2929C20.0976 14.6834 20.0976 15.3166 19.7071 15.7071L18.4142 17H22C22.5523 17 23 17.4477 23 18C23 18.5523 22.5523 19 22 19H18.4142L19.7071 20.2929C20.0976 20.6834 20.0976 21.3166 19.7071 21.7071C19.3166 22.0976 18.6834 22.0976 18.2929 21.7071L15.2929 18.7071C14.9024 18.3166 14.9024 17.6834 15.2929 17.2929L18.2929 14.2929C18.6834 13.9024 19.3166 13.9024 19.7071 14.2929ZM7.32632 14.5C7.38339 14.5 7.44129 14.5 7.50006 14.5H12C12.5523 14.5 13 14.9477 13 15.5C13 16.0523 12.5523 16.5 12 16.5H7.50006C6.03175 16.5 5.51928 16.5109 5.12921 16.6292C4.17041 16.92 3.4201 17.6703 3.12925 18.6291C3.01093 19.0192 3.00008 19.5317 3.00008 21C3.00008 21.5523 2.55236 22 2.00008 22C1.4478 22 1.00008 21.5523 1.00008 21C1.00008 20.9412 1.00006 20.8833 1.00004 20.8263C0.999587 19.599 0.999277 18.761 1.21538 18.0486C1.70013 16.4506 2.95064 15.2 4.54864 14.7153C5.26104 14.4992 6.09906 14.4995 7.32632 14.5Z"
        />
      </svg>
    );
  },
);

UserLeft01Icon.displayName = 'UserLeft01Icon';
