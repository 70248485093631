import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const NoConnectionIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="NoConnection"
      >
        <path d="M11.157 20.7598C10.575 20.7598 10.1033 20.2932 10.1033 19.7176V17.6332L6.72378 14.2907C6.52616 14.0952 6.41514 13.8302 6.41514 13.5537V8.25327C6.41514 7.83639 6.70492 7.08078 6.70492 7.08078C6.70492 7.08078 6.33611 8.23595 6.70492 8.04488L8.52264 10.1293V13.0996L12.2108 16.7473V18.6754H13.2645V16.7473L14.2392 15.7833L2.30553 3.9802C1.89816 3.57729 1.89816 2.92402 2.30553 2.52111C2.71291 2.11819 3.3734 2.11819 3.78078 2.52111L21.6945 20.2387C22.1018 20.6416 22.1018 21.2949 21.6945 21.6978C21.2871 22.1007 20.6266 22.1007 20.2192 21.6978L15.7408 17.2684L15.372 17.6332V19.7176C15.372 20.2932 14.9002 20.7598 14.3182 20.7598H11.157ZM18.7978 13.1506C18.448 13.4965 17.8809 13.4965 17.5311 13.1506L17.2612 12.8837C17.0636 12.6883 16.9526 12.4232 16.9526 12.1468V8.25327H12.5796L8.52264 4.24076V3.04221C8.52264 2.46661 8.99441 2 9.57638 2C10.1584 2 10.6301 2.46661 10.6301 3.04221V6.16885H14.8451V3.04221C14.8451 2.46661 15.3169 2 15.8989 2C16.4808 2 16.9526 2.46661 16.9526 3.04221V7.21106L15.8989 6.16885H16.9526C17.5322 6.16885 18.0283 6.37295 18.441 6.78115C18.8537 7.18935 19.0601 7.68006 19.0601 8.25327V12.5242C19.0601 12.7591 18.9657 12.9845 18.7978 13.1506Z" />
      </svg>
    );
  },
);

NoConnectionIcon.displayName = 'NoConnectionIcon';
