import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavResourcesIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavResources"
      >
        <g clipPath="url(#clip0_485_39243)">
          <path d="M12 23.925C11.895 23.925 11.79 23.925 11.685 23.895C11.46 23.85 11.28 23.76 11.1 23.67L0.689987 18.435C0.134987 18.15 -0.090013 17.475 0.194987 16.92C0.479987 16.365 1.15499 16.14 1.70999 16.425L12.03 21.615L22.305 16.425C22.86 16.14 23.535 16.365 23.82 16.92C24.105 17.475 23.88 18.15 23.325 18.435L12.915 23.67C12.735 23.76 12.57 23.85 12.33 23.895C12.225 23.91 12.12 23.925 12.015 23.925H12ZM12 18.495C11.895 18.495 11.79 18.495 11.7 18.465C11.475 18.42 11.31 18.345 11.115 18.24L0.704987 13.005C0.149987 12.72 -0.0750127 12.045 0.209987 11.49C0.494987 10.935 1.16999 10.71 1.72499 10.995L12.045 16.185L22.32 10.995C22.875 10.71 23.55 10.935 23.835 11.49C24.12 12.045 23.895 12.72 23.34 13.005L12.93 18.24C12.75 18.33 12.585 18.42 12.36 18.465C12.255 18.48 12.15 18.495 12.045 18.495H12ZM12 13.065C11.895 13.065 11.79 13.065 11.685 13.035C11.46 12.99 11.295 12.915 11.115 12.825L0.689987 7.57504C0.314987 7.38004 0.0749871 6.99004 0.0749871 6.57004C0.0749871 6.15004 0.314987 5.76004 0.689987 5.56504L11.1 0.330041C11.28 0.240041 11.46 0.150039 11.685 0.105039C11.88 0.0600391 12.09 0.0600391 12.3 0.105039C12.54 0.150039 12.705 0.240041 12.885 0.330041L23.295 5.56504C23.67 5.76004 23.91 6.15004 23.91 6.57004C23.91 6.99004 23.67 7.38004 23.295 7.57504L12.885 12.81C12.705 12.9 12.525 12.99 12.3 13.035C12.195 13.05 12.09 13.065 11.985 13.065H12ZM3.70499 6.57004L12.03 10.755L20.31 6.57004L11.985 2.38503L3.70499 6.57004Z" />
        </g>
        <defs>
          <clipPath id="clip0_485_39243">
            <rect width="24" height="24" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

SideNavResourcesIcon.displayName = 'SideNavResourcesIcon';
