import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Target02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Target02"
      >
        <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13 2C13 1.44772 12.5523 1 12 1C11.4477 1 11 1.44772 11 2V3.05493C6.82838 3.51608 3.51608 6.82838 3.05493 11H2C1.44772 11 1 11.4477 1 12C1 12.5523 1.44772 13 2 13H3.05493C3.51608 17.1716 6.82838 20.4839 11 20.9451V22C11 22.5523 11.4477 23 12 23C12.5523 23 13 22.5523 13 22V20.9451C17.1716 20.4839 20.4839 17.1716 20.9451 13H22C22.5523 13 23 12.5523 23 12C23 11.4477 22.5523 11 22 11H20.9451C20.4839 6.82838 17.1716 3.51608 13 3.05493V2ZM5 12C5 8.13401 8.13401 5 12 5C15.866 5 19 8.13401 19 12C19 15.866 15.866 19 12 19C8.13401 19 5 15.866 5 12Z"
        />
      </svg>
    );
  },
);

Target02Icon.displayName = 'Target02Icon';
