import { gql } from '@apollo/client';

export const GET_TEAMS_LIST = gql`
  query getTeamsList($where: TeamsFilterInput) {
    listTeams(where: $where) {
      id
      name
      type
      members {
        teamId
        user {
          id
          fullName
        }
      }
      memberCount
      resourceAllocationRulesCount
    }
  }
`;

export const GET_TEAMS_TREE = gql`
  query getTeamsTree {
    listTeams {
      id
      name
      type
      childTeams {
        id
        name
      }
      memberCount
      resourceAllocationRules {
        rule
        name
      }
    }
  }
`;

export const GET_TEAM = gql`
  query getTeam($id: UUID!) {
    getTeam(id: $id) {
      id
      name
      type
      members {
        role
        userId
        user {
          firstName
          lastName
          email
        }
      }
      memberCount
      childTeams {
        id
        name
        memberCount
        resourceAllocationRulesCount
      }
      parentTeams {
        id
      }
      resourceAllocationRules {
        name
        rule
      }
      resourceAllocationRulesCount
    }
  }
`;
