import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Cube01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Cube01"
      >
        <path d="M12 22C11.88 22 11.7709 21.9888 11.6509 21.9665C11.3891 21.9108 11.1927 21.7992 10.8982 21.6319L4.16727 17.8059C3.85091 17.6274 3.64364 17.5047 3.45818 17.304C3.29455 17.1143 3.16364 16.9024 3.08727 16.657C3 16.3893 3 16.1439 3 15.7758V8.23536C3 7.86726 3 7.62186 3.08727 7.35416C3.16364 7.11991 3.29455 6.89682 3.45818 6.70719C3.64364 6.49526 3.85091 6.38371 4.16727 6.20524L10.8982 2.3681C11.2036 2.20078 11.4 2.08924 11.6509 2.03346C11.88 1.98885 12.1091 1.98885 12.3491 2.03346C12.6109 2.08924 12.8073 2.20078 13.1018 2.3681L19.8327 6.19409C20.1491 6.37256 20.3564 6.49526 20.5418 6.69604C20.7055 6.88567 20.8364 7.0976 20.9127 7.343C21 7.61071 21 7.85611 21 8.22421V15.7646C21 16.1327 21 16.3781 20.9127 16.6458C20.8364 16.8912 20.7055 17.1032 20.5418 17.2928C20.3564 17.5047 20.1491 17.6163 19.8327 17.7948L13.1018 21.6207C12.8073 21.7881 12.6109 21.8996 12.3491 21.9554C12.2291 21.9777 12.1091 21.9888 12 21.9888V22ZM12.8182 12.4964V19.8806L19.0364 16.3447C19.1891 16.2554 19.2873 16.2108 19.3527 16.155C19.3745 16.0547 19.3636 15.9543 19.3636 15.7758V8.78193L12.8182 12.4964ZM4.63636 8.77078V15.7646C4.63636 15.9431 4.63636 16.0547 4.63636 16.1327C4.71273 16.1997 4.8 16.2443 4.95273 16.3335L11.1709 19.8695V12.4852L4.63636 8.77078ZM5.49818 7.35416L12 11.0463L18.5018 7.35416L12.3164 3.84049C12.1745 3.76241 12.0873 3.70664 12.0109 3.67317C11.9236 3.69548 11.8364 3.75125 11.7055 3.82934L5.50909 7.343L5.49818 7.35416Z" />
      </svg>
    );
  },
);

Cube01Icon.displayName = 'Cube01Icon';
