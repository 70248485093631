import { DetailedHTMLProps, HTMLAttributes } from 'react';

import { useIsHorizontalOverflow } from 'hooks/useIsHorizontalOverflow';

import { Icon } from './Icon';
import { ToolTip } from './Tooltip';

type ListItemOptions = {
  startIconName?: string;
  endIconName?: string;
  iconSize?: number;
  text: string;
  selected?: boolean;
  disabled?: boolean;
};

//take all the native Div props types and merge with the ListItemOptions, also removing children from type as they are not rendered
export type ListItemProps = Omit<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'children'
> &
  ListItemOptions;

export const ListItem = ({
  startIconName,
  endIconName,
  iconSize = 20,
  text,
  selected = false,
  disabled = false,
  ...rest
}: ListItemProps) => {
  const { ref, isHorizontalOverflow } = useIsHorizontalOverflow();

  return (
    <ToolTip message={text} disabled={!isHorizontalOverflow} side="top">
      <div
        {...rest}
        className={`duration-250 text-body-2 flex w-full max-w-full cursor-pointer select-none items-center justify-start gap-2 overflow-hidden rounded-lg px-3 py-1.5 transition-[background] ${selected ? 'pointer-events-none bg-gray-200' : 'hover:bg-gray-50'} ${disabled ? 'pointer-events-none text-gray-300' : 'text-gray-900'} `}
      >
        {startIconName ? <Icon name={startIconName} size={iconSize} /> : null}
        <span ref={ref} className="flex-1 truncate">
          {text}
        </span>
        {endIconName ? (
          <Icon
            name={endIconName}
            size={iconSize}
            className="justify-self-end"
          />
        ) : null}
      </div>
    </ToolTip>
  );
};
