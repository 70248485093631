import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as AzureColorIcon } from './variant-svgs/azure-color.svg';
import { ReactComponent as AzureDisabledIcon } from './variant-svgs/azure-disabled.svg';
import { ReactComponent as AzureBlackIcon } from './variant-svgs/azure-regular.svg';

const VARIANT_ICON_MAP = {
  default: AzureColorIcon,
  black: AzureBlackIcon,
  disabled: AzureDisabledIcon,
};

export const AzureIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="azure-icon"
      />
    );
  },
);

AzureIcon.displayName = 'AzureIcon';
