import { useEffect, useState } from 'react';

import { IntercomProvider, useIntercom } from 'react-use-intercom';

import { UserMe } from '@pointfive/gql';
import { Icon } from '@pointfive/ui';
import { TopNavButton } from '@pointfive/ui/components/features/TopNav';

import { useWindowSize } from '../../../hooks/useWindowSize';

const OnlineHelpButton = ({
  intercomUnreadCount,
}: {
  intercomUnreadCount: number;
}) => {
  const { show, hide, update: updateIntercomOptions, isOpen } = useIntercom();
  const windowSize = useWindowSize();
  const toggle = () => (isOpen ? hide() : show());

  // Intercom doesn't support setting vertical padding from the top, only from the bottom, so we need to do it manually
  useEffect(() => {
    updateIntercomOptions({
      // 600: fixed height of the intercom frame, 52: height of the top bar, 8: padding
      verticalPadding: windowSize.height - 600 - 52 - 8,
    });
  }, [windowSize]);

  const iconName =
    intercomUnreadCount > 0 ? 'sidenav-help-unread' : 'sidenav-help';

  return (
    <TopNavButton
      onClick={toggle}
      label="Help & Support"
      isSelected={isOpen}
      icon={<Icon size={16} name={iconName} />}
      dataCy="side-bar-btn-help-support"
    />
  );
};

type IntercomWrappedOnlineHelpButtonProps = {
  user: UserMe | undefined;
};
export const IntercomWrappedOnlineHelpButton = ({
  user,
}: IntercomWrappedOnlineHelpButtonProps) => {
  const intercomAppId = import.meta.env.VITE_INTERCOM_APP_ID;
  const [unreadCount, setUnreadCount] = useState(0);

  const isSu = user?.isSuperUser ?? false;
  const suPrefix = isSu ? 'su-' : '';
  const suSuffix = isSu ? ' (SU)' : '';

  // TODO-bar: plan to open a PR to intercom pkg, the onUnreadCountChange should be part of the hook, not provider!
  return (
    <IntercomProvider
      appId={intercomAppId}
      autoBoot
      autoBootProps={{
        userHash: user?.intercomId,
        email: `${suPrefix}${user?.email}`,
        name: `${user?.firstName} ${user?.lastName}${suSuffix}`,
        customAttributes: {
          Role: user?.role,
          CustomerId: user?.customerId,
          CustomerStatus: user?.customerStatus,
          CreatedAt: user?.createdAt,
          UpdatedAt: user?.updatedAt,
          alignment: 'right',
          horizontal_padding: 104,
        },
        hideDefaultLauncher: true,
      }}
      onUnreadCountChange={(count) => setUnreadCount(count)}
    >
      <OnlineHelpButton intercomUnreadCount={unreadCount} />
    </IntercomProvider>
  );
};
