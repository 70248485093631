import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { RowSelection } from '../types';

type RowState = {
  included: boolean;
  excluded: boolean;
};

type RowsState = { [key: string]: RowState };

type SelectedRowsContextArgs = {
  rowsState: RowsState;
  setRowsState: (state: RowsState) => void;
  isAllSelected: boolean;
  setIsAllSelected: (isAllSelected: boolean) => void;
  toggleIsAllSelected: () => void;
  isSomeSelected: boolean;
  toggleSingleRow: (rowId: string) => void;
  isRowSelected: (rowId: string) => boolean;
  rowSelection: RowSelection;
  clearTableSelection: () => void;
};

type SelectedRowsProviderProps = {
  totalRows: number;
  onRowSelectionUpdated?: (query: RowSelection) => void;
  children: ReactNode;
};

const createRowSelectionContext = () => {
  return createContext<SelectedRowsContextArgs>({
    rowsState: {},
    setRowsState: () => {},
    isAllSelected: false,
    setIsAllSelected: () => {},
    toggleIsAllSelected: () => {},
    isSomeSelected: false,
    toggleSingleRow: () => {},
    isRowSelected: () => false,
    rowSelection: { isAllSelected: false, selectedRows: [], excludedRows: [] },
    clearTableSelection: () => {},
  });
};

const RowSelectionContext = createRowSelectionContext();

const RowSelectionProvider = ({
  totalRows,
  onRowSelectionUpdated,
  children,
}: SelectedRowsProviderProps) => {
  const [rowSelection, setRowSelection] = useState<RowSelection>({
    isAllSelected: false,
    selectedRows: [],
    excludedRows: [],
  });
  const [rowsState, setRowsState] = useState<RowsState>({});
  const [_isAllSelected, _setIsAllSelected] = useState<boolean>(false);
  const [_isSomeSelected, _setIsSomeSelected] = useState<boolean>(false);

  useEffect(() => {
    const selectedRows =
      totalRows > 0
        ? Object.entries(rowsState)
            .filter(([, row]) => row.included)
            .map(([key]) => key)
        : [];
    const excludedRows =
      totalRows > 0
        ? Object.entries(rowsState)
            .filter(([, row]) => row.excluded)
            .map(([key]) => key)
        : [];

    if (selectedRows.length === totalRows && totalRows > 0) {
      setIsAllSelected(true);
    }
    if (excludedRows.length === totalRows && totalRows > 0) {
      setIsAllSelected(false);
    }
    if (totalRows === 0) {
      setIsAllSelected(false);
    }

    _setIsSomeSelected(!!selectedRows.length || !!excludedRows.length);

    setRowSelection({
      isAllSelected: _isAllSelected,
      selectedRows: selectedRows,
      excludedRows: excludedRows,
    });
    onRowSelectionUpdated?.({
      selectedRows: selectedRows,
      excludedRows: excludedRows,
      isAllSelected: _isAllSelected,
    });
  }, [rowsState, totalRows]);

  const toggleIsAllSelected = () => {
    setRowsState({});
    _setIsSomeSelected(false);
    _setIsAllSelected((prev) => !prev);
  };

  const setIsAllSelected = (value: boolean) => {
    setRowsState({});
    _setIsSomeSelected(false);
    _setIsAllSelected(value);
  };

  const toggleSingleRow = (rowId: string) => {
    const row = rowsState[rowId];
    if (!row) {
      const include = _isAllSelected ? false : true;
      const excluded = _isAllSelected ? true : false;
      setRowsState({
        ...rowsState,
        [rowId]: { included: include, excluded },
      });
      return;
    }
    if (row.included) {
      setRowsState({
        ...rowsState,
        [rowId]: { included: false, excluded: false },
      });
    } else {
      setRowsState({
        ...rowsState,
        [rowId]: { included: true, excluded: false },
      });
    }
  };

  const isRowSelected = (rowId: string) => {
    const row = rowsState[rowId];
    if (!row && _isAllSelected) {
      return true;
    }
    return row?.included || false;
  };

  return (
    <RowSelectionContext.Provider
      value={{
        rowsState,
        setRowsState,
        isAllSelected: _isAllSelected,
        setIsAllSelected,
        toggleIsAllSelected,
        isSomeSelected: _isSomeSelected,
        toggleSingleRow,
        isRowSelected,
        rowSelection,
        clearTableSelection: () => setRowsState({}),
      }}
    >
      {children}
    </RowSelectionContext.Provider>
  );
};

const useRowSelectionContext = () => {
  return useContext(RowSelectionContext);
};

export { RowSelectionProvider, useRowSelectionContext };
