import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Notification02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Notification02"
      >
        <path d="M9.10954 5.43303C9.07742 5.47553 9.04553 5.5177 9.0122 5.55843L8.99319 5.54891L7.62309 4.17882C7.70872 4.07417 7.79436 3.9695 7.8895 3.87436C9.05027 2.67553 10.6202 2 12.2662 2C13.9122 2 15.4821 2.67553 16.6428 3.87436C17.8036 5.06367 18.4506 6.69066 18.4506 8.37472C18.4506 11.0007 19.0881 12.7324 19.7636 13.836V13.8551C20.0776 14.3689 20.325 14.7875 20.4867 15.0824C20.5723 15.2347 20.6485 15.3774 20.7055 15.5106C20.7341 15.5772 20.7626 15.6628 20.7911 15.7484L20.7922 15.7521C20.8118 15.8207 20.8574 15.9801 20.8387 16.1671C20.8292 16.2908 20.8007 16.5096 20.677 16.738C20.6214 16.8491 20.5478 16.9332 20.4825 17.0078L20.4772 17.0139L17.9178 14.4545C17.1661 13.0559 16.5572 11.0864 16.5572 8.38423C16.5572 7.20443 16.1005 6.07221 15.3013 5.23493C14.5021 4.40717 13.4174 3.94096 12.2852 3.94096C11.153 3.94096 10.0683 4.40717 9.2691 5.23493C9.21098 5.29886 9.15997 5.36634 9.10954 5.43303Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0202 17.4991L19.4712 20.9501C19.8428 21.3217 20.4452 21.3217 20.8167 20.9501C21.1883 20.5785 21.1883 19.9761 20.8167 19.6046L3.69063 2.47844C3.31907 2.10688 2.71664 2.10688 2.34508 2.47844C1.97352 2.85001 1.97352 3.45243 2.34508 3.824L6.14796 7.62688C6.11676 7.88365 6.10079 8.14301 6.10079 8.40322C6.10079 11.0292 5.46332 12.7609 4.80681 13.8645V13.8836C4.48332 14.3974 4.23594 14.8065 4.07419 15.1109C3.98856 15.2632 3.91245 15.4059 3.85536 15.5391C3.82682 15.6057 3.79828 15.6818 3.76974 15.777C3.75071 15.8531 3.71265 16.0053 3.72216 16.1956C3.73168 16.3193 3.76022 16.5381 3.88391 16.7665C4.00759 16.9948 4.17886 17.1375 4.274 17.2137C4.48701 17.3755 4.72291 17.423 4.83138 17.4448C4.84403 17.4473 4.85495 17.4495 4.8639 17.4515C4.98965 17.4695 5.13236 17.479 5.26802 17.488L5.29206 17.4896C5.58701 17.4991 5.99613 17.4991 6.49089 17.4991H16.0202ZM14.0697 15.5486H6.01516C6.12933 15.3583 6.27205 15.1205 6.42428 14.8636C7.17547 13.6199 7.82416 11.8564 7.97209 9.45101L14.0697 15.5486Z"
        />
        <path d="M13.9406 19.402C13.4934 19.8112 12.9131 20.0585 12.2756 20.0585C11.6381 20.0585 11.0577 19.8112 10.6105 19.402C10.2205 19.0405 9.62104 19.0785 9.269 19.4782C8.91696 19.8778 8.94551 20.4867 9.33561 20.8387C10.1158 21.5523 11.1434 21.99 12.2756 21.99C13.4078 21.99 14.4354 21.5523 15.2156 20.8387C15.6057 20.4772 15.6342 19.8683 15.2822 19.4782C14.9301 19.0785 14.3307 19.05 13.9406 19.402Z" />
      </svg>
    );
  },
);

Notification02Icon.displayName = 'Notification02Icon';
