import { AmazonIcon, AzureIcon, GcpIcon } from '@pointfive/assets';
import { CloudIntelligenceProvider } from '@pointfive/gql';

export const PROVIDER_DROPDOWN_OPTIONS = [
  {
    label: 'AWS',
    value: CloudIntelligenceProvider.Aws,
  },
  {
    label: 'GCP',
    value: CloudIntelligenceProvider.Gcp,
  },
  {
    label: 'Azure',
    value: CloudIntelligenceProvider.Azure,
  },
] as const;

export const PROVIDER_ICON_MAP = {
  [CloudIntelligenceProvider.Aws]: AmazonIcon,
  [CloudIntelligenceProvider.Gcp]: GcpIcon,
  [CloudIntelligenceProvider.Azure]: AzureIcon,
};
