import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavArrowLeftDoubleIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavArrowLeftDouble"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6632 8.02889C12.9991 7.66258 12.9746 7.09326 12.6082 6.75729C12.2419 6.42131 11.6726 6.44591 11.3366 6.81223L7.13663 11.3915C6.81688 11.7401 6.8216 12.2768 7.14743 12.6197L11.3474 17.0405C11.6898 17.4008 12.2594 17.4154 12.6198 17.073C12.9802 16.7307 12.9947 16.161 12.6524 15.8007L9.03105 11.989L12.6632 8.02889ZM17.0999 7.19995C17.0999 6.70289 16.697 6.29995 16.1999 6.29995C15.7028 6.29995 15.2999 6.70289 15.2999 7.19995L15.2999 16.7999C15.2999 17.297 15.7028 17.7 16.1999 17.7C16.697 17.7 17.0999 17.297 17.0999 16.7999L17.0999 7.19995Z"
        />
      </svg>
    );
  },
);

SideNavArrowLeftDoubleIcon.displayName = 'SideNavArrowLeftDoubleIcon';
