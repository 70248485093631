import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const NoWorkflowIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="NoWorkflow"
      >
        <path d="M20.8 17.09V18.58L22.4 20.18C22.4 20.18 22.4 20.11 22.4 20.08V17.02C22.4 16.7 22.4 16.45 22.27 16.18C22.15 15.94 21.96 15.75 21.72 15.63C21.45 15.49 21.2 15.49 20.88 15.49H17.82C17.82 15.49 17.75 15.49 17.72 15.49L19.32 17.09H20.81H20.8Z" />
        <path d="M7.3 3.59V5.08L8.9 6.68C8.9 6.68 8.9 6.62 8.9 6.58V3.52C8.9 3.2 8.9 2.95 8.76 2.68C8.64 2.44 8.45 2.26 8.22 2.14C7.95 2 7.7 2 7.38 2H4.32C4.32 2 4.25 2 4.22 2L5.82 3.6H7.31L7.3 3.59Z" />
        <path d="M14.05 10.34V11.83L15.65 13.43C15.65 13.43 15.65 13.36 15.65 13.33V10.27C15.65 9.95 15.65 9.7 15.51 9.43C15.39 9.2 15.2 9 14.97 8.89C14.7 8.75 14.45 8.75 14.13 8.75H11.07C11.07 8.75 11 8.75 10.97 8.75L12.57 10.35H14.06L14.05 10.34Z" />
        <path d="M11.7 5.84H18.27C19.09 5.84 19.59 5.84 19.81 5.95C20 6.05 20.15 6.2 20.25 6.39C20.36 6.6 20.36 7.1 20.36 7.92V9.09C20.36 9.78 20.36 10.19 20.28 10.37C20.18 10.61 19.98 10.81 19.74 10.91C19.56 10.99 19.14 10.99 18.46 10.99C18.02 10.99 17.66 11.35 17.66 11.79C17.66 12.23 18.02 12.59 18.46 12.59C19.37 12.59 19.88 12.59 20.35 12.39C20.99 12.12 21.49 11.62 21.76 10.98C21.96 10.5 21.96 10 21.96 9.09V7.92C21.96 6.82 21.96 6.22 21.68 5.66C21.43 5.17 21.04 4.77 20.54 4.52C19.98 4.24 19.38 4.24 18.28 4.24H11.71C11.27 4.24 10.91 4.6 10.91 5.04C10.91 5.48 11.27 5.84 11.71 5.84H11.7Z" />
        <path d="M11.15 13.24V11.75L9.55 10.15C9.55 10.15 9.55 10.21 9.55 10.25V13.31C9.55 13.63 9.55 13.88 9.69 14.15C9.81 14.39 10 14.57 10.23 14.69C10.5 14.83 10.75 14.83 11.07 14.83H14.13C14.13 14.83 14.2 14.83 14.23 14.83L12.63 13.23H11.14L11.15 13.24Z" />
        <path d="M17.9 19.99V18.5L16.3 16.9C16.3 16.9 16.3 16.96 16.3 17V20.06C16.3 20.38 16.3 20.63 16.43 20.9C16.55 21.14 16.74 21.33 16.97 21.44C17.24 21.58 17.49 21.58 17.81 21.58H20.87C20.87 21.58 20.94 21.58 20.97 21.58L19.37 19.98H17.88L17.9 19.99Z" />
        <path d="M4.4 6.49V5L2.8 3.4C2.8 3.4 2.8 3.46 2.8 3.5V6.56C2.8 6.88 2.8 7.13 2.94 7.4C3.06 7.63 3.25 7.82 3.49 7.94C3.76 8.08 4.01 8.08 4.33 8.08H7.39C7.39 8.08 7.46 8.08 7.49 8.08L5.89 6.48H4.4V6.49Z" />
        <path d="M13.5 17.74H6.93C6.11 17.74 5.61 17.74 5.4 17.63C5.21 17.53 5.06 17.38 4.96 17.19C4.85 16.98 4.85 16.48 4.85 15.66V14.49C4.85 13.8 4.85 13.39 4.93 13.21C5.03 12.97 5.23 12.77 5.47 12.67C5.65 12.59 6.07 12.59 6.75 12.59C7.19 12.59 7.55 12.23 7.55 11.79C7.55 11.35 7.19 10.99 6.75 10.99C5.84 10.99 5.33 10.99 4.85 11.19C4.22 11.45 3.7 11.96 3.44 12.6C3.24 13.08 3.24 13.58 3.24 14.49V15.66C3.24 16.76 3.24 17.36 3.52 17.92C3.77 18.41 4.16 18.8 4.66 19.06C5.22 19.34 5.82 19.34 6.92 19.34H13.49C13.93 19.34 14.29 18.98 14.29 18.54C14.29 18.1 13.93 17.74 13.49 17.74H13.5Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.23431 2.43431C2.54673 2.1219 3.05327 2.1219 3.36569 2.43431L22.3657 21.4343C22.6781 21.7467 22.6781 22.2533 22.3657 22.5657C22.0533 22.8781 21.5467 22.8781 21.2343 22.5657L2.23431 3.56569C1.9219 3.25327 1.9219 2.74673 2.23431 2.43431Z"
        />
      </svg>
    );
  },
);

NoWorkflowIcon.displayName = 'NoWorkflowIcon';
