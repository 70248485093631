import { useLocation } from 'react-router-dom';

import { SideNav as Nav } from '@pointfive/ui';

import { TOP_NAV_HEIGHT } from '../TopNav/constants';
import { useNavBarLinks } from './useNavBarLinks';

export const SideNav = () => {
  const { pathname } = useLocation();

  const links = useNavBarLinks();

  return (
    <div
      className="group/sidenav absolute z-side-nav bg-white"
      style={{ height: `calc(100vh - ${TOP_NAV_HEIGHT}px)` }}
    >
      <Nav links={links} currentLocation={pathname} />
    </div>
  );
};
