import { gql } from '@apollo/client';

export const RESOURCE_AGGREGATION = gql`
  query getTotalCostAggregation(
    $where: ResourceFilterInput! = {}
    $groupBy: [ResourceGrouping!]
  ) {
    resourceAggregation(where: $where, groupBy: $groupBy) {
      count
      group
      totalCloudCost
      totalApplicativeCost
    }
  }
`;

export const POTENTIAL_SAVING_AGGREGATION = gql`
  query getPotentialSavingAggregation(
    $where: ResourceFilterInput! = {}
    $groupBy: [ResourceGrouping!]
  ) {
    resourceAggregation(where: $where, groupBy: $groupBy) {
      count
      group
      totalSaving
      totalCloudMonthlySavings
      totalApplicativeMonthlySavings
    }
  }
`;
