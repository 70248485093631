import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Download01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Download01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3C12.4971 3 12.9 3.40294 12.9 3.9V12.5272L15.8636 9.5636C16.2151 9.21213 16.7849 9.21213 17.1364 9.5636C17.4879 9.91508 17.4879 10.4849 17.1364 10.8364L12.6364 15.3364C12.2849 15.6879 11.7151 15.6879 11.3636 15.3364L6.8636 10.8364C6.51213 10.4849 6.51213 9.91508 6.8636 9.5636C7.21508 9.21213 7.78493 9.21213 8.1364 9.5636L11.1 12.5272V3.9C11.1 3.40294 11.5029 3 12 3ZM3.9 13.8C4.39706 13.8 4.8 14.2029 4.8 14.7V15.78C4.8 16.5509 4.8007 17.075 4.8338 17.4801C4.86604 17.8746 4.92447 18.0764 4.99619 18.2172C5.16876 18.5559 5.44413 18.8312 5.78282 19.0038C5.92358 19.0755 6.12536 19.134 6.51993 19.1662C6.92502 19.1993 7.44908 19.2 8.22 19.2H15.78C16.5509 19.2 17.075 19.1993 17.4801 19.1662C17.8746 19.134 18.0764 19.0755 18.2172 19.0038C18.5559 18.8312 18.8312 18.5559 19.0038 18.2172C19.0755 18.0764 19.134 17.8746 19.1662 17.4801C19.1993 17.075 19.2 16.5509 19.2 15.78V14.7C19.2 14.2029 19.6029 13.8 20.1 13.8C20.5971 13.8 21 14.2029 21 14.7V15.8172C21 16.5417 21 17.1396 20.9602 17.6266C20.9189 18.1325 20.8302 18.5976 20.6076 19.0344C20.2625 19.7117 19.7118 20.2625 19.0344 20.6076C18.5976 20.8302 18.1325 20.9189 17.6266 20.9602C17.1396 21 16.5417 21 15.8172 21H8.18281C7.45835 21 6.86043 21 6.37336 20.9602C5.86747 20.9189 5.40244 20.8302 4.96564 20.6076C4.28825 20.2625 3.73752 19.7117 3.39238 19.0344C3.16982 18.5976 3.08111 18.1325 3.03978 17.6266C2.99998 17.1396 2.99999 16.5416 3 15.8172L3 14.7C3 14.2029 3.40294 13.8 3.9 13.8Z"
        />
      </svg>
    );
  },
);

Download01Icon.displayName = 'Download01Icon';
