import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as VirtualMachinesColorIcon } from './variant-svgs/virtual machines-color.svg';
import { ReactComponent as VirtualMachinesDisabledIcon } from './variant-svgs/virtual machines-disabled.svg';
import { ReactComponent as VirtualMachinesBlackIcon } from './variant-svgs/virtual machines-regular.svg';

const VARIANT_ICON_MAP = {
  default: VirtualMachinesColorIcon,
  black: VirtualMachinesBlackIcon,
  disabled: VirtualMachinesDisabledIcon,
};

export const VirtualMachinesIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="virtual machines-icon"
      />
    );
  },
);

VirtualMachinesIcon.displayName = 'VirtualMachinesIcon';
