import { ComponentPropsWithoutRef, MouseEvent } from 'react';

import * as PopoverPrimitive from '@radix-ui/react-popover';

import { cn } from '@pointfive/ui/utils/utils';

import {
  PopoverContainer,
  PopoverContent as PopoverContentWrapper,
  PopoverTrigger,
} from './shadcn/popover';

export type PopoverProps = ComponentPropsWithoutRef<
  typeof PopoverPrimitive.Content
> & {
  isOpen?: boolean;
  isModal?: boolean;
  onClickOutside?: () => void;
  onTriggerClick?: () => void;
  triggerComponent: JSX.Element | null;
  popoverContent: JSX.Element;
  popoverContentProps?: Record<string, unknown>;
  className?: string;
  popoverContentClassName?: string;
  onClose?: () => void;
  disabled?: boolean;
};

// TODO: Dropdown is probably a better component to use here?
// https://ui.shadcn.com/docs/components/dropdown-menu
export const Popover = ({
  isOpen,
  isModal = true,
  onClickOutside,
  triggerComponent,
  onTriggerClick,
  popoverContent,
  align = 'center',
  popoverContentProps,
  // TODO: rename to triggerClassName
  className = '',
  popoverContentClassName = '',
  onClose = () => {},
  disabled,
  side,
  tabIndex,
  ...rest
}: PopoverProps) => {
  return (
    <PopoverContainer
      {...rest}
      modal={isModal}
      open={isOpen}
      onOpenChange={(openChange) => {
        // handle click outside
        if (isOpen && !openChange) {
          onClickOutside?.();
        }

        if (!openChange) {
          onClose?.();
        }
      }}
    >
      <PopoverTrigger asChild tabIndex={tabIndex}>
        {triggerComponent ? (
          <div
            className={className}
            onClick={(e: MouseEvent<HTMLElement>) => {
              if (disabled) {
                return;
              }

              e.stopPropagation();

              onTriggerClick?.();
            }}
          >
            {triggerComponent}
          </div>
        ) : null}
      </PopoverTrigger>
      <PopoverContentWrapper
        side={side}
        align={align}
        // TODO: remove w-max and p-3 from the outside
        className={cn(
          `h-full w-max border-none p-2 shadow-sm`,
          popoverContentClassName,
        )}
        {...popoverContentProps}
      >
        {popoverContent || null}
      </PopoverContentWrapper>
    </PopoverContainer>
  );
};
