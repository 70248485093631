import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as MemorydbColorIcon } from './variant-svgs/memorydb-color.svg';
import { ReactComponent as MemorydbDisabledIcon } from './variant-svgs/memorydb-disabled.svg';
import { ReactComponent as MemorydbBlackIcon } from './variant-svgs/memorydb-regular.svg';

const VARIANT_ICON_MAP = {
  default: MemorydbColorIcon,
  black: MemorydbBlackIcon,
  disabled: MemorydbDisabledIcon,
};

export const MemorydbIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="memorydb-icon"
      />
    );
  },
);

MemorydbIcon.displayName = 'MemorydbIcon';
