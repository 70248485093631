import { ReactNode } from 'react';

const linkUnderlineAnimationStyles = `
    relative 
    
    before:content-[''] 
    before:absolute 
    before:w-full 
    before:transition 
    before:h-[1px] 
    before:bottom-0 
    before:left-0 
    before:bg-primary-500
    before:scale-x-0 
    before:hover:scale-x-100 
    before:origin-top-left
    before:ease-in-out 
    before:duration-300
  `;

type LinkProps = {
  text: string;
  href?: string;
  openNewTab?: boolean;
};

// TODO: move to Link component
// TODO: move to @pointfive/ui
export const AnimatedLink = ({ text, href, openNewTab }: LinkProps) => {
  if (!href) {
    return (
      <span className={`text-primary-500 ${linkUnderlineAnimationStyles}`}>
        {text}
      </span>
    );
  }

  return (
    <a
      target={openNewTab ? '_blank' : undefined}
      rel={openNewTab ? 'noopener noreferrer' : undefined}
      href={href}
      className={`text-primary-500 ${linkUnderlineAnimationStyles}`}
    >
      {text}
    </a>
  );
};

type LinkButtonProps = {
  text: ReactNode;
  onClick: () => void;
  className?: string;
};

export const AnimatedLinkButton = ({
  text,
  onClick,
  className = '',
}: LinkButtonProps) => {
  return (
    // Converted so span to support multi-line text (div will break line)
    <span
      onClick={onClick}
      className={`w-fit cursor-pointer ${linkUnderlineAnimationStyles} ${className}`}
    >
      {text}
    </span>
  );
};
