import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const WarningIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Warning"
      >
        <path d="M12.0522 8.39969C12.5372 8.39969 12.9303 8.80264 12.9303 9.29971V12.8998C12.9303 13.3969 12.5372 13.7998 12.0522 13.7998C11.5673 13.7998 11.1742 13.3969 11.1742 12.8998V9.29971C11.1742 8.80264 11.5673 8.39969 12.0522 8.39969Z" />
        <path d="M12.0522 15.5999C11.5673 15.5999 11.1742 16.0028 11.1742 16.4999C11.1742 16.9969 11.5673 17.3999 12.0522 17.3999H12.061C12.5459 17.3999 12.9391 16.9969 12.9391 16.4999C12.9391 16.0028 12.5459 15.5999 12.061 15.5999H12.0522Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7126 3.34561C11.1055 3.11903 11.5487 3 11.9996 3C12.4504 3 12.8936 3.11903 13.2865 3.34561C13.6794 3.5722 14.0087 3.8987 14.2427 4.29364L14.2455 4.29832L21.6414 16.9526L21.6487 16.9653C21.8776 17.372 21.9988 17.8331 22 18.3026C22.0012 18.7722 21.8825 19.2339 21.6557 19.6419C21.4289 20.0499 21.1018 20.3899 20.7071 20.6281C20.3123 20.8663 19.8636 20.9945 19.4055 20.9999L19.3954 21L4.59451 20.9999C4.13644 20.9946 3.68771 20.8663 3.29294 20.6281C2.89817 20.3899 2.57112 20.0499 2.34429 19.6419C2.11747 19.2339 1.99877 18.7722 2.00001 18.3026C2.00125 17.8331 2.12237 17.372 2.35134 16.9653L2.35859 16.9527L9.75637 4.29363C9.9904 3.89869 10.3197 3.5722 10.7126 3.34561ZM11.9996 4.80004C11.8504 4.80004 11.7037 4.83943 11.5737 4.9144C11.4444 4.98901 11.3358 5.09635 11.2585 5.22618L3.86943 17.8703C3.79557 18.0035 3.75652 18.1541 3.75612 18.3075C3.75571 18.4629 3.79499 18.6156 3.87004 18.7506C3.94509 18.8856 4.05331 18.9981 4.18393 19.0769C4.31336 19.1551 4.46033 19.1974 4.61046 19.1999H19.3895C19.5397 19.1974 19.6866 19.1551 19.8161 19.0769C19.9467 18.9981 20.0549 18.8856 20.13 18.7506C20.205 18.6156 20.2443 18.4629 20.2439 18.3075C20.2435 18.1542 20.2044 18.0036 20.1306 17.8703L12.7418 5.22808L12.7407 5.22618C12.6633 5.09635 12.5547 4.98901 12.4254 4.9144C12.2954 4.83943 12.1487 4.80004 11.9996 4.80004Z"
        />
      </svg>
    );
  },
);

WarningIcon.displayName = 'WarningIcon';
