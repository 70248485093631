import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as CloudtrailColorIcon } from './variant-svgs/cloudtrail-color.svg';
import { ReactComponent as CloudtrailDisabledIcon } from './variant-svgs/cloudtrail-disabled.svg';
import { ReactComponent as CloudtrailBlackIcon } from './variant-svgs/cloudtrail-regular.svg';

const VARIANT_ICON_MAP = {
  default: CloudtrailColorIcon,
  black: CloudtrailBlackIcon,
  disabled: CloudtrailDisabledIcon,
};

export const CloudtrailIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="cloudtrail-icon"
      />
    );
  },
);

CloudtrailIcon.displayName = 'CloudtrailIcon';
