import { useState } from 'react';

import { UserMe } from '@pointfive/gql';
import { ListItem, Popover } from '@pointfive/ui/components';

import { NameButton } from './NameButton';
import { NO_USER_FOUND } from './constants';

type ProfileDropdownProps = {
  user: UserMe | undefined;
  logout: () => void;
};

export const ProfileDropdown = ({ user, logout }: ProfileDropdownProps) => {
  const [isOpen, setOpen] = useState(false);
  const closeDropdown = () => setOpen(false);
  const toggleDropdown = () => {
    setOpen((lastState) => !lastState);
  };

  const name = user ? `${user?.firstName} ${user?.lastName}` : NO_USER_FOUND;

  return (
    <Popover
      isOpen={isOpen}
      onClose={closeDropdown}
      side="bottom"
      align="center"
      className="w-full"
      popoverContentProps={{
        style: {
          padding: 0,
          marginTop: 4,
          marginRight: 20,
        },
      }}
      onClickOutside={closeDropdown}
      triggerComponent={
        <NameButton
          name={name}
          isOpen={isOpen}
          onClick={name !== NO_USER_FOUND ? toggleDropdown : undefined}
        />
      }
      popoverContent={
        <div className="flex flex-col items-start justify-start gap-0.5 rounded-lg bg-white p-3 shadow-sm">
          <ListItem
            text="Profile (Coming soon)"
            startIconName="user-01"
            iconSize={16}
            disabled
          />
          <ListItem
            text="Log out"
            startIconName="log-out-01"
            iconSize={16}
            onClick={() => logout?.()}
          />
        </div>
      }
    />
  );
};
