import { EmptyStateImage, ImageType } from '@pointfive/assets';

type EmptyStateImageComponentProps = {
  src?: string | ImageType;
  height?: number;
  width?: number;
};

const DEFAULT_SVG_HEIGHT = 124;
const DEFAULT_SVG_WIDTH = 124;

export const EmptyStateImageComponent = ({
  src,
  height = DEFAULT_SVG_HEIGHT,
  width = DEFAULT_SVG_WIDTH,
}: EmptyStateImageComponentProps) => {
  if (typeof src === 'string') {
    return (
      <img
        src={src}
        alt="Error, something went wrong"
        height={height}
        width={width}
      />
    );
  }

  if (src) {
    const ImageComponent = src;
    return <ImageComponent height={height} width={width} />;
  }

  return <EmptyStateImage height={height} width={width} />;
};
