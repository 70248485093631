import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Star01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Star01"
      >
        <path d="M16.8605 20.2562C16.5289 20.2562 16.2235 20.0991 15.7959 19.8723L12.0175 17.8914L8.23897 19.8723C7.73285 20.1428 7.40125 20.3173 6.97366 20.2388C6.61589 20.169 6.30174 19.9421 6.12721 19.6192C5.92651 19.2527 5.98759 18.8775 6.08358 18.3103L6.80786 14.1042L3.75367 11.1285C3.34353 10.7271 3.07302 10.4653 3.01193 10.0465C2.95957 9.67997 3.08174 9.31346 3.3348 9.04295C3.62277 8.73753 3.998 8.68517 4.56521 8.59791L8.78873 7.97834L10.6736 4.15623C10.9267 3.64138 11.0925 3.30106 11.4764 3.1178C11.808 2.96073 12.192 2.96073 12.5236 3.1178C12.9075 3.30106 13.0733 3.64138 13.3264 4.15623L15.2113 7.97834L19.4348 8.59791C20.002 8.68517 20.3772 8.73753 20.6652 9.04295C20.9183 9.31346 21.0404 9.67997 20.9881 10.0465C20.927 10.4653 20.6565 10.7271 20.2551 11.1285L17.2009 14.1042L17.9251 18.3103C18.0211 18.8775 18.0822 19.2527 17.8815 19.6192C17.707 19.9421 17.3928 20.169 17.0351 20.2388C16.9653 20.2475 16.9042 20.2562 16.8344 20.2562H16.8605ZM12.0262 16.2683C12.1047 16.2683 12.1833 16.2683 12.2618 16.2945C12.445 16.3294 12.5759 16.3992 12.7156 16.4777L16.3719 18.3975L15.6738 14.3223C15.6476 14.1565 15.6214 14.0082 15.6389 13.8337C15.6563 13.6766 15.7087 13.5283 15.7872 13.3886C15.8745 13.2316 15.9792 13.1268 16.1014 13.0134L19.0683 10.125L14.9757 9.52289C14.8099 9.49672 14.6615 9.47926 14.5044 9.40073C14.3561 9.33092 14.2339 9.24365 14.1205 9.12149C13.9983 8.99059 13.9372 8.8597 13.8587 8.70262L12.0262 5.00268L10.1937 8.71135C10.1151 8.86842 10.054 8.99932 9.93187 9.13021C9.82716 9.24365 9.69626 9.33964 9.55664 9.40073C9.39084 9.47926 9.25122 9.49672 9.0767 9.52289L4.98407 10.125L7.94228 13.0047C8.06445 13.1268 8.16916 13.2316 8.25643 13.3886C8.32624 13.5195 8.37859 13.6679 8.39605 13.825C8.4135 14.0082 8.39605 14.1478 8.36114 14.3136L7.66304 18.3888L11.3193 16.4603C11.459 16.3905 11.5899 16.3119 11.7644 16.277C11.8429 16.2596 11.9302 16.2509 12.0087 16.2509L12.0262 16.2683Z" />
      </svg>
    );
  },
);

Star01Icon.displayName = 'Star01Icon';
