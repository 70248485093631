import { v4 as uuidv4 } from 'uuid';

export const randomId = () => uuidv4();

export const StringFormats = {
  SnakeCase: 'SnakeCase',
  TitleCase: 'TitleCase',
} as const;

type FormatArguments = {
  originalString: string;
  formatFrom: keyof typeof StringFormats;
  formatTo: keyof typeof StringFormats;
  considerSpaces?: boolean;
};

export const formatToStringCase = ({
  originalString,
  formatFrom,
  formatTo,
  considerSpaces = false,
}: FormatArguments) => {
  if (typeof originalString !== 'string') {
    return;
  }

  if (!StringFormats[formatFrom] || !StringFormats[formatTo]) {
    throw new Error('Cannot format string without supported string formats');
  }

  if (
    formatFrom === StringFormats.SnakeCase &&
    formatTo === StringFormats.TitleCase
  ) {
    return originalString
      .split(considerSpaces ? /[ _]/g : '_')
      .map((keyword) => {
        return keyword.charAt(0).toUpperCase() + keyword.slice(1).toLowerCase();
      })
      .join(' ')
      .trim();
  }

  if (
    formatFrom === StringFormats.TitleCase &&
    formatTo === StringFormats.SnakeCase
  ) {
    return originalString.toLowerCase().replaceAll(' ', '_');
  }
};

export const formatPlural = (
  amount: number,
  textSingle: string,
  textPlural?: string | undefined,
  amountToDisplay?: string,
): string => {
  const plural = textPlural ?? `${textSingle}s`;
  const displayedAmount = amountToDisplay ?? amount;

  return `${displayedAmount} ${amount === 1 ? textSingle : plural}`;
};

export const sortStrings = (
  stringA: string,
  stringB: string,
  desc: boolean,
) => {
  const aVal = String(stringA) || '';
  const bVal = String(stringB) || '';

  return desc ? bVal.localeCompare(aVal) : aVal.localeCompare(bVal);
};
