import Sentry from 'services/sentry';

const numberRegex = /[+-]?\d+(\.\d+)?/g;

const numberFormatter = ({ num, locale }: { num: number; locale?: string }) =>
  num.toLocaleString(locale);

const defaultFormatter = ({
  str,
  locale,
  shorten,
  round,
}: {
  str: string;
  locale?: string;
  shorten?: boolean;
  round?: boolean;
}) => {
  if (shorten) {
    return shortenNumber(Number(str));
  }

  const roundedPreFormat = round ? Math.round(Number(str)) : str;
  return numberFormatter({
    num: Number(Number(roundedPreFormat).toFixed(2)),
    locale,
  });
};

export const formatNumber = ({
  original,
  locale,
  customFormatter,
  shorten,
  round,
}: {
  original: string | number;
  locale?: string;
  customFormatter?: (original: string) => string;
  shorten?: boolean;
  round?: boolean;
}) => {
  const originalString =
    typeof original === 'string' ? original : String(original);

  const formattedNumber = originalString.replace(numberRegex, (match) => {
    return (
      customFormatter?.(match) ||
      defaultFormatter({ str: match, locale, shorten, round })
    );
  });

  return formattedNumber;
};

export const shortenNumber = (n: number) => {
  if (typeof n !== 'number') {
    return '-';
  }

  const roundedNumber = Math.round(n);

  if (n < 1e3) {
    return roundedNumber.toString();
  }
  if (n >= 1e3 && n < 1e6) {
    return +(roundedNumber / 1e3).toFixed(0) + 'K';
  }
  if (n >= 1e6 && n < 1e9) {
    return +(roundedNumber / 1e6).toFixed(0) + 'M';
  }
  if (n >= 1e9 && n < 1e12) {
    return +(roundedNumber / 1e9).toFixed(0) + 'B';
  }
  if (n >= 1e12) {
    return +(roundedNumber / 1e12).toFixed(0) + 'T';
  }

  return roundedNumber.toFixed(0);
};

export const isValidPrice = (price: number): boolean => {
  return Number.isFinite(price);
};

export type formatPriceOptions = {
  originalPrice: number;
  shouldShortenNumber?: boolean;
  skipLowerThanOneCondition?: boolean;
  zeroFallback?: string;
};

export const NO_VALUE_FALLBACK = '-';

export const formatPrice = ({
  originalPrice,
  shouldShortenNumber,
  skipLowerThanOneCondition,
  zeroFallback = NO_VALUE_FALLBACK,
}: formatPriceOptions) => {
  if (!isValidPrice(originalPrice)) {
    Sentry.captureException(
      new Error(`formatPrice: price is not a number: ${typeof originalPrice}`),
    );
    return zeroFallback;
  }

  if (originalPrice === 0) {
    return zeroFallback;
  }

  if (originalPrice > 0 && originalPrice < 1 && !skipLowerThanOneCondition) {
    return '<$1';
  }

  const formattedPrice = formatNumber({
    // We want to avoid adding the minus to the formattedPrice string before adding the price sign.
    original: Math.abs(originalPrice),
    shorten: shouldShortenNumber,
    round: !shouldShortenNumber,
  });

  if (originalPrice < 0) {
    return `-$${formattedPrice}`;
  }

  return `$${formattedPrice}`;
};

export const sortByNumbers = (
  numberA: number,
  numberB: number,
  desc: boolean,
) => (desc ? numberB - numberA : numberA - numberB);
