import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Automations01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Automations01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3382 1.22024C13.6844 1.3705 13.89 1.73096 13.8432 2.10538L12.9626 9.14995L19.3242 9.14995C19.5683 9.14991 19.8061 9.14988 19.9985 9.1673C20.1818 9.1839 20.5042 9.22647 20.7807 9.4499C21.1037 9.71087 21.2883 10.1057 21.2821 10.5202C21.2767 10.8756 21.1031 11.1507 20.9984 11.3022C20.8886 11.4612 20.7362 11.6439 20.5798 11.8315L11.6528 22.5441C11.4112 22.834 11.0075 22.9299 10.6613 22.7797C10.3152 22.6294 10.1095 22.2689 10.1563 21.8945L11.0369 14.8499L4.67539 14.85C4.43121 14.85 4.19343 14.85 4.00101 14.8326C3.81769 14.816 3.49533 14.7734 3.21879 14.55C2.89618 14.2892 2.71117 13.8945 2.71744 13.4796C2.72281 13.1241 2.89643 12.8493 3.00108 12.6977C3.11095 12.5387 3.2633 12.3559 3.41973 12.1683L12.3468 1.45579C12.5884 1.16591 12.9921 1.06999 13.3382 1.22024ZM4.81459 13.1499H11.9998C12.2436 13.1499 12.4757 13.2546 12.637 13.4374C12.7984 13.6202 12.8735 13.8635 12.8432 14.1054L12.2028 19.2285L19.1849 10.8499H11.9998C11.756 10.8499 11.5239 10.7453 11.3626 10.5625C11.2012 10.3797 11.1261 10.1364 11.1563 9.89452L11.7967 4.77135L4.81459 13.1499Z"
        />
      </svg>
    );
  },
);

Automations01Icon.displayName = 'Automations01Icon';
