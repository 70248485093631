import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as JobColorIcon } from './variant-svgs/job-color.svg';
import { ReactComponent as JobDisabledIcon } from './variant-svgs/job-disabled.svg';
import { ReactComponent as JobBlackIcon } from './variant-svgs/job-regular.svg';

const VARIANT_ICON_MAP = {
  default: JobColorIcon,
  black: JobBlackIcon,
  disabled: JobDisabledIcon,
};

export const JobIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="job-icon"
      />
    );
  },
);

JobIcon.displayName = 'JobIcon';
