import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as EksColorIcon } from './variant-svgs/eks-color.svg';
import { ReactComponent as EksDisabledIcon } from './variant-svgs/eks-disabled.svg';
import { ReactComponent as EksBlackIcon } from './variant-svgs/eks-regular.svg';

const VARIANT_ICON_MAP = {
  default: EksColorIcon,
  black: EksBlackIcon,
  disabled: EksDisabledIcon,
};

export const EksIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="eks-icon"
      />
    );
  },
);

EksIcon.displayName = 'EksIcon';
