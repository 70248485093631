import { Link, useLocation } from 'react-router-dom';

import {
  AutomationsButton,
  ProfileDropdown,
  SettingsButton,
} from '@pointfive/ui/components/features/TopNav';

import { useRouteTitle } from 'router/routeTitle';
import { USER_ROLES, useAuth } from 'services/auth';
import { FeatureFlags, useFeatureFlagsValue } from 'services/featureFlags';

import navLogoSymbol from '../../../assets/logo-white.svg';
import { ROUTE_CONSTANTS } from '../../router/constants';
import { OnlineHelpButton } from './components';
import { TOP_NAV_HEIGHT } from './constants';

export const TopNav = () => {
  const title = useRouteTitle();
  const { pathname } = useLocation();
  const user = useAuth.use.user();
  const isAutomationsPageEnabled = useFeatureFlagsValue(
    FeatureFlags.AutomationsPage,
  );

  return (
    <div
      className="flex w-full bg-neutral-800 text-white"
      style={{ height: `${TOP_NAV_HEIGHT}px` }}
    >
      <Link
        to="/"
        className="flex h-full w-12 shrink-0 cursor-pointer justify-center"
      >
        <img src={navLogoSymbol} alt="Pointfive logo" className="w-5" />
      </Link>

      <div className="flex w-full items-center px-5">
        <div className="w-full font-bold">{title}</div>
        <div className="flex h-full items-center gap-1">
          {isAutomationsPageEnabled && user?.role === USER_ROLES.ADMIN ? (
            <AutomationsButton
              isSelected={pathname?.startsWith(
                `/${ROUTE_CONSTANTS.automations}`,
              )}
            />
          ) : null}
          <OnlineHelpButton user={user} />
          {user?.role === USER_ROLES.ADMIN ? (
            <SettingsButton
              isSelected={pathname?.startsWith(`/${ROUTE_CONSTANTS.settings}`)}
            />
          ) : null}
          <ProfileDropdown
            user={user}
            logout={() => {
              window.location.href = `/${ROUTE_CONSTANTS.logout}`;
            }}
          />
        </div>
      </div>
    </div>
  );
};
