import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as ElasticSearchColorIcon } from './variant-svgs/elastic-search-color.svg';
import { ReactComponent as ElasticSearchDisabledIcon } from './variant-svgs/elastic-search-disabled.svg';
import { ReactComponent as ElasticSearchBlackIcon } from './variant-svgs/elastic-search-regular.svg';

const VARIANT_ICON_MAP = {
  default: ElasticSearchColorIcon,
  black: ElasticSearchBlackIcon,
  disabled: ElasticSearchDisabledIcon,
};

export const ElasticSearchIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="elastic-search-icon"
      />
    );
  },
);

ElasticSearchIcon.displayName = 'ElasticSearchIcon';
