import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as StorageaccountsColorIcon } from './variant-svgs/storageaccounts-color.svg';
import { ReactComponent as StorageaccountsDisabledIcon } from './variant-svgs/storageaccounts-disabled.svg';
import { ReactComponent as StorageaccountsBlackIcon } from './variant-svgs/storageaccounts-regular.svg';

const VARIANT_ICON_MAP = {
  default: StorageaccountsColorIcon,
  black: StorageaccountsBlackIcon,
  disabled: StorageaccountsDisabledIcon,
};

export const StorageaccountsIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="storageaccounts-icon"
      />
    );
  },
);

StorageaccountsIcon.displayName = 'StorageaccountsIcon';
