import { lazyWithRetries } from '../../router/lazyWithRetries';

const importRouter = () => import('./router.index');

export const automationsElements = {
  Automations: lazyWithRetries(async () => (await importRouter()).Automations),
  NewAutomation: lazyWithRetries(
    async () => (await importRouter()).NewAutomation,
  ),
  EditAutomation: lazyWithRetries(
    async () => (await importRouter()).EditAutomation,
  ),
};
