import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Hash02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Hash02"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.3163 3.01126C10.762 3.08555 11.0631 3.5071 10.9889 3.95282L10.3901 7.54558H16.0039L16.6475 3.6838C16.7218 3.23808 17.1433 2.93697 17.5891 3.01126C18.0348 3.08555 18.3359 3.5071 18.2616 3.95282L17.6628 7.54558H20.1818C20.6337 7.54558 21 7.91189 21 8.36376C21 8.81563 20.6337 9.18195 20.1818 9.18195H17.3901L16.4507 14.8183H19.2727C19.7246 14.8183 20.0909 15.1846 20.0909 15.6365C20.0909 16.0884 19.7246 16.4547 19.2727 16.4547H16.178L15.5343 20.3165C15.46 20.7622 15.0385 21.0633 14.5928 20.989C14.147 20.9147 13.8459 20.4932 13.9202 20.0474L14.519 16.4547H8.90522L8.26159 20.3165C8.1873 20.7622 7.76575 21.0633 7.32003 20.989C6.87431 20.9147 6.5732 20.4932 6.64749 20.0474L7.24628 16.4547H3.81818C3.36631 16.4547 3 16.0884 3 15.6365C3 15.1846 3.36631 14.8183 3.81818 14.8183H7.51901L8.4584 9.18195H4.72727C4.2754 9.18195 3.90909 8.81563 3.90909 8.36376C3.90909 7.91189 4.2754 7.54558 4.72727 7.54558H8.73113L9.37476 3.6838C9.44905 3.23808 9.8706 2.93697 10.3163 3.01126ZM10.1173 9.18195L9.17794 14.8183H14.7917L15.7311 9.18195H10.1173Z"
        />
      </svg>
    );
  },
);

Hash02Icon.displayName = 'Hash02Icon';
