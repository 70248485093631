import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const ClockFastForwardIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="ClockFastForward"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 12C2 6.47715 6.47716 2 12 2C17.1343 2 21.3649 5.86936 21.9347 10.8511L21.9928 10.793C22.3833 10.4024 23.0164 10.4023 23.407 10.7928C23.7976 11.1833 23.7977 11.8164 23.4072 12.207L21.4077 14.207C21.0172 14.5976 20.3841 14.5977 19.9935 14.2072L17.993 12.2072C17.6024 11.8167 17.6023 11.1836 17.9928 10.793C18.3833 10.4024 19.0164 10.4023 19.407 10.7928L19.9748 11.3605C19.649 7.2412 16.203 4 12 4C7.58173 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C14.5127 20 16.7543 18.8427 18.2226 17.0282C18.5701 16.5989 19.1997 16.5325 19.6291 16.8799C20.0584 17.2274 20.1248 17.857 19.7774 18.2864C17.9457 20.5499 15.1419 22 12 22C6.47716 22 2 17.5229 2 12ZM12 6C12.5523 6 13 6.44772 13 7V11.4648L15.5547 13.1679C16.0142 13.4743 16.1384 14.0952 15.8321 14.5547C15.5257 15.0142 14.9048 15.1384 14.4453 14.8321L11.4453 12.832C11.1671 12.6466 11 12.3344 11 12V7C11 6.44772 11.4477 6 12 6Z"
        />
      </svg>
    );
  },
);

ClockFastForwardIcon.displayName = 'ClockFastForwardIcon';
