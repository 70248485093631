import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Flag06Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Flag06"
      >
        <path d="M3.85 21.7C3.38 21.7 3 21.32 3 20.85V3.85C3 3.78 3 3.72 3.02 3.65C3.04 3.41 3.09 3.21 3.2 3.01C3.38 2.66 3.66 2.38 4.01 2.2C4.41 2 4.8 2 5.45 2H11.25C11.9 2 12.29 2 12.69 2.2C13.04 2.38 13.32 2.66 13.5 3.01C13.65 3.31 13.69 3.6 13.7 4H19.25C19.9 4 20.29 4 20.69 4.2C21.04 4.38 21.32 4.66 21.5 5.01C21.7 5.41 21.7 5.8 21.7 6.45V13.25C21.7 13.9 21.7 14.3 21.5 14.69C21.32 15.04 21.04 15.32 20.69 15.5C20.3 15.7 19.9 15.7 19.25 15.7H14.45C13.8 15.7 13.41 15.7 13.01 15.5C12.66 15.32 12.38 15.04 12.2 14.69C12.05 14.39 12.01 14.09 12 13.68C11.79 13.7 11.54 13.7 11.25 13.7H4.7V20.85C4.7 21.32 4.32 21.7 3.85 21.7ZM13.7 11.25V13.25C13.7 13.55 13.7 13.85 13.72 13.94C13.84 14 14.15 14 14.45 14H19.25C19.55 14 19.85 14 19.94 13.98C20 13.86 20 13.55 20 13.25V6.45C20 6.15 20 5.85 19.98 5.76C19.86 5.7 19.55 5.7 19.25 5.7H13.7V11.25ZM4.7 12H11.25C11.55 12 11.85 12 11.94 11.98C12 11.86 12 11.56 12 11.26V4.45C12 4.15 12 3.85 11.98 3.76C11.86 3.7 11.55 3.7 11.25 3.7H5.45C5.15 3.7 4.84 3.7 4.76 3.72C4.7 3.84 4.7 4.15 4.7 4.45V12Z" />
      </svg>
    );
  },
);

Flag06Icon.displayName = 'Flag06Icon';
