import { GET_DISTINCT_TAG_KEYS_VALUES } from 'domains/opportunities/filters/queries';

import { ANALYTICS_DASHBOARDS, ANALYTICS_DASHBOARD_TOKEN } from './analytics';
import {
  CLOUD_INTELLIGENCE_CREATORS,
  CLOUD_INTELLIGENCE_DASHBOARDS,
  CLOUD_INTELLIGENCE_DASHBOARDS_CREATED_BY_FILTER,
  CLOUD_INTELLIGENCE_DASHBOARDS_PROVIDER_FILTER,
  CLOUD_INTELLIGENCE_DASHBOARDS_USER_PERMISSIONS_FILTER,
  CLOUD_INTELLIGENCE_DASHBOARD_COUNT,
  CLOUD_INTELLIGENCE_DASHBOARD_EXPORT,
  CLOUD_INTELLIGENCE_DASHBOARD_TOKEN,
  CLOUD_INTELLIGENCE_MY_PERMISSIONS,
  CLOUD_INTELLIGENCE_WORKSPACE_ENABLE,
  DELETE_CLOUD_INTELLIGENCE_PERMISSIONS,
  GET_MEMBERS_LIST,
  GET_TEAM_NAME_LIST,
  UPDATE_CLOUD_INTELLIGENCE_CREATORS,
  UPDATE_CLOUD_INTELLIGENCE_PERMISSIONS,
} from './cloud-intelligence';
import {
  COMMITMENTS_BASELINE_USAGE,
  COMMITMENTS_COST_AGGREGATION,
  COMMITMENTS_DATA_INTEGRITY,
  COMMITMENTS_UTILIZATION_RATES,
  COMMITMENT_INSIGHTS,
  COMMITMENT_INSIGHTS_FILTERS,
  EFFECTIVE_SAVINGS_RATE,
} from './commitments';
import {
  INTEGRATIONS_STATUS,
  K8S_CLUSTERS_STATUS,
  NOTIFICATION_RULES_STATUS,
  STATISTICS_AGGREGATION,
} from './executive-dashboard';
import { INTEGRATION_SCRIPT, LIST_INTEGRATIONS } from './integrations';
// TODO: why the key name must match the query name?
import {
  GET_JIRA_INTEGRATION,
  GET_JIRA_PROJECT,
  GET_JIRA_PROJECTS,
  GET_JIRA_TICKET_OF_O9Y,
  GET_JIRA_USERS,
} from './jira';
import {
  POTENTIAL_SAVING_AGGREGATION,
  RESOURCE_AGGREGATION,
} from './resources';
import { GET_TEAM, GET_TEAMS_LIST, GET_TEAMS_TREE } from './teams';
import { GET_USERS_LIST_WITH_TEAMS, USER_ME } from './user';

// Collection of all our basic queries.
// Can be overridden by the usePFQuery hook.

// TODO: split to objects, for example all the jira queries in one object.
export const QUERIES = {
  me: USER_ME,
  analyticsDashboards: ANALYTICS_DASHBOARDS,
  analyticsDashboardToken: ANALYTICS_DASHBOARD_TOKEN,
  integrations: LIST_INTEGRATIONS,
  opportunityFilters: GET_DISTINCT_TAG_KEYS_VALUES,
  jiraIntegrations: GET_JIRA_INTEGRATION,
  jiraProjects: GET_JIRA_PROJECTS,
  jiraProject: GET_JIRA_PROJECT,
  jiraUsers: GET_JIRA_USERS,
  jiraTicketForO9y: GET_JIRA_TICKET_OF_O9Y,
  statisticsAggregation: STATISTICS_AGGREGATION,
  executiveDashboardIntegrationsStatus: INTEGRATIONS_STATUS,
  executiveDashboardNotificationRulesStatus: NOTIFICATION_RULES_STATUS,
  executiveDashboardK8sClustersStatus: K8S_CLUSTERS_STATUS,
  resourceAggregation: RESOURCE_AGGREGATION,
  potentialSavingAggregation: POTENTIAL_SAVING_AGGREGATION,
  listTeams: GET_TEAMS_LIST,
  listTeamsTree: GET_TEAMS_TREE,
  getTeam: GET_TEAM,
  commitmentsCostAggregation: COMMITMENTS_COST_AGGREGATION,
  commitmentsBaselineUsage: COMMITMENTS_BASELINE_USAGE,
  commitmentsUtilizationRates: COMMITMENTS_UTILIZATION_RATES,
  effectiveSavingsRate: EFFECTIVE_SAVINGS_RATE,
  commitmentsDataIntegrity: COMMITMENTS_DATA_INTEGRITY,
  commitmentsInsights: COMMITMENT_INSIGHTS,
  commitmentsInsightsFilters: COMMITMENT_INSIGHTS_FILTERS,
  cloudIntelligenceDashboards: CLOUD_INTELLIGENCE_DASHBOARDS,
  cloudIntelligenceDashboardsCreatedByFilter:
    CLOUD_INTELLIGENCE_DASHBOARDS_CREATED_BY_FILTER,
  cloudIntelligenceDashboardsUserPermissionsFilter:
    CLOUD_INTELLIGENCE_DASHBOARDS_USER_PERMISSIONS_FILTER,
  cloudIntelligenceDashboardsProviderFilter:
    CLOUD_INTELLIGENCE_DASHBOARDS_PROVIDER_FILTER,
  cloudIntelligenceDashboardCount: CLOUD_INTELLIGENCE_DASHBOARD_COUNT,
  cloudIntelligenceDashboardToken: CLOUD_INTELLIGENCE_DASHBOARD_TOKEN,
  cloudIntelligenceMyPermissions: CLOUD_INTELLIGENCE_MY_PERMISSIONS,
  cloudIntelligenceWorkspaceEnable: CLOUD_INTELLIGENCE_WORKSPACE_ENABLE,
  cloudIntelligenceCreators: CLOUD_INTELLIGENCE_CREATORS,
  cloudIntelligenceDashboardExport: CLOUD_INTELLIGENCE_DASHBOARD_EXPORT,
  updateCloudIntelligenceCreators: UPDATE_CLOUD_INTELLIGENCE_CREATORS,
  updateCloudIntelligencePermissions: UPDATE_CLOUD_INTELLIGENCE_PERMISSIONS,
  deleteCloudIntelligencePermissions: DELETE_CLOUD_INTELLIGENCE_PERMISSIONS,
  getTeamList: GET_TEAM_NAME_LIST,
  getMemberList: GET_MEMBERS_LIST,
  integrationScript: INTEGRATION_SCRIPT,

  // TODO: TMP patch. JOIN with team members crashes BE.
  get_users_with_teams: GET_USERS_LIST_WITH_TEAMS,
};

// TODO: move the generated types into gql folder and import them here?
export type QueryNames = keyof typeof QUERIES;
