import { PropsWithChildren, useEffect } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { ErrorStateImage } from '@pointfive/assets/images';
import {
  ConfirmationModal,
  ConfirmationModalVariants,
} from '@pointfive/ui/components/base/Modal/ConfirmationModal';

import { useAuth } from 'services/auth';
import Sentry from 'services/sentry';

import { EmptyState } from '../EmptyState';
import {
  DEFAULT_CTA_TEXT,
  DEFAULT_LINK_HREF,
  DEFAULT_LINK_TEXT,
  DEFAULT_SECONDARY_TEXT,
  DEFAULT_SVG_HEIGHT,
  DEFAULT_SVG_WIDTH,
  DEFAULT_TITLE,
} from './constants';

export const useSentryOnMount = (source: string) => {
  const user = useAuth.use.user();
  const { state: originalError } = useLocation();

  // Try to collect additional context for the extra field
  const errMessage = originalError?.message;
  const errStack = originalError?.stack;
  const errStatusCode = originalError?.response?.status; // in case of axios error
  const errCause = originalError?.cause;
  // End of context collection

  useEffect(() => {
    const su = user?.isSuperUser ? ' SU' : '';
    const oid = user?.originalUserId ? ` ${user.originalUserId}` : '';
    const email = user?.email ? ` ${user.email}` : '';

    Sentry.captureException(
      new Error(
        `[email:${email} ${su ? 'su:' + su : ''} ${
          oid ? 'oid:' + oid : ''
        }] ErrorBoundaryFallback rendered from: ${source}`,
      ),
      {
        extra: {
          errMessage,
          errStack,
          errStatusCode,
          errCause,
        },
      },
    );
  }, []);
};

export type ErrorBoundaryFallbackProps = {
  className?: string;
  imageSrc?: string;
  title?: string;
  secondaryText?: string;
  linkText?: string;
  linkHref?: string;
  svgHeight?: number;
  svgWidth?: number;
  source: string;
  hideSVG?: boolean;
  hideButton?: boolean;
  ctaButtonText?: string;
  ctaButtonOnClick?: () => void;
};

// TODO: refactor error component (https://github.com/orgs/PointFiveInc/projects/4?pane=issue&itemId=37611882)
export const ErrorBoundaryFallback = ({
  className,
  imageSrc,
  secondaryText,
  title,
  linkHref,
  linkText,
  svgHeight,
  svgWidth,
  source,
  hideSVG,
  hideButton,
  ctaButtonText,
  ctaButtonOnClick,
}: ErrorBoundaryFallbackProps) => {
  useSentryOnMount(source);
  const doRefresh = () => window.location.reload();

  return (
    <EmptyState
      className={className}
      imageSrc={imageSrc ?? ErrorStateImage}
      CTAButtonText={ctaButtonText ?? DEFAULT_CTA_TEXT}
      CTAButtonOnClick={() =>
        ctaButtonOnClick ? ctaButtonOnClick() : doRefresh()
      }
      linkHref={linkHref ?? DEFAULT_LINK_HREF}
      linkText={linkText ?? DEFAULT_LINK_TEXT}
      svgWidth={svgWidth ?? DEFAULT_SVG_WIDTH}
      svgHeight={svgHeight ?? DEFAULT_SVG_HEIGHT}
      title={title ?? DEFAULT_TITLE}
      secondaryText={secondaryText ?? DEFAULT_SECONDARY_TEXT}
      hideSVG={hideSVG}
      hideButton={hideButton}
    />
  );
};

// TODO: https://linear.app/pointfive/issue/PF-3509/fe-or-wrap-portals-with-error-boundaries
export const PortalErrorBoundary = ({
  children,
  closeModal,
}: PropsWithChildren<{
  closeModal: () => void;
}>) => {
  return (
    <ErrorBoundary
      fallback={
        <ConfirmationModal
          variant={ConfirmationModalVariants.confirmation}
          title={'Something went wrong'}
          onClose={closeModal}
          hideActionButtons
        >
          <div className="pb-7">
            We&apos;re doing our best to fix it soon. Please try again later.
          </div>
        </ConfirmationModal>
      }
    >
      {children}
    </ErrorBoundary>
  );
};
