/* eslint-disable @typescript-eslint/naming-convention */
import {
  SUPPORTED_SERVICES,
  SUPPORTED_TYPES,
} from 'domains/resource/resourceDetails/constants';

type ValueOf<T> = T[keyof T];

export type SupportedServicesMap = Omit<
  Record<ValueOf<typeof SUPPORTED_SERVICES>, string>,
  'Organization'
>;

export const SERVICE_TO_ICON_NAME_MAP: SupportedServicesMap = {
  [SUPPORTED_SERVICES.ec2]: 'ec2-service-icon',
  [SUPPORTED_SERVICES.rds]: 'rds-service-icon',
  [SUPPORTED_SERVICES.eks]: 'eks-service-icon',
  [SUPPORTED_SERVICES.redshift]: 'redshift-service-icon',
  [SUPPORTED_SERVICES.lambda]: 'lambda-service-icon',
  [SUPPORTED_SERVICES.dynamoDB]: 'dynamodb-outline',
  [SUPPORTED_SERVICES.ebs]: 'ebs-service-icon',
  [SUPPORTED_SERVICES.s3]: 's3-service-icon',
  [SUPPORTED_SERVICES.cloudfront]: 'cloudfront-service-icon',
  [SUPPORTED_SERVICES.vpc]: 'vpc-service-icon',
  [SUPPORTED_SERVICES.cloudWatch]: 'cloudwatch-service-icon',
  [SUPPORTED_SERVICES.elb]: 'elastic-load-balancer-outline',
  [SUPPORTED_SERVICES.elastiCache]: 'elasticache-outline',
  [SUPPORTED_SERVICES.cloudTrail]: 'cloudtrail',
  [SUPPORTED_SERVICES.bigQuery]: 'gcp-big-query-icon',
  [SUPPORTED_SERVICES.compute]: 'gcp-compute-disk-icon',
  [SUPPORTED_SERVICES.storage]: 'gcp-storage-icon',
  [SUPPORTED_SERVICES.gke]: 'gcp-container-cluster-icon',
  [SUPPORTED_SERVICES.logging]: 'gcp-logging-icon',
  [SUPPORTED_SERVICES.sql]: 'gcp-sql-instance-icon',
  [SUPPORTED_SERVICES.memorystore]: 'gcp-memorystore-icon',
  [SUPPORTED_SERVICES.azureSql]: 'gcp-sql-instance-icon',
  [SUPPORTED_SERVICES.azureCompute]: 'gcp-compute-disk-icon',
  [SUPPORTED_SERVICES.azureContainer]: 'azure-container-cluster-icon',
  [SUPPORTED_SERVICES.azureStorage]: 'gcp-storage-icon',
};

export const serviceToOutlineIconNameMap: SupportedServicesMap = {
  [SUPPORTED_SERVICES.ec2]: 'ec2-instance-outline',
  [SUPPORTED_SERVICES.rds]: 'rds-cluster-outline',
  [SUPPORTED_SERVICES.eks]: 'eks-outline',
  [SUPPORTED_SERVICES.redshift]: 'redshift-outline',
  [SUPPORTED_SERVICES.lambda]: 'lambda-outline',
  [SUPPORTED_SERVICES.dynamoDB]: 'dynamodb-outline',
  [SUPPORTED_SERVICES.ebs]: 'ebs-outline',
  [SUPPORTED_SERVICES.s3]: 's3-bucket-outline',
  [SUPPORTED_SERVICES.cloudfront]: 'cloudfront-outline',
  [SUPPORTED_SERVICES.vpc]: 'vpc-service-icon',
  [SUPPORTED_SERVICES.cloudWatch]: 'cloudwatch-service-icon', // TODO: switch to outline
  [SUPPORTED_SERVICES.elb]: 'elastic-load-balancer-outline',
  [SUPPORTED_SERVICES.elastiCache]: 'elasticache-outline',
  [SUPPORTED_SERVICES.cloudTrail]: 'cloudtrail-outline',
  [SUPPORTED_SERVICES.bigQuery]: 'gcp-big-query-icon',
  [SUPPORTED_SERVICES.compute]: 'gcp-compute-disk-icon',
  [SUPPORTED_SERVICES.storage]: 'gcp-storage-icon',
  [SUPPORTED_SERVICES.gke]: 'gcp-container-cluster-icon',
  [SUPPORTED_SERVICES.logging]: 'gcp-logging-icon',
  [SUPPORTED_SERVICES.sql]: 'gcp-sql-instance-icon',
  [SUPPORTED_SERVICES.memorystore]: 'gcp-memorystore-icon',
  [SUPPORTED_SERVICES.azureContainer]: 'azure-container-cluster-icon',
  [SUPPORTED_SERVICES.azureStorage]: 'gcp-storage-icon',
  [SUPPORTED_SERVICES.azureSql]: 'gcp-sql-instance-icon',
  [SUPPORTED_SERVICES.azureCompute]: 'gcp-sql-instance-icon',
};

type SupportedGenericMap = Pick<
  Record<keyof typeof SUPPORTED_TYPES, string>,
  'user' | 'team' | 'organization' | 'account' | 'region'
>;

export const GENERIC_GRAPH_ICON_MAP: SupportedGenericMap = {
  user: 'attribution-user-icon',
  team: 'attribution-team-icon',
  organization: 'organization-icon',
  account: 'account-icon',
  region: 'region-outline',
};

export const SERVICE_RESOURCE_TYPE_GRAPH_ICONS: Partial<
  Record<
    keyof SupportedServicesMap,
    Partial<Record<keyof typeof SUPPORTED_TYPES, string>>
  >
> = {
  [SUPPORTED_SERVICES.s3]: {
    bucket: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.s3],
  },
  [SUPPORTED_SERVICES.ec2]: {
    instance: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.ec2],
    ami: 'ec2-ami-icon',
    'Launch Template': 'ec2-launch-template-icon',
    'Auto Scaling Group': 'ec2-auto-scaling-group-icon',
  },
  [SUPPORTED_SERVICES.ebs]: {
    volume: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.ebs],
    snapshot: 'ebs-snapshot-icon',
  },
  [SUPPORTED_SERVICES.cloudfront]: {
    distribution: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.cloudfront],
  },
  [SUPPORTED_SERVICES.vpc]: {
    endpoint: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.vpc],
    'Nat Gateway': 'vpc-nat-gateway-outline',
  },
  [SUPPORTED_SERVICES.cloudWatch]: {
    'Log Group': 'cloudwatch-service-icon',
  },
  [SUPPORTED_SERVICES.elb]: {
    elb: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.elb],
  },
  [SUPPORTED_SERVICES.eks]: {
    cluster: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.eks],
    nodegroup: 'ec2-auto-scaling-group-icon', // most similar service that has icon (nodegroup is basically an ASG for K8s)
    deployment: 'k8s-deployment-icon',
    namespace: 'k8s-namespace-icon',
    statefulset: 'k8s-statefulset-icon',
    daemonset: 'k8s-daemonset-icon',
    cronJob: 'k8s-cronjob-icon',
    job: 'k8s-job-icon',
    replicaset: 'k8s-daemonset-icon',
    karpenterProvisioner: 'k8s-daemonset-icon',
    karpenterNodeTemplate: 'k8s-daemonset-icon',
    karpenterNodePool: 'k8s-daemonset-icon',
    karpenterNodeClass: 'k8s-daemonset-icon',
  },
  [SUPPORTED_SERVICES.elastiCache]: {
    globalDatastore: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.elastiCache],
    cluster: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.elastiCache],
    node: 'elasticache-node-icon',
  },
  [SUPPORTED_SERVICES.dynamoDB]: {
    table: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.dynamoDB],
  },
  [SUPPORTED_SERVICES.cloudTrail]: {
    globalDatastore: 'cloudtrail',
    cluster: 'cloudtrail',
    node: 'cloudtrail',
    karpenterNodeClass: 'k8s-daemonset-icon',
  },
  [SUPPORTED_SERVICES.logging]: {
    LoggingBucket: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.logging],
  },
  [SUPPORTED_SERVICES.bigQuery]: {
    BigQueryDataset: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.bigQuery],
  },
  [SUPPORTED_SERVICES.storage]: {
    StorageBucket: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.storage],
  },
  [SUPPORTED_SERVICES.compute]: {
    ComputeDisk: 'gcp-compute-disk-icon',
    ComputeInstance: 'gcp-compute-disk-icon', // TODO: switch to compute instance icon
    ComputeInstanceGroup: 'gcp-compute-disk-icon', // TODO: switch to compute instance icon
  },
  [SUPPORTED_SERVICES.gke]: {
    ContainerCluster: 'gcp-container-cluster-icon',
  },
  [SUPPORTED_SERVICES.sql]: {
    SqlInstance: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.sql],
  },
  [SUPPORTED_SERVICES.memorystore]: {
    MemoryStoreRedis: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.memorystore],
  },
  [SUPPORTED_SERVICES.azureContainer]: {
    ContainerCluster: 'azure-container-cluster-icon',
  },
  [SUPPORTED_SERVICES.azureCompute]: {
    ComputeDisk: 'gcp-compute-disk-icon',
    ComputeInstance: 'gcp-compute-disk-icon',
  },
  [SUPPORTED_SERVICES.azureStorage]: {
    StorageAccount: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.azureStorage],
  },
  [SUPPORTED_SERVICES.azureSql]: {
    SQLInstance: SERVICE_TO_ICON_NAME_MAP[SUPPORTED_SERVICES.azureSql],
  },
};
