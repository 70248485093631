// TODO: think about.
// The DX can be better. Consider absolute paths? or nested object? add the "/" here?
export const ROUTE_CONSTANTS = {
  default: '',
  content: '/',
  overview: 'overview',
  resources: 'inventory',
  resource: 'inventory/:id',
  resourceOverview: 'overview',
  resourceAnalysis: 'analysis',
  resourceOpportunities: 'opportunities',
  opportunities: 'opportunities',
  cloudIntelligence: 'analytics',
  cloudIntelligenceDashboards: 'dashboards',
  cloudIntelligenceDashboardView: 'dashboards/:id',
  commitments: 'commitments',
  opportunitiesGroups: 'groups',
  opportunitiesOpen: 'open',
  opportunitiesClosed: 'closed',
  opportunitiesAll: 'all',
  specificOpportunity: 'opportunities/:id',
  opportunityOverview: 'overview',
  opportunityActivityLog: 'activity_log',
  opportunityAnalysis: 'analysis',
  expertInsights: 'expert-insights',
  settings: 'settings',
  users: 'users',
  teams: 'teams',
  specificTeam: 'teams/:id',
  integration: 'integration',
  aws: 'aws',
  integrationType: 'integration/:type?',
  notifications: 'notifications',
  preferences: 'preferences',
  preferencesDetections: 'detection',
  newRule: 'settings/notifications/new',
  editRule: 'settings/notifications/edit/:id',
  newAwsAccount: 'settings/integration/aws/new',
  newAwsMultiAccounts: 'settings/integration/awsorg/new',
  newSlackAccount: 'settings/integration/slack/new',
  setupSlackAccount: 'settings/integration/slack/callback-setup',
  newJiraAccount: 'settings/integration/jira/new',
  newAzureIntegration: 'settings/integration/azure/new',
  automations: 'automations',
  newAutomation: 'automations/new',
  editAutomation: 'automations/edit/:id',
  error: 'error',
  logout: 'logout',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  404: '*',
} as const;
