import { SERVICE_TO_ICON_NAME_MAP } from './constants';

export const ICON_NAME_TO_COLOR_MAP = {
  [SERVICE_TO_ICON_NAME_MAP.EC2]: '#E4750D',
  [SERVICE_TO_ICON_NAME_MAP.RDS]: '#4053D6',
  [SERVICE_TO_ICON_NAME_MAP.EKS]: '#E4750D',
  [SERVICE_TO_ICON_NAME_MAP.REDSHIFT]: '#7747D4',
  [SERVICE_TO_ICON_NAME_MAP.Lambda]: '#E4750D',
  [SERVICE_TO_ICON_NAME_MAP.DynamoDB]: '#4154D6',
  [SERVICE_TO_ICON_NAME_MAP.EBS]: '#3F8624',
  [SERVICE_TO_ICON_NAME_MAP.S3]: '#448A27',
  [SERVICE_TO_ICON_NAME_MAP.Cloudfront]: '#7747D4',
  [SERVICE_TO_ICON_NAME_MAP.VPC]: '#4D27AA',
  [SERVICE_TO_ICON_NAME_MAP.CloudWatch]: '#D72B6C',
  [SERVICE_TO_ICON_NAME_MAP.ELB]: '#4D27AA',
  [SERVICE_TO_ICON_NAME_MAP.BigQuery]: '#326CE5',
  [SERVICE_TO_ICON_NAME_MAP.Logging]: '#326CE5',
  [SERVICE_TO_ICON_NAME_MAP.GKE]: '#326CE5',
  [SERVICE_TO_ICON_NAME_MAP.AzureStorage]: '#326CE5',
  [SERVICE_TO_ICON_NAME_MAP.AzureCompute]: '#326CE5',
  [SERVICE_TO_ICON_NAME_MAP.AzureSQL]: '#326CE5',
  [SERVICE_TO_ICON_NAME_MAP.AzureContainer]: '#326CE5',
};
