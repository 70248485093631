import { NavigationLink } from '@pointfive/ui';

import { ROUTE_CONSTANTS } from '../../router/constants';
import {
  FeatureFlags,
  useFeatureFlagsValue,
} from '../../services/featureFlags';
import { useRoleBaseAccessControl } from '../AccessControl/RoleBasedAccessControl';
import { NAV_BAR_ITEMS } from './constants';
import { PagesPath } from './types';

const useSideNavFeaturesFlags = (): Partial<Record<PagesPath, boolean>> => {
  const commitmentsEnabler = useFeatureFlagsValue(
    FeatureFlags.CommitmentsEnabled,
  );
  const expertInsightsEnabler = useFeatureFlagsValue(
    FeatureFlags.Expert_Insights_Page,
  );

  return {
    [ROUTE_CONSTANTS.commitments]: commitmentsEnabler,
    [ROUTE_CONSTANTS.expertInsights]: expertInsightsEnabler,
  };
};

export const useNavBarLinks = (): NavigationLink[] => {
  const { getCanAccess } = useRoleBaseAccessControl({});
  const featureFlags = useSideNavFeaturesFlags();

  const featureFlagsKeys = new Set(Object.keys(featureFlags));

  return NAV_BAR_ITEMS.reduce((acc: NavigationLink[], item) => {
    if (item.roleAccess) {
      const canAccess = getCanAccess(item.roleAccess);
      if (!canAccess) {
        return acc;
      }
    }

    if (featureFlagsKeys.has(item.path) && !featureFlags[item.path]) {
      return acc;
    }

    const { icon: Icon, path, label } = item;
    return [
      ...acc,
      {
        label,
        path: `/${path}`,
        icon: <Icon size="sm" />,
      },
    ];
  }, []);
};

// TODO: Potential bug here
// If more than one route is using the same last segment of the URL
// the active class will be applied to all of them

// TODO: allow other roles when teams dashboard is implemented
