import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Dotpoints02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Dotpoints02"
      >
        <path d="M2.88764 21C1.8427 21 1 20.1094 1 19.0312C1 17.9531 1.85393 17.0625 2.88764 17.0625C3.92135 17.0625 4.77528 17.9531 4.77528 19.0312C4.77528 20.1094 3.92135 21 2.88764 21ZM21.9888 20.0859H8.50562C7.94382 20.0859 7.49438 19.6172 7.49438 19.0312C7.49438 18.4453 7.94382 17.9766 8.50562 17.9766H21.9888C22.5506 17.9766 23 18.4453 23 19.0312C23 19.6172 22.5506 20.0859 21.9888 20.0859ZM2.88764 13.9688C1.8427 13.9688 1 13.0898 1 12C1 10.9102 1.85393 10.0312 2.88764 10.0312C3.92135 10.0312 4.77528 10.9219 4.77528 12C4.77528 13.0781 3.92135 13.9688 2.88764 13.9688ZM21.9888 13.0547H8.50562C7.94382 13.0547 7.49438 12.5859 7.49438 12C7.49438 11.4141 7.94382 10.9453 8.50562 10.9453H21.9888C22.5506 10.9453 23 11.4141 23 12C23 12.5859 22.5506 13.0547 21.9888 13.0547ZM2.88764 6.9375C1.8427 6.9375 1 6.04687 1 4.96875C1 3.89062 1.85393 3 2.88764 3C3.92135 3 4.77528 3.89062 4.77528 4.96875C4.77528 6.04687 3.92135 6.9375 2.88764 6.9375ZM21.9888 6.02344H8.50562C7.94382 6.02344 7.49438 5.55469 7.49438 4.96875C7.49438 4.38281 7.94382 3.91406 8.50562 3.91406H21.9888C22.5506 3.91406 23 4.38281 23 4.96875C23 5.55469 22.5506 6.02344 21.9888 6.02344Z" />
      </svg>
    );
  },
);

Dotpoints02Icon.displayName = 'Dotpoints02Icon';
