import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as GcpColorIcon } from './variant-svgs/gcp-color.svg';
import { ReactComponent as GcpDisabledIcon } from './variant-svgs/gcp-disabled.svg';
import { ReactComponent as GcpBlackIcon } from './variant-svgs/gcp-regular.svg';

const VARIANT_ICON_MAP = {
  default: GcpColorIcon,
  black: GcpBlackIcon,
  disabled: GcpDisabledIcon,
};

export const GcpIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="gcp-icon"
      />
    );
  },
);

GcpIcon.displayName = 'GcpIcon';
