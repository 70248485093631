import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as EbsColorIcon } from './variant-svgs/ebs-color.svg';
import { ReactComponent as EbsDisabledIcon } from './variant-svgs/ebs-disabled.svg';
import { ReactComponent as EbsBlackIcon } from './variant-svgs/ebs-regular.svg';

const VARIANT_ICON_MAP = {
  default: EbsColorIcon,
  black: EbsBlackIcon,
  disabled: EbsDisabledIcon,
};

export const EbsIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="ebs-icon"
      />
    );
  },
);

EbsIcon.displayName = 'EbsIcon';
