/* eslint-disable @typescript-eslint/naming-convention */
export const AWS_SUPPORTED_SERVICES = {
  ec2: 'EC2',
  rds: 'RDS',
  eks: 'EKS',
  elb: 'ELB',
  redshift: 'REDSHIFT',
  lambda: 'Lambda',
  dynamoDB: 'DynamoDB',
  ebs: 'EBS',
  s3: 'S3',
  vpc: 'VPC',
  cloudfront: 'Cloudfront',
  organization: 'Organization',
  cloudWatch: 'CloudWatch',
  elastiCache: 'ElastiCache',
  cloudTrail: 'CloudTrail',
} as const;

export const AWS_SUPPORTED_TYPES = {
  instance: 'instance',
  ami: 'ami',
  ['Launch Template']: 'launch_template',
  volume: 'volume',
  snapshot: 'snapshot',
  endpoint: 'endpoint',
  bucket: 'bucket',
  globalDatastore: 'global_datastore',
  cluster: 'cluster',
  node: 'node',
  reservedNode: 'reserved_node',
  nodegroup: 'nodegroup',
  deployment: 'deployment',
  namespace: 'namespace',
  karpenterProvisioner: 'karpenter_provisioner',
  karpenterNodeTemplate: 'karpenter_node_template',
  karpenterNodeClass: 'karpenter_node_class',
  karpenterNodePool: 'karpenter_node_pool',
  statefulset: 'statefulset',
  daemonset: 'daemonset',
  cronJob: 'cronJob',
  job: 'job',
  replicaset: 'replicaset',
  distribution: 'distribution',
  ['Auto Scaling Group']: 'auto_scaling_group',
  ['Log Group']: 'log_group',
  user: 'user',
  team: 'team',
  organization: 'organization',
  account: 'account',
  region: 'region',
  ['Nat Gateway']: 'nat_gateway',
  elb: 'elastic_load_balancer',
  table: 'table',
  function: 'function',
} as const;

export const AWS_RESOURCE_TYPE_NAMES = {
  AWSEKSNodeGroup: 'EKS Nodegroup',
  AWSEC2Instance: 'EC2 Instance',
  AWSEC2ReservedInstance: 'EC2 Reserved Instance',
  AWSEC2Ami: 'EC2 AMI',
  AWSEC2AutoScalingGroup: 'EC2 ASG',
  AWSEC2LaunchTemplate: 'EC2 Launch Template',
  AWSEBSVolume: 'EBS Volume',
  AWSEBSSnapshot: 'EBS Snapshot',
  AWSEKSCluster: 'EKS Cluster',
  AWSRDSInstance: 'RDS Instance',
  AWSRDSReservedInstance: 'RDS Reserved Instance',
  AWSRDSCluster: 'RDS Cluster',
  AWSRedshiftCluster: 'Redshift Cluster',
  AWSRedshiftWorkgroup: 'Redshift Workgroup',
  AWSS3Bucket: 'S3 Bucket',
  AWSOrganizationAccount: 'Organization Account',
  AWSOrganizationUnit: 'Organization Unit',
  AWSCloudfrontDistribution: 'Cloudfront Distribution',
  AWSVpcEndpoint: 'VPC Endpoint',
  AWSVpcNatGateway: 'VPC Nat Gateway',
  AWSCloudwatchLogGroup: 'Cloudwatch Log Group',
  AWSElasticLoadBalancer: 'Elastic Load Balancer',
  AWSCloudTrailTrail: 'Cloudtrail Trail',
  AWSElastiCacheGlobalDatastore: 'ElastiCache Global Datastore',
  AWSElastiCacheCluster: 'ElastiCache Cluster',
  AWSElastiCacheNode: 'ElastiCache Node',
  AWSElastiCacheReservedNode: 'ElastiCache Reserved Node',
  AWSOpenSearchReservedInstance: 'OpenSearch Reserved Instance',
  AWSRedshiftReservedNode: 'Redshift Reserved Node',
  AWSMemoryDBReservedNode: 'MemoryDB Reserved Node',
  AWSSavingsPlan: 'Savings Plan',
  AWSLambdaFunction: 'Lambda Function',
  KubernetesDeployment: 'EKS Deployment',
  KubernetesNamespace: 'EKS Namespace',
  KubernetesStatefulSet: 'EKS Stateful Set',
  KubernetesReplicaSet: 'EKS Replica Set',
  KubernetesDaemonSet: 'EKS Daemon Set',
  KubernetesCronJob: 'EKS Cron Job',
  KubernetesJob: 'EKS Job',
  KubernetesProvisioner: 'EKS Provisioner',
  KubernetesNodeTemplate: 'EKS Node Template',
  KubernetesNodePool: 'EKS Node Pool',
  KubernetesNodeClass: 'EKS Node Class',
  AWSDynamoDbTable: 'DynamoDB Table',
} as const;
