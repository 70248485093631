import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavNavbrAvatarBlueSmIcon = forwardRef<
  SVGSVGElement,
  IconProps
>(({ size = 'sm', color, onClick }, ref) => {
  const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

  return (
    <svg
      ref={ref}
      width={ICON_SIZE_OPTIONS[size]}
      height={ICON_SIZE_OPTIONS[size]}
      color={iconColor}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={iconColor}
      onClick={onClick}
      aria-label="SideNavNavbrAvatarBlueSm"
    >
      <rect width="28" height="28" rx="14" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.0001 8.66659C12.7114 8.66659 11.6667 9.71125 11.6667 10.9999C11.6667 12.2886 12.7114 13.3333 14.0001 13.3333C15.2887 13.3333 16.3334 12.2886 16.3334 10.9999C16.3334 9.71125 15.2887 8.66659 14.0001 8.66659ZM10.3334 10.9999C10.3334 8.97487 11.975 7.33325 14.0001 7.33325C16.0251 7.33325 17.6667 8.97487 17.6667 10.9999C17.6667 13.025 16.0251 14.6666 14.0001 14.6666C11.975 14.6666 10.3334 13.025 10.3334 10.9999ZM12.2176 15.6666C12.2556 15.6666 12.2942 15.6666 12.3334 15.6666H15.6667C15.7059 15.6666 15.7445 15.6666 15.7825 15.6666C16.6007 15.6663 17.1594 15.666 17.6343 15.8101C18.6997 16.1333 19.5334 16.967 19.8565 18.0323C20.0006 18.5072 20.0004 19.0659 20.0001 19.8841C20.0001 19.9221 20.0001 19.9607 20.0001 19.9999C20.0001 20.3681 19.7016 20.6666 19.3334 20.6666C18.9652 20.6666 18.6667 20.3681 18.6667 19.9999C18.6667 19.021 18.6595 18.6794 18.5806 18.4193C18.3867 17.7801 17.8865 17.2799 17.2473 17.086C16.9872 17.0072 16.6456 16.9999 15.6667 16.9999H12.3334C11.3545 16.9999 11.0129 17.0072 10.7528 17.086C10.1136 17.2799 9.61341 17.7801 9.41951 18.4193C9.34063 18.6794 9.33339 19.021 9.33339 19.9999C9.33339 20.3681 9.03491 20.6666 8.66672 20.6666C8.29853 20.6666 8.00006 20.3681 8.00006 19.9999C8.00006 19.9607 8.00004 19.9221 8.00003 19.8841C7.99972 19.0659 7.99952 18.5072 8.14359 18.0323C8.46676 16.967 9.30043 16.1333 10.3658 15.8101C10.8407 15.666 11.3994 15.6663 12.2176 15.6666Z"
      />
    </svg>
  );
});

SideNavNavbrAvatarBlueSmIcon.displayName = 'SideNavNavbrAvatarBlueSmIcon';
