/* eslint-disable @typescript-eslint/naming-convention */
export const AZURE_SUPPORTED_SERVICES = {
  azureCompute: 'AzureCompute',
  azureStorage: 'AzureStorage',
  azureSql: 'AzureSQL',
  azureContainer: 'AzureContainer',
} as const;

export const AZURE_SUPPORTED_TYPES = {
  ComputeDisk: 'ComputeDisk',
  ComputeInstance: 'ComputeInstance',
  ContainerCluster: 'ContainerCluster',
  SQLInstance: 'SQLInstance',
  StorageAccount: 'StorageAccount',
} as const;

export const AZURE_RESOURCE_TYPE_NAMES = {
  AzureComputeDisk: 'Compute Disk',
  AzureComputeInstance: 'Compute Instance',
  AzureContainerCluster: 'Container Cluster',
  AzureSQLInstance: 'SQL Instance',
  AzureStorageAccount: 'Storage Account',
} as const;

export const URL_SECTION_DISK_TYPES = '/diskTypes/';
export const URL_SECTION_MACHINE_TYPES = '/machineTypes/';
