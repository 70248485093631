import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Help02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Help02"
      >
        <path d="M12 22C6.49032 22 2 17.5097 2 12C2 6.49032 6.49032 2 12 2C17.5097 2 22 6.49032 22 12C22 17.5097 17.5097 22 12 22ZM12 3.93548C7.54839 3.93548 3.93548 7.54839 3.93548 12C3.93548 16.4516 7.54839 20.0645 12 20.0645C16.4516 20.0645 20.0645 16.4516 20.0645 12C20.0645 7.54839 16.4516 3.93548 12 3.93548ZM12.0129 17.4839C11.4839 17.4839 11.0452 17.0452 11.0452 16.5161C11.0452 15.9871 11.471 15.5484 12.0129 15.5484C12.5419 15.5484 12.9806 15.9871 12.9806 16.5161C12.9806 17.0452 12.5419 17.4839 12.0129 17.4839ZM11.9226 13.871C11.5226 13.871 11.1355 13.6129 11.0065 13.2129C10.8387 12.7097 11.1097 12.1548 11.6129 11.9871C12.2839 11.7677 13.6645 11.0194 13.6645 10.1936C13.6645 9.78065 13.5226 9.38065 13.2516 9.05807C12.9935 8.74839 12.6194 8.52903 12.2194 8.46451C11.8194 8.39999 11.3935 8.46451 11.0452 8.68386C10.6968 8.89031 10.4258 9.2258 10.2839 9.6129C10.1032 10.1161 9.56129 10.3871 9.04516 10.2065C8.54194 10.0258 8.27097 9.47096 8.45161 8.96773C8.73548 8.15483 9.30323 7.45806 10.0516 7.01935C10.8 6.58064 11.6903 6.4258 12.5419 6.56773C13.3935 6.70967 14.1677 7.16128 14.7355 7.81935C15.2903 8.49031 15.6 9.32904 15.6 10.1936C15.6 12.6194 12.5677 13.7032 12.2323 13.8194C12.129 13.8581 12.0258 13.871 11.9226 13.871Z" />
      </svg>
    );
  },
);

Help02Icon.displayName = 'Help02Icon';
