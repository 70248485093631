import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const UserKeyIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="UserKey"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0002 3.90022C8.01194 3.90022 6.40016 5.51199 6.40016 7.50022C6.40016 9.48844 8.01194 11.1002 10.0002 11.1002C11.9884 11.1002 13.6002 9.48844 13.6002 7.50022C13.6002 5.51199 11.9884 3.90022 10.0002 3.90022ZM4.60016 7.50022C4.60016 4.51788 7.01783 2.10022 10.0002 2.10022C12.9825 2.10022 15.4002 4.51788 15.4002 7.50022C15.4002 10.4825 12.9825 12.9002 10.0002 12.9002C7.01783 12.9002 4.60016 10.4825 4.60016 7.50022ZM7.33802 14.6002C7.39129 14.6002 7.44533 14.6002 7.50016 14.6002H9.00016C9.49722 14.6002 9.90016 15.0031 9.90016 15.5002C9.90016 15.9973 9.49722 16.4002 9.00016 16.4002H7.50016C6.03913 16.4002 5.50809 16.41 5.10023 16.5337C4.10951 16.8342 3.3342 17.6096 3.03365 18.6004C2.90993 19.0082 2.90016 19.5391 2.90016 21.0002C2.90016 21.4973 2.49722 21.9002 2.00016 21.9002C1.50311 21.9002 1.10016 21.4973 1.10016 21.0002C1.10016 20.9454 1.10015 20.8913 1.10013 20.838C1.09975 19.599 1.0995 18.7756 1.31116 18.0778C1.7862 16.5118 3.01168 15.2862 4.57777 14.8112C5.27549 14.5996 6.09902 14.5998 7.33802 14.6002Z"
        />
        <path d="M13.3599 22.2501H12.3999C11.9999 22.2501 11.7199 22.2501 11.4099 22.0901C11.1299 21.9501 10.9099 21.7301 10.7699 21.4501C10.6099 21.1401 10.6099 20.8501 10.6099 20.4601V19.4901C10.6099 19.3201 10.6099 19.1801 10.6499 19.0101C10.6799 18.8701 10.7399 18.7201 10.8299 18.5901C10.9199 18.4501 11.0199 18.3401 11.1399 18.2201L13.9999 15.3601C13.9999 15.3301 13.9999 15.3001 13.9899 15.2601C13.9599 15.0601 13.9499 14.8701 13.9499 14.6801C13.9499 12.3501 15.8499 10.4501 18.1799 10.4501C20.5099 10.4501 22.4099 12.3501 22.4099 14.6801C22.4099 17.0101 20.5099 18.9101 18.1799 18.9101C17.9899 18.9101 17.7999 18.9001 17.6099 18.8701C17.5699 18.8701 17.5299 18.8601 17.4899 18.8501L14.6299 21.7101C14.5099 21.8301 14.4099 21.9301 14.2599 22.0201C14.1299 22.1001 13.9899 22.1601 13.8499 22.1901C13.6799 22.2301 13.5399 22.2301 13.3699 22.2301L13.3599 22.2501ZM12.4099 20.4501H13.3599L16.1999 17.6101C16.3699 17.4401 16.4899 17.3201 16.6699 17.2201C16.8299 17.1301 16.9799 17.0801 17.1699 17.0501C17.3699 17.0201 17.5399 17.0501 17.8499 17.0901C17.9699 17.1101 18.0799 17.1101 18.1899 17.1101C19.5299 17.1101 20.6199 16.0201 20.6199 14.6801C20.6199 13.3401 19.5299 12.2501 18.1899 12.2501C16.8499 12.2501 15.7599 13.3401 15.7599 14.6801C15.7599 14.7901 15.7599 14.9001 15.7799 15.0101C15.8199 15.3301 15.8399 15.5001 15.8199 15.6901C15.7899 15.8801 15.7399 16.0301 15.6499 16.1901C15.5499 16.3701 15.4199 16.5001 15.2599 16.6601L12.4299 19.4901V20.4401L12.4099 20.4501Z" />
      </svg>
    );
  },
);

UserKeyIcon.displayName = 'UserKeyIcon';
