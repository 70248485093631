import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const BarChart12Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="BarChart12"
      >
        <path d="M19.4001 21.9H4.6001C3.9401 21.9 3.5401 21.9 3.1401 21.69C2.7801 21.51 2.5001 21.22 2.3101 20.86C2.1001 20.45 2.1001 20.05 2.1001 19.39V13.59C2.1001 12.93 2.1001 12.53 2.3101 12.13C2.4901 11.77 2.7801 11.48 3.1401 11.3C3.5501 11.09 3.9501 11.09 4.6001 11.09H8.1001V8.58997C8.1001 7.92997 8.1001 7.52997 8.3101 7.12997C8.4901 6.76997 8.7801 6.47997 9.1401 6.29997C9.5501 6.08997 9.9501 6.08997 10.6001 6.08997H13.4001C13.6701 6.08997 13.9001 6.08997 14.1001 6.09997V4.58997C14.1001 3.92997 14.1001 3.52997 14.3101 3.12997C14.4901 2.76997 14.7801 2.47997 15.1401 2.29997C15.5501 2.08997 15.9401 2.08997 16.6001 2.08997H19.4001C20.0601 2.08997 20.4601 2.08997 20.8601 2.29997C21.2201 2.47997 21.5101 2.76997 21.6901 3.12997C21.9001 3.53997 21.9001 3.92997 21.9001 4.58997V19.39C21.9001 20.05 21.9001 20.45 21.6901 20.85C21.5101 21.21 21.2201 21.5 20.8601 21.68C20.4601 21.89 20.0601 21.89 19.4001 21.89V21.9ZM15.9001 20.1H19.4001C19.6901 20.1 19.9901 20.1 20.0701 20.08C20.1001 19.99 20.1001 19.69 20.1001 19.4V4.59997C20.1001 4.30997 20.1001 4.00997 20.0801 3.92997C19.9901 3.89997 19.6901 3.89997 19.4001 3.89997H16.6001C16.3101 3.89997 16.0101 3.89997 15.9301 3.91997C15.9001 4.00997 15.9001 4.30997 15.9001 4.59997V7.99997C15.9001 7.99997 15.9001 8.05997 15.9001 8.08997C15.9001 8.23997 15.9001 8.40997 15.9001 8.59997V20.1ZM9.9001 20.1H14.1001V8.59997C14.1001 8.30997 14.1001 8.00997 14.0801 7.92997C13.9901 7.89997 13.6901 7.89997 13.4001 7.89997H10.6001C10.3101 7.89997 10.0101 7.89997 9.9301 7.91997C9.9001 8.00997 9.9001 8.30997 9.9001 8.59997V20.1ZM4.6001 12.9C4.3101 12.9 4.0101 12.9 3.9301 12.92C3.9001 13.01 3.9001 13.31 3.9001 13.6V19.4C3.9001 19.69 3.9001 19.99 3.9201 20.07C4.0101 20.1 4.3101 20.1 4.6001 20.1H8.1001V12.9H4.6001Z" />
      </svg>
    );
  },
);

BarChart12Icon.displayName = 'BarChart12Icon';
