import { StepAction } from '@pointfive/gql';

export const PAGE_TITLE = 'Automations';

export const FIELD_NAME = {
  name: 'name',
  description: 'description',
  provider: 'provider',
  trigger: 'trigger',
  criteria: 'criteria',
  notifications: 'notifications',
} as const;

export const STEP_DISPLAY_NAME = {
  [StepAction.Notifications]: 'Notifications',
  [StepAction.Tickets]: 'Tickets',
  [StepAction.OpportunityActions]: 'Opportunity Actions',
};
