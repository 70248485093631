import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as DeploymentDeployColorIcon } from './variant-svgs/deployment-deploy-color.svg';
import { ReactComponent as DeploymentDeployDisabledIcon } from './variant-svgs/deployment-deploy-disabled.svg';
import { ReactComponent as DeploymentDeployBlackIcon } from './variant-svgs/deployment-deploy-regular.svg';

const VARIANT_ICON_MAP = {
  default: DeploymentDeployColorIcon,
  black: DeploymentDeployBlackIcon,
  disabled: DeploymentDeployDisabledIcon,
};

export const DeploymentDeployIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="deployment-deploy-icon"
      />
    );
  },
);

DeploymentDeployIcon.displayName = 'DeploymentDeployIcon';
