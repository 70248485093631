import { CloudLoader } from '@pointfive/ui/components/elements/CloudLoader/CloudLoader';

export const EmptyState = () => {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <div className="flex flex-col items-center justify-center">
        <CloudLoader width="80px" height="80px" text="Loading..." />
      </div>
    </div>
  );
};
