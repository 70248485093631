import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as NamespaceNsColorIcon } from './variant-svgs/namespace-ns-color.svg';
import { ReactComponent as NamespaceNsDisabledIcon } from './variant-svgs/namespace-ns-disabled.svg';
import { ReactComponent as NamespaceNsBlackIcon } from './variant-svgs/namespace-ns-regular.svg';

const VARIANT_ICON_MAP = {
  default: NamespaceNsColorIcon,
  black: NamespaceNsBlackIcon,
  disabled: NamespaceNsDisabledIcon,
};

export const NamespaceNsIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="namespace-ns-icon"
      />
    );
  },
);

NamespaceNsIcon.displayName = 'NamespaceNsIcon';
