import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as S3BucketColorIcon } from './variant-svgs/s3-bucket-color.svg';
import { ReactComponent as S3BucketDisabledIcon } from './variant-svgs/s3-bucket-disabled.svg';
import { ReactComponent as S3BucketBlackIcon } from './variant-svgs/s3-bucket-regular.svg';

const VARIANT_ICON_MAP = {
  default: S3BucketColorIcon,
  black: S3BucketBlackIcon,
  disabled: S3BucketDisabledIcon,
};

export const S3BucketIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="s3-bucket-icon"
      />
    );
  },
);

S3BucketIcon.displayName = 'S3BucketIcon';
