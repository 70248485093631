import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const NoFiltersIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="NoFilters"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.27459 4.27459C4.6407 3.90847 5.2343 3.90847 5.60041 4.27459L19.3504 18.0246C19.7165 18.3907 19.7165 18.9843 19.3504 19.3504C18.9843 19.7165 18.3907 19.7165 18.0246 19.3504L4.27459 5.60041C3.90847 5.2343 3.90847 4.6407 4.27459 4.27459Z"
        />
        <path d="M7.77916 7.85498H4.9377C4.47747 7.85498 4.10437 8.22808 4.10437 8.68831C4.10437 9.14855 4.47747 9.52165 4.9377 9.52165H9.44582L7.77916 7.85498Z" />
        <path d="M12.7792 12.855H7.4377C6.97747 12.855 6.60437 13.2281 6.60437 13.6883C6.60437 14.1486 6.97747 14.5216 7.4377 14.5216H14.4458L12.7792 12.855Z" />
        <path d="M17.0975 14.5216L15.4308 12.855H17.4377C17.8979 12.855 18.271 13.2281 18.271 13.6883C18.271 14.1486 17.8979 14.5216 17.4377 14.5216H17.0975Z" />
        <path d="M12.0975 9.52165L10.4308 7.85498H19.9377C20.3979 7.85498 20.771 8.22808 20.771 8.68831C20.771 9.14855 20.3979 9.52165 19.9377 9.52165H12.0975Z" />
        <path d="M9.9377 17.855C9.47747 17.855 9.10437 18.2281 9.10437 18.6883C9.10437 19.1486 9.47747 19.5216 9.9377 19.5216H14.9377C15.3979 19.5216 15.771 19.1486 15.771 18.6883C15.771 18.2281 15.3979 17.855 14.9377 17.855H9.9377Z" />
      </svg>
    );
  },
);

NoFiltersIcon.displayName = 'NoFiltersIcon';
