import { useCallback, useEffect } from 'react';

import { UserMe } from '@pointfive/gql';

type IdleTimerOptions = {
  loginTime?: string;
  timeoutMinutes?: UserMe['sessionIdleTimeout']; // if not provided, idle detection will be disabled
  onIdle?: () => void;
  onActive?: () => void;
  events?: string[];
  debounce?: number;
  storageKey?: string;
};

const DEFAULT_LISTENING_EVENTS = [
  'mousedown',
  'mousemove',
  'keypress',
  'scroll',
  'touchstart',
];
const DEFAULT_STORAGE_KEY = 'pf_idleTimerLastActive';

export const usePersistIdleDetector = (options: IdleTimerOptions = {}) => {
  const {
    loginTime,
    timeoutMinutes, // 9 hours default
    onIdle,
    onActive,
    events = DEFAULT_LISTENING_EVENTS,
    debounce = 100,
    storageKey = DEFAULT_STORAGE_KEY,
  } = options;

  const timeout = 60_000 * (timeoutMinutes || 540);

  const getLastActiveTime = useCallback(() => {
    const lastActive = localStorage.getItem(storageKey);
    return lastActive ? parseInt(lastActive, 10) : null;
  }, [storageKey]);

  const isIdleTimePassed = useCallback(
    (lastActiveTime: number) => {
      return Date.now() - lastActiveTime > timeout;
    },
    [timeout],
  );

  const updateLastActive = useCallback(() => {
    localStorage.setItem(storageKey, Date.now().toString());
  }, [storageKey]);

  const handleActivity = useCallback(() => {
    updateLastActive();
    onActive?.();
  }, [onActive, updateLastActive]);

  const debounceActivity = useCallback(() => {
    let timeoutId: NodeJS.Timeout;
    return () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(handleActivity, debounce);
    };
  }, [handleActivity, debounce]);

  useEffect(() => {
    if (!timeoutMinutes) {
      return;
    }

    const checkIdle = () => {
      const lastActive = getLastActiveTime();
      if (lastActive && isIdleTimePassed(lastActive)) {
        clearInterval(timer);
        onIdle?.();
      }
    };

    const debouncedActivity = debounceActivity();
    const timer = setInterval(checkIdle, 1000); // Check every second

    events.forEach((event) => {
      window.addEventListener(event, debouncedActivity);
    });

    // on mount: check if user just logged in, and trigger immediate idle check
    if (loginTime) {
      const loginTimeUnix = new Date(loginTime).getTime();
      if (Date.now() - loginTimeUnix < 60_000) {
        cleanupLastActive(storageKey);
      }
    }
    checkIdle();

    return () => {
      clearInterval(timer);
      events.forEach((event) => {
        window.removeEventListener(event, debouncedActivity);
      });
    };
  }, [
    timeoutMinutes,
    getLastActiveTime,
    isIdleTimePassed,
    onIdle,
    events,
    debounceActivity,
  ]);

  // Update last active time before unload
  useEffect(() => {
    if (!timeoutMinutes) {
      return;
    }

    const handleBeforeUnload = () => {
      updateLastActive();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [timeoutMinutes, updateLastActive]);
};

const cleanupLastActive = (storageKey = DEFAULT_STORAGE_KEY) => {
  localStorage.removeItem(storageKey);
};
