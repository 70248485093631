import { ReactNode } from 'react';

import { ToolTip } from '@pointfive/ui';
import Button from '@pointfive/ui/components/base/Button';

export type BaseDropdownContentFooterProps = {
  onApplyClick?: () => void;
  isApplyDisabled?: boolean;
  applyDisabledMessage?: string;
  leftContent?: ReactNode;
};

export const BaseDropdownContentFooter = ({
  onApplyClick,
  isApplyDisabled,
  applyDisabledMessage,
  leftContent,
}: BaseDropdownContentFooterProps) => {
  if (!onApplyClick && !leftContent) {
    return;
  }

  return (
    <div
      data-cy="base-dropdown-content"
      className="mt-3 flex h-[2rem] items-center justify-between"
    >
      {leftContent}
      {onApplyClick ? (
        <ToolTip
          message={applyDisabledMessage}
          disabled={!isApplyDisabled}
          side="top"
        >
          <div className="ml-auto flex justify-center">
            <Button
              data-cy="apply-button"
              type="button"
              className="w-[5rem]"
              onClick={onApplyClick}
              disabled={isApplyDisabled}
            >
              Apply
            </Button>
          </div>
        </ToolTip>
      ) : null}
    </div>
  );
};
