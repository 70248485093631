import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as VpcNetworkColorIcon } from './variant-svgs/vpc-network-color.svg';
import { ReactComponent as VpcNetworkDisabledIcon } from './variant-svgs/vpc-network-disabled.svg';
import { ReactComponent as VpcNetworkBlackIcon } from './variant-svgs/vpc-network-regular.svg';

const VARIANT_ICON_MAP = {
  default: VpcNetworkColorIcon,
  black: VpcNetworkBlackIcon,
  disabled: VpcNetworkDisabledIcon,
};

export const VpcNetworkIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="vpc-network-icon"
      />
    );
  },
);

VpcNetworkIcon.displayName = 'VpcNetworkIcon';
