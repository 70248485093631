export const PF_ERROR_CODES = {
  notSet: 'NOT_SET',
  notFound: 'NOT_FOUND',
  validation: 'VALIDATION',
  authorization: 'AUTHORIZATION',
  authentication: 'AUTHENTICATION',
  internal: 'INTERNAL',
  conflict: 'CONFLICT',
  noAccess: 'NO_ACCESS',
} as const;
export type PfErrorCode = (typeof PF_ERROR_CODES)[keyof typeof PF_ERROR_CODES];

export const PF_ERRORS_SKIP_REPORTING = [
  PF_ERROR_CODES.conflict,
  PF_ERROR_CODES.notFound,
  PF_ERROR_CODES.validation,
];
