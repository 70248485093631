import { lazy } from 'react';

import Sentry from 'services/sentry';

const RETRY_COUNT = 1;
const RETRY_DELAY = 500; // milliseconds
const BACKOFF_FACTOR = 2;
export const ERR_IMPORT_MESSAGE =
  'Failed to fetch dynamically imported module:';

const getURLFromError = (error: Error) => {
  // this assumes that the exception will contain this specific text with the url of the module
  // if not, the url will not be able to parse and we'll get an error on that
  // eg. "Failed to fetch dynamically imported module: https://example.com/assets/Home.tsx"

  const url = new URL(error.message.replace(ERR_IMPORT_MESSAGE, '').trim());

  // add a timestamp to the url to force a reload the module (and not use the cached version - cache busting)
  url.searchParams.set('t', `${+new Date()}`);
  return url;
};

export const lazyWithRetries: typeof lazy = (importer) => {
  const retryImport = async () => {
    try {
      return await importer();
    } catch (error) {
      for (let i = 0; i < RETRY_COUNT; i++) {
        await new Promise((resolve) =>
          setTimeout(resolve, RETRY_DELAY * BACKOFF_FACTOR ** i),
        );

        try {
          const url = getURLFromError(error as Error);
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return await import(/* @vite-ignore */ url.href);
        } catch (e) {
          const errorMessage =
            e instanceof Error ? e.message : 'no error message';

          Sentry.captureException(
            new Error('getURLFromError or import failed in lazyWithRetries'),
            {
              extra: {
                msg: errorMessage,
              },
            },
          );
        }
      }
      throw error;
    }
  };
  return lazy(retryImport);
};
