import { useEffect } from 'react';

import { FullStory, init } from '@fullstory/browser';

import { IS_DEV_MODE } from 'constants/env';
import { useAuth } from 'services/auth';

const enableFullStory = !IS_DEV_MODE;
const ANONYMOUS = 'Anonymous';

export const initFullStory = () => {
  if (enableFullStory) {
    init({
      orgId: import.meta.env.VITE_FULLSTORY_ORG_ID,
    });
  }
};

export const FullStoryProvider = () => {
  const user = useAuth.use.user();

  useEffect(() => {
    if (!enableFullStory) {
      return;
    }

    const { email, firstName, lastName } = user || {};
    const isSu = user?.isSuperUser;
    const suPrefix = isSu ? 'su-' : '';
    const suSuffix = isSu ? ` (SU)` : '';
    const name = firstName
      ? `${firstName} ${lastName}${suSuffix}`
      : 'Anonymous';
    const userIdentifier = email ?? ANONYMOUS;

    FullStory('setProperties', {
      type: 'user',
      properties: {
        uid: suPrefix + userIdentifier,
        displayName: name,
        customerId: user?.customerId,
        customerStatus: user?.customerStatus,
        email: user?.email,
        popup_help: true,
      },
    });
  }, [user]);

  return null;
};
