import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Analytics01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Analytics01"
      >
        <path d="M16.1 21.8H7.7C5.88 21.8 4.96 21.8 4.13 21.38C3.39 21 2.8 20.41 2.43 19.68C2 18.84 2 17.92 2 16.1V7.7C2 5.88 2 4.96 2.43 4.13C2.81 3.39 3.4 2.8 4.13 2.43C4.96 2 5.88 2 7.7 2H16.1C17.92 2 18.84 2 19.67 2.43C20.41 2.81 21 3.4 21.37 4.13C21.8 4.96 21.8 5.88 21.8 7.7V16.1C21.8 17.92 21.8 18.84 21.37 19.67C20.99 20.41 20.4 21 19.67 21.37C18.84 21.79 17.92 21.79 16.1 21.79V21.8ZM7.7 3.8C6.21 3.8 5.4 3.8 4.95 4.03C4.55 4.23 4.23 4.55 4.03 4.95C3.8 5.4 3.8 6.22 3.8 7.7V16.1C3.8 17.59 3.8 18.4 4.03 18.85C4.23 19.25 4.55 19.56 4.95 19.77C5.4 20 6.22 20 7.7 20H16.1C17.59 20 18.4 20 18.85 19.77C19.25 19.57 19.56 19.25 19.77 18.85C20 18.4 20 17.58 20 16.1V7.7C20 6.21 20 5.4 19.77 4.95C19.57 4.55 19.25 4.23 18.85 4.03C18.4 3.8 17.58 3.8 16.1 3.8H7.7ZM15.9 17.8C15.4 17.8 15 17.4 15 16.9V10.9C15 10.4 15.4 10 15.9 10C16.4 10 16.8 10.4 16.8 10.9V16.9C16.8 17.4 16.4 17.8 15.9 17.8ZM11.9 17.8C11.4 17.8 11 17.4 11 16.9V6.9C11 6.4 11.4 6 11.9 6C12.4 6 12.8 6.4 12.8 6.9V16.9C12.8 17.4 12.4 17.8 11.9 17.8ZM7.9 17.8C7.4 17.8 7 17.4 7 16.9V12.9C7 12.4 7.4 12 7.9 12C8.4 12 8.8 12.4 8.8 12.9V16.9C8.8 17.4 8.4 17.8 7.9 17.8Z" />
      </svg>
    );
  },
);

Analytics01Icon.displayName = 'Analytics01Icon';
