import { gql } from '@apollo/client';

import { graphql } from '../generated';

export const COMMITMENTS_DATA_INTEGRITY = graphql(`
  query commitmentsDataIntegrity {
    commitmentsDataIntegrity {
      dataCollectedFirstTime
      missingData {
        projectId
        commitments
      }
      managementAccountsIssues {
        missingManagementAccounts
        mgmtRootAccountsConnected
      }
    }
  }
`);

export const COMMITMENTS_COST_AGGREGATION = gql`
  query commitmentsAggregation(
    $where: CostFilterInput
    $groupBy: [CostGrouping!]
  ) {
    commitmentsCostAggregation(where: $where, groupBy: $groupBy) {
      group
      actualCost
      usageCost
      coveredCost
      totalCostBeforeDiscounts
    }
  }
`;

export const COMMITMENTS_BASELINE_USAGE = gql`
  query commitmentsBaselineUsage(
    $where: CostFilterInput
    $groupBy: [CostGrouping!]
  ) {
    commitmentsBaselineUsage(where: $where, groupBy: $groupBy) {
      baselineUsage
      group
    }
  }
`;

export const COMMITMENTS_UTILIZATION_RATES = gql`
  query commitmentsUtilization(
    $timeRange: TimeRangeInput!
    $where: CommitmentsFilterInput
    $groupBy: [CommitmentUtilizationGrouping!]
  ) {
    commitmentsUtilization(
      timeRange: $timeRange
      where: $where
      groupBy: $groupBy
    ) {
      group
      utilization
      totalCommitmentsCost
      savings
      netSavings
    }
  }
`;

export const EFFECTIVE_SAVINGS_RATE = gql`
  query effectiveSavingsRate(
    $timeRange: TimeRangeInput!
    $groupBy: [EsrGrouping!]
  ) {
    effectiveSavingsRate(timeRange: $timeRange, groupBy: $groupBy) {
      group
      savings
      netSavings
      effectiveSavingsRate
      netEffectiveSavingsRate
    }
  }
`;

export const COMMITMENT_INSIGHTS = gql`
  query commitmentsInsights(
    $orderBy: [CommitmentInsightsOrdering!]
    $where: CommitmentInsightsFilterInput
    $limit: Int
    $offset: Int
  ) {
    commitmentsInsights(
      orderBy: $orderBy
      where: $where
      limit: $limit
      offset: $offset
    ) {
      id
      createdAt
      type
      utilizationPercentage
      commitment {
        id
        type
        specifications
        instanceCount
        region
        expiresInDays
        monthlyPrice
        duration
        term
        offeringType
        tenancy
      }
      project {
        id
        canonicalName
      }
      utilizationPercentage
    }
  }
`;

export const COMMITMENT_INSIGHTS_FILTERS = gql`
  query commitmentsInsightsFilters($where: CommitmentInsightsFilterInput) {
    commitmentsInsightsFilters(where: $where) {
      _id
      insightType
      type
      specifications
      expiresInDays {
        max
      }
      instanceCount {
        max
      }
      duration
      project {
        id
        name
      }
      region
      hourlyPrice {
        max
      }
      utilization {
        max
      }
    }
  }
`;
