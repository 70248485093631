import {
  DocumentNode,
  QueryHookOptions,
  WatchQueryOptions,
  useQuery,
} from '@apollo/client';

import { Query } from './generated/graphql';
import { QUERIES, QueryNames } from './queries';
import { onApolloError } from './utils';

type UsePFQuery<TQuery> = {
  queryName: QueryNames | string;
  query?: DocumentNode;
  options?: QueryHookOptions<TQuery>;
};

// TODO: The generic type could be improved for better DX
// TODO: Add accessor for the data to make it easier to use
export const usePFQuery = <TQuery extends Partial<Query>>({
  queryName,
  query,
  options,
  variables,
}: UsePFQuery<TQuery> & { variables?: WatchQueryOptions['variables'] }) => {
  const { loading, error, data, refetch } = useQuery<TQuery>(
    query ?? QUERIES[queryName as QueryNames],
    {
      variables,
      onError: (error) => {
        onApolloError({
          error,
          sentryMessage: `usePFQuery ${queryName} failed`,
        });
        options?.onError?.(error);
      },
      onCompleted: options?.onCompleted,
      errorPolicy: 'all',
    },
  );

  return {
    refetch,
    data,
    loading,
    error,
  };
};

// TODO: try react-query as well. DONT forget to intercept with cookies.
