import { ApolloQueryResult } from '@apollo/client';

import { Role, UserMe } from '@pointfive/gql';

export type LoggedInUser = UserMe;

export type UserRole = Role;

export const USER_ROLES: Record<UserRole, UserRole> = {
  ADMIN: Role.Admin,
  MEMBER: Role.Member,
};

export interface AuthContext {
  user: LoggedInUser | undefined;
  setUser: (user: LoggedInUser) => void;

  refetchUserFunction: () => void;
  setRefetchUserFunction: (
    refetchUser: () => Promise<ApolloQueryResult<{ me: UserMe }>>,
  ) => void;
}
