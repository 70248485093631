import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as FargateColorIcon } from './variant-svgs/fargate-color.svg';
import { ReactComponent as FargateDisabledIcon } from './variant-svgs/fargate-disabled.svg';
import { ReactComponent as FargateBlackIcon } from './variant-svgs/fargate-regular.svg';

const VARIANT_ICON_MAP = {
  default: FargateColorIcon,
  black: FargateBlackIcon,
  disabled: FargateDisabledIcon,
};

export const FargateIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="fargate-icon"
      />
    );
  },
);

FargateIcon.displayName = 'FargateIcon';
