import { ErrorBoundary } from '@sentry/react';
import { ToastContainer } from 'react-toastify';

import Sentry from 'services/sentry';

import 'react-toastify/dist/ReactToastify.css';

export const ToastOutlet = () => {
  return (
    // Added basic configurations since we'll be using our custom components
    // can be overriden by passing props to the toast() function
    <ErrorBoundary
      onError={(error) => {
        let msg = '-';
        if (error instanceof Error) {
          msg = error.message;
        }
        Sentry.captureException(new Error('ModalOutlet captured exception'), {
          extra: { error, msg },
        });
      }}
    >
      <ToastContainer
        hideProgressBar={true}
        autoClose={3000}
        closeButton={false}
        limit={1}
        position="top-center"
        draggable={false}
        className={'top-0 w-min whitespace-nowrap'}
        bodyClassName={'p-0'}
        toastClassName={'bg-transparent shadow-none mb-0'}
      />
    </ErrorBoundary>
  );
};
