import { ReactElement, cloneElement } from 'react';

import { ToolTip } from './ToolTip';
import { ToolTipProps } from './types';
import { useIsHorizontalOverflow } from './useIsHorizontalOverflow';

export const EllipsisToolTip = ({
  children,
  ...props
}: Omit<ToolTipProps, 'disabled'>) => {
  const { ref, isHorizontalOverflow } = useIsHorizontalOverflow();

  const childrenWithRef = cloneElement(children as ReactElement, { ref });

  return (
    <ToolTip {...props} disabled={!isHorizontalOverflow}>
      {childrenWithRef}
    </ToolTip>
  );
};
