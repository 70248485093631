import { gql } from '@apollo/client';

export const GET_JIRA_PROJECTS = gql`
  query getJiraProjects($integrationId: UUID!) {
    external {
      jira {
        projects(integrationId: $integrationId) {
          id
          key
          name
        }
      }
    }
  }
`;

export const GET_JIRA_PROJECT = gql`
  query JiraProject($integrationId: UUID!, $projectKey: String!) {
    external {
      jira {
        project(integrationId: $integrationId, projectKey: $projectKey) {
          issueTypes {
            name
            hasPriorityField
            requiredFields {
              name
              key
              type
              options
            }
          }
        }
      }
    }
  }
`;

export const GET_JIRA_USERS = gql`
  query GetJiraUsers($integrationId: UUID!) {
    external {
      jira {
        users(integrationId: $integrationId) {
          id
          name
        }
      }
    }
  }
`;

export const GET_JIRA_TICKET_OF_O9Y = gql`
  query GetTicket(
    $integrationId: UUID!
    $shareableId: UUID!
    $shareableType: ShareableType!
    $type: TicketType!
  ) {
    external {
      jira {
        ticket(
          integrationId: $integrationId
          shareableId: $shareableId
          shareableType: $shareableType
          type: $type
        ) {
          ticketId
          key
          type
          status
          link
          id
        }
      }
    }
  }
`;

export const GET_JIRA_INTEGRATION = gql`
  query GetJiraIntegrations {
    integrations(where: { type: { EQ: "JIRA" } }) {
      id
      name
    }
  }
`;

export const GET_JIRA_ASSIGNABLE_USERS = gql`
  query assignableUsers(
    $integrationId: UUID!
    $projectKey: String!
    $searchTerm: String
  ) {
    external {
      jira {
        assignableUsers(
          integrationId: $integrationId
          projectKey: $projectKey
          searchTerm: $searchTerm
        ) {
          emailAddress
          displayName
          accountID
        }
      }
    }
  }
`;
