import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as DaemonsetDsColorIcon } from './variant-svgs/daemonset-ds-color.svg';
import { ReactComponent as DaemonsetDsDisabledIcon } from './variant-svgs/daemonset-ds-disabled.svg';
import { ReactComponent as DaemonsetDsBlackIcon } from './variant-svgs/daemonset-ds-regular.svg';

const VARIANT_ICON_MAP = {
  default: DaemonsetDsColorIcon,
  black: DaemonsetDsBlackIcon,
  disabled: DaemonsetDsDisabledIcon,
};

export const DaemonsetDsIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="daemonset-ds-icon"
      />
    );
  },
);

DaemonsetDsIcon.displayName = 'DaemonsetDsIcon';
