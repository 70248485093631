import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as IamUserColorIcon } from './variant-svgs/iam-user-color.svg';
import { ReactComponent as IamUserDisabledIcon } from './variant-svgs/iam-user-disabled.svg';
import { ReactComponent as IamUserBlackIcon } from './variant-svgs/iam-user-regular.svg';

const VARIANT_ICON_MAP = {
  default: IamUserColorIcon,
  black: IamUserBlackIcon,
  disabled: IamUserDisabledIcon,
};

export const IamUserIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="iam-user-icon"
      />
    );
  },
);

IamUserIcon.displayName = 'IamUserIcon';
