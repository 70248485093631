import { create } from 'zustand';

import { createSelectors } from 'stores/createSelectors';

import { ModalStoreType } from './types';

const baseModalStore = create<ModalStoreType>((set) => ({
  // state
  modal: undefined,

  // actions
  openModal: (modal: JSX.Element) => set({ modal }),
  closeModal: () => set({ modal: undefined }),
}));

export const useModalStore = createSelectors(baseModalStore);
