import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as K8ClusterColorIcon } from './variant-svgs/k8-cluster-color.svg';
import { ReactComponent as K8ClusterDisabledIcon } from './variant-svgs/k8-cluster-disabled.svg';
import { ReactComponent as K8ClusterBlackIcon } from './variant-svgs/k8-cluster-regular.svg';

const VARIANT_ICON_MAP = {
  default: K8ClusterColorIcon,
  black: K8ClusterBlackIcon,
  disabled: K8ClusterDisabledIcon,
};

export const K8ClusterIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="k8-cluster-icon"
      />
    );
  },
);

K8ClusterIcon.displayName = 'K8ClusterIcon';
