import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavAnomaliesIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavAnomalies"
      >
        <g clipPath="url(#clip0_956_11328)">
          <path d="M17.0397 23.9252H6.95971C4.76971 23.9252 3.65971 23.9252 2.65471 23.4152C1.75471 22.9652 1.04971 22.2452 0.584707 21.3452C0.0747073 20.3402 0.074707 19.2302 0.074707 17.0402V6.96019C0.074707 4.77019 0.0747073 3.6602 0.584707 2.6552C1.03471 1.7552 1.75471 1.05019 2.65471 0.58519C3.65971 0.0751898 4.76971 0.0751953 6.95971 0.0751953H17.0397C19.2297 0.0751953 20.3397 0.0751898 21.3447 0.58519C22.2447 1.03519 22.9497 1.7552 23.4147 2.6552C23.9247 3.6752 23.9247 4.77019 23.9247 6.96019V17.0402C23.9247 19.2302 23.9247 20.3402 23.4147 21.3452C22.9647 22.2452 22.2447 22.9502 21.3447 23.4152C20.3397 23.9252 19.2297 23.9252 17.0397 23.9252ZM6.95971 2.3252C5.18971 2.3252 4.19971 2.32518 3.67471 2.59518C3.20971 2.83518 2.83471 3.21019 2.59471 3.67519C2.32471 4.20019 2.32471 5.19019 2.32471 6.96019V17.0402C2.32471 18.8102 2.32471 19.8002 2.59471 20.3252C2.83471 20.7902 3.20971 21.1652 3.67471 21.4052C4.19971 21.6752 5.18971 21.6752 6.95971 21.6752H17.0397C18.8097 21.6752 19.7997 21.6752 20.3247 21.4052C20.7897 21.1652 21.1647 20.7902 21.4047 20.3252C21.6747 19.8002 21.6747 18.8252 21.6747 17.0402V6.96019C21.6747 5.19019 21.6747 4.20019 21.4047 3.67519C21.1647 3.21019 20.7897 2.83518 20.3247 2.59518C19.7997 2.32518 18.8247 2.3252 17.0397 2.3252H6.95971Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.29971 4.87519C9.74971 4.87519 10.1397 5.13019 10.3197 5.53519L14.6997 15.2552L16.3797 11.5352C16.5597 11.1302 16.9647 10.8752 17.3997 10.8752H20.9997C21.6147 10.8752 22.1247 11.3852 22.1247 12.0002C22.1247 12.6152 21.6147 13.1252 20.9997 13.1252H18.1197L15.7197 18.4652C15.5397 18.8702 15.1347 19.1252 14.6997 19.1252C14.2647 19.1252 13.8597 18.8702 13.6797 18.4652L9.29971 8.74519L7.61971 12.4652C7.43971 12.8702 7.03471 13.1252 6.59971 13.1252H2.99971C2.38471 13.1252 1.87471 12.6152 1.87471 12.0002C1.87471 11.3852 2.38471 10.8752 2.99971 10.8752H5.87971L8.27971 5.53519C8.45971 5.13019 8.86471 4.87519 9.29971 4.87519Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_956_11328">
            <rect width="24" height="24" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

SideNavAnomaliesIcon.displayName = 'SideNavAnomaliesIcon';
