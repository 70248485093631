import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const ExpertInsights02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="ExpertInsights02"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.0572 2.29289C3.66668 1.90237 3.03352 1.90237 2.64299 2.29289C2.25247 2.68342 2.25247 3.31658 2.64299 3.70711L5.26866 6.33277C4.71218 7.39301 4.40199 8.59092 4.40981 9.84999C4.40981 11.01 4.68981 12.18 5.21981 13.22C5.73981 14.26 6.50981 15.18 7.44981 15.89C7.58981 16 7.7098 16.14 7.7898 16.3C7.8698 16.46 7.90981 16.63 7.90981 16.8V17.31C7.90981 17.73 8.07981 18.14 8.38981 18.44C8.68981 18.73 9.0898 18.9 9.5098 18.9H15.1098C15.5298 18.9 15.9298 18.74 16.2298 18.44C16.4315 18.245 16.579 17.9912 16.654 17.7181L20.643 21.7071C21.0335 22.0976 21.6667 22.0976 22.0572 21.7071C22.4477 21.3166 22.4477 20.6834 22.0572 20.2929L4.0572 2.29289ZM14.9938 16.0579L6.62706 7.69117C6.35723 8.36185 6.20982 9.0898 6.20982 9.84999C6.21982 10.73 6.42981 11.62 6.82981 12.41C7.22981 13.21 7.8198 13.91 8.5398 14.46C8.8898 14.73 9.18981 15.09 9.38981 15.49C9.58981 15.89 9.69981 16.34 9.69981 16.79V17.09H14.8998V16.8C14.8998 16.5504 14.9306 16.3007 14.9938 16.0579Z"
        />
        <path d="M12.1498 3.89999C10.7298 3.92999 9.4198 4.44999 8.3998 5.27999L8.3898 5.25999L7.1198 3.98999C8.4698 2.83999 10.2098 2.11999 12.1098 2.07999C13.7598 2.04999 15.3898 2.50999 16.7598 3.42999C18.1398 4.35999 19.1598 5.64999 19.7298 7.18999C20.2998 8.72999 20.3498 10.38 19.8898 11.95C19.5498 13.1 18.9398 14.14 18.1298 15.01L16.8498 13.73C17.4598 13.08 17.9098 12.3 18.1598 11.44C18.5098 10.25 18.4698 8.98999 18.0398 7.81999C17.6098 6.63999 16.8198 5.64999 15.7598 4.93999C14.6898 4.22999 13.4598 3.85999 12.1498 3.89999Z" />
        <path d="M12.3998 8.44999L12.0398 8.92999L10.9698 7.85999L11.1998 7.54999C11.4498 7.21999 11.9198 7.14999 12.2498 7.39999C12.5798 7.64999 12.6498 8.11999 12.3998 8.44999Z" />
        <path d="M16.3102 21.9001H8.31015C7.81015 21.9001 7.41016 21.5001 7.41016 21.0001C7.41016 20.5001 7.81015 20.1001 8.31015 20.1001H16.3102C16.8102 20.1001 17.2102 20.5001 17.2102 21.0001C17.2102 21.5001 16.8102 21.9001 16.3102 21.9001Z" />
        <path d="M14.3298 11.21L14.4898 11C14.7398 10.67 14.7798 10.23 14.5898 9.84999C14.3998 9.47999 14.0298 9.23999 13.6098 9.23999H12.3698L14.3298 11.2V11.21Z" />
      </svg>
    );
  },
);

ExpertInsights02Icon.displayName = 'ExpertInsights02Icon';
