import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const DollarIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Dollar"
      >
        <path d="M13.3254 18.0053V19.7333C13.3254 19.8041 13.2973 19.8719 13.2473 19.9219C13.1973 19.9719 13.1294 20 13.0587 20H11.8054C11.7347 20 11.6668 19.9719 11.6168 19.9219C11.5668 19.8719 11.5387 19.8041 11.5387 19.7333V18.096C10.2054 18.0187 9.13871 17.68 8.18405 16.8853C8.12883 16.8375 8.08404 16.7788 8.05245 16.7129C8.02085 16.647 8.00313 16.5754 8.00038 16.5024C7.99763 16.4294 8.00991 16.3566 8.03646 16.2885C8.06301 16.2204 8.10326 16.1585 8.15472 16.1067L8.93605 15.3067C9.02535 15.2119 9.14624 15.1531 9.27592 15.1413C9.4056 15.1295 9.53512 15.1656 9.64005 15.2427C10.3574 15.776 11.256 15.9733 12.2134 15.9733C13.6054 15.9733 14.3467 15.44 14.3467 14.4613C14.3589 14.2729 14.3321 14.0839 14.2678 13.9063C14.2036 13.7287 14.1033 13.5664 13.9734 13.4293C13.675 13.1724 13.2999 13.0215 12.9067 13L11.4587 12.8C10.424 12.6453 9.66138 12.32 9.11738 11.7867C8.54138 11.2027 8.25338 10.4133 8.25338 9.38667C8.25338 7.46667 9.48272 6.056 11.5387 5.73867V4.26667C11.5387 4.19594 11.5668 4.12811 11.6168 4.0781C11.6668 4.02809 11.7347 4 11.8054 4H13.0587C13.1294 4 13.1973 4.02809 13.2473 4.0781C13.2973 4.12811 13.3254 4.19594 13.3254 4.26667V5.71733C14.259 5.78918 15.1523 6.12798 15.8987 6.69333C15.9595 6.73888 16.0099 6.79688 16.0465 6.86347C16.083 6.93005 16.1049 7.00368 16.1108 7.07943C16.1166 7.15517 16.1061 7.23128 16.0801 7.30267C16.0541 7.37405 16.0132 7.43905 15.96 7.49333L15.2187 8.24C15.1315 8.33113 15.0147 8.38836 14.8892 8.40155C14.7638 8.41473 14.6377 8.383 14.5334 8.312C13.8999 7.90292 13.1528 7.70587 12.4 7.74933C11.144 7.74933 10.5334 8.45333 10.5334 9.296C10.5297 9.45892 10.5595 9.62086 10.6209 9.77178C10.6824 9.9227 10.7742 10.0594 10.8907 10.1733C11.207 10.4428 11.5975 10.6102 12.0107 10.6533L13.4347 10.8587C14.5387 11.0133 15.232 11.3227 15.7414 11.8027C16.3867 12.4213 16.6747 13.3147 16.6747 14.3973C16.6667 16.4 15.272 17.656 13.3254 18.0053Z" />
      </svg>
    );
  },
);

DollarIcon.displayName = 'DollarIcon';
