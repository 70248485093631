import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavAutomationsIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavAutomations"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.514 0.0777963C13.9056 0.243974 14.1382 0.642636 14.0853 1.05674L13.0892 8.84794L20.2851 8.84794C20.5613 8.8479 20.8303 8.84786 21.0479 8.86713C21.2553 8.88549 21.6199 8.93256 21.9327 9.17968C22.2981 9.4683 22.5068 9.90495 22.4998 10.3634C22.4937 10.7565 22.2973 11.0607 22.1789 11.2283C22.0547 11.4042 21.8824 11.6062 21.7055 11.8137L11.6075 23.6617C11.3342 23.9823 10.8775 24.0884 10.486 23.9222C10.0945 23.756 9.86185 23.3574 9.91479 22.9433L10.9109 15.1521L3.71494 15.1521C3.43873 15.1521 3.16976 15.1521 2.9521 15.1329C2.74474 15.1145 2.3801 15.0674 2.06728 14.8203C1.70236 14.5319 1.49309 14.0954 1.50017 13.6365C1.50625 13.2433 1.70264 12.9393 1.82102 12.7717C1.9453 12.5958 2.11763 12.3937 2.29458 12.1862L12.3926 0.338312C12.6658 0.0177093 13.1225 -0.0883818 13.514 0.0777963ZM3.8724 13.2719H12C12.2758 13.2719 12.5383 13.3877 12.7208 13.5898C12.9033 13.792 12.9883 14.061 12.9541 14.3286L12.2297 19.9947L20.1276 10.7281H12C11.7242 10.7281 11.4617 10.6123 11.2792 10.4102C11.0967 10.208 11.0118 9.93899 11.046 9.67142L11.7704 4.00526L3.8724 13.2719Z"
        />
      </svg>
    );
  },
);

SideNavAutomationsIcon.displayName = 'SideNavAutomationsIcon';
