import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Resources01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Resources01"
      >
        <path d="M11.9 21.8999C11.81 21.8999 11.73 21.8999 11.64 21.8699C11.46 21.8399 11.32 21.7699 11.17 21.6899L2.5 17.3299C2.06 17.1099 1.88 16.5699 2.1 16.1199C2.32 15.6799 2.87 15.4999 3.31 15.7199L11.91 20.0399L20.51 15.7199C20.95 15.4999 21.5 15.6799 21.72 16.1199C21.94 16.5599 21.76 17.0999 21.32 17.3299L12.64 21.6899C12.49 21.7699 12.35 21.8399 12.16 21.8699C12.08 21.8899 12 21.8899 11.92 21.8899L11.9 21.8999ZM11.9 17.3699C11.82 17.3699 11.73 17.3699 11.65 17.3499C11.46 17.3099 11.32 17.2399 11.17 17.1699L2.5 12.7999C2.06 12.5799 1.88 12.0399 2.1 11.5899C2.32 11.1499 2.87 10.9699 3.31 11.1899L11.91 15.5099L20.51 11.1899C20.95 10.9699 21.5 11.1499 21.72 11.5899C21.94 12.0299 21.76 12.5699 21.32 12.7999L12.64 17.1599C12.49 17.2299 12.35 17.3099 12.16 17.3399C12.08 17.3599 11.99 17.3599 11.91 17.3599L11.9 17.3699ZM11.9 12.8499C11.82 12.8499 11.73 12.8499 11.65 12.8299C11.46 12.7899 11.33 12.7299 11.18 12.6499L2.5 8.27991C2.19 8.12991 2 7.80991 2 7.46991C2 7.12991 2.19 6.81991 2.5 6.66991L11.18 2.29991C11.34 2.21991 11.47 2.14991 11.66 2.11991C11.83 2.08991 11.99 2.08991 12.16 2.11991C12.35 2.15991 12.48 2.21991 12.63 2.29991L21.31 6.66991C21.61 6.81991 21.81 7.12991 21.81 7.46991C21.81 7.80991 21.62 8.11991 21.31 8.26991L12.63 12.6299C12.47 12.7099 12.34 12.7799 12.15 12.8099C12.07 12.8299 11.98 12.8299 11.9 12.8299V12.8499ZM4.9 7.46991L11.9 10.9899L18.9 7.46991L11.9 3.94991L4.9 7.46991Z" />
      </svg>
    );
  },
);

Resources01Icon.displayName = 'Resources01Icon';
