import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const User1Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="User1"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0001 4C10.0671 4 8.50008 5.567 8.50008 7.5C8.50008 9.433 10.0671 11 12.0001 11C13.9331 11 15.5001 9.433 15.5001 7.5C15.5001 5.567 13.9331 4 12.0001 4ZM6.50008 7.5C6.50008 4.46243 8.96252 2 12.0001 2C15.0376 2 17.5001 4.46243 17.5001 7.5C17.5001 10.5376 15.0376 13 12.0001 13C8.96252 13 6.50008 10.5376 6.50008 7.5ZM9.32635 14.5C9.38341 14.5 9.44132 14.5 9.50009 14.5H14.5001C14.5589 14.5 14.6168 14.5 14.6738 14.5C15.9011 14.4995 16.7391 14.4992 17.4515 14.7153C19.0495 15.2 20.3 16.4506 20.7848 18.0486C21.0009 18.761 21.0006 19.599 21.0001 20.8263C21.0001 20.8833 21.0001 20.9412 21.0001 21C21.0001 21.5523 20.5524 22 20.0001 22C19.4478 22 19.0001 21.5523 19.0001 21C19.0001 19.5317 18.9892 19.0192 18.8709 18.6291C18.5801 17.6703 17.8297 16.92 16.8709 16.6292C16.4809 16.5108 15.9684 16.5 14.5001 16.5H9.50009C8.03177 16.5 7.51929 16.5108 7.12923 16.6292C6.17042 16.92 5.42011 17.6703 5.12926 18.6291C5.01094 19.0192 5.00008 19.5317 5.00008 21C5.00008 21.5523 4.55237 22 4.00008 22C3.4478 22 3.00008 21.5523 3.00008 21C3.00008 20.9412 3.00006 20.8833 3.00004 20.8263C2.99959 19.599 2.99928 18.761 3.21538 18.0486C3.70013 16.4506 4.95065 15.2 6.54866 14.7153C7.26106 14.4992 8.09908 14.4995 9.32635 14.5Z"
        />
      </svg>
    );
  },
);

User1Icon.displayName = 'User1Icon';
