import { ReactNode, useEffect, useRef, useState } from 'react';

import * as LDClient from 'launchdarkly-js-client-sdk';

import { useAuth } from 'services/auth';
import Sentry from 'services/sentry';

import { EmptyState } from './components/EmptyState';
import { FeatureFlagType, FlagChanges, fallbackFlags } from './types';
import { useFeatureFlags, useLaunchDarkly } from './useFeatureFlags';

const noop = () => {};
const fallbackClient: Partial<LDClient.LDClient> = {
  allFlags: () => ({}),
  on: () => {},
  variation: (flag: FeatureFlagType) => fallbackFlags[flag] || false,
};

const useFallback = () => {
  const flags = useFeatureFlags((state) => state.flags);
  const [shouldFallback, setShouldFallback] = useState(false);
  const flagsRef = useRef(flags);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    flagsRef.current = flags;
  }, [flags]);

  useEffect(() => {
    // TODO: should we clear the timeout on unmount?
    timeoutRef.current = setTimeout(() => {
      if (!flagsRef.current) {
        setShouldFallback(true);
      }
    }, 3000);
  }, []);

  useEffect(() => {
    if (shouldFallback) {
      Sentry.captureException(new Error('LaunchDarkly - using fallback flags'));
    }
  }, [shouldFallback]);

  return shouldFallback;
};

export const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const user = useAuth.use.user();
  const [featureFlags, updateFlags] = useFeatureFlags((state) => [
    state.flags,
    state.updateFlags,
  ]);
  const setLDClient = useLaunchDarkly((state) => state.setClient);
  const LDClientID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT_ID;
  const shouldFallback = useFallback();

  useEffect(() => {
    if (!user) {
      return;
    }

    if (shouldFallback) {
      setLDClient(fallbackClient as LDClient.LDClient);
      updateFlags(fallbackFlags);
    }

    const { id: key, ...userFields } = user;

    const isSu = Boolean(userFields?.originalUserId);
    const oid = userFields?.originalUserId
      ? `${userFields.originalUserId}`
      : '';
    const oidkey = `${userFields.originalUserId}-${key}`;
    const client = LDClient.initialize(
      LDClientID,
      {
        kind: isSu ? 'superuser' : 'user',
        key: oid ? oidkey : key,
        ...userFields,
      },
      {
        sendEventsOnlyForVariation: true,
        bootstrap: 'localStorage',
        logger: {
          debug: noop,
          info: noop,
          warn: noop,
          error: Sentry.captureException,
        },
      },
    );

    client.on('ready', () => {
      if (shouldFallback) {
        return;
      }

      setLDClient(client);
      const flags = client.allFlags() as Record<FeatureFlagType, boolean>;
      updateFlags(flags);
    });

    client.on('change', (changes: FlagChanges) => {
      if (shouldFallback) {
        return;
      }

      const flagsChanged = Object.entries(changes).reduce<
        Record<string, boolean>
      >((acc, [key, { current }]) => {
        acc[key] = current;
        return acc;
      }, {});

      updateFlags(flagsChanged);
    });

    client.on('error', (err) =>
      Sentry.captureException(new Error(`FeatureFlagsProvider: ${err}`)),
    );
    client.on('failed', (err) =>
      Sentry.captureException(new Error(`FeatureFlagsProvider: ${err}`)),
    );
  }, [user, shouldFallback]);

  if (!featureFlags) {
    return <EmptyState />;
  }

  return <>{children}</>;
};
