import { ReactNode } from 'react';

import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URL || '/query',
  credentials: 'include',
});

const defaultClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

export const GraphQLProvider = ({
  children,
  client = defaultClient,
}: {
  children: ReactNode;
  client?: ApolloClient<unknown>;
}) => {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
