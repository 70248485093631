import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Automations02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Automations02"
      >
        <path d="M13.1296 4.51952L10.4396 7.73952V7.72952L9.26959 6.55953L13.6596 1.29952C13.8896 1.01952 14.2796 0.919521 14.6196 1.06952C14.9596 1.21952 15.1596 1.56952 15.1096 1.92952L14.2596 8.74953H20.4296C20.6595 8.74953 20.8897 8.74953 21.0796 8.76952C21.2596 8.77952 21.5696 8.81952 21.8396 9.03952C22.1596 9.29952 22.3296 9.67953 22.3296 10.0795C22.3296 10.4295 22.1596 10.6895 22.0596 10.8395C21.9497 10.9894 21.7998 11.1693 21.6499 11.3492L18.1896 15.4895L17.0196 14.3195L20.2896 10.3995H13.3196C13.2196 10.3995 13.1196 10.3695 13.0296 10.3395L12.5596 9.86952C12.5096 9.73952 12.4896 9.60952 12.5096 9.47952L13.1296 4.51952Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8427 18.3165L20.2929 22.7667C20.6834 23.1572 21.3166 23.1572 21.7071 22.7667C22.0976 22.3762 22.0976 21.743 21.7071 21.3525L3.70711 3.35246C3.31658 2.96194 2.68342 2.96194 2.29289 3.35246C1.90237 3.74299 1.90237 4.37615 2.29289 4.76668L6.91901 9.39279L5.01959 11.6695C4.86968 11.8494 4.71953 12.0296 4.60959 12.1795C4.50959 12.3295 4.33959 12.5895 4.32959 12.9395C4.32959 13.3395 4.49958 13.7195 4.81958 13.9795C5.08958 14.1995 5.39959 14.2395 5.57959 14.2495C5.76948 14.2695 5.99932 14.2695 6.22919 14.2695H11.7957L12.3327 14.8064L11.5496 21.0895C11.4996 21.4495 11.6996 21.7995 12.0396 21.9495C12.3796 22.0895 12.7696 21.9995 12.9996 21.7195L15.8427 18.3165ZM14.667 17.1408L13.5296 18.4995L13.8075 16.2813L14.667 17.1408ZM10.1457 12.6195L8.08826 10.562L6.36958 12.6195H10.1457Z"
        />
      </svg>
    );
  },
);

Automations02Icon.displayName = 'Automations02Icon';
