import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const AddTagIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="AddTag"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.2302 12.9C20.9702 12.11 20.3902 11.52 19.3202 10.46L12.9202 4.06C12.6002 3.74 12.4102 3.55 12.1402 3.38C11.9102 3.24 11.6502 3.13 11.3902 3.07C11.0902 3 10.8102 3 10.3602 3H6.62023C5.57023 3 4.99023 3 4.43023 3.29C3.93023 3.54 3.54023 3.94 3.28023 4.44C2.99023 5 2.99023 5.58 2.99023 6.63V10.37C2.99023 10.82 2.99023 11.1 3.06023 11.39C3.12023 11.66 3.23023 11.91 3.37023 12.15C3.64023 12.6 4.23023 12.74 4.68023 12.47C5.13023 12.2 5.27023 11.61 4.99023 11.16C4.95023 11.1 4.92023 11.03 4.90023 10.95C4.88023 10.87 4.88023 10.67 4.88023 10.37V6.63C4.88023 5.97 4.88023 5.46 4.96023 5.3C5.03023 5.16 5.14023 5.05 5.27023 4.99C5.42023 4.91 5.94023 4.91 6.60023 4.91H10.3402C10.6402 4.91 10.8402 4.91 10.9202 4.93C10.9902 4.95 11.0702 4.98 11.1302 5.02C11.2002 5.06 11.3402 5.21 11.5602 5.42L17.9602 11.82C18.8202 12.68 19.2902 13.15 19.4002 13.5C19.5002 13.81 19.5002 14.15 19.4002 14.46C19.2802 14.81 18.8102 15.29 17.9602 16.14L16.1102 17.99C15.2502 18.85 14.7802 19.32 14.4302 19.43C14.1202 19.53 13.7902 19.53 13.4702 19.43C12.9702 19.27 12.4302 19.54 12.2702 20.04C12.1102 20.54 12.3802 21.08 12.8802 21.24C13.2302 21.35 13.5902 21.41 13.9502 21.41C14.3102 21.41 14.6702 21.35 15.0202 21.24C15.8102 20.98 16.4002 20.4 17.4602 19.33L19.3102 17.48C20.3702 16.42 20.9602 15.83 21.2202 15.04C21.4502 14.34 21.4502 13.6 21.2202 12.9H21.2302Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.4502 15.09H9.31023V12.95C9.31023 12.47 8.92023 12.09 8.45023 12.09C7.98023 12.09 7.59023 12.48 7.59023 12.95V15.09H5.45023C4.97023 15.09 4.59023 15.48 4.59023 15.95C4.59023 16.42 4.98023 16.81 5.45023 16.81H7.59023V18.95C7.59023 19.43 7.98023 19.81 8.45023 19.81C8.92023 19.81 9.31023 19.42 9.31023 18.95V16.81H11.4502C11.9302 16.81 12.3102 16.42 12.3102 15.95C12.3102 15.48 11.9202 15.09 11.4502 15.09Z"
        />
      </svg>
    );
  },
);

AddTagIcon.displayName = 'AddTagIcon';
