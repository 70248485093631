import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Upload02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Upload02"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3C2 2.44772 2.44772 2 3 2H21C21.5523 2 22 2.44772 22 3C22 3.55228 21.5523 4 21 4H3C2.44772 4 2 3.55228 2 3ZM12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289L18.7071 12.2929C19.0976 12.6834 19.0976 13.3166 18.7071 13.7071C18.3166 14.0976 17.6834 14.0976 17.2929 13.7071L13 9.41421V21C13 21.5523 12.5523 22 12 22C11.4477 22 11 21.5523 11 21V9.41421L6.70711 13.7071C6.31658 14.0976 5.68342 14.0976 5.29289 13.7071C4.90237 13.3166 4.90237 12.6834 5.29289 12.2929L11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6Z"
        />
      </svg>
    );
  },
);

Upload02Icon.displayName = 'Upload02Icon';
