import { useEffect, useState } from 'react';

import * as Tooltip from '@radix-ui/react-tooltip';

import { TOOLTIP_VARIANT_CLASSNAMES, ToolTipVariants } from './constants';
import { ToolTipAlignment, ToolTipProps } from './types';

// It seems that radix needs help in aligning the arrow when alignment is different than "center"
const calcArrowPadding = (alignment: ToolTipAlignment) => {
  switch (alignment) {
    case 'start':
      return 12;
    default:
      return 0;
  }
};

export const ToolTip = ({
  message,
  children,
  side = 'left',
  sideOffset = 5,
  delayDuration = 300,
  classNames = '',
  variant = ToolTipVariants.BLACK,
  showCaret = true,
  alignTooltip,
  collisionPadding = 12,
  disabled,
  forceOpen,
  tooltipDuration,
}: ToolTipProps) => {
  const [timeoutVisibility, setTimeoutVisibility] = useState<
    boolean | undefined
  >(undefined);

  let visibility = timeoutVisibility;

  if (forceOpen) {
    visibility = true;
  }

  if (disabled) {
    visibility = false;
  }

  useEffect(() => {
    if (tooltipDuration) {
      setTimeoutVisibility(true);

      const timeoutId = setTimeout(() => {
        setTimeoutVisibility(false);
      }, tooltipDuration);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, []);
  const { container, body, arrow, arrowStyle, align } =
    TOOLTIP_VARIANT_CLASSNAMES[variant] ?? {};

  const alignContent = alignTooltip ?? align;
  const arrowPadding = calcArrowPadding(alignContent);

  return (
    <Tooltip.Provider delayDuration={delayDuration}>
      <Tooltip.Root open={visibility}>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content
            align={alignContent}
            side={side}
            // TODO: use twMerge instead of string concatenation
            className={`z-50 flex max-h-[60dvh] max-w-[21.75rem] overflow-hidden rounded-md ${classNames} ${container}`}
            sideOffset={sideOffset}
            collisionPadding={collisionPadding} // creates space between tooltip and window frame
            arrowPadding={arrowPadding}
          >
            <div
              className={`text-overline w-full whitespace-pre-line border border-solid ${body}`}
              data-cy="tooltip-message"
            >
              {message}
            </div>
            {showCaret ? (
              <Tooltip.Arrow className={arrow} style={arrowStyle} />
            ) : null}
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  );
};
