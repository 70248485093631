import { Suspense } from 'react';

import classNames from 'classnames';
import { Outlet } from 'react-router-dom';

import { LoadingState } from 'components/LoadingState';

import { SideNav } from '../components/SideNav';
import { useSideNav } from '../components/SideNav/useSideNav';
import { TopNav } from '../components/TopNav/TopNav';
import { TOP_NAV_HEIGHT } from '../components/TopNav/constants';
import { UnusableScreenSizeFallback } from './UnusableScreenSizeFallback';

const Content = () => {
  const isCollapsed = useSideNav((state) => state.collapsed);
  const isLargeScreenOnly = import.meta.env.MODE !== 'localdev';

  return (
    <div>
      <div
        className={classNames(
          {
            'is-navbar-collapsed': isCollapsed,
            hidden: isLargeScreenOnly,
            'lg:block': isLargeScreenOnly,
          },
          'group/root h-screen w-screen overflow-auto',
        )}
      >
        <TopNav />
        <div
          className="flex"
          style={{ height: `calc(100vh - ${TOP_NAV_HEIGHT}px)` }}
        >
          <SideNav />
          <div className="ml-[200px] h-full w-full overflow-auto bg-gray-150 p-5 transition-[margin] group-[.is-navbar-collapsed]/root:ml-12">
            <Suspense
              // TODO: TMP
              fallback={
                <div className="flex h-full w-full items-center justify-center">
                  <LoadingState />
                </div>
              }
            >
              <Outlet />
            </Suspense>
          </div>
        </div>
      </div>
      <div
        className={classNames(
          {
            block: isLargeScreenOnly,
            'lg:hidden': isLargeScreenOnly,
            hidden: !isLargeScreenOnly,
          },
          'group h-screen w-screen overflow-auto',
        )}
      >
        <UnusableScreenSizeFallback />
      </div>
    </div>
  );
};

export default Content;
