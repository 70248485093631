import { AnimatePresence, motion } from 'framer-motion';
import { twJoin } from 'tailwind-merge';

type Props = {
  text?: string;
  height?: string;
  width?: string;
  textClassName?: string;
};

export const CloudLoader = ({ text, height, width, textClassName }: Props) => {
  return (
    <AnimatePresence>
      {/* Conditional rendering needed for AnimatePresence to work */}
      {/* eslint-disable-next-line no-constant-binary-expression */}
      {true && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height={height || '60px'}
              width={width || '80px'}
              viewBox="0 0 512 512"
            >
              <motion.path
                initial={{ strokeDashoffset: 0 }}
                animate={{ strokeDashoffset: 2566 }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  repeatType: 'reverse',
                  type: 'tween',
                  ease: 'easeInOut',
                }}
                d="M 320,112 A 112,112 0 0 1 432,224 V 241.618 A 80,80 0 0 1 416,400 H 95.5 A 80,80 0 0 1 95.5,240 H 113.616 A 80,80 0 0 1 192,176 L 217.219,179.453 A 112,112 0 0 1 320,112 Z"
                strokeDashoffset={2566}
                strokeDasharray={2566}
                fill={'none'}
                strokeWidth={12}
                stroke={'#0216C9'}
              />
            </svg>
            <div
              className={twJoin(
                'select-none',
                textClassName || 'text-body-2 text-text-brand',
              )}
            >
              {text}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
