import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Mail02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Mail02"
      >
        <path d="M16.7513 21.0025H7.24873C5.5736 21.0025 4.74112 21.0025 3.96954 20.6168C3.28934 20.2716 2.74112 19.7335 2.39594 19.0533C2 18.2817 2 17.4594 2 15.8046V9.18528C2 8.85025 2 8.6269 2.08122 8.3731C2.15228 8.14975 2.26396 7.93655 2.41624 7.75381C2.58883 7.55076 2.77157 7.42893 3.05584 7.24619L3.23858 7.12437C3.23858 7.12437 3.30964 7.08376 3.3401 7.0533L9.93909 2.80964C10.5787 2.3934 10.934 2.17005 11.3909 2.06853C11.797 1.97716 12.2132 1.97716 12.6193 2.06853C13.0761 2.17005 13.4213 2.3934 14.0609 2.80964L20.6599 7.0533C20.6599 7.0533 20.731 7.09391 20.7614 7.12437L20.934 7.23604C21.2284 7.41878 21.4112 7.54061 21.5838 7.74365C21.736 7.9264 21.8477 8.13959 21.9188 8.36294C22 8.61675 22 8.8401 22 9.17513V15.7944C22 17.4492 22 18.2817 21.6041 19.0431C21.2589 19.7234 20.7107 20.2614 20.0203 20.6066C19.2487 20.9924 18.4162 20.9924 16.7411 20.9924L16.7513 21.0025ZM3.72589 9.97716V15.7944C3.72589 17.1244 3.72589 17.8553 3.92893 18.2513C4.11168 18.5964 4.39594 18.8807 4.74112 19.0533C5.14721 19.2563 5.88832 19.2563 7.23858 19.2563H16.7411C18.0914 19.2563 18.8426 19.2563 19.2386 19.0533C19.5939 18.8706 19.8782 18.5964 20.0609 18.2513C20.264 17.8553 20.264 17.1244 20.264 15.7944V9.97716L14.0609 13.967C13.4213 14.3832 13.066 14.6066 12.6091 14.7081C12.203 14.7995 11.7868 14.7995 11.3807 14.7081C10.9239 14.6066 10.5787 14.3731 9.93909 13.967L3.72589 9.97716ZM4.467 8.3934L10.8731 12.5051C11.3401 12.7995 11.6142 12.9822 11.7766 13.0127C11.9188 13.0431 12.0711 13.0431 12.2233 13.0127C12.3858 12.9721 12.6599 12.7995 13.1168 12.5051L19.533 8.38325L13.1269 4.27157C12.67 3.97716 12.3959 3.79442 12.2335 3.76396C12.0914 3.7335 11.9391 3.7335 11.7868 3.76396C11.6244 3.80457 11.3401 3.97716 10.8832 4.27157L4.47716 8.38325L4.467 8.3934Z" />
      </svg>
    );
  },
);

Mail02Icon.displayName = 'Mail02Icon';
