import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavLogOutIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavLogOut"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 2L7.7587 2L9 2ZM7.7587 2C6.95373 1.99999 6.28937 1.99998 5.74818 2.04419L7.7587 2ZM2 16.2413C1.99999 17.0463 1.99998 17.7106 2.04419 18.2518L2 16.2413Z"
        />
        <path d="M10 3C10 2.44772 9.55229 2 9 2L7.7587 2C6.95373 1.99999 6.28937 1.99998 5.74818 2.04419C5.18608 2.09012 4.66937 2.18868 4.18404 2.43597C3.43139 2.81947 2.81947 3.43139 2.43598 4.18404C2.18868 4.66937 2.09012 5.18608 2.04419 5.74817C1.99998 6.28937 1.99999 6.95373 2 7.7587V16.2413C1.99999 17.0463 1.99998 17.7106 2.04419 18.2518C2.09012 18.8139 2.18868 19.3306 2.43598 19.816C2.81947 20.5686 3.43139 21.1805 4.18404 21.564C4.66937 21.8113 5.18608 21.9099 5.74818 21.9558C6.28936 22 6.95372 22 7.75868 22H9C9.55229 22 10 21.5523 10 21C10 20.4477 9.55229 20 9 20H7.8C6.94342 20 6.36113 19.9992 5.91104 19.9624C5.47262 19.9266 5.24842 19.8617 5.09202 19.782C4.7157 19.5903 4.40973 19.2843 4.21799 18.908C4.1383 18.7516 4.07337 18.5274 4.03755 18.089C4.00078 17.6389 4 17.0566 4 16.2V7.8C4 6.94342 4.00078 6.36113 4.03755 5.91104C4.07337 5.47262 4.1383 5.24842 4.21799 5.09202C4.40974 4.7157 4.7157 4.40973 5.09202 4.21799C5.24842 4.1383 5.47262 4.07337 5.91104 4.03755C6.36113 4.00078 6.94342 4 7.8 4H9C9.55229 4 10 3.55229 10 3Z" />
        <path d="M16.7071 6.29289C16.3166 5.90237 15.6834 5.90237 15.2929 6.29289C14.9024 6.68342 14.9024 7.31658 15.2929 7.70711L18.5858 11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13H18.5858L15.2929 16.2929C14.9024 16.6834 14.9024 17.3166 15.2929 17.7071C15.6834 18.0976 16.3166 18.0976 16.7071 17.7071L21.7071 12.7071C22.0976 12.3166 22.0976 11.6834 21.7071 11.2929L16.7071 6.29289Z" />
      </svg>
    );
  },
);

SideNavLogOutIcon.displayName = 'SideNavLogOutIcon';
