import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as TeamsColorIcon } from './variant-svgs/teams-color.svg';
import { ReactComponent as TeamsDisabledIcon } from './variant-svgs/teams-disabled.svg';
import { ReactComponent as TeamsBlackIcon } from './variant-svgs/teams-regular.svg';

const VARIANT_ICON_MAP = {
  default: TeamsColorIcon,
  black: TeamsBlackIcon,
  disabled: TeamsDisabledIcon,
};

export const TeamsIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="teams-icon"
      />
    );
  },
);

TeamsIcon.displayName = 'TeamsIcon';
