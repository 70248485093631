import { Navigate } from 'react-router-dom';

import { ROUTE_CONSTANTS } from 'router/constants';
import { USER_ROLES, useAuth } from 'services/auth';

export const DefaultRoute = () => {
  const auth = useAuth();

  if (!auth.user) {
    // TODO: @molaga redirect to logout?
    return null;
  }

  switch (auth.user.role) {
    case USER_ROLES.ADMIN:
      return <Navigate to={ROUTE_CONSTANTS.overview} replace />;
    default:
      return <Navigate to={ROUTE_CONSTANTS.opportunities} replace />;
  }
};
