import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavExpertInsightsIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavExpertInsights"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.07461 22.8002C6.07461 22.1789 6.57829 21.6752 7.19961 21.6752H16.7996C17.4209 21.6752 17.9246 22.1789 17.9246 22.8002C17.9246 23.4215 17.4209 23.9252 16.7996 23.9252H7.19961C6.57829 23.9252 6.07461 23.4215 6.07461 22.8002Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.1261 3.57018C14.8653 2.72798 13.3608 2.29177 11.8263 2.32719C7.8591 2.41622 4.7067 5.62783 4.72469 9.42907L4.72469 9.43061C4.72825 10.4887 4.97896 11.5332 5.45922 12.4854C5.93955 13.4377 6.63752 14.2738 7.50242 14.9288L7.50806 14.9331C7.94317 15.2669 8.298 15.6937 8.54415 16.1835C8.79036 16.6733 8.92091 17.2125 8.92476 17.7607L8.92481 17.7686L8.92478 18.0752H15.075L15.075 17.7663C15.0761 17.2125 15.2072 16.6675 15.457 16.1732C15.706 15.6805 16.0657 15.2523 16.5065 14.9195C17.7061 14.0013 18.574 12.7426 18.993 11.3249C19.4122 9.90643 19.3624 8.39594 18.85 7.00595C18.3375 5.61555 17.3864 4.41212 16.1261 3.57018ZM11.7751 0.0777768C13.7677 0.0319226 15.7272 0.597896 17.3759 1.69923C19.0251 2.80096 20.2815 4.38403 20.9612 6.22775C21.6409 8.07188 21.7075 10.079 21.1507 11.9626C20.5941 13.8459 19.4444 15.5055 17.8703 16.7091L17.8636 16.7142C17.6931 16.8425 17.5576 17.0051 17.4652 17.188C17.3729 17.3706 17.3255 17.5696 17.325 17.7702V18.3821C17.325 18.9072 17.1105 19.4046 16.7388 19.7665C16.3682 20.1275 15.8716 20.3252 15.3599 20.3252H8.6398C8.12811 20.3252 7.63158 20.1275 7.26092 19.7665C6.88925 19.4046 6.67478 18.9072 6.67478 18.3821V17.7731C6.67287 17.5735 6.62515 17.3757 6.53378 17.1939C6.44227 17.0118 6.30871 16.8494 6.14085 16.72C5.00715 15.8608 4.08636 14.7597 3.45029 13.4986C2.81354 12.2362 2.47944 10.8475 2.47471 9.43818L3.5997 9.43439L2.47471 9.43972L2.47471 9.43818C2.45155 4.36421 6.62903 0.193637 11.7751 0.0777768Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9246 5.85019C12.4217 6.22299 12.5224 6.92814 12.1496 7.42519L11.2496 8.62519H13.7496C15.1917 8.62519 16.0149 10.2715 15.1496 11.4252L13.6496 13.4252C13.2768 13.9222 12.5717 14.023 12.0746 13.6502C11.5776 13.2774 11.4768 12.5722 11.8496 12.0752L12.7496 10.8752H10.2496C8.80753 10.8752 7.98436 9.22886 8.84961 8.07519L10.3496 6.07519C10.7224 5.57814 11.4276 5.4774 11.9246 5.85019Z"
        />
      </svg>
    );
  },
);

SideNavExpertInsightsIcon.displayName = 'SideNavExpertInsightsIcon';
