import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const RefreshCw05Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="RefreshCw05"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0478 5.14682C12.9283 4.2065 10.4082 4.25878 8.24972 5.50497C5.09362 7.32715 3.76293 11.0968 4.89922 14.4197L4.99159 14.075C5.13452 13.5415 5.68286 13.2249 6.21633 13.3678C6.74979 13.5108 7.06638 14.0591 6.92344 14.5926L6.19139 17.3247C6.12275 17.5809 5.95514 17.7993 5.72546 17.9319C5.49577 18.0645 5.22281 18.1004 4.96663 18.0318L2.23458 17.2997C1.70111 17.1567 1.38454 16.6084 1.52749 16.0749C1.67044 15.5415 2.21878 15.2249 2.75225 15.3678L3.15719 15.4764C1.45856 11.1712 3.12111 6.15658 7.24972 3.77292C9.98522 2.19358 13.1801 2.13025 15.8588 3.31865C16.3637 3.54262 16.5914 4.13343 16.3674 4.63827C16.1434 5.1431 15.5526 5.37079 15.0478 5.14682ZM17.8088 6.67498C17.9517 6.14152 18.5001 5.82495 19.0335 5.96789L21.7655 6.69994C22.299 6.84289 22.6156 7.39122 22.4726 7.92469C22.3297 8.45815 21.7813 8.77473 21.2479 8.63179L20.8424 8.52315C22.5415 12.8284 20.8791 17.8435 16.7502 20.2273C14.0147 21.8067 10.8198 21.87 8.14107 20.6815C7.63624 20.4576 7.40856 19.8667 7.63254 19.3619C7.85651 18.8571 8.44733 18.6294 8.95216 18.8534C11.0716 19.7937 13.5917 19.7415 15.7502 18.4952C18.9063 16.6731 20.237 12.9035 19.1007 9.58062L19.0085 9.9247C18.8656 10.4582 18.3172 10.7747 17.7838 10.6318C17.2503 10.4888 16.9337 9.94049 17.0767 9.40703L17.8088 6.67498Z"
        />
      </svg>
    );
  },
);

RefreshCw05Icon.displayName = 'RefreshCw05Icon';
