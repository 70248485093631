import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as MicrosoftColorIcon } from './variant-svgs/microsoft-color.svg';
import { ReactComponent as MicrosoftDisabledIcon } from './variant-svgs/microsoft-disabled.svg';
import { ReactComponent as MicrosoftBlackIcon } from './variant-svgs/microsoft-regular.svg';

const VARIANT_ICON_MAP = {
  default: MicrosoftColorIcon,
  black: MicrosoftBlackIcon,
  disabled: MicrosoftDisabledIcon,
};

export const MicrosoftIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="microsoft-icon"
      />
    );
  },
);

MicrosoftIcon.displayName = 'MicrosoftIcon';
