import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const BellOffIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="BellOff"
      >
        <path d="M20.7316 18.7864L19.7543 17.9116C19.7543 17.9116 19.6903 17.8471 19.6538 17.8195L8.35577 7.69061C8.35577 7.69061 8.32836 7.66298 8.31923 7.65378C8.29183 7.62615 8.26443 7.60773 8.23703 7.58011L4.29139 4.05341C3.97172 3.76796 3.47852 3.79558 3.19538 4.11786C2.91225 4.44015 2.93965 4.93738 3.25932 5.22284L6.69348 8.30755C6.07241 9.19153 5.73447 10.2228 5.73447 11.2726C5.73447 12.9576 5.37827 14.4217 4.68413 15.6096C3.61552 17.4328 3.45112 17.709 3.48765 18.1142C3.52418 18.5101 3.67032 18.768 3.98999 18.9982C4.31879 19.2376 4.675 19.2376 6.38295 19.2376H8.56583C8.92204 20.8214 10.3195 22 12 22C13.6806 22 15.078 20.8214 15.4342 19.2376H18.9049L19.7086 19.965C20.0283 20.2505 20.5215 20.2228 20.8046 19.9006C21.0878 19.5783 21.0604 19.081 20.7407 18.7956L20.7316 18.7864ZM12 20.4346C11.1963 20.4346 10.5021 19.9374 10.2007 19.2376H13.8084C13.507 19.9374 12.8129 20.4346 12.0091 20.4346H12ZM6.39208 17.6722C5.98108 17.6722 5.59747 17.6722 5.29607 17.6722C5.47874 17.3407 5.74361 16.8987 6.02674 16.4107C6.86702 14.9742 7.29629 13.2431 7.29629 11.2726C7.29629 10.6004 7.49722 9.94659 7.87169 9.35727L17.1512 17.6722H6.39208Z" />
        <path d="M9.55224 6.86188C9.73491 7.05525 10.0454 7.2302 10.3103 7.30387C10.63 7.39595 10.904 7.35912 11.315 7.30387C11.5433 7.27624 11.7717 7.24862 12.0183 7.25783C13.2695 7.25783 14.4934 7.69982 15.3702 8.4733C16.2288 9.22836 16.7037 10.2228 16.7037 11.2634C16.7037 11.5856 16.7037 11.8987 16.7311 12.1934C16.7585 12.6077 17.0964 12.93 17.5075 12.93C17.5257 12.93 17.5349 12.93 17.5531 12.93C17.9824 12.9024 18.3112 12.5341 18.2838 12.1013C18.2655 11.8343 18.2564 11.5488 18.2564 11.2541C18.2564 9.76243 17.5714 8.31676 16.384 7.27624C15.9182 6.87109 15.3885 6.53039 14.8222 6.28177C14.9775 5.90424 15.0597 5.49908 15.0597 5.08471C15.0597 3.38122 13.6897 2 12 2C10.3103 2 8.9403 3.38122 8.9403 5.08471C8.9403 5.48987 9.01337 5.88582 9.16864 6.26335C9.25084 6.45672 9.32391 6.63168 9.54311 6.85267L9.55224 6.86188ZM12 3.58379C12.8311 3.58379 13.507 4.26519 13.507 5.10313C13.507 5.36096 13.4431 5.60958 13.3243 5.83057C12.8951 5.7477 12.4567 5.71087 12.0091 5.70166C11.6803 5.66482 11.4063 5.72007 11.1049 5.75691C10.9405 5.77532 10.7579 5.80295 10.7305 5.80295C10.7122 5.80295 10.6574 5.76611 10.6574 5.76611L10.6117 5.68324C10.5387 5.49908 10.5021 5.30571 10.5021 5.10313C10.5021 4.26519 11.178 3.58379 12.0091 3.58379H12Z" />
      </svg>
    );
  },
);

BellOffIcon.displayName = 'BellOffIcon';
