import { useEffect, useRef, useState } from 'react';

export const useIsHorizontalOverflow = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [isHorizontalOverflow, setIsHorizontalOverflow] = useState(false);

  useEffect(() => {
    if (!ref?.current) {
      return;
    }

    const element = ref.current;

    setIsHorizontalOverflow(element.offsetWidth < element.scrollWidth);
  }, []);

  return { ref, isHorizontalOverflow };
};
