import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const LockUnlocked03Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="LockUnlocked03"
      >
        <path d="M15.2001 21.9H8.8001C6.9801 21.9 6.0601 21.9 5.2301 21.47C4.4901 21.09 3.9001 20.5 3.5301 19.77C3.1001 18.94 3.1001 18.02 3.1001 16.2V15.8C3.1001 13.98 3.1001 13.06 3.5301 12.23C3.9101 11.49 4.5001 10.9 5.2301 10.53C5.5001 10.39 5.7801 10.3 6.1001 10.24V8.00999C6.1001 4.75999 8.7501 2.10999 12.0001 2.10999C14.8001 2.10999 17.2301 4.09999 17.7801 6.82999C17.8801 7.31999 17.5601 7.78999 17.0801 7.88999C16.5901 7.98998 16.1201 7.66999 16.0201 7.18999C15.6301 5.28999 13.9401 3.90999 12.0001 3.90999C9.7401 3.90999 7.9001 5.74999 7.9001 8.00999V10.11C8.1701 10.11 8.4701 10.11 8.8001 10.11H15.2001C17.0201 10.11 17.9401 10.11 18.7701 10.53C19.5101 10.91 20.1001 11.5 20.4801 12.23C20.9001 13.06 20.9001 13.98 20.9001 15.8V16.2C20.9001 18.02 20.9001 18.94 20.4801 19.77C20.1001 20.51 19.5101 21.1 18.7701 21.48C17.9401 21.91 17.0201 21.91 15.2001 21.91V21.9ZM8.8001 11.9C7.3101 11.9 6.5001 11.9 6.0501 12.13C5.6501 12.33 5.3401 12.65 5.1301 13.05C4.9001 13.5 4.9001 14.32 4.9001 15.8V16.2C4.9001 17.69 4.9001 18.5 5.1301 18.95C5.3301 19.35 5.6501 19.66 6.0501 19.87C6.5001 20.1 7.3201 20.1 8.8001 20.1H15.2001C16.6901 20.1 17.5001 20.1 17.9501 19.87C18.3501 19.67 18.6701 19.35 18.8701 18.95C19.1001 18.5 19.1001 17.68 19.1001 16.2V15.8C19.1001 14.31 19.1001 13.5 18.8701 13.05C18.6701 12.65 18.3501 12.34 17.9501 12.13C17.5001 11.9 16.6801 11.9 15.2001 11.9H8.8001Z" />
      </svg>
    );
  },
);

LockUnlocked03Icon.displayName = 'LockUnlocked03Icon';
