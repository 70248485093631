import { Icon, ToolTip, ToolTipVariants } from '@pointfive/ui';

import { useSideNav } from '../../../../../components/SideNav/useSideNav';

export const CollapseToggle = () => {
  const [collapsed, toggleCollapsed] = useSideNav((state) => [
    state.collapsed,
    state.toggleCollapsed,
  ]);
  const tooltipMessage = collapsed ? 'Pin Sidebar' : 'Collapse Sidebar';

  return (
    <div className="group-hover/sidenav:!block group-[.is-navbar-collapsed]/root:hidden">
      <ToolTip
        message={tooltipMessage}
        side="right"
        variant={ToolTipVariants.SIDE_NAV}
        collisionPadding={0}
      >
        <div
          className="absolute bottom-0 flex w-full cursor-pointer flex-row-reverse px-5 py-3 text-neutral-400 hover:bg-ds-navigation-side-bg-hover"
          onClick={toggleCollapsed}
        >
          <Icon
            className={`transition-transform duration-200 ${
              !collapsed ? 'rotate-180' : ''
            }`}
            name="sidebar-navigation-arrow"
            size={24}
          />
        </div>
      </ToolTip>
    </div>
  );
};
