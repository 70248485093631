import { ImageType } from '@pointfive/assets';
import Button from '@pointfive/ui/components/base/Button';

import { AnimatedLink } from './AnimatedLink';
import { EmptyStateImageComponent } from './EmptyStateImageComponent';

type EmptyStateProps = {
  className?: string;
  imageSrc?: string | ImageType;
  title?: string;
  secondaryText?: string;
  CTAButtonOnClick?: () => void;
  CTAButtonText?: string;
  linkText?: string;
  linkHref?: string;
  svgHeight?: number;
  svgWidth?: number;
  source?: string;
  hideSVG?: boolean;
  hideButton?: boolean;
};

const DEFAULT_TITLE = 'Something Went Wrong';
const DEFAULT_SECONDARY_TEXT =
  "Sorry, we're having some technical issues try to refresh the page or";

export const EmptyState = ({
  CTAButtonText,
  CTAButtonOnClick,
  className,
  imageSrc,
  secondaryText,
  title,
  linkHref,
  linkText,
  svgHeight,
  svgWidth,
  hideSVG,
  hideButton,
}: EmptyStateProps) => {
  return (
    <div
      // TODO: when root font size fix is merged - change to rem or tailwind units
      className={`m-auto flex h-full max-w-[300px] animate-fade-in flex-col items-center justify-center gap-[12px] rounded-xl border-gray-100 ${className}`}
    >
      {!hideSVG ? (
        <EmptyStateImageComponent
          src={imageSrc}
          height={svgHeight}
          width={svgWidth}
        />
      ) : null}
      <div className="flex flex-col items-center gap-[4px]">
        <div
          data-cy="something-went-wrong-title"
          className="font-bold text-text-primary"
        >
          {title ?? DEFAULT_TITLE}
        </div>
        <span className="text-body-2 whitespace-pre-wrap text-center text-gray-600">
          {secondaryText ?? DEFAULT_SECONDARY_TEXT}{' '}
          {linkHref && linkText ? (
            /*needs to be inline-block to no break in the middle because then the animation will break*/
            <span className="m-auto inline-block w-fit">
              <AnimatedLink href={linkHref} text={linkText} />
            </span>
          ) : null}
        </span>
      </div>
      {!hideButton ? (
        <Button
          size="medium"
          variant="outline"
          onClick={() => CTAButtonOnClick?.()}
        >
          {CTAButtonText}
        </Button>
      ) : null}
    </div>
  );
};
