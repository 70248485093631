import { CSSProperties, ReactNode, forwardRef } from 'react';

interface IconProps {
  children?: ReactNode;
  name: string;
  size?: number;
  color?: string;
  width?: number;
  height?: number;
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { name, size, color, width, height } = props as IconProps;

  const widthPx = width ?? size;
  const heightPx = height ?? size;

  return (
    <svg
      {...props}
      ref={ref}
      className={
        `icon icon-${name}` + (props.className ? ` ${props.className}` : '')
      }
      width={widthPx ? `${widthPx}px` : undefined}
      height={heightPx ? `${heightPx}px` : undefined}
      color={color ?? 'currentColor'}
      style={{
        minWidth: widthPx ? `${widthPx}px` : undefined,
      }}
    >
      <use xlinkHref={`#${name}`} />
    </svg>
  );
});

Icon.displayName = 'Icon';
