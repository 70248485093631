import { ReactNode, useEffect } from 'react';

import {
  Query,
  interceptSessionExpiryErrors,
  usePFQuery,
} from '@pointfive/gql';

import { usePersistIdleDetector } from 'hooks/usePersistIdleDetector';
import Sentry from 'services/sentry';

import { useAuth } from './useAuth';

const ErrorSvg = () => {
  return (
    <svg
      width="124"
      height="124"
      viewBox="0 0 124 124"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.0277 103.595C31.3679 103.595 36.5077 98.4279 36.5077 92.0542C36.5077 85.6805 31.3679 80.5136 25.0277 80.5136C18.6874 80.5136 13.5477 85.6805 13.5477 92.0542C13.5477 98.4279 18.6874 103.595 25.0277 103.595ZM25.0277 104.902C32.0863 104.902 37.8085 99.1501 37.8085 92.0542C37.8085 84.9583 32.0863 79.206 25.0277 79.206C17.969 79.206 12.2468 84.9583 12.2468 92.0542C12.2468 99.1501 17.969 104.902 25.0277 104.902Z"
        fill="#0216C9"
      />
      <path
        d="M33.2377 88.5479C33.2377 95.6438 27.5155 101.396 20.4569 101.396C13.3982 101.396 7.67603 95.6438 7.67603 88.5479C7.67603 81.452 13.3982 75.6997 20.4569 75.6997C27.5155 75.6997 33.2377 81.452 33.2377 88.5479Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4569 100.088C26.7971 100.088 31.9369 94.9216 31.9369 88.5479C31.9369 82.1742 26.7971 77.0074 20.4569 77.0074C14.1166 77.0074 8.97686 82.1742 8.97686 88.5479C8.97686 94.9216 14.1166 100.088 20.4569 100.088ZM20.4569 101.396C27.5155 101.396 33.2377 95.6438 33.2377 88.5479C33.2377 81.452 27.5155 75.6997 20.4569 75.6997C13.3982 75.6997 7.67603 81.452 7.67603 88.5479C7.67603 95.6438 13.3982 101.396 20.4569 101.396Z"
        fill="#0216C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7808 97.7349C24.1211 97.7349 29.2609 92.568 29.2609 86.1943C29.2609 79.8207 24.1211 74.6538 17.7808 74.6538C11.4406 74.6538 6.30083 79.8207 6.30083 86.1943C6.30083 92.568 11.4406 97.7349 17.7808 97.7349ZM17.7808 99.0426C24.8395 99.0426 30.5617 93.2902 30.5617 86.1943C30.5617 79.0984 24.8395 73.3461 17.7808 73.3461C10.7222 73.3461 5 79.0984 5 86.1943C5 93.2902 10.7222 99.0426 17.7808 99.0426Z"
        fill="#0216C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7977 101.881C29.1379 101.881 34.2777 96.7145 34.2777 90.3409C34.2777 83.9672 29.1379 78.8003 22.7977 78.8003C16.4575 78.8003 11.3177 83.9672 11.3177 90.3409C11.3177 96.7145 16.4575 101.881 22.7977 101.881ZM22.7977 103.189C29.8564 103.189 35.5785 97.4368 35.5785 90.3409C35.5785 83.245 29.8564 77.4926 22.7977 77.4926C15.739 77.4926 10.0168 83.245 10.0168 90.3409C10.0168 97.4368 15.739 103.189 22.7977 103.189Z"
        fill="#0216C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M65.1835 66.6315L53.5046 61.5261L37.69 98.0857L49.3689 103.191L65.1835 66.6315ZM65.7023 65.4323L54.0234 60.3269L69.838 23.7674L81.5169 28.8728L65.7023 65.4323ZM50.043 104.912L35.9783 98.7634L69.1638 22.0467L83.2286 28.1951L50.043 104.912Z"
        fill="#0216C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.3292 60.4563L89.7537 63.2679L98.4628 24.3745L86.0383 21.5629L77.3292 60.4563ZM85.0549 20L100.018 23.3859L81.7423 105L66.7797 101.614L85.0549 20ZM68.3344 100.626L77.0436 61.7321L89.468 64.5436L80.7589 103.437L68.3344 100.626Z"
        fill="#0216C9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.297 62.49H117.033V22.6234H104.297V62.49ZM102.996 21.3157H118.333V104.972H102.996V21.3157ZM104.297 103.664V63.7977H117.033V103.664H104.297Z"
        fill="#0216C9"
      />
    </svg>
  );
};

const TmpAuthErrorState = () => {
  return (
    <div
      style={{ height: '100dvh', width: '100dvw' }}
      className="flex flex-col items-center justify-center gap-4"
    >
      <ErrorSvg />
      <div>The authentication process failed.</div>
      <a
        href="/logout"
        className="cursor-pointer no-underline hover:underline"
        onClick={() => (window.location.href = '/logout')}
      >
        logout
      </a>
    </div>
  );
};

interface AuthProviderProps {
  children: ReactNode;
  pollIntervalInSeconds?: number;
}

const POLLING_INTERVAL_SECONDS = 60;

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const user = useAuth.use.user();
  const setUser = useAuth.use.setUser();
  const setRefetchUserFunction = useAuth.use.setRefetchUserFunction();

  const onIdle = () => {
    if (!user?.sessionIdleTimeout) {
      return;
    }

    window.location.href = '/logout';
  };

  usePersistIdleDetector({
    loginTime: user?.updatedAt,
    timeoutMinutes: user?.sessionIdleTimeout,
    onIdle,
  });

  const { loading, error, refetch } = usePFQuery<{ me: Query['me'] }>({
    queryName: 'me',
    options: {
      onCompleted: (user) => {
        const userData = user?.me;
        if (!userData) {
          return;
        }
        setUser(userData);

        Sentry.setUser({
          id: userData?.id,
          email: userData?.email,
          ip_address: '{{auto}}',
          customerId: userData?.customerId,
          pointfive_isSuperUser: Boolean(userData?.isSuperUser),
        });

        setRefetchUserFunction(refetch);
      },
      onError: interceptSessionExpiryErrors,
      // used to avoid caching the user object
      fetchPolicy: 'network-only',
    },
  });

  useEffect(() => {
    const pollingInterval = setInterval(() => {
      refetch();
    }, POLLING_INTERVAL_SECONDS * 1000);

    return () => {
      clearInterval(pollingInterval);
    };
  }, []);

  if (loading) {
    return null;
  }

  if (error) {
    return <TmpAuthErrorState />;
  }

  if (!user) {
    return null;
  }

  return children;
};

AuthProvider.displayName = 'AuthProvider';
