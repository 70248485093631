import { OpportunityStatus } from '@pointfive/gql/generated/graphql';
import { RadioOption } from '@pointfive/ui';

export const OPPORTUNITY_STATUS_CATEGORIES = {
  all: 'All',
  open: 'Open',
  closed: 'Closed',
  resolved: 'Resolved',
} as const;

export const OPPORTUNITY_TABS = {
  all: 'all',
  open: 'open',
  closed: 'closed',
  groups: 'groups',
} as const;

export const O9Y_STATUS_TO_CATEGORY: {
  [key in keyof typeof OPPORTUNITY_STATUS_CATEGORIES]: OpportunityStatus[];
} = {
  all: [
    OpportunityStatus.Open,
    OpportunityStatus.Validated,
    OpportunityStatus.InProgress,
    OpportunityStatus.Resolved,
    OpportunityStatus.Dismissed,
  ],
  open: [
    OpportunityStatus.Open,
    OpportunityStatus.Validated,
    OpportunityStatus.InProgress,
  ],
  closed: [OpportunityStatus.Resolved, OpportunityStatus.Dismissed],
  resolved: [OpportunityStatus.Resolved],
};

const TAB_ICON_PREFIX = '-tab-icon';
export const STATUS_OPTIONS: {
  header: string;
  icon: string;
  options: OpportunityStatus[];
}[] = [
  {
    header: OPPORTUNITY_STATUS_CATEGORIES.all,
    icon: OPPORTUNITY_TABS.all + TAB_ICON_PREFIX,
    options: O9Y_STATUS_TO_CATEGORY.all,
  },
  {
    header: OPPORTUNITY_STATUS_CATEGORIES.open,
    icon: OPPORTUNITY_TABS.open + TAB_ICON_PREFIX,
    options: O9Y_STATUS_TO_CATEGORY.open,
  },
  {
    header: OPPORTUNITY_STATUS_CATEGORIES.closed,
    icon: OPPORTUNITY_TABS.closed + TAB_ICON_PREFIX,
    options: O9Y_STATUS_TO_CATEGORY.closed,
  },
];
// OPPORTUNITY_STATUS_OPTIONS

export const OPPORTUNITY_STATUS_OPTIONS = {
  [OpportunityStatus.Open]: 'Open',
  [OpportunityStatus.Validated]: 'Validated',
  [OpportunityStatus.InProgress]: 'In Progress',
  [OpportunityStatus.Resolved]: 'Resolved',
  [OpportunityStatus.Dismissed]: 'Dismissed',
} as const;

export const NUMBER_OF_POTENTIAL_SAVINGS_BY_ITEMS = 5;
export const NUMBER_OF_TOP_OPPORTUNITY_TYPES_ITEMS = 4;

export const SAVINGS_TITLE = {
  totalSaved: 'Total Saved',
  potentialSavings: 'Potential Savings',
} as const;

export enum DismissReasons {
  INACCURATE_OPPORTUNITY = 'Inaccurate opportunity - Identified mistakenly',
  CHALLENGING_TO_RESOLVE = 'Challenging to Resolve',
  LOW_ROI = 'Low ROI - The effort outweighs the benefits',
  OTHER = 'Other',
}

export const SPECIFIC_DISMISS_REASONS = [
  DismissReasons.INACCURATE_OPPORTUNITY,
  DismissReasons.CHALLENGING_TO_RESOLVE,
  DismissReasons.LOW_ROI,
];

export const DISMISS_REASONS_OPTIONS: RadioOption<DismissReasons>[] = [
  {
    label: DismissReasons.INACCURATE_OPPORTUNITY,
    value: DismissReasons.INACCURATE_OPPORTUNITY,
  },
  {
    label: DismissReasons.CHALLENGING_TO_RESOLVE,
    value: DismissReasons.CHALLENGING_TO_RESOLVE,
  },
  {
    label: DismissReasons.LOW_ROI,
    value: DismissReasons.LOW_ROI,
  },
  {
    label: DismissReasons.OTHER,
    value: DismissReasons.OTHER,
  },
];
