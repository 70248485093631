import { ReactNode } from 'react';

import { ToolTip } from '../../../base/Tooltip';
import { ToolTipVariants } from '../../../base/Tooltip/constants';

export interface TopNavButtonProps {
  label?: string;
  isSelected?: boolean;
  icon?: ReactNode;
  dataCy?: string;
  onClick?: () => void;
}

export const TopNavButton = ({
  isSelected,
  icon: ButtonIcon,
  label,
  dataCy,
  onClick,
}: TopNavButtonProps) => {
  return (
    <ToolTip
      showCaret={true}
      message={label}
      side="bottom"
      variant={ToolTipVariants.TOP_NAV}
    >
      <button
        className={`flex border-none px-3 py-4 transition-[background-color] duration-300 focus:outline-none ${isSelected ? 'bg-ds-navigation-top-bg-hover' : 'hover:bg-ds-navigation-top-bg-hover'}`}
        onClick={onClick}
        data-cy={dataCy}
      >
        <div
          className={`${isSelected ? 'text-ds-navigation-top-icon-active' : ''}`}
        >
          {ButtonIcon}
        </div>
      </button>
    </ToolTip>
  );
};
