import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as KarpenterColorIcon } from './variant-svgs/karpenter-color.svg';
import { ReactComponent as KarpenterDisabledIcon } from './variant-svgs/karpenter-disabled.svg';
import { ReactComponent as KarpenterBlackIcon } from './variant-svgs/karpenter-regular.svg';

const VARIANT_ICON_MAP = {
  default: KarpenterColorIcon,
  black: KarpenterBlackIcon,
  disabled: KarpenterDisabledIcon,
};

export const KarpenterIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="karpenter-icon"
      />
    );
  },
);

KarpenterIcon.displayName = 'KarpenterIcon';
