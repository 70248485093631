import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as LoggingColorIcon } from './variant-svgs/logging-color.svg';
import { ReactComponent as LoggingDisabledIcon } from './variant-svgs/logging-disabled.svg';
import { ReactComponent as LoggingBlackIcon } from './variant-svgs/logging-regular.svg';

const VARIANT_ICON_MAP = {
  default: LoggingColorIcon,
  black: LoggingBlackIcon,
  disabled: LoggingDisabledIcon,
};

export const LoggingIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="logging-icon"
      />
    );
  },
);

LoggingIcon.displayName = 'LoggingIcon';
