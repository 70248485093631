import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Send01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Send01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7117 2.2883C21.9815 2.55812 22.0711 2.95961 21.9415 3.31852L15.5441 21.0343C15.5348 21.0599 15.5245 21.0852 15.5131 21.11C15.3914 21.3756 15.1961 21.6006 14.9502 21.7584C14.7044 21.9161 14.4184 22 14.1263 22C13.8342 22 13.5482 21.9161 13.3024 21.7584C13.0624 21.6044 12.8705 21.3863 12.7483 21.129L9.45589 14.5441L2.87102 11.2517C2.61372 11.1295 2.39563 10.9376 2.24164 10.6976C2.08387 10.4518 2 10.1658 2 9.87369C2 9.58158 2.08387 9.29561 2.24164 9.04977C2.3994 8.80393 2.62444 8.60855 2.88999 8.48686C2.9148 8.47549 2.94007 8.46516 2.96573 8.45589L20.6815 2.05854C21.0404 1.92893 21.4419 2.01847 21.7117 2.2883ZM11.387 14.0049L14.0595 19.3499L18.5912 6.8007L11.387 14.0049ZM17.1993 5.40882L4.65011 9.94047L9.99514 12.613L17.1993 5.40882Z"
        />
      </svg>
    );
  },
);

Send01Icon.displayName = 'Send01Icon';
