import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const RefreshCcw01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="RefreshCcw01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9 4.8C10.9106 4.8 9.11114 5.60563 7.80707 6.9106C7.12511 7.59302 6.35071 8.51815 5.72771 9.3H9.3C9.79706 9.3 10.2 9.70294 10.2 10.2C10.2 10.6971 9.79706 11.1 9.3 11.1H3.9C3.40294 11.1 3 10.6971 3 10.2V4.8C3 4.30294 3.40294 3.9 3.9 3.9C4.39706 3.9 4.8 4.30294 4.8 4.8V7.585C5.34169 6.92623 5.95816 6.21431 6.53384 5.63824C8.1617 4.00926 10.4138 3 12.9 3C17.8706 3 21.9 7.02944 21.9 12C21.9 16.9706 17.8706 21 12.9 21C8.79559 21 5.33506 18.2533 4.25187 14.4995C4.11406 14.022 4.38949 13.5231 4.86706 13.3853C5.34464 13.2475 5.8435 13.5229 5.98131 14.0005C6.84814 17.0044 9.61884 19.2 12.9 19.2C16.8764 19.2 20.1 15.9764 20.1 12C20.1 8.02355 16.8764 4.8 12.9 4.8Z"
        />
      </svg>
    );
  },
);

RefreshCcw01Icon.displayName = 'RefreshCcw01Icon';
