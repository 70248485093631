import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  MouseEvent,
  ReactNode,
  forwardRef,
  useCallback,
  useMemo,
  useState,
} from 'react';

import { Icon } from './Icon';
import Spinner from './Spinner';

export const ButtonSizes = {
  ExtraSmall: 'extraSmall', // For buttons without padding, that only fit the content's size.
  Small: 'small',
  Medium: 'medium',
  medLarge: 'medLarge',
  Large: 'large',
} as const;
export type ButtonSizeValues = (typeof ButtonSizes)[keyof typeof ButtonSizes];

export const ButtonVariants = {
  Primary: 'primary',
  Secondary: 'secondary',
  Distractive: 'distractive',
  Outline: 'outline',
  Ghost: 'ghost',
} as const;
export type ButtonVariantsValues =
  (typeof ButtonVariants)[keyof typeof ButtonVariants];

type ButtonOptions = {
  size?: ButtonSizeValues;
  variant?: ButtonVariantsValues;
  startIconName?: string;
  endIconName?: string;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  compact?: boolean;
  isHostLoading?: boolean;
};

type Ref = HTMLButtonElement;

export type ButtonProps = DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  ButtonOptions;

//sizes
const smallClassName =
  'text-caption h-7 gap-1 px-2 text-xs font-bold leading-4';
const mediumClassName =
  'text-body-2-accented h-8 gap-2 px-3 text-sm font-bold leading-5';
const largeClassName =
  'text-body-1-accented h-10 gap-2 px-4 text-base font-bold leading-6';

const iconSizes = new Map<ButtonSizeValues, 16 | 20>([
  ['small', 16],
  ['medium', 20],
  ['medLarge', 20],
  ['large', 20],
]);

//resets
const disableBorderWhenActive = `${
  /*when clicked, button is active and focus,
     we want it to be focus only by keyboard, no mouse events
     so we disabled any styles applied by focus when clicked i.e. active*/
  'active:border-none'
}`;

const buttonResets = ` ${
  /*to revert the default outline on focused Button*/
  'hover:outline-none focus:outline-none'
}`;

//variants
const primaryClassName = `
  bg-primary-500 text-text-inverted hover:bg-primary-400 border border-primary-500
  focus:border-primary-800
  active:bg-primary-700
  disabled:text-text-disabled disabled:bg-gray-50 disabled:border-untitled-white-100
`;

const distractiveClassName = `
  bg-rose-600 text-text-inverted hover:bg-rose-500/70
  focus:bg-rose-600 focus:border-rose-800
  active:bg-rose-700
  disabled:text-text-disabled disabled:bg-rose-50
  ${disableBorderWhenActive}
`;

const secondaryClassName = `
  bg-blue-100 text-primary-500 hover:bg-untitled-blue-100
  focus:border-untitled-blue-500
  active:bg-untitled-blue-500
  disabled:text-text-disabled disabled:bg-gray-50
  ${disableBorderWhenActive}
`;

const outlineClassName = `
  bg-white border border-solid border-untitled-white-100 text-gray-900
  hover:bg-gray-50 hover:text-gray-600
  active:text-gray-900 active:bg-gray-100 active:border-gray-600
  focus:border-gray-600 focus:border
  disabled:text-text-disabled disabled:bg-gray-50 disabled:border-none
`;

const ghostClassName = `
  bg-transparent text-gray-900 border-none
  active:bg-gray-100
  focuse:border focus:border-gray-300
  disabled:text-gray-300 disabled:bg-transparent
  ${disableBorderWhenActive}
`;

const variantReducer = (variant: ButtonVariantsValues) => {
  switch (variant) {
    case ButtonVariants.Primary:
      return primaryClassName;
    case ButtonVariants.Secondary:
      return secondaryClassName;
    case ButtonVariants.Distractive:
      return distractiveClassName;
    case ButtonVariants.Outline:
      return outlineClassName;
    case ButtonVariants.Ghost:
      return ghostClassName;
    default:
      return '';
  }
};

const sizeReducer = (buttonSize: ButtonSizeValues) => {
  switch (buttonSize) {
    case ButtonSizes.Small:
      return smallClassName;
    case ButtonSizes.Medium:
    case ButtonSizes.medLarge:
      return mediumClassName;
    case ButtonSizes.Large:
      return largeClassName;
    default:
      return '';
  }
};

const Button = forwardRef<Ref, ButtonProps>((props, ref) => {
  const [loading, setLoading] = useState(false);

  const {
    children,
    size = ButtonSizes.Medium,
    variant = ButtonVariants.Primary,
    startIconName,
    endIconName,
    startIcon,
    endIcon,
    onClick,
    className,
    compact = false,
    isHostLoading = false,
    ...rest
  } = props;

  const disabaleWhenLoading = useMemo(
    () =>
      ` ${
        loading
          ? /*we want to disable hover, active etc when button is loading*/ 'pointer-events-none select-none'
          : ''
      } `,
    [loading],
  );

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      e.currentTarget.blur();
      if (loading) {
        e.preventDefault();
        return;
      }
      if (onClick) {
        setLoading(true);
        try {
          // If onClick is async, it will return a Promise
          // This will await the Promise, or immediately resolve if it's not
          await Promise.resolve(onClick(e));
        } catch {
          // Mute errors
        }
        setLoading(false);
      }
    },
    [onClick],
  );

  const endIconComponent = endIcon ? (
    endIcon
  ) : endIconName ? (
    <Icon
      height={iconSizes.get(size)}
      width={iconSizes.get(size)}
      name={endIconName}
    />
  ) : null;

  const startIconComponent =
    isHostLoading || loading ? (
      <Spinner size={iconSizes.get(size) ?? 20} />
    ) : startIcon ? (
      startIcon
    ) : startIconName ? (
      <Icon
        height={iconSizes.get(size)}
        width={iconSizes.get(size)}
        name={startIconName}
      />
    ) : null;

  return (
    <button
      {...rest}
      onClick={handleClick}
      ref={ref}
      className={`flex select-none items-center justify-center whitespace-pre rounded transition-colors ${sizeReducer(size)} focus:border focus:border-solid ${buttonResets} ${variantReducer(
        variant,
      )} ${disabaleWhenLoading} ${className} `}
    >
      {startIconComponent}
      {loading ? (!compact ? 'Loading...' : null) : children}
      {endIconComponent}
    </button>
  );
});

Button.displayName = 'Button';
export default Button;
