import { MouseEvent, useEffect, useRef, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';

import { Icon } from './Icon';
import { IconButton } from './IconButton';

const copyVariants = {
  hidden: {
    opacity: 0,
    translateY: -10,
  },
  visible: {
    opacity: 1,
    translateY: 0,
  },
};

const checkVariants = {
  hidden: {
    opacity: 0,
    translateY: 10,
  },
  visible: {
    opacity: 1,
    translateY: 0,
  },
};

export const CopyToClipboardIcon = ({
  text,
  groupHoverOpacityClass = '',
}: {
  text: string | number;
  groupHoverOpacityClass?: string;
}) => {
  const timeoutId = useRef<NodeJS.Timeout>();
  const [click, setClick] = useState(false);

  const onClick = (e: MouseEvent<HTMLElement>) => {
    navigator.clipboard.writeText(text.toString());
    e.stopPropagation();
    setClick(true);
  };

  useEffect(() => {
    if (click) {
      clearTimeout(timeoutId.current);
      timeoutId.current = setTimeout(() => {
        setClick(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId.current);
    };
  }, [click]);

  return (
    <div
      onClick={onClick}
      className={`${groupHoverOpacityClass} relative flex h-[16px] w-[16px] cursor-pointer items-baseline text-black opacity-0 transition-opacity duration-300 hover:text-gray-600`}
    >
      <AnimatePresence>
        {!click && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={copyVariants}
          >
            <IconButton
              variant="ghost"
              iconName="copy-to-clipboard"
              size="small"
            />
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div
        initial="hidden"
        animate={click ? 'visible' : 'hidden'}
        exit="hidden"
        variants={checkVariants}
        className="absolute right-0 top-0"
      >
        <Icon size={16} name="check-circle" />
      </motion.div>
    </div>
  );
};
