import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Key02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Key02"
      >
        <path d="M6.34 22.01H4.6C3.95 22.01 3.52 22.01 3.09 21.79C2.71 21.6 2.41 21.3 2.22 20.92C2 20.49 2 20.06 2 19.41V17.67C2 17.38 2 17.18 2.06 16.95C2.11 16.75 2.19 16.55 2.3 16.37C2.42 16.17 2.56 16.03 2.75 15.84L7.87 10.72C8.01 10.58 8.09 10.5 8.15 10.43C8.13 10.39 8.1 10.22 8.06 9.94C8.02 9.63 8 9.32 8 9C8 5.14 11.14 2 15 2C18.86 2 22 5.14 22 9C22 12.86 18.86 16 15 16C14.68 16 14.37 15.98 14.06 15.94C13.78 15.9 13.63 15.88 13.5 15.87C13.5 15.9 13.42 15.99 13.29 16.12L8.18 21.23C7.98 21.43 7.84 21.57 7.64 21.69C7.46 21.8 7.26 21.88 7.06 21.93C6.83 21.99 6.64 21.99 6.35 21.99L6.34 22.01ZM15 4.01C12.24 4.01 10 6.25 10 9.01C10 9.24 10.02 9.46 10.04 9.69C10.11 10.21 10.15 10.48 10.11 10.75C10.07 11.01 10.01 11.21 9.88 11.44C9.74 11.69 9.56 11.87 9.29 12.14L4.18 17.25C4.09 17.34 4.03 17.39 3.99 17.44C3.99 17.47 4 17.55 4 17.67V19.41C4 19.69 4 19.98 4.02 20.06C4.03 20.01 4.32 20.01 4.6 20.01H6.34C6.46 20.01 6.54 20.01 6.61 20.01C6.62 19.99 6.68 19.93 6.77 19.84L11.88 14.73C12.16 14.45 12.34 14.27 12.58 14.14C12.82 14.01 13.01 13.94 13.27 13.91C13.55 13.87 13.81 13.91 14.33 13.98C14.55 14.01 14.78 14.02 15 14.02C17.76 14.02 20 11.78 20 9.02C20 6.26 17.76 4.02 15 4.02V4.01ZM15.01 10.01C14.46 10.01 14.01 9.56 14.01 9.01C14.01 8.46 14.45 8.01 15.01 8.01C15.56 8.01 16.01 8.46 16.01 9.01C16.01 9.56 15.56 10.01 15.01 10.01Z" />
      </svg>
    );
  },
);

Key02Icon.displayName = 'Key02Icon';
