import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as ReplicasetColorIcon } from './variant-svgs/replicaset-color.svg';
import { ReactComponent as ReplicasetDisabledIcon } from './variant-svgs/replicaset-disabled.svg';
import { ReactComponent as ReplicasetBlackIcon } from './variant-svgs/replicaset-regular.svg';

const VARIANT_ICON_MAP = {
  default: ReplicasetColorIcon,
  black: ReplicasetBlackIcon,
  disabled: ReplicasetDisabledIcon,
};

export const ReplicasetIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="replicaset-icon"
      />
    );
  },
);

ReplicasetIcon.displayName = 'ReplicasetIcon';
