import { gql } from '@apollo/client';

export const STATISTICS_AGGREGATION = gql`
  query getStatisticsAggregationByMonth(
    $where: StatisticsFilterInput
    $groupBy: [StatisticsGrouping!]
  ) {
    statisticsAggregation(where: $where, groupBy: $groupBy) {
      group
      totalMonthlyCost
      potentialSavings
      realSavings
    }
  }
`;

export const INTEGRATIONS_STATUS = gql`
  query getIntegrationsStatus($where: IntegrationFilterInput) {
    integrations(where: $where) {
      status
    }
  }
`;

export const NOTIFICATION_RULES_STATUS = gql`
  query getNotificationRulesStatus {
    notificationRules {
      name
      status
    }
  }
`;

export const K8S_CLUSTERS_STATUS = gql`
  query getK8sClustersStatus {
    resources(
      where: {
        AND: [{ service: { EQ: "EKS" } }, { resourceType: { EQ: "cluster" } }]
      }
    ) {
      resourceType
      id
    }
    resourceAggregation(
      where: { AND: [{ category: { EQ: "Applicative" } }] }
      groupBy: [ROOT_PARENT_ID]
    ) {
      group
      totalCost
      totalSaving
    }
  }
`;
