import { gql } from '@apollo/client';

import { graphql } from '../generated';

export const USER_ME = gql`
  query {
    me {
      id
      email
      createdAt
      updatedAt
      firstName
      lastName
      role
      permissions
      isSuperUser
      originalUserId
      customerId
      customerStatus
      intercomId
      sessionIdleTimeout
      assignedTeams {
        id
        name
      }
    }
  }
`;

// TODO: TMP patch. JOIN with team members crashes BE.
export const GET_USERS_LIST_WITH_TEAMS = graphql(`
  query get_users($limit: Int = 100, $offset: Int = 0) {
    users(limit: $limit, offset: $offset) {
      id
      firstName
      lastName
      email
      role
      teams {
        name
        id
      }
      updatedAt
      remoteAssistanceConsent
      cloudIntelligenceCreator
    }
  }
`);
