import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavArrowRightDoubleIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavArrowRightDouble"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3368 15.9711C11.0009 16.3374 11.0254 16.9067 11.3918 17.2427C11.7581 17.5787 12.3274 17.5541 12.6634 17.1878L16.8634 12.6085C17.1831 12.2599 17.1784 11.7232 16.8526 11.3803L12.6526 6.95954C12.3102 6.59919 11.7406 6.5846 11.3802 6.92696C11.0198 7.26932 11.0053 7.83899 11.3476 8.19934L14.969 12.011L11.3368 15.9711ZM6.9001 16.8C6.9001 17.2971 7.30304 17.7 7.8001 17.7C8.29715 17.7 8.7001 17.2971 8.7001 16.8L8.7001 7.20005C8.7001 6.70299 8.29715 6.30005 7.8001 6.30005C7.30304 6.30005 6.9001 6.70299 6.9001 7.20005L6.9001 16.8Z"
        />
      </svg>
    );
  },
);

SideNavArrowRightDoubleIcon.displayName = 'SideNavArrowRightDoubleIcon';
