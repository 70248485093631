import { motion } from 'framer-motion';

export const StepCompleteIcon = (props: { width: number; height: number }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <g clipPath="url(#a)">
      <rect width="20" height="20" fill="#10B981" rx="10" />
      <motion.path
        animate={{ fillOpacity: 1 }}
        initial={{ fillOpacity: 0 }}
        transition={{
          delay: 0.1,
          duration: 0.4,
          type: 'tween',
          ease: 'easeOut',
        }}
        fill="#fff"
        fillRule="evenodd"
        d="M14.214 7.176a.6.6 0 0 1 0 .848l-4.798 4.798a.6.6 0 0 1-.848 0l-2.4-2.399a.6.6 0 0 1 .849-.848l1.975 1.975 4.374-4.374a.6.6 0 0 1 .848 0Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width="20" height="20" fill="#fff" rx="10" />
      </clipPath>
    </defs>
  </svg>
);
