import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const CheckDone02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="CheckDone02"
      >
        <path d="M17.7 21.23H11.46C10.43 21.23 9.87 21.23 9.32 20.95C8.84 20.7 8.45 20.32 8.21 19.84C7.93 19.3 7.93 18.73 7.93 17.7V16.3H6.53C5.5 16.3 4.94 16.3 4.39 16.02C3.91 15.77 3.52 15.39 3.28 14.91C3 14.37 3 13.8 3 12.77V6.53C3 5.5 3 4.94 3.28 4.39C3.52 3.91 3.91 3.53 4.39 3.28C4.93 3 5.5 3 6.53 3H12.77C13.8 3 14.36 3 14.91 3.28C15.39 3.53 15.78 3.91 16.02 4.39C16.3 4.93 16.3 5.5 16.3 6.53V7.93H17.7C18.73 7.93 19.3 7.93 19.84 8.21C20.32 8.46 20.7 8.84 20.95 9.32C21.23 9.86 21.23 10.43 21.23 11.46V17.7C21.23 18.73 21.23 19.3 20.95 19.84C20.71 20.32 20.32 20.7 19.84 20.95C19.3 21.23 18.73 21.23 17.7 21.23ZM9.74 17.71C9.74 18.39 9.74 18.87 9.82 19.03C9.89 19.17 10 19.28 10.14 19.35C10.3 19.43 10.79 19.43 11.46 19.43H17.7C18.38 19.43 18.86 19.43 19.02 19.35C19.16 19.28 19.27 19.17 19.34 19.03C19.42 18.87 19.42 18.38 19.42 17.71V11.47C19.42 10.8 19.42 10.31 19.34 10.15C19.27 10.01 19.16 9.9 19.02 9.82C18.86 9.74 18.37 9.74 17.7 9.74H11.46C10.79 9.74 10.3 9.74 10.14 9.82C10 9.89 9.89 10 9.82 10.14C9.74 10.3 9.74 10.79 9.74 11.46V17.71ZM6.54 4.82C5.86 4.82 5.38 4.82 5.22 4.9C5.08 4.97 4.97 5.08 4.9 5.22C4.82 5.38 4.82 5.87 4.82 6.54V12.78C4.82 13.45 4.82 13.94 4.9 14.1C4.97 14.24 5.09 14.35 5.22 14.42C5.38 14.5 5.87 14.5 6.54 14.5H7.94V11.46C7.94 10.43 7.94 9.86 8.22 9.32C8.47 8.84 8.85 8.45 9.33 8.21C9.87 7.93 10.44 7.93 11.47 7.93H14.51V6.53C14.51 5.86 14.51 5.37 14.43 5.21C14.36 5.07 14.25 4.96 14.11 4.89C13.95 4.81 13.46 4.81 12.79 4.81H6.55L6.54 4.82ZM13.76 17.13C13.52 17.13 13.29 17.04 13.12 16.87L11.48 15.23C11.13 14.88 11.13 14.31 11.48 13.96C11.83 13.61 12.4 13.61 12.75 13.96L13.75 14.96L16.81 11.9C17.16 11.55 17.73 11.55 18.08 11.9C18.43 12.25 18.43 12.82 18.08 13.17L14.39 16.86C14.22 17.03 13.99 17.12 13.75 17.12L13.76 17.13Z" />
      </svg>
    );
  },
);

CheckDone02Icon.displayName = 'CheckDone02Icon';
