import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavHelpWithNotificationIcon = forwardRef<
  SVGSVGElement,
  IconProps
>(({ size = 'sm', color, onClick }, ref) => {
  const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

  return (
    <svg
      ref={ref}
      width={ICON_SIZE_OPTIONS[size]}
      height={ICON_SIZE_OPTIONS[size]}
      color={iconColor}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      fill={iconColor}
      onClick={onClick}
      aria-label="SideNavHelpWithNotification"
    >
      <g clipPath="url(#clip0_2865_201134)">
        <path d="M23.9995 3.75049C23.9995 5.82156 22.3206 7.50049 20.2495 7.50049C18.1784 7.50049 16.4995 5.82156 16.4995 3.75049C16.4995 1.67942 18.1784 0.000488281 20.2495 0.000488281C22.3206 0.000488281 23.9995 1.67942 23.9995 3.75049Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.375 12C0.375 18.405 5.595 23.625 12 23.625C18.405 23.625 23.625 18.405 23.625 12C23.625 11.0194 23.5027 10.0666 23.2724 9.15607C23.2723 9.15613 23.2725 9.15601 23.2724 9.15607C22.254 5.12792 19.1249 1.9265 15.1379 0.805664C15.1378 0.805769 15.138 0.805559 15.1379 0.805664C14.1392 0.524911 13.0866 0.375 12 0.375C5.595 0.375 0.375 5.595 0.375 12ZM15.1379 0.805664C14.7414 1.43905 14.4596 2.15207 14.3223 2.91488C17.7117 3.77956 20.3646 6.49275 21.1425 9.91588C21.91 9.79838 22.6297 9.53544 23.2724 9.15607C22.254 5.12792 19.1249 1.9265 15.1379 0.805664ZM2.625 12C2.625 6.825 6.825 2.625 12 2.625C17.175 2.625 21.375 6.825 21.375 12C21.375 17.175 17.175 21.375 12 21.375C6.825 21.375 2.625 17.175 2.625 12ZM10.89 17.25C10.89 17.865 11.4 18.375 12.015 18.375C12.63 18.375 13.14 17.865 13.14 17.25C13.14 16.635 12.63 16.125 12.015 16.125C11.385 16.125 10.89 16.635 10.89 17.25ZM10.845 13.41C10.995 13.875 11.445 14.175 11.91 14.175C12.03 14.175 12.15 14.16 12.27 14.115C12.66 13.98 16.185 12.72 16.185 9.90001C16.185 8.89501 15.825 7.91999 15.18 7.13999C14.52 6.37499 13.62 5.84999 12.63 5.68499C11.64 5.51999 10.605 5.7 9.735 6.21C8.865 6.72 8.205 7.52999 7.875 8.47499C7.665 9.05999 7.98 9.705 8.565 9.915C9.165 10.125 9.795 9.80999 10.005 9.22499C10.17 8.77499 10.485 8.38499 10.89 8.14499C11.295 7.88999 11.79 7.81499 12.255 7.88999C12.72 7.96499 13.155 8.22 13.455 8.58C13.77 8.955 13.935 9.42001 13.935 9.90001C13.935 10.86 12.33 11.73 11.55 11.985C10.965 12.18 10.65 12.825 10.845 13.41Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_2865_201134">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </svg>
  );
});

SideNavHelpWithNotificationIcon.displayName = 'SideNavHelpWithNotificationIcon';
