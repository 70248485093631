import { LDClient } from 'launchdarkly-js-client-sdk';

export const FeatureFlags = {
  Integrations_AwsOrg_View: 'integrations.awsorg.view',
  Expert_Insights_Page: 'expert-insights-page',
  Analytics: 'analytics',
  CommitmentsEnabled: 'Commitments.Enabled',
  FeatureK8sIntegration: 'feature-k-8-s-integration',
  ContextGraphWithTargetOrigin: 'ContextGraphWithTargetOrigin',
  FeatureGcpIntegration: 'feature-gcp-integration',
  FeatureAzureIntegration: 'feature-azure-integration',
  CloudIntelligenceMenuActions: 'cloud-intelligence-menu-actions',
  ExecutiveDashboardNew: 'executive-dashboard-new',
  DisableExportO11s: 'disable-export-opportunities',
  AutomationsPage: 'automation-page',
  ExportResources: 'export-resources',
  SnowflakeIntegration: 'snowflake-integration',
  OpportunitiesBulkOperations: 'opportunities-bulk-operations',
  AllOpportunitiesTab: 'all-opportunities-tab',
} as const;

export type FeatureFlagType = (typeof FeatureFlags)[keyof typeof FeatureFlags];

export const fallbackFlags: Record<FeatureFlagType, boolean> = {
  [FeatureFlags.Integrations_AwsOrg_View]: true,
  [FeatureFlags.Expert_Insights_Page]: false,
  [FeatureFlags.Analytics]: false,
  [FeatureFlags.CommitmentsEnabled]: false,
  [FeatureFlags.FeatureK8sIntegration]: false,
  [FeatureFlags.ContextGraphWithTargetOrigin]: false,
  [FeatureFlags.FeatureGcpIntegration]: false,
  [FeatureFlags.FeatureAzureIntegration]: false,
  [FeatureFlags.CloudIntelligenceMenuActions]: false,
  [FeatureFlags.ExecutiveDashboardNew]: false,
  [FeatureFlags.DisableExportO11s]: false,
  [FeatureFlags.AutomationsPage]: false,
  [FeatureFlags.ExportResources]: false,
  [FeatureFlags.SnowflakeIntegration]: false,
  [FeatureFlags.OpportunitiesBulkOperations]: false,
  [FeatureFlags.AllOpportunitiesTab]: false,
};

// LaunchDarkly Store
type LDState = {
  client?: LDClient;
};
type LDActions = {
  setClient: (client: LDClient) => void;
};
export type LaunchDarklyStoreType = LDState & LDActions;

// Feature Flags Store
type FlagChange = {
  previous: boolean;
  current: boolean;
};

export type FlagChanges = Record<FeatureFlagType, FlagChange>;
export type FlagsVariationCallCache = Record<FeatureFlagType, boolean>;

type State = {
  flags?: Record<FeatureFlagType, boolean>;
  getFlag: (flag: FeatureFlagType) => boolean;
};
type Actions = {
  updateFlags: (flags: Record<FeatureFlagType, boolean>) => void;
};
export type FeatureFlagsStoreType = State & Actions;
