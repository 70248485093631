import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Speedometer03Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Speedometer03"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.81818C7.4813 3.81818 3.81818 7.4813 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM12 6.54545C8.98754 6.54545 6.54545 8.98754 6.54545 12C6.54545 12.5021 6.13844 12.9091 5.63636 12.9091C5.13429 12.9091 4.72727 12.5021 4.72727 12C4.72727 7.98339 7.98339 4.72727 12 4.72727C12.5021 4.72727 12.9091 5.13429 12.9091 5.63636C12.9091 6.13844 12.5021 6.54545 12 6.54545ZM15.7336 8.26627C16.0887 8.62129 16.0887 9.19689 15.7336 9.55192L13.7566 11.529C13.7968 11.6792 13.8182 11.8371 13.8182 12C13.8182 13.0042 13.0042 13.8182 12 13.8182C10.9958 13.8182 10.1818 13.0042 10.1818 12C10.1818 10.9958 10.9958 10.1818 12 10.1818C12.1628 10.1818 12.3207 10.2032 12.4709 10.2434L14.448 8.26627C14.803 7.91124 15.3786 7.91124 15.7336 8.26627Z"
        />
      </svg>
    );
  },
);

Speedometer03Icon.displayName = 'Speedometer03Icon';
