import { Icon } from '@pointfive/ui/components/base/Icon';

import navLogoSymbol from '../../assets/nav-logo-symbol.svg';
import navLogoText from '../../assets/nav-logo-text.svg';

export const UnusableScreenSizeFallback = () => (
  <div className="flex h-screen w-screen flex-col items-center justify-start gap-[3.625rem] px-6 py-14">
    <div className="flex-start flex h-[2.625rem]">
      <img src={navLogoSymbol} className="mr-3" alt="PointFive logo" />
      <img src={navLogoText} alt="PointFive" />
    </div>
    <div className="flex w-full flex-col items-center justify-center gap-5 rounded-2xl px-10 pb-10 pt-8 shadow-[0px_2px_30px_0px_rgba(9,30,66,0.07)]">
      <Icon name="browser-drawing" size={124} />
      <div className="flex flex-col items-center justify-center gap-1">
        <div className="text-headline-3-accented text-gray-900">
          Use Larger Screen
        </div>
        <div className="text-body-1 text-center text-gray-600">
          Our platform is designed for optimal use on larger screens. For the
          best experience, please visit our website using a device with a bigger
          screen.
        </div>
      </div>
    </div>
  </div>
);
