/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation connectKubernetesIntegration(\n    $clusterId: UUID!\n    $projectId: String!\n  ) {\n    connectKubernetesIntegration(\n      input: { clusterId: $clusterId, projectId: $projectId }\n    ) {\n      success\n    }\n  }\n": types.ConnectKubernetesIntegrationDocument,
    "\n  mutation disconnectKubernetesIntegration(\n    $clusterId: UUID!\n    $projectId: String!\n  ) {\n    disconnectKubernetesIntegration(\n      input: { clusterId: $clusterId, projectId: $projectId }\n    ) {\n      success\n    }\n  }\n": types.DisconnectKubernetesIntegrationDocument,
    "\n  mutation multiConnectKubernetesIntegrations($clusterIds: [UUID!]) {\n    multiConnectKubernetesIntegrations(input: { clusterIds: $clusterIds }) {\n      success\n    }\n  }\n": types.MultiConnectKubernetesIntegrationsDocument,
    "\n  mutation toggleAutoConnectKubernetes($enable: Boolean!) {\n    toggleAutoConnectKubernetes(enable: $enable) {\n      success\n    }\n  }\n": types.ToggleAutoConnectKubernetesDocument,
    "\n  query kubernetesHealthChecks($integrationId: UUID!) {\n    kubernetesHealthChecks(integrationId: $integrationId) {\n      name\n      comment\n      status\n      parentKubernetesIntegrationType\n      shouldUpdateAwsIntegration\n    }\n  }\n": types.KubernetesHealthChecksDocument,
    "\n  query getKubernetesAutoConnectSettings {\n    listSettings {\n      kubernetesAutoConnect {\n        autoConnect\n      }\n    }\n  }\n": types.GetKubernetesAutoConnectSettingsDocument,
    "\n  query export_opportunities(\n    $where: OpportunitiesFilterInput\n    $type: ExportType!\n  ) {\n    opportunitiesExport(where: $where, exportType: $type)\n  }\n": types.Export_OpportunitiesDocument,
    "\n  mutation updateOpportunities(\n    $where: OpportunitiesFilterInput!\n    $input: OpportunityPatch!\n    $comment: String\n    $reason: StateReason\n  ) {\n    updateOpportunities(\n      where: $where\n      input: $input\n      comment: $comment\n      reason: $reason\n    ) {\n      success\n    }\n  }\n": types.UpdateOpportunitiesDocument,
    "\n  mutation updateIntegration($id: UUID!, $patch: UpdateIntegrationPatchInput!) {\n    updateIntegration(id: $id, patch: $patch) {\n      success\n      integration {\n        id\n        name\n      }\n    }\n  }\n": types.UpdateIntegrationDocument,
    "\n  mutation deleteIntegration($id: UUID!) {\n    deleteIntegration(id: $id) {\n      success\n    }\n  }\n": types.DeleteIntegrationDocument,
    "\n  mutation createNewIntegration($input: IntegrationInput!) {\n    createIntegration(input: $input) {\n      success\n      integration {\n        id\n        name\n\n        ... on GcpIntegration {\n          serviceAccount\n        }\n\n        ... on AzureIntegration {\n          tenantId\n          managementGroupId\n        }\n      }\n    }\n  }\n": types.CreateNewIntegrationDocument,
    "\n  mutation connectIntegration($input: ConnectIntegrationInput!) {\n    connectIntegration(input: $input) {\n      success\n      integration {\n        id\n        name\n\n        ... on GcpIntegration {\n          serviceAccount\n        }\n\n        ... on AzureIntegration {\n          tenantId\n          managementGroupId\n        }\n      }\n    }\n  }\n": types.ConnectIntegrationDocument,
    "\n  query hasSlackIntegration {\n    integrations(where: { type: { EQ: \"SLACK\" } }) {\n      id\n    }\n  }\n": types.HasSlackIntegrationDocument,
    "\n  query slackUsersAndChannels(\n    $resourceOwners: [OwnerIdentityInput!]\n    $search: String\n    $limit: Int!\n    $offset: Int = 0\n  ) {\n    external {\n      slack {\n        channels(limit: $limit, offset: $offset, search: $search) {\n          channelId\n          name\n          __typename\n        }\n        users(\n          resourceOwners: $resourceOwners\n          limit: $limit\n          offset: $offset\n          search: $search\n        ) {\n          name\n          email\n          relatedToResource\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n  }\n": types.SlackUsersAndChannelsDocument,
    "\n  mutation shareViaSlack(\n    $id: UUID!\n    $shareableType: SlackShareableType!\n    $recipients: [RecipientInput!]!\n  ) {\n    shareViaSlack(\n      id: $id\n      shareableType: $shareableType\n      recipients: $recipients\n    ) {\n      success\n      id\n    }\n  }\n": types.ShareViaSlackDocument,
    "\n  query teamsHierarchy {\n    listTeamHierarchies {\n      childId\n      parentId\n    }\n  }\n": types.TeamsHierarchyDocument,
    "\n  query getTeamsNamesList {\n    listTeams {\n      id\n      name\n      type\n    }\n  }\n": types.GetTeamsNamesListDocument,
    "\n  query users_id_emails_names($limit: Int, $search: String) {\n    users(limit: $limit, search: $search) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n": types.Users_Id_Emails_NamesDocument,
    "\n  query users_id_emails($limit: Int, $search: String) {\n    users(limit: $limit, search: $search) {\n      id\n      email\n      firstName\n      lastName\n    }\n  }\n": types.Users_Id_EmailsDocument,
    "\n  query listAutomationWorkflows {\n    listAutomationWorkflows {\n      id\n      name\n      status\n      steps {\n        action\n      }\n    }\n  }\n": types.ListAutomationWorkflowsDocument,
    "\n  mutation CreateAutomationWorkflow($input: CreateAutomationWorkflowInput!) {\n    createAutomationWorkflow(input: $input) {\n      success\n      workflow {\n        id\n        name\n        status\n      }\n    }\n  }\n": types.CreateAutomationWorkflowDocument,
    "\n  query get_notifications_rules_names($ruleName: String!) {\n    notificationRules(ruleName: $ruleName) {\n      name\n    }\n  }\n": types.Get_Notifications_Rules_NamesDocument,
    "\n  mutation createJiraIntegration($input: IntegrationInput!) {\n    createIntegration(input: $input) {\n      success\n    }\n  }\n": types.CreateJiraIntegrationDocument,
    "\n  mutation createOpportunitiesNotificationRule(\n    $ruleName: String!\n    $triggerEvent: TriggerEvent!\n    $filterInput: OpportunitiesFilterInput\n    $applyRetroactively: Boolean!\n    $recipients: [RecipientInput]!\n    $associateAllResourceEntities: Boolean!\n  ) {\n    createOpportunitiesNotificationRule(\n      input: {\n        ruleName: $ruleName\n        triggerEvent: $triggerEvent\n        filterInput: $filterInput\n        ApplyRetroactively: $applyRetroactively\n        AssociateAllResourceEntities: $associateAllResourceEntities\n        recipients: $recipients\n      }\n    ) {\n      success\n      rule {\n        id\n      }\n    }\n  }\n": types.CreateOpportunitiesNotificationRuleDocument,
    "\n  mutation updateOpportunitiesNotificationRule(\n    $ruleName: String!\n    $triggerEvent: TriggerEvent!\n    $filterInput: OpportunitiesFilterInput\n    $applyRetroactively: Boolean!\n    $recipients: [RecipientInput]!\n    $associateAllResourceEntities: Boolean!\n  ) {\n    updateOpportunitiesNotificationRule(\n      ruleName: $ruleName\n      input: {\n        triggerEvent: $triggerEvent\n        filterInput: $filterInput\n        ApplyRetroactively: $applyRetroactively\n        AssociateAllResourceEntities: $associateAllResourceEntities\n        recipients: $recipients\n      }\n    ) {\n      success\n      rule {\n        id\n      }\n    }\n  }\n": types.UpdateOpportunitiesNotificationRuleDocument,
    "\n  query notificationRules {\n    notificationRules(limit: 1000) {\n      id\n      name\n      status\n      recipients {\n        id\n        type\n        name\n      }\n      status\n      createdAt\n      triggerEvent\n      triggerType\n      filterInput\n      associateAllResourceEntities\n    }\n  }\n": types.NotificationRulesDocument,
    "\n  query getRuleById($ruleId: UUID) {\n    notificationRules(ruleId: $ruleId) {\n      id\n      name\n      status\n      recipients {\n        id\n        type\n        name\n      }\n      status\n      triggerEvent\n      triggerType\n      filterInput\n      associateAllResourceEntities\n    }\n  }\n": types.GetRuleByIdDocument,
    "\n  query allSampleSettings {\n    listSettings {\n      samplingSettings {\n        settingType\n        isEnable\n        projectedMonthlyPrice\n      }\n    }\n  }\n": types.AllSampleSettingsDocument,
    "\n  mutation updateS3Sampling($enable: Boolean!) {\n    enableS3LensSetting(enable: $enable) {\n      success\n    }\n  }\n": types.UpdateS3SamplingDocument,
    "\n  query CountResourceAllocationRules($rule: ResourceAllocationRuleFilter!) {\n    countResourceAllocationRuleMatches(rule: $rule) {\n      resourcesCount\n    }\n  }\n": types.CountResourceAllocationRulesDocument,
    "\n  query getTeamsPreferencesSettings {\n    listSettings {\n      teamsPreferences {\n        enableCrossTeamsResources\n      }\n    }\n  }\n": types.GetTeamsPreferencesSettingsDocument,
    "\n  mutation updateCrossTeamsResourcesSettings($enable: Boolean!) {\n    toggleEnableCrossTeamsResources(enable: $enable) {\n      success\n    }\n  }\n": types.UpdateCrossTeamsResourcesSettingsDocument,
    "\n  mutation CreateTeam($input: TeamInput!) {\n    createTeam(input: $input) {\n      id\n    }\n  }\n": types.CreateTeamDocument,
    "\n  mutation UpdateTeam($id: UUID!, $input: TeamUpdateInput!) {\n    updateTeam(id: $id, input: $input) {\n      success\n    }\n  }\n": types.UpdateTeamDocument,
    "\n  mutation deleteTeam($id: UUID!, $deleteSubTeams: Boolean!) {\n    deleteTeam(id: $id, deleteSubTeams: $deleteSubTeams) {\n      deleteCount\n    }\n  }\n": types.DeleteTeamDocument,
    "\n  mutation addMembersToTeam($id: UUID!, $userIds: [UUID!]!) {\n    addMembersToTeam(id: $id, userIds: $userIds) {\n      success\n    }\n  }\n": types.AddMembersToTeamDocument,
    "\n  mutation removeMembersFromTeam($id: UUID!, $userIds: [UUID!]!) {\n    removeMembersFromTeam(id: $id, userIds: $userIds) {\n      success\n    }\n  }\n": types.RemoveMembersFromTeamDocument,
    "\n  mutation updateTeamMember(\n    $userId: UUID!\n    $teamId: UUID!\n    $role: TeamMemberRole!\n  ) {\n    updateTeamMember(userId: $userId, teamId: $teamId, role: $role) {\n      success\n    }\n  }\n": types.UpdateTeamMemberDocument,
    "\n  mutation addChildTeamsToTeam($id: UUID!, $childTeamIds: [UUID!]!) {\n    addChildTeamsToTeam(id: $id, childTeamIds: $childTeamIds) {\n      success\n    }\n  }\n": types.AddChildTeamsToTeamDocument,
    "\n  mutation removeChildTeamsFromTeam($id: UUID!, $childTeamIds: [UUID!]!) {\n    removeChildTeamsFromTeam(id: $id, childTeamIds: $childTeamIds) {\n      success\n    }\n  }\n": types.RemoveChildTeamsFromTeamDocument,
    "\n  mutation createUser($newUsers: [CreateUserInput!]!) {\n    createUser(input: $newUsers) {\n      success\n      users {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n": types.CreateUserDocument,
    "\n  mutation updateUser(\n    $userId: UUID!\n    $userInfo: UpdateUserPatch!\n    $addedTeamIds: [UUID!]!\n    $removedTeamIds: [UUID!]!\n  ) {\n    updateUser(userId: $userId, input: $userInfo) {\n      success\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n    addMemberToTeams(userId: $userId, teamIds: $addedTeamIds) {\n      success\n    }\n    removeMemberFromTeams(userId: $userId, teamIds: $removedTeamIds) {\n      success\n    }\n  }\n": types.UpdateUserDocument,
    "\n  mutation deleteUser($userId: UUID!) {\n    deleteUser(userId: $userId) {\n      success\n      user {\n        id\n      }\n    }\n  }\n": types.DeleteUserDocument,
    "\n  mutation resendInvitation($userId: UUID!) {\n    resendInvitation(userId: $userId) {\n      success\n    }\n  }\n": types.ResendInvitationDocument,
    "\n  mutation CreateJiraTicketV3(\n    $integrationId: UUID!\n    $shareableId: UUID!\n    $shareableType: ShareableType!\n    $projectKey: String!\n    $issueType: String!\n    $title: String!\n    $body: String!\n    $reporterId: String\n    $assigneeId: String\n    $priority: JiraTicketPriority\n    $type: TicketType!\n    $customFields: [CustomField!]\n  ) {\n    createTicketV3(\n      integrationId: $integrationId\n      input: {\n        shareableId: $shareableId\n        shareableType: $shareableType\n        projectKey: $projectKey\n        issueType: $issueType\n        title: $title\n        body: $body\n        reporterId: $reporterId\n        assigneeId: $assigneeId\n        priority: $priority\n        type: $type\n        customFields: $customFields\n      }\n    ) {\n      success\n      ticket {\n        link\n        id\n        status\n      }\n    }\n  }\n": types.CreateJiraTicketV3Document,
    "\n  query analyticsDashboardToken($input: AnalyticsDashboardTokenInput) {\n    external {\n      analyticsDashboardToken(input: $input) {\n        id\n        tokens\n      }\n    }\n  }\n": types.AnalyticsDashboardTokenDocument,
    "\n  query analyticsDashboards {\n    analyticsDashboards {\n      dashboardId\n      name\n    }\n  }\n": types.AnalyticsDashboardsDocument,
    "\n  query cloudIntelligenceDashboards(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboards(where: $where) {\n      id\n      presetDashboardId\n      embeddedId\n      name\n      updatedAt\n      isSystem\n      createdBy\n      workspaceSlug\n      workspaceRegion\n      isFavorite\n      providers\n      permissions {\n        everyoneCanView\n        hasViewPermission\n        usersViewPermissions {\n          id\n          name\n        }\n        teamsViewPermissions {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.CloudIntelligenceDashboardsDocument,
    "\n  query cloudIntelligenceDashboardsCreatedByFilter(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboardsFilters(where: $where) {\n      createdBy {\n        id\n        fullName\n      }\n    }\n  }\n": types.CloudIntelligenceDashboardsCreatedByFilterDocument,
    "\n  query cloudIntelligenceDashboardsProvidersFilter(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboardsFilters(where: $where) {\n      providers\n    }\n  }\n": types.CloudIntelligenceDashboardsProvidersFilterDocument,
    "\n  query cloudIntelligenceDashboardsUserPermissionsFilter(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboardsFilters(where: $where) {\n      usersPermissions {\n        id\n        fullName\n      }\n    }\n  }\n": types.CloudIntelligenceDashboardsUserPermissionsFilterDocument,
    "\n  query cloudIntelligenceDashboardCount {\n    cloudIntelligenceDashboardCount\n  }\n": types.CloudIntelligenceDashboardCountDocument,
    "\n  query cloudIntelligenceDashboardToken {\n    cloudIntelligenceDashboardTokens {\n      id\n      tokens\n    }\n  }\n": types.CloudIntelligenceDashboardTokenDocument,
    "\n  query cloudIntelligenceMyPermissions {\n    cloudIntelligenceMyPermissions {\n      isCloudIntelligenceCreator\n    }\n  }\n": types.CloudIntelligenceMyPermissionsDocument,
    "\n  mutation createCloudIntelligenceDashboard(\n    $name: String!\n    $providers: [CloudIntelligenceProvider!]!\n  ) {\n    createCloudIntelligenceDashboard(name: $name, providers: $providers) {\n      id\n      presetDashboardId\n      embeddedId\n      name\n      updatedAt\n      isSystem\n      createdBy\n      providers\n      workspaceSlug\n      workspaceRegion\n      isFavorite\n      permissions {\n        everyoneCanView\n        hasViewPermission\n        usersViewPermissions {\n          id\n          name\n        }\n        teamsViewPermissions {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.CreateCloudIntelligenceDashboardDocument,
    "\n  mutation updateCloudIntelligenceDashboard(\n    $input: UpdateCloudIntelligenceDashboardInput!\n  ) {\n    updateCloudIntelligenceDashboard(input: $input) {\n      id\n      presetDashboardId\n      embeddedId\n      name\n      updatedAt\n      isSystem\n      createdBy\n      providers\n      workspaceSlug\n      workspaceRegion\n      isFavorite\n      permissions {\n        everyoneCanView\n        hasViewPermission\n        usersViewPermissions {\n          id\n          name\n        }\n        teamsViewPermissions {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.UpdateCloudIntelligenceDashboardDocument,
    "\n  mutation deleteCloudIntelligenceDashboard($id: UUID!) {\n    deleteCloudIntelligenceDashboard(id: $id) {\n      success\n    }\n  }\n": types.DeleteCloudIntelligenceDashboardDocument,
    "\n  query cloudIntelligenceWorkspaceEnable {\n    cloudIntelligenceState {\n      enabled\n    }\n  }\n": types.CloudIntelligenceWorkspaceEnableDocument,
    "\n  mutation UpdateCloudIntelligenceCreators(\n    $usersToAdd: [UUID!]\n    $usersToRemove: [UUID!]\n  ) {\n    updateCloudIntelligenceCreators(\n      usersToAdd: $usersToAdd\n      usersToRemove: $usersToRemove\n    ) {\n      success\n    }\n  }\n": types.UpdateCloudIntelligenceCreatorsDocument,
    "\n  query getTeamList {\n    listTeams {\n      id\n      name\n      childTeams {\n        id\n        name\n      }\n    }\n  }\n": types.GetTeamListDocument,
    "\n  query getMembersList($where: UsersFilterInput, $limit: Int, $offset: Int) {\n    users(where: $where, limit: $limit, offset: $offset) {\n      id\n      fullName\n      role\n      cloudIntelligenceCreator\n    }\n  }\n": types.GetMembersListDocument,
    "\n  mutation updateCloudIntelligencePermissions(\n    $input: UpdateCloudIntelligencePermissionsInput!\n  ) {\n    updateCloudIntelligencePermissions(input: $input) {\n      success\n      __typename\n    }\n  }\n": types.UpdateCloudIntelligencePermissionsDocument,
    "\n  mutation deleteCloudIntelligencePermissions(\n    $input: DeleteCloudIntelligencePermissionsInput!\n  ) {\n    deleteCloudIntelligencePermissions(input: $input) {\n      success\n      __typename\n    }\n  }\n": types.DeleteCloudIntelligencePermissionsDocument,
    "\n  query cloudIntelligenceCreators {\n    cloudIntelligenceCreators {\n      creators {\n        id\n        name\n      }\n      usersQuota\n    }\n  }\n": types.CloudIntelligenceCreatorsDocument,
    "\n  mutation updateCloudIntelligenceUserIsFavorite(\n    $input: CloudIntelligenceUserIsFavoriteInput!\n  ) {\n    updateCloudIntelligenceUserIsFavorite(input: $input) {\n      success\n    }\n  }\n": types.UpdateCloudIntelligenceUserIsFavoriteDocument,
    "\n  query CloudIntelligenceDashboardExport($id: UUID!) {\n    cloudIntelligenceDashboardExport(id: $id)\n  }\n": types.CloudIntelligenceDashboardExportDocument,
    "\n  mutation duplicateCloudIntelligenceDashboard(\n    $input: DuplicateCloudIntelligenceDashboardInput!\n  ) {\n    duplicateCloudIntelligenceDashboard(input: $input) {\n      success\n    }\n  }\n": types.DuplicateCloudIntelligenceDashboardDocument,
    "\n  query commitmentsDataIntegrity {\n    commitmentsDataIntegrity {\n      dataCollectedFirstTime\n      missingData {\n        projectId\n        commitments\n      }\n      managementAccountsIssues {\n        missingManagementAccounts\n        mgmtRootAccountsConnected\n      }\n    }\n  }\n": types.CommitmentsDataIntegrityDocument,
    "\n  query commitmentsAggregation(\n    $where: CostFilterInput\n    $groupBy: [CostGrouping!]\n  ) {\n    commitmentsCostAggregation(where: $where, groupBy: $groupBy) {\n      group\n      actualCost\n      usageCost\n      coveredCost\n      totalCostBeforeDiscounts\n    }\n  }\n": types.CommitmentsAggregationDocument,
    "\n  query commitmentsBaselineUsage(\n    $where: CostFilterInput\n    $groupBy: [CostGrouping!]\n  ) {\n    commitmentsBaselineUsage(where: $where, groupBy: $groupBy) {\n      baselineUsage\n      group\n    }\n  }\n": types.CommitmentsBaselineUsageDocument,
    "\n  query commitmentsUtilization(\n    $timeRange: TimeRangeInput!\n    $where: CommitmentsFilterInput\n    $groupBy: [CommitmentUtilizationGrouping!]\n  ) {\n    commitmentsUtilization(\n      timeRange: $timeRange\n      where: $where\n      groupBy: $groupBy\n    ) {\n      group\n      utilization\n      totalCommitmentsCost\n      savings\n      netSavings\n    }\n  }\n": types.CommitmentsUtilizationDocument,
    "\n  query effectiveSavingsRate(\n    $timeRange: TimeRangeInput!\n    $groupBy: [EsrGrouping!]\n  ) {\n    effectiveSavingsRate(timeRange: $timeRange, groupBy: $groupBy) {\n      group\n      savings\n      netSavings\n      effectiveSavingsRate\n      netEffectiveSavingsRate\n    }\n  }\n": types.EffectiveSavingsRateDocument,
    "\n  query commitmentsInsights(\n    $orderBy: [CommitmentInsightsOrdering!]\n    $where: CommitmentInsightsFilterInput\n    $limit: Int\n    $offset: Int\n  ) {\n    commitmentsInsights(\n      orderBy: $orderBy\n      where: $where\n      limit: $limit\n      offset: $offset\n    ) {\n      id\n      createdAt\n      type\n      utilizationPercentage\n      commitment {\n        id\n        type\n        specifications\n        instanceCount\n        region\n        expiresInDays\n        monthlyPrice\n        duration\n        term\n        offeringType\n        tenancy\n      }\n      project {\n        id\n        canonicalName\n      }\n      utilizationPercentage\n    }\n  }\n": types.CommitmentsInsightsDocument,
    "\n  query commitmentsInsightsFilters($where: CommitmentInsightsFilterInput) {\n    commitmentsInsightsFilters(where: $where) {\n      _id\n      insightType\n      type\n      specifications\n      expiresInDays {\n        max\n      }\n      instanceCount {\n        max\n      }\n      duration\n      project {\n        id\n        name\n      }\n      region\n      hourlyPrice {\n        max\n      }\n      utilization {\n        max\n      }\n    }\n  }\n": types.CommitmentsInsightsFiltersDocument,
    "\n  query getStatisticsAggregationByMonth(\n    $where: StatisticsFilterInput\n    $groupBy: [StatisticsGrouping!]\n  ) {\n    statisticsAggregation(where: $where, groupBy: $groupBy) {\n      group\n      totalMonthlyCost\n      potentialSavings\n      realSavings\n    }\n  }\n": types.GetStatisticsAggregationByMonthDocument,
    "\n  query getIntegrationsStatus($where: IntegrationFilterInput) {\n    integrations(where: $where) {\n      status\n    }\n  }\n": types.GetIntegrationsStatusDocument,
    "\n  query getNotificationRulesStatus {\n    notificationRules {\n      name\n      status\n    }\n  }\n": types.GetNotificationRulesStatusDocument,
    "\n  query getK8sClustersStatus {\n    resources(\n      where: {\n        AND: [{ service: { EQ: \"EKS\" } }, { resourceType: { EQ: \"cluster\" } }]\n      }\n    ) {\n      resourceType\n      id\n    }\n    resourceAggregation(\n      where: { AND: [{ category: { EQ: \"Applicative\" } }] }\n      groupBy: [ROOT_PARENT_ID]\n    ) {\n      group\n      totalCost\n      totalSaving\n    }\n  }\n": types.GetK8sClustersStatusDocument,
    "\n  query listIntegrations($where: IntegrationFilterInput) {\n    integrations(where: $where) {\n      id\n      name\n      type\n      status\n      updatedAt\n      createdAt\n      ... on AwsIntegration {\n        accountId\n      }\n      ... on AwsOrgIntegration {\n        accountId\n        managementAccountId\n        managementAccountName\n        accountCount\n      }\n      ... on KubernetesIntegration {\n        name\n        status\n        kubernetesLastSyncDate\n        deletedAt\n        cluster {\n          id\n          name\n          region\n          deletedAt\n          project {\n            name\n            canonicalName\n            id\n          }\n        }\n      }\n      ... on GcpIntegration {\n        serviceAccount\n      }\n      ... on AzureIntegration {\n        tenantId\n        managementGroupId\n        appId\n      }\n    }\n  }\n": types.ListIntegrationsDocument,
    "\n  query integrationScript($provider: IntegrationProvider!) {\n    integrationScript(providerType: $provider)\n  }\n": types.IntegrationScriptDocument,
    "\n  query getJiraProjects($integrationId: UUID!) {\n    external {\n      jira {\n        projects(integrationId: $integrationId) {\n          id\n          key\n          name\n        }\n      }\n    }\n  }\n": types.GetJiraProjectsDocument,
    "\n  query JiraProject($integrationId: UUID!, $projectKey: String!) {\n    external {\n      jira {\n        project(integrationId: $integrationId, projectKey: $projectKey) {\n          issueTypes {\n            name\n            hasPriorityField\n            requiredFields {\n              name\n              key\n              type\n              options\n            }\n          }\n        }\n      }\n    }\n  }\n": types.JiraProjectDocument,
    "\n  query GetJiraUsers($integrationId: UUID!) {\n    external {\n      jira {\n        users(integrationId: $integrationId) {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.GetJiraUsersDocument,
    "\n  query GetTicket(\n    $integrationId: UUID!\n    $shareableId: UUID!\n    $shareableType: ShareableType!\n    $type: TicketType!\n  ) {\n    external {\n      jira {\n        ticket(\n          integrationId: $integrationId\n          shareableId: $shareableId\n          shareableType: $shareableType\n          type: $type\n        ) {\n          ticketId\n          key\n          type\n          status\n          link\n          id\n        }\n      }\n    }\n  }\n": types.GetTicketDocument,
    "\n  query GetJiraIntegrations {\n    integrations(where: { type: { EQ: \"JIRA\" } }) {\n      id\n      name\n    }\n  }\n": types.GetJiraIntegrationsDocument,
    "\n  query assignableUsers(\n    $integrationId: UUID!\n    $projectKey: String!\n    $searchTerm: String\n  ) {\n    external {\n      jira {\n        assignableUsers(\n          integrationId: $integrationId\n          projectKey: $projectKey\n          searchTerm: $searchTerm\n        ) {\n          emailAddress\n          displayName\n          accountID\n        }\n      }\n    }\n  }\n": types.AssignableUsersDocument,
    "\n  query getTotalCostAggregation(\n    $where: ResourceFilterInput! = {}\n    $groupBy: [ResourceGrouping!]\n  ) {\n    resourceAggregation(where: $where, groupBy: $groupBy) {\n      count\n      group\n      totalCloudCost\n      totalApplicativeCost\n    }\n  }\n": types.GetTotalCostAggregationDocument,
    "\n  query getPotentialSavingAggregation(\n    $where: ResourceFilterInput! = {}\n    $groupBy: [ResourceGrouping!]\n  ) {\n    resourceAggregation(where: $where, groupBy: $groupBy) {\n      count\n      group\n      totalSaving\n      totalCloudMonthlySavings\n      totalApplicativeMonthlySavings\n    }\n  }\n": types.GetPotentialSavingAggregationDocument,
    "\n  query getTeamsList($where: TeamsFilterInput) {\n    listTeams(where: $where) {\n      id\n      name\n      type\n      members {\n        teamId\n        user {\n          id\n          fullName\n        }\n      }\n      memberCount\n      resourceAllocationRulesCount\n    }\n  }\n": types.GetTeamsListDocument,
    "\n  query getTeamsTree {\n    listTeams {\n      id\n      name\n      type\n      childTeams {\n        id\n        name\n      }\n      memberCount\n      resourceAllocationRules {\n        rule\n        name\n      }\n    }\n  }\n": types.GetTeamsTreeDocument,
    "\n  query getTeam($id: UUID!) {\n    getTeam(id: $id) {\n      id\n      name\n      type\n      members {\n        role\n        userId\n        user {\n          firstName\n          lastName\n          email\n        }\n      }\n      memberCount\n      childTeams {\n        id\n        name\n        memberCount\n        resourceAllocationRulesCount\n      }\n      parentTeams {\n        id\n      }\n      resourceAllocationRules {\n        name\n        rule\n      }\n      resourceAllocationRulesCount\n    }\n  }\n": types.GetTeamDocument,
    "\n  query get_users($limit: Int = 100, $offset: Int = 0) {\n    users(limit: $limit, offset: $offset) {\n      id\n      firstName\n      lastName\n      email\n      role\n      teams {\n        name\n        id\n      }\n      updatedAt\n      remoteAssistanceConsent\n      cloudIntelligenceCreator\n    }\n  }\n": types.Get_UsersDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation connectKubernetesIntegration(\n    $clusterId: UUID!\n    $projectId: String!\n  ) {\n    connectKubernetesIntegration(\n      input: { clusterId: $clusterId, projectId: $projectId }\n    ) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation connectKubernetesIntegration(\n    $clusterId: UUID!\n    $projectId: String!\n  ) {\n    connectKubernetesIntegration(\n      input: { clusterId: $clusterId, projectId: $projectId }\n    ) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation disconnectKubernetesIntegration(\n    $clusterId: UUID!\n    $projectId: String!\n  ) {\n    disconnectKubernetesIntegration(\n      input: { clusterId: $clusterId, projectId: $projectId }\n    ) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation disconnectKubernetesIntegration(\n    $clusterId: UUID!\n    $projectId: String!\n  ) {\n    disconnectKubernetesIntegration(\n      input: { clusterId: $clusterId, projectId: $projectId }\n    ) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation multiConnectKubernetesIntegrations($clusterIds: [UUID!]) {\n    multiConnectKubernetesIntegrations(input: { clusterIds: $clusterIds }) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation multiConnectKubernetesIntegrations($clusterIds: [UUID!]) {\n    multiConnectKubernetesIntegrations(input: { clusterIds: $clusterIds }) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation toggleAutoConnectKubernetes($enable: Boolean!) {\n    toggleAutoConnectKubernetes(enable: $enable) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation toggleAutoConnectKubernetes($enable: Boolean!) {\n    toggleAutoConnectKubernetes(enable: $enable) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query kubernetesHealthChecks($integrationId: UUID!) {\n    kubernetesHealthChecks(integrationId: $integrationId) {\n      name\n      comment\n      status\n      parentKubernetesIntegrationType\n      shouldUpdateAwsIntegration\n    }\n  }\n"): (typeof documents)["\n  query kubernetesHealthChecks($integrationId: UUID!) {\n    kubernetesHealthChecks(integrationId: $integrationId) {\n      name\n      comment\n      status\n      parentKubernetesIntegrationType\n      shouldUpdateAwsIntegration\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getKubernetesAutoConnectSettings {\n    listSettings {\n      kubernetesAutoConnect {\n        autoConnect\n      }\n    }\n  }\n"): (typeof documents)["\n  query getKubernetesAutoConnectSettings {\n    listSettings {\n      kubernetesAutoConnect {\n        autoConnect\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query export_opportunities(\n    $where: OpportunitiesFilterInput\n    $type: ExportType!\n  ) {\n    opportunitiesExport(where: $where, exportType: $type)\n  }\n"): (typeof documents)["\n  query export_opportunities(\n    $where: OpportunitiesFilterInput\n    $type: ExportType!\n  ) {\n    opportunitiesExport(where: $where, exportType: $type)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateOpportunities(\n    $where: OpportunitiesFilterInput!\n    $input: OpportunityPatch!\n    $comment: String\n    $reason: StateReason\n  ) {\n    updateOpportunities(\n      where: $where\n      input: $input\n      comment: $comment\n      reason: $reason\n    ) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation updateOpportunities(\n    $where: OpportunitiesFilterInput!\n    $input: OpportunityPatch!\n    $comment: String\n    $reason: StateReason\n  ) {\n    updateOpportunities(\n      where: $where\n      input: $input\n      comment: $comment\n      reason: $reason\n    ) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateIntegration($id: UUID!, $patch: UpdateIntegrationPatchInput!) {\n    updateIntegration(id: $id, patch: $patch) {\n      success\n      integration {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateIntegration($id: UUID!, $patch: UpdateIntegrationPatchInput!) {\n    updateIntegration(id: $id, patch: $patch) {\n      success\n      integration {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteIntegration($id: UUID!) {\n    deleteIntegration(id: $id) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation deleteIntegration($id: UUID!) {\n    deleteIntegration(id: $id) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createNewIntegration($input: IntegrationInput!) {\n    createIntegration(input: $input) {\n      success\n      integration {\n        id\n        name\n\n        ... on GcpIntegration {\n          serviceAccount\n        }\n\n        ... on AzureIntegration {\n          tenantId\n          managementGroupId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createNewIntegration($input: IntegrationInput!) {\n    createIntegration(input: $input) {\n      success\n      integration {\n        id\n        name\n\n        ... on GcpIntegration {\n          serviceAccount\n        }\n\n        ... on AzureIntegration {\n          tenantId\n          managementGroupId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation connectIntegration($input: ConnectIntegrationInput!) {\n    connectIntegration(input: $input) {\n      success\n      integration {\n        id\n        name\n\n        ... on GcpIntegration {\n          serviceAccount\n        }\n\n        ... on AzureIntegration {\n          tenantId\n          managementGroupId\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation connectIntegration($input: ConnectIntegrationInput!) {\n    connectIntegration(input: $input) {\n      success\n      integration {\n        id\n        name\n\n        ... on GcpIntegration {\n          serviceAccount\n        }\n\n        ... on AzureIntegration {\n          tenantId\n          managementGroupId\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query hasSlackIntegration {\n    integrations(where: { type: { EQ: \"SLACK\" } }) {\n      id\n    }\n  }\n"): (typeof documents)["\n  query hasSlackIntegration {\n    integrations(where: { type: { EQ: \"SLACK\" } }) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query slackUsersAndChannels(\n    $resourceOwners: [OwnerIdentityInput!]\n    $search: String\n    $limit: Int!\n    $offset: Int = 0\n  ) {\n    external {\n      slack {\n        channels(limit: $limit, offset: $offset, search: $search) {\n          channelId\n          name\n          __typename\n        }\n        users(\n          resourceOwners: $resourceOwners\n          limit: $limit\n          offset: $offset\n          search: $search\n        ) {\n          name\n          email\n          relatedToResource\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n  }\n"): (typeof documents)["\n  query slackUsersAndChannels(\n    $resourceOwners: [OwnerIdentityInput!]\n    $search: String\n    $limit: Int!\n    $offset: Int = 0\n  ) {\n    external {\n      slack {\n        channels(limit: $limit, offset: $offset, search: $search) {\n          channelId\n          name\n          __typename\n        }\n        users(\n          resourceOwners: $resourceOwners\n          limit: $limit\n          offset: $offset\n          search: $search\n        ) {\n          name\n          email\n          relatedToResource\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation shareViaSlack(\n    $id: UUID!\n    $shareableType: SlackShareableType!\n    $recipients: [RecipientInput!]!\n  ) {\n    shareViaSlack(\n      id: $id\n      shareableType: $shareableType\n      recipients: $recipients\n    ) {\n      success\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation shareViaSlack(\n    $id: UUID!\n    $shareableType: SlackShareableType!\n    $recipients: [RecipientInput!]!\n  ) {\n    shareViaSlack(\n      id: $id\n      shareableType: $shareableType\n      recipients: $recipients\n    ) {\n      success\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query teamsHierarchy {\n    listTeamHierarchies {\n      childId\n      parentId\n    }\n  }\n"): (typeof documents)["\n  query teamsHierarchy {\n    listTeamHierarchies {\n      childId\n      parentId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTeamsNamesList {\n    listTeams {\n      id\n      name\n      type\n    }\n  }\n"): (typeof documents)["\n  query getTeamsNamesList {\n    listTeams {\n      id\n      name\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query users_id_emails_names($limit: Int, $search: String) {\n    users(limit: $limit, search: $search) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"): (typeof documents)["\n  query users_id_emails_names($limit: Int, $search: String) {\n    users(limit: $limit, search: $search) {\n      id\n      firstName\n      lastName\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query users_id_emails($limit: Int, $search: String) {\n    users(limit: $limit, search: $search) {\n      id\n      email\n      firstName\n      lastName\n    }\n  }\n"): (typeof documents)["\n  query users_id_emails($limit: Int, $search: String) {\n    users(limit: $limit, search: $search) {\n      id\n      email\n      firstName\n      lastName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listAutomationWorkflows {\n    listAutomationWorkflows {\n      id\n      name\n      status\n      steps {\n        action\n      }\n    }\n  }\n"): (typeof documents)["\n  query listAutomationWorkflows {\n    listAutomationWorkflows {\n      id\n      name\n      status\n      steps {\n        action\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateAutomationWorkflow($input: CreateAutomationWorkflowInput!) {\n    createAutomationWorkflow(input: $input) {\n      success\n      workflow {\n        id\n        name\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateAutomationWorkflow($input: CreateAutomationWorkflowInput!) {\n    createAutomationWorkflow(input: $input) {\n      success\n      workflow {\n        id\n        name\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query get_notifications_rules_names($ruleName: String!) {\n    notificationRules(ruleName: $ruleName) {\n      name\n    }\n  }\n"): (typeof documents)["\n  query get_notifications_rules_names($ruleName: String!) {\n    notificationRules(ruleName: $ruleName) {\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createJiraIntegration($input: IntegrationInput!) {\n    createIntegration(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation createJiraIntegration($input: IntegrationInput!) {\n    createIntegration(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createOpportunitiesNotificationRule(\n    $ruleName: String!\n    $triggerEvent: TriggerEvent!\n    $filterInput: OpportunitiesFilterInput\n    $applyRetroactively: Boolean!\n    $recipients: [RecipientInput]!\n    $associateAllResourceEntities: Boolean!\n  ) {\n    createOpportunitiesNotificationRule(\n      input: {\n        ruleName: $ruleName\n        triggerEvent: $triggerEvent\n        filterInput: $filterInput\n        ApplyRetroactively: $applyRetroactively\n        AssociateAllResourceEntities: $associateAllResourceEntities\n        recipients: $recipients\n      }\n    ) {\n      success\n      rule {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createOpportunitiesNotificationRule(\n    $ruleName: String!\n    $triggerEvent: TriggerEvent!\n    $filterInput: OpportunitiesFilterInput\n    $applyRetroactively: Boolean!\n    $recipients: [RecipientInput]!\n    $associateAllResourceEntities: Boolean!\n  ) {\n    createOpportunitiesNotificationRule(\n      input: {\n        ruleName: $ruleName\n        triggerEvent: $triggerEvent\n        filterInput: $filterInput\n        ApplyRetroactively: $applyRetroactively\n        AssociateAllResourceEntities: $associateAllResourceEntities\n        recipients: $recipients\n      }\n    ) {\n      success\n      rule {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateOpportunitiesNotificationRule(\n    $ruleName: String!\n    $triggerEvent: TriggerEvent!\n    $filterInput: OpportunitiesFilterInput\n    $applyRetroactively: Boolean!\n    $recipients: [RecipientInput]!\n    $associateAllResourceEntities: Boolean!\n  ) {\n    updateOpportunitiesNotificationRule(\n      ruleName: $ruleName\n      input: {\n        triggerEvent: $triggerEvent\n        filterInput: $filterInput\n        ApplyRetroactively: $applyRetroactively\n        AssociateAllResourceEntities: $associateAllResourceEntities\n        recipients: $recipients\n      }\n    ) {\n      success\n      rule {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateOpportunitiesNotificationRule(\n    $ruleName: String!\n    $triggerEvent: TriggerEvent!\n    $filterInput: OpportunitiesFilterInput\n    $applyRetroactively: Boolean!\n    $recipients: [RecipientInput]!\n    $associateAllResourceEntities: Boolean!\n  ) {\n    updateOpportunitiesNotificationRule(\n      ruleName: $ruleName\n      input: {\n        triggerEvent: $triggerEvent\n        filterInput: $filterInput\n        ApplyRetroactively: $applyRetroactively\n        AssociateAllResourceEntities: $associateAllResourceEntities\n        recipients: $recipients\n      }\n    ) {\n      success\n      rule {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query notificationRules {\n    notificationRules(limit: 1000) {\n      id\n      name\n      status\n      recipients {\n        id\n        type\n        name\n      }\n      status\n      createdAt\n      triggerEvent\n      triggerType\n      filterInput\n      associateAllResourceEntities\n    }\n  }\n"): (typeof documents)["\n  query notificationRules {\n    notificationRules(limit: 1000) {\n      id\n      name\n      status\n      recipients {\n        id\n        type\n        name\n      }\n      status\n      createdAt\n      triggerEvent\n      triggerType\n      filterInput\n      associateAllResourceEntities\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getRuleById($ruleId: UUID) {\n    notificationRules(ruleId: $ruleId) {\n      id\n      name\n      status\n      recipients {\n        id\n        type\n        name\n      }\n      status\n      triggerEvent\n      triggerType\n      filterInput\n      associateAllResourceEntities\n    }\n  }\n"): (typeof documents)["\n  query getRuleById($ruleId: UUID) {\n    notificationRules(ruleId: $ruleId) {\n      id\n      name\n      status\n      recipients {\n        id\n        type\n        name\n      }\n      status\n      triggerEvent\n      triggerType\n      filterInput\n      associateAllResourceEntities\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query allSampleSettings {\n    listSettings {\n      samplingSettings {\n        settingType\n        isEnable\n        projectedMonthlyPrice\n      }\n    }\n  }\n"): (typeof documents)["\n  query allSampleSettings {\n    listSettings {\n      samplingSettings {\n        settingType\n        isEnable\n        projectedMonthlyPrice\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateS3Sampling($enable: Boolean!) {\n    enableS3LensSetting(enable: $enable) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation updateS3Sampling($enable: Boolean!) {\n    enableS3LensSetting(enable: $enable) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CountResourceAllocationRules($rule: ResourceAllocationRuleFilter!) {\n    countResourceAllocationRuleMatches(rule: $rule) {\n      resourcesCount\n    }\n  }\n"): (typeof documents)["\n  query CountResourceAllocationRules($rule: ResourceAllocationRuleFilter!) {\n    countResourceAllocationRuleMatches(rule: $rule) {\n      resourcesCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTeamsPreferencesSettings {\n    listSettings {\n      teamsPreferences {\n        enableCrossTeamsResources\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTeamsPreferencesSettings {\n    listSettings {\n      teamsPreferences {\n        enableCrossTeamsResources\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCrossTeamsResourcesSettings($enable: Boolean!) {\n    toggleEnableCrossTeamsResources(enable: $enable) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation updateCrossTeamsResourcesSettings($enable: Boolean!) {\n    toggleEnableCrossTeamsResources(enable: $enable) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTeam($input: TeamInput!) {\n    createTeam(input: $input) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTeam($input: TeamInput!) {\n    createTeam(input: $input) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateTeam($id: UUID!, $input: TeamUpdateInput!) {\n    updateTeam(id: $id, input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateTeam($id: UUID!, $input: TeamUpdateInput!) {\n    updateTeam(id: $id, input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteTeam($id: UUID!, $deleteSubTeams: Boolean!) {\n    deleteTeam(id: $id, deleteSubTeams: $deleteSubTeams) {\n      deleteCount\n    }\n  }\n"): (typeof documents)["\n  mutation deleteTeam($id: UUID!, $deleteSubTeams: Boolean!) {\n    deleteTeam(id: $id, deleteSubTeams: $deleteSubTeams) {\n      deleteCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addMembersToTeam($id: UUID!, $userIds: [UUID!]!) {\n    addMembersToTeam(id: $id, userIds: $userIds) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation addMembersToTeam($id: UUID!, $userIds: [UUID!]!) {\n    addMembersToTeam(id: $id, userIds: $userIds) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation removeMembersFromTeam($id: UUID!, $userIds: [UUID!]!) {\n    removeMembersFromTeam(id: $id, userIds: $userIds) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation removeMembersFromTeam($id: UUID!, $userIds: [UUID!]!) {\n    removeMembersFromTeam(id: $id, userIds: $userIds) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateTeamMember(\n    $userId: UUID!\n    $teamId: UUID!\n    $role: TeamMemberRole!\n  ) {\n    updateTeamMember(userId: $userId, teamId: $teamId, role: $role) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation updateTeamMember(\n    $userId: UUID!\n    $teamId: UUID!\n    $role: TeamMemberRole!\n  ) {\n    updateTeamMember(userId: $userId, teamId: $teamId, role: $role) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation addChildTeamsToTeam($id: UUID!, $childTeamIds: [UUID!]!) {\n    addChildTeamsToTeam(id: $id, childTeamIds: $childTeamIds) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation addChildTeamsToTeam($id: UUID!, $childTeamIds: [UUID!]!) {\n    addChildTeamsToTeam(id: $id, childTeamIds: $childTeamIds) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation removeChildTeamsFromTeam($id: UUID!, $childTeamIds: [UUID!]!) {\n    removeChildTeamsFromTeam(id: $id, childTeamIds: $childTeamIds) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation removeChildTeamsFromTeam($id: UUID!, $childTeamIds: [UUID!]!) {\n    removeChildTeamsFromTeam(id: $id, childTeamIds: $childTeamIds) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createUser($newUsers: [CreateUserInput!]!) {\n    createUser(input: $newUsers) {\n      success\n      users {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createUser($newUsers: [CreateUserInput!]!) {\n    createUser(input: $newUsers) {\n      success\n      users {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateUser(\n    $userId: UUID!\n    $userInfo: UpdateUserPatch!\n    $addedTeamIds: [UUID!]!\n    $removedTeamIds: [UUID!]!\n  ) {\n    updateUser(userId: $userId, input: $userInfo) {\n      success\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n    addMemberToTeams(userId: $userId, teamIds: $addedTeamIds) {\n      success\n    }\n    removeMemberFromTeams(userId: $userId, teamIds: $removedTeamIds) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation updateUser(\n    $userId: UUID!\n    $userInfo: UpdateUserPatch!\n    $addedTeamIds: [UUID!]!\n    $removedTeamIds: [UUID!]!\n  ) {\n    updateUser(userId: $userId, input: $userInfo) {\n      success\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n    addMemberToTeams(userId: $userId, teamIds: $addedTeamIds) {\n      success\n    }\n    removeMemberFromTeams(userId: $userId, teamIds: $removedTeamIds) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteUser($userId: UUID!) {\n    deleteUser(userId: $userId) {\n      success\n      user {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation deleteUser($userId: UUID!) {\n    deleteUser(userId: $userId) {\n      success\n      user {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation resendInvitation($userId: UUID!) {\n    resendInvitation(userId: $userId) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation resendInvitation($userId: UUID!) {\n    resendInvitation(userId: $userId) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateJiraTicketV3(\n    $integrationId: UUID!\n    $shareableId: UUID!\n    $shareableType: ShareableType!\n    $projectKey: String!\n    $issueType: String!\n    $title: String!\n    $body: String!\n    $reporterId: String\n    $assigneeId: String\n    $priority: JiraTicketPriority\n    $type: TicketType!\n    $customFields: [CustomField!]\n  ) {\n    createTicketV3(\n      integrationId: $integrationId\n      input: {\n        shareableId: $shareableId\n        shareableType: $shareableType\n        projectKey: $projectKey\n        issueType: $issueType\n        title: $title\n        body: $body\n        reporterId: $reporterId\n        assigneeId: $assigneeId\n        priority: $priority\n        type: $type\n        customFields: $customFields\n      }\n    ) {\n      success\n      ticket {\n        link\n        id\n        status\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateJiraTicketV3(\n    $integrationId: UUID!\n    $shareableId: UUID!\n    $shareableType: ShareableType!\n    $projectKey: String!\n    $issueType: String!\n    $title: String!\n    $body: String!\n    $reporterId: String\n    $assigneeId: String\n    $priority: JiraTicketPriority\n    $type: TicketType!\n    $customFields: [CustomField!]\n  ) {\n    createTicketV3(\n      integrationId: $integrationId\n      input: {\n        shareableId: $shareableId\n        shareableType: $shareableType\n        projectKey: $projectKey\n        issueType: $issueType\n        title: $title\n        body: $body\n        reporterId: $reporterId\n        assigneeId: $assigneeId\n        priority: $priority\n        type: $type\n        customFields: $customFields\n      }\n    ) {\n      success\n      ticket {\n        link\n        id\n        status\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query analyticsDashboardToken($input: AnalyticsDashboardTokenInput) {\n    external {\n      analyticsDashboardToken(input: $input) {\n        id\n        tokens\n      }\n    }\n  }\n"): (typeof documents)["\n  query analyticsDashboardToken($input: AnalyticsDashboardTokenInput) {\n    external {\n      analyticsDashboardToken(input: $input) {\n        id\n        tokens\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query analyticsDashboards {\n    analyticsDashboards {\n      dashboardId\n      name\n    }\n  }\n"): (typeof documents)["\n  query analyticsDashboards {\n    analyticsDashboards {\n      dashboardId\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cloudIntelligenceDashboards(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboards(where: $where) {\n      id\n      presetDashboardId\n      embeddedId\n      name\n      updatedAt\n      isSystem\n      createdBy\n      workspaceSlug\n      workspaceRegion\n      isFavorite\n      providers\n      permissions {\n        everyoneCanView\n        hasViewPermission\n        usersViewPermissions {\n          id\n          name\n        }\n        teamsViewPermissions {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query cloudIntelligenceDashboards(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboards(where: $where) {\n      id\n      presetDashboardId\n      embeddedId\n      name\n      updatedAt\n      isSystem\n      createdBy\n      workspaceSlug\n      workspaceRegion\n      isFavorite\n      providers\n      permissions {\n        everyoneCanView\n        hasViewPermission\n        usersViewPermissions {\n          id\n          name\n        }\n        teamsViewPermissions {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cloudIntelligenceDashboardsCreatedByFilter(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboardsFilters(where: $where) {\n      createdBy {\n        id\n        fullName\n      }\n    }\n  }\n"): (typeof documents)["\n  query cloudIntelligenceDashboardsCreatedByFilter(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboardsFilters(where: $where) {\n      createdBy {\n        id\n        fullName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cloudIntelligenceDashboardsProvidersFilter(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboardsFilters(where: $where) {\n      providers\n    }\n  }\n"): (typeof documents)["\n  query cloudIntelligenceDashboardsProvidersFilter(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboardsFilters(where: $where) {\n      providers\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cloudIntelligenceDashboardsUserPermissionsFilter(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboardsFilters(where: $where) {\n      usersPermissions {\n        id\n        fullName\n      }\n    }\n  }\n"): (typeof documents)["\n  query cloudIntelligenceDashboardsUserPermissionsFilter(\n    $where: CloudIntelligenceDashboardsFilterInput\n  ) {\n    cloudIntelligenceDashboardsFilters(where: $where) {\n      usersPermissions {\n        id\n        fullName\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cloudIntelligenceDashboardCount {\n    cloudIntelligenceDashboardCount\n  }\n"): (typeof documents)["\n  query cloudIntelligenceDashboardCount {\n    cloudIntelligenceDashboardCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cloudIntelligenceDashboardToken {\n    cloudIntelligenceDashboardTokens {\n      id\n      tokens\n    }\n  }\n"): (typeof documents)["\n  query cloudIntelligenceDashboardToken {\n    cloudIntelligenceDashboardTokens {\n      id\n      tokens\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cloudIntelligenceMyPermissions {\n    cloudIntelligenceMyPermissions {\n      isCloudIntelligenceCreator\n    }\n  }\n"): (typeof documents)["\n  query cloudIntelligenceMyPermissions {\n    cloudIntelligenceMyPermissions {\n      isCloudIntelligenceCreator\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createCloudIntelligenceDashboard(\n    $name: String!\n    $providers: [CloudIntelligenceProvider!]!\n  ) {\n    createCloudIntelligenceDashboard(name: $name, providers: $providers) {\n      id\n      presetDashboardId\n      embeddedId\n      name\n      updatedAt\n      isSystem\n      createdBy\n      providers\n      workspaceSlug\n      workspaceRegion\n      isFavorite\n      permissions {\n        everyoneCanView\n        hasViewPermission\n        usersViewPermissions {\n          id\n          name\n        }\n        teamsViewPermissions {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createCloudIntelligenceDashboard(\n    $name: String!\n    $providers: [CloudIntelligenceProvider!]!\n  ) {\n    createCloudIntelligenceDashboard(name: $name, providers: $providers) {\n      id\n      presetDashboardId\n      embeddedId\n      name\n      updatedAt\n      isSystem\n      createdBy\n      providers\n      workspaceSlug\n      workspaceRegion\n      isFavorite\n      permissions {\n        everyoneCanView\n        hasViewPermission\n        usersViewPermissions {\n          id\n          name\n        }\n        teamsViewPermissions {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCloudIntelligenceDashboard(\n    $input: UpdateCloudIntelligenceDashboardInput!\n  ) {\n    updateCloudIntelligenceDashboard(input: $input) {\n      id\n      presetDashboardId\n      embeddedId\n      name\n      updatedAt\n      isSystem\n      createdBy\n      providers\n      workspaceSlug\n      workspaceRegion\n      isFavorite\n      permissions {\n        everyoneCanView\n        hasViewPermission\n        usersViewPermissions {\n          id\n          name\n        }\n        teamsViewPermissions {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateCloudIntelligenceDashboard(\n    $input: UpdateCloudIntelligenceDashboardInput!\n  ) {\n    updateCloudIntelligenceDashboard(input: $input) {\n      id\n      presetDashboardId\n      embeddedId\n      name\n      updatedAt\n      isSystem\n      createdBy\n      providers\n      workspaceSlug\n      workspaceRegion\n      isFavorite\n      permissions {\n        everyoneCanView\n        hasViewPermission\n        usersViewPermissions {\n          id\n          name\n        }\n        teamsViewPermissions {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteCloudIntelligenceDashboard($id: UUID!) {\n    deleteCloudIntelligenceDashboard(id: $id) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation deleteCloudIntelligenceDashboard($id: UUID!) {\n    deleteCloudIntelligenceDashboard(id: $id) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cloudIntelligenceWorkspaceEnable {\n    cloudIntelligenceState {\n      enabled\n    }\n  }\n"): (typeof documents)["\n  query cloudIntelligenceWorkspaceEnable {\n    cloudIntelligenceState {\n      enabled\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateCloudIntelligenceCreators(\n    $usersToAdd: [UUID!]\n    $usersToRemove: [UUID!]\n  ) {\n    updateCloudIntelligenceCreators(\n      usersToAdd: $usersToAdd\n      usersToRemove: $usersToRemove\n    ) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateCloudIntelligenceCreators(\n    $usersToAdd: [UUID!]\n    $usersToRemove: [UUID!]\n  ) {\n    updateCloudIntelligenceCreators(\n      usersToAdd: $usersToAdd\n      usersToRemove: $usersToRemove\n    ) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTeamList {\n    listTeams {\n      id\n      name\n      childTeams {\n        id\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTeamList {\n    listTeams {\n      id\n      name\n      childTeams {\n        id\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getMembersList($where: UsersFilterInput, $limit: Int, $offset: Int) {\n    users(where: $where, limit: $limit, offset: $offset) {\n      id\n      fullName\n      role\n      cloudIntelligenceCreator\n    }\n  }\n"): (typeof documents)["\n  query getMembersList($where: UsersFilterInput, $limit: Int, $offset: Int) {\n    users(where: $where, limit: $limit, offset: $offset) {\n      id\n      fullName\n      role\n      cloudIntelligenceCreator\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCloudIntelligencePermissions(\n    $input: UpdateCloudIntelligencePermissionsInput!\n  ) {\n    updateCloudIntelligencePermissions(input: $input) {\n      success\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation updateCloudIntelligencePermissions(\n    $input: UpdateCloudIntelligencePermissionsInput!\n  ) {\n    updateCloudIntelligencePermissions(input: $input) {\n      success\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteCloudIntelligencePermissions(\n    $input: DeleteCloudIntelligencePermissionsInput!\n  ) {\n    deleteCloudIntelligencePermissions(input: $input) {\n      success\n      __typename\n    }\n  }\n"): (typeof documents)["\n  mutation deleteCloudIntelligencePermissions(\n    $input: DeleteCloudIntelligencePermissionsInput!\n  ) {\n    deleteCloudIntelligencePermissions(input: $input) {\n      success\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query cloudIntelligenceCreators {\n    cloudIntelligenceCreators {\n      creators {\n        id\n        name\n      }\n      usersQuota\n    }\n  }\n"): (typeof documents)["\n  query cloudIntelligenceCreators {\n    cloudIntelligenceCreators {\n      creators {\n        id\n        name\n      }\n      usersQuota\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCloudIntelligenceUserIsFavorite(\n    $input: CloudIntelligenceUserIsFavoriteInput!\n  ) {\n    updateCloudIntelligenceUserIsFavorite(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation updateCloudIntelligenceUserIsFavorite(\n    $input: CloudIntelligenceUserIsFavoriteInput!\n  ) {\n    updateCloudIntelligenceUserIsFavorite(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query CloudIntelligenceDashboardExport($id: UUID!) {\n    cloudIntelligenceDashboardExport(id: $id)\n  }\n"): (typeof documents)["\n  query CloudIntelligenceDashboardExport($id: UUID!) {\n    cloudIntelligenceDashboardExport(id: $id)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation duplicateCloudIntelligenceDashboard(\n    $input: DuplicateCloudIntelligenceDashboardInput!\n  ) {\n    duplicateCloudIntelligenceDashboard(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation duplicateCloudIntelligenceDashboard(\n    $input: DuplicateCloudIntelligenceDashboardInput!\n  ) {\n    duplicateCloudIntelligenceDashboard(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query commitmentsDataIntegrity {\n    commitmentsDataIntegrity {\n      dataCollectedFirstTime\n      missingData {\n        projectId\n        commitments\n      }\n      managementAccountsIssues {\n        missingManagementAccounts\n        mgmtRootAccountsConnected\n      }\n    }\n  }\n"): (typeof documents)["\n  query commitmentsDataIntegrity {\n    commitmentsDataIntegrity {\n      dataCollectedFirstTime\n      missingData {\n        projectId\n        commitments\n      }\n      managementAccountsIssues {\n        missingManagementAccounts\n        mgmtRootAccountsConnected\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query commitmentsAggregation(\n    $where: CostFilterInput\n    $groupBy: [CostGrouping!]\n  ) {\n    commitmentsCostAggregation(where: $where, groupBy: $groupBy) {\n      group\n      actualCost\n      usageCost\n      coveredCost\n      totalCostBeforeDiscounts\n    }\n  }\n"): (typeof documents)["\n  query commitmentsAggregation(\n    $where: CostFilterInput\n    $groupBy: [CostGrouping!]\n  ) {\n    commitmentsCostAggregation(where: $where, groupBy: $groupBy) {\n      group\n      actualCost\n      usageCost\n      coveredCost\n      totalCostBeforeDiscounts\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query commitmentsBaselineUsage(\n    $where: CostFilterInput\n    $groupBy: [CostGrouping!]\n  ) {\n    commitmentsBaselineUsage(where: $where, groupBy: $groupBy) {\n      baselineUsage\n      group\n    }\n  }\n"): (typeof documents)["\n  query commitmentsBaselineUsage(\n    $where: CostFilterInput\n    $groupBy: [CostGrouping!]\n  ) {\n    commitmentsBaselineUsage(where: $where, groupBy: $groupBy) {\n      baselineUsage\n      group\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query commitmentsUtilization(\n    $timeRange: TimeRangeInput!\n    $where: CommitmentsFilterInput\n    $groupBy: [CommitmentUtilizationGrouping!]\n  ) {\n    commitmentsUtilization(\n      timeRange: $timeRange\n      where: $where\n      groupBy: $groupBy\n    ) {\n      group\n      utilization\n      totalCommitmentsCost\n      savings\n      netSavings\n    }\n  }\n"): (typeof documents)["\n  query commitmentsUtilization(\n    $timeRange: TimeRangeInput!\n    $where: CommitmentsFilterInput\n    $groupBy: [CommitmentUtilizationGrouping!]\n  ) {\n    commitmentsUtilization(\n      timeRange: $timeRange\n      where: $where\n      groupBy: $groupBy\n    ) {\n      group\n      utilization\n      totalCommitmentsCost\n      savings\n      netSavings\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query effectiveSavingsRate(\n    $timeRange: TimeRangeInput!\n    $groupBy: [EsrGrouping!]\n  ) {\n    effectiveSavingsRate(timeRange: $timeRange, groupBy: $groupBy) {\n      group\n      savings\n      netSavings\n      effectiveSavingsRate\n      netEffectiveSavingsRate\n    }\n  }\n"): (typeof documents)["\n  query effectiveSavingsRate(\n    $timeRange: TimeRangeInput!\n    $groupBy: [EsrGrouping!]\n  ) {\n    effectiveSavingsRate(timeRange: $timeRange, groupBy: $groupBy) {\n      group\n      savings\n      netSavings\n      effectiveSavingsRate\n      netEffectiveSavingsRate\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query commitmentsInsights(\n    $orderBy: [CommitmentInsightsOrdering!]\n    $where: CommitmentInsightsFilterInput\n    $limit: Int\n    $offset: Int\n  ) {\n    commitmentsInsights(\n      orderBy: $orderBy\n      where: $where\n      limit: $limit\n      offset: $offset\n    ) {\n      id\n      createdAt\n      type\n      utilizationPercentage\n      commitment {\n        id\n        type\n        specifications\n        instanceCount\n        region\n        expiresInDays\n        monthlyPrice\n        duration\n        term\n        offeringType\n        tenancy\n      }\n      project {\n        id\n        canonicalName\n      }\n      utilizationPercentage\n    }\n  }\n"): (typeof documents)["\n  query commitmentsInsights(\n    $orderBy: [CommitmentInsightsOrdering!]\n    $where: CommitmentInsightsFilterInput\n    $limit: Int\n    $offset: Int\n  ) {\n    commitmentsInsights(\n      orderBy: $orderBy\n      where: $where\n      limit: $limit\n      offset: $offset\n    ) {\n      id\n      createdAt\n      type\n      utilizationPercentage\n      commitment {\n        id\n        type\n        specifications\n        instanceCount\n        region\n        expiresInDays\n        monthlyPrice\n        duration\n        term\n        offeringType\n        tenancy\n      }\n      project {\n        id\n        canonicalName\n      }\n      utilizationPercentage\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query commitmentsInsightsFilters($where: CommitmentInsightsFilterInput) {\n    commitmentsInsightsFilters(where: $where) {\n      _id\n      insightType\n      type\n      specifications\n      expiresInDays {\n        max\n      }\n      instanceCount {\n        max\n      }\n      duration\n      project {\n        id\n        name\n      }\n      region\n      hourlyPrice {\n        max\n      }\n      utilization {\n        max\n      }\n    }\n  }\n"): (typeof documents)["\n  query commitmentsInsightsFilters($where: CommitmentInsightsFilterInput) {\n    commitmentsInsightsFilters(where: $where) {\n      _id\n      insightType\n      type\n      specifications\n      expiresInDays {\n        max\n      }\n      instanceCount {\n        max\n      }\n      duration\n      project {\n        id\n        name\n      }\n      region\n      hourlyPrice {\n        max\n      }\n      utilization {\n        max\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getStatisticsAggregationByMonth(\n    $where: StatisticsFilterInput\n    $groupBy: [StatisticsGrouping!]\n  ) {\n    statisticsAggregation(where: $where, groupBy: $groupBy) {\n      group\n      totalMonthlyCost\n      potentialSavings\n      realSavings\n    }\n  }\n"): (typeof documents)["\n  query getStatisticsAggregationByMonth(\n    $where: StatisticsFilterInput\n    $groupBy: [StatisticsGrouping!]\n  ) {\n    statisticsAggregation(where: $where, groupBy: $groupBy) {\n      group\n      totalMonthlyCost\n      potentialSavings\n      realSavings\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getIntegrationsStatus($where: IntegrationFilterInput) {\n    integrations(where: $where) {\n      status\n    }\n  }\n"): (typeof documents)["\n  query getIntegrationsStatus($where: IntegrationFilterInput) {\n    integrations(where: $where) {\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getNotificationRulesStatus {\n    notificationRules {\n      name\n      status\n    }\n  }\n"): (typeof documents)["\n  query getNotificationRulesStatus {\n    notificationRules {\n      name\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getK8sClustersStatus {\n    resources(\n      where: {\n        AND: [{ service: { EQ: \"EKS\" } }, { resourceType: { EQ: \"cluster\" } }]\n      }\n    ) {\n      resourceType\n      id\n    }\n    resourceAggregation(\n      where: { AND: [{ category: { EQ: \"Applicative\" } }] }\n      groupBy: [ROOT_PARENT_ID]\n    ) {\n      group\n      totalCost\n      totalSaving\n    }\n  }\n"): (typeof documents)["\n  query getK8sClustersStatus {\n    resources(\n      where: {\n        AND: [{ service: { EQ: \"EKS\" } }, { resourceType: { EQ: \"cluster\" } }]\n      }\n    ) {\n      resourceType\n      id\n    }\n    resourceAggregation(\n      where: { AND: [{ category: { EQ: \"Applicative\" } }] }\n      groupBy: [ROOT_PARENT_ID]\n    ) {\n      group\n      totalCost\n      totalSaving\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query listIntegrations($where: IntegrationFilterInput) {\n    integrations(where: $where) {\n      id\n      name\n      type\n      status\n      updatedAt\n      createdAt\n      ... on AwsIntegration {\n        accountId\n      }\n      ... on AwsOrgIntegration {\n        accountId\n        managementAccountId\n        managementAccountName\n        accountCount\n      }\n      ... on KubernetesIntegration {\n        name\n        status\n        kubernetesLastSyncDate\n        deletedAt\n        cluster {\n          id\n          name\n          region\n          deletedAt\n          project {\n            name\n            canonicalName\n            id\n          }\n        }\n      }\n      ... on GcpIntegration {\n        serviceAccount\n      }\n      ... on AzureIntegration {\n        tenantId\n        managementGroupId\n        appId\n      }\n    }\n  }\n"): (typeof documents)["\n  query listIntegrations($where: IntegrationFilterInput) {\n    integrations(where: $where) {\n      id\n      name\n      type\n      status\n      updatedAt\n      createdAt\n      ... on AwsIntegration {\n        accountId\n      }\n      ... on AwsOrgIntegration {\n        accountId\n        managementAccountId\n        managementAccountName\n        accountCount\n      }\n      ... on KubernetesIntegration {\n        name\n        status\n        kubernetesLastSyncDate\n        deletedAt\n        cluster {\n          id\n          name\n          region\n          deletedAt\n          project {\n            name\n            canonicalName\n            id\n          }\n        }\n      }\n      ... on GcpIntegration {\n        serviceAccount\n      }\n      ... on AzureIntegration {\n        tenantId\n        managementGroupId\n        appId\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query integrationScript($provider: IntegrationProvider!) {\n    integrationScript(providerType: $provider)\n  }\n"): (typeof documents)["\n  query integrationScript($provider: IntegrationProvider!) {\n    integrationScript(providerType: $provider)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getJiraProjects($integrationId: UUID!) {\n    external {\n      jira {\n        projects(integrationId: $integrationId) {\n          id\n          key\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getJiraProjects($integrationId: UUID!) {\n    external {\n      jira {\n        projects(integrationId: $integrationId) {\n          id\n          key\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query JiraProject($integrationId: UUID!, $projectKey: String!) {\n    external {\n      jira {\n        project(integrationId: $integrationId, projectKey: $projectKey) {\n          issueTypes {\n            name\n            hasPriorityField\n            requiredFields {\n              name\n              key\n              type\n              options\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query JiraProject($integrationId: UUID!, $projectKey: String!) {\n    external {\n      jira {\n        project(integrationId: $integrationId, projectKey: $projectKey) {\n          issueTypes {\n            name\n            hasPriorityField\n            requiredFields {\n              name\n              key\n              type\n              options\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetJiraUsers($integrationId: UUID!) {\n    external {\n      jira {\n        users(integrationId: $integrationId) {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetJiraUsers($integrationId: UUID!) {\n    external {\n      jira {\n        users(integrationId: $integrationId) {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTicket(\n    $integrationId: UUID!\n    $shareableId: UUID!\n    $shareableType: ShareableType!\n    $type: TicketType!\n  ) {\n    external {\n      jira {\n        ticket(\n          integrationId: $integrationId\n          shareableId: $shareableId\n          shareableType: $shareableType\n          type: $type\n        ) {\n          ticketId\n          key\n          type\n          status\n          link\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTicket(\n    $integrationId: UUID!\n    $shareableId: UUID!\n    $shareableType: ShareableType!\n    $type: TicketType!\n  ) {\n    external {\n      jira {\n        ticket(\n          integrationId: $integrationId\n          shareableId: $shareableId\n          shareableType: $shareableType\n          type: $type\n        ) {\n          ticketId\n          key\n          type\n          status\n          link\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetJiraIntegrations {\n    integrations(where: { type: { EQ: \"JIRA\" } }) {\n      id\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetJiraIntegrations {\n    integrations(where: { type: { EQ: \"JIRA\" } }) {\n      id\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query assignableUsers(\n    $integrationId: UUID!\n    $projectKey: String!\n    $searchTerm: String\n  ) {\n    external {\n      jira {\n        assignableUsers(\n          integrationId: $integrationId\n          projectKey: $projectKey\n          searchTerm: $searchTerm\n        ) {\n          emailAddress\n          displayName\n          accountID\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query assignableUsers(\n    $integrationId: UUID!\n    $projectKey: String!\n    $searchTerm: String\n  ) {\n    external {\n      jira {\n        assignableUsers(\n          integrationId: $integrationId\n          projectKey: $projectKey\n          searchTerm: $searchTerm\n        ) {\n          emailAddress\n          displayName\n          accountID\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTotalCostAggregation(\n    $where: ResourceFilterInput! = {}\n    $groupBy: [ResourceGrouping!]\n  ) {\n    resourceAggregation(where: $where, groupBy: $groupBy) {\n      count\n      group\n      totalCloudCost\n      totalApplicativeCost\n    }\n  }\n"): (typeof documents)["\n  query getTotalCostAggregation(\n    $where: ResourceFilterInput! = {}\n    $groupBy: [ResourceGrouping!]\n  ) {\n    resourceAggregation(where: $where, groupBy: $groupBy) {\n      count\n      group\n      totalCloudCost\n      totalApplicativeCost\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getPotentialSavingAggregation(\n    $where: ResourceFilterInput! = {}\n    $groupBy: [ResourceGrouping!]\n  ) {\n    resourceAggregation(where: $where, groupBy: $groupBy) {\n      count\n      group\n      totalSaving\n      totalCloudMonthlySavings\n      totalApplicativeMonthlySavings\n    }\n  }\n"): (typeof documents)["\n  query getPotentialSavingAggregation(\n    $where: ResourceFilterInput! = {}\n    $groupBy: [ResourceGrouping!]\n  ) {\n    resourceAggregation(where: $where, groupBy: $groupBy) {\n      count\n      group\n      totalSaving\n      totalCloudMonthlySavings\n      totalApplicativeMonthlySavings\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTeamsList($where: TeamsFilterInput) {\n    listTeams(where: $where) {\n      id\n      name\n      type\n      members {\n        teamId\n        user {\n          id\n          fullName\n        }\n      }\n      memberCount\n      resourceAllocationRulesCount\n    }\n  }\n"): (typeof documents)["\n  query getTeamsList($where: TeamsFilterInput) {\n    listTeams(where: $where) {\n      id\n      name\n      type\n      members {\n        teamId\n        user {\n          id\n          fullName\n        }\n      }\n      memberCount\n      resourceAllocationRulesCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTeamsTree {\n    listTeams {\n      id\n      name\n      type\n      childTeams {\n        id\n        name\n      }\n      memberCount\n      resourceAllocationRules {\n        rule\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query getTeamsTree {\n    listTeams {\n      id\n      name\n      type\n      childTeams {\n        id\n        name\n      }\n      memberCount\n      resourceAllocationRules {\n        rule\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getTeam($id: UUID!) {\n    getTeam(id: $id) {\n      id\n      name\n      type\n      members {\n        role\n        userId\n        user {\n          firstName\n          lastName\n          email\n        }\n      }\n      memberCount\n      childTeams {\n        id\n        name\n        memberCount\n        resourceAllocationRulesCount\n      }\n      parentTeams {\n        id\n      }\n      resourceAllocationRules {\n        name\n        rule\n      }\n      resourceAllocationRulesCount\n    }\n  }\n"): (typeof documents)["\n  query getTeam($id: UUID!) {\n    getTeam(id: $id) {\n      id\n      name\n      type\n      members {\n        role\n        userId\n        user {\n          firstName\n          lastName\n          email\n        }\n      }\n      memberCount\n      childTeams {\n        id\n        name\n        memberCount\n        resourceAllocationRulesCount\n      }\n      parentTeams {\n        id\n      }\n      resourceAllocationRules {\n        name\n        rule\n      }\n      resourceAllocationRulesCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query get_users($limit: Int = 100, $offset: Int = 0) {\n    users(limit: $limit, offset: $offset) {\n      id\n      firstName\n      lastName\n      email\n      role\n      teams {\n        name\n        id\n      }\n      updatedAt\n      remoteAssistanceConsent\n      cloudIntelligenceCreator\n    }\n  }\n"): (typeof documents)["\n  query get_users($limit: Int = 100, $offset: Int = 0) {\n    users(limit: $limit, offset: $offset) {\n      id\n      firstName\n      lastName\n      email\n      role\n      teams {\n        name\n        id\n      }\n      updatedAt\n      remoteAssistanceConsent\n      cloudIntelligenceCreator\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;