import { ReactNode, useEffect, useRef } from 'react';

import { cva } from 'class-variance-authority';
import { motion } from 'framer-motion';

import { STEPPER_TRANSITION_ANIMATION } from '../constants';
import { StepIndicator, StepIndicatorProps } from './StepIndicator';
import { StepStyles } from './types';

const StepTitle = ({ children }: { children: ReactNode }) => (
  <motion.h2 layout className="flex items-center gap-2 text-lg font-semibold">
    {children}
  </motion.h2>
);

const containerSpecificStyle = cva('', {
  variants: {
    style: {
      [StepStyles.Compact]: 'px-6 py-3',
      [StepStyles.Wide]: 'p-4',
    },
  },
});

export type StepProps = StepIndicatorProps & {
  title: ReactNode;
  style?: StepStyles;
  isCurrent: boolean;
  isFirst: boolean;
  collapsed?: boolean;
  children: ReactNode;
};

export const Step = ({
  title: titleContents,
  style = StepStyles.Compact,
  isCurrent,
  isFirst,
  collapsed = false,
  children,
  ...props
}: StepProps) => {
  const stepElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isCurrent && stepElement.current) {
      stepElement.current.scrollIntoView({
        behavior: 'smooth',
        block: isFirst ? 'end' : 'start',
        inline: 'nearest',
      });
    }
  }, [isCurrent]);

  return (
    <motion.div
      ref={stepElement}
      className={`relative flex flex-col rounded-xl border border-solid border-untitled-gray-100 bg-white [&>:not(${
        collapsed ? ':nth-last-child(2)' : ':last-child'
      })]:mb-3 ${containerSpecificStyle({ style })}`}
      layout
      transition={STEPPER_TRANSITION_ANIMATION}
    >
      <StepIndicator style={style} isCurrent={isCurrent} {...props} />
      <StepTitle>{titleContents}</StepTitle>
      <motion.div layout transition={STEPPER_TRANSITION_ANIMATION}>
        {collapsed ? null : children}
      </motion.div>
    </motion.div>
  );
};
