import { OpportunityStatus } from '@pointfive/gql/generated/graphql';
import { OpportunityStatusIcon } from '@pointfive/ui';

import {
  O9Y_STATUS_TO_CATEGORY,
  OPPORTUNITY_STATUS_CATEGORIES,
  OPPORTUNITY_STATUS_OPTIONS,
} from 'domains/opportunity/constants';
import {
  MainOpportunityCategories,
  OpportunityStatusOptionsValue,
} from 'domains/opportunity/types';

export const OPPORTUNITY_STATUS_COLORS = {
  [OpportunityStatus.Open]: 'bg-status-open',
  [OpportunityStatus.Validated]: 'bg-status-validated',
  [OpportunityStatus.InProgress]: 'bg-status-in_progress',
  [OpportunityStatus.Resolved]: 'bg-status-resolved',
  [OpportunityStatus.Dismissed]: 'bg-status-dismiss',
};

export type OpportunityStatusColors =
  (typeof OPPORTUNITY_STATUS_COLORS)[keyof typeof OPPORTUNITY_STATUS_COLORS];

export type OpportunityStatusProperties = {
  value: OpportunityStatusOptionsValue | MainOpportunityCategories;
  iconClassName: OpportunityStatusColors;
};

export const OPPORTUNITY_STATUS_OPTIONS_PROPERTIES: Record<
  OpportunityStatus,
  OpportunityStatusProperties
> = {
  [OpportunityStatus.Open]: {
    value: OPPORTUNITY_STATUS_OPTIONS.OPEN,
    iconClassName: OPPORTUNITY_STATUS_COLORS[OpportunityStatus.Open],
  },
  [OpportunityStatus.Validated]: {
    value: OPPORTUNITY_STATUS_OPTIONS.VALIDATED,
    iconClassName: OPPORTUNITY_STATUS_COLORS[OpportunityStatus.Validated],
  },
  [OpportunityStatus.InProgress]: {
    value: OPPORTUNITY_STATUS_OPTIONS.IN_PROGRESS,
    iconClassName: OPPORTUNITY_STATUS_COLORS[OpportunityStatus.InProgress],
  },
  [OpportunityStatus.Resolved]: {
    value: OPPORTUNITY_STATUS_OPTIONS.RESOLVED,
    iconClassName: OPPORTUNITY_STATUS_COLORS[OpportunityStatus.Resolved],
  },
  [OpportunityStatus.Dismissed]: {
    value: OPPORTUNITY_STATUS_OPTIONS.DISMISSED,
    iconClassName: OPPORTUNITY_STATUS_COLORS[OpportunityStatus.Dismissed],
  },
};

export const OPPORTUNITY_STATUS_CATEGORIES_PROPERTIES: Record<
  MainOpportunityCategories,
  OpportunityStatusProperties
> = {
  [OPPORTUNITY_STATUS_CATEGORIES.open]: {
    value: OPPORTUNITY_STATUS_CATEGORIES.open,
    iconClassName: 'bg-status-open',
  },
  [OPPORTUNITY_STATUS_CATEGORIES.closed]: {
    value: OPPORTUNITY_STATUS_CATEGORIES.closed,
    iconClassName: 'bg-status-resolved',
  },
};

export const OPPORTUNITY_STATUS_DROPDOWN_OPTIONS = O9Y_STATUS_TO_CATEGORY.all
  .map((option) => {
    const { iconClassName: dotColor, value: label } =
      OPPORTUNITY_STATUS_OPTIONS_PROPERTIES[option];
    return {
      value: option,
      label: label,
      icon: <OpportunityStatusIcon dotColor={dotColor} />,
    };
  })
  .flat();
