import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const NoTeamIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="NoTeam"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.50081 3.1951C3.19231 2.91649 2.718 2.93897 2.44141 3.24531C2.16483 3.55166 2.1907 4.02586 2.49921 4.30447L5.03677 6.59615C4.78381 7.10287 4.64151 7.67448 4.64151 8.27933C4.64151 10.3634 6.331 12.0529 8.4151 12.0529C9.15702 12.0529 9.84894 11.8388 10.4324 11.469L17.0943 17.4854V18.8454C17.0943 19.2622 17.4322 19.6001 17.8491 19.6001C18.2648 19.6001 18.6021 19.2639 18.6038 18.8486L20.4992 20.5603C20.8077 20.8389 21.282 20.8165 21.5586 20.5101C21.8352 20.2038 21.8093 19.7296 21.5008 19.451L3.50081 3.1951ZM9.23723 10.3896L6.23227 7.67581C6.17926 7.86795 6.15095 8.07033 6.15095 8.27933C6.15095 9.52978 7.16464 10.5435 8.4151 10.5435C8.70511 10.5435 8.98238 10.489 9.23723 10.3896Z"
        />
        <path d="M10.5833 7.62501C10.3451 6.83478 9.68676 6.22669 8.86795 6.06047L7.33526 4.66251C7.67738 4.56052 8.03984 4.50574 8.4151 4.50574C10.4992 4.50574 12.1887 6.19523 12.1887 8.27933C12.1887 8.53374 12.1635 8.78227 12.1155 9.02257L10.5833 7.62501Z" />
        <path d="M14.3558 11.0659L13.233 10.0418C13.7473 9.62632 14.0755 8.99064 14.0755 8.27933C14.0755 7.33031 13.4914 6.51592 12.6602 6.17947C12.2739 6.02308 12.0874 5.58308 12.2438 5.19672C12.4002 4.81035 12.8402 4.62393 13.2266 4.78032C14.6081 5.33956 15.5849 6.69447 15.5849 8.27933C15.5849 9.38361 15.1107 10.3763 14.3558 11.0659Z" />
        <path d="M18.2566 14.6238L18.8395 15.1555C19.5301 15.3492 20.0868 15.8624 20.341 16.525L22 18.0382V17.3359C22 15.5765 20.7967 14.1001 19.1693 13.6812C18.7656 13.5773 18.3541 13.8204 18.2502 14.224C18.2151 14.3605 18.2196 14.4978 18.2566 14.6238Z" />
        <path d="M9.57404 13.5623C10.2546 13.5623 10.8036 13.5623 11.2503 13.5928C11.7098 13.6242 12.116 13.6903 12.5007 13.8496C13.4253 14.2326 14.16 14.9672 14.5429 15.8918C14.7023 16.2765 14.7684 16.6828 14.7997 17.1422C14.8302 17.589 14.8302 18.1379 14.8302 18.8184V18.8454C14.8302 19.2622 14.4923 19.6001 14.0755 19.6001C13.6587 19.6001 13.3208 19.2622 13.3208 18.8454C13.3208 18.1317 13.3204 17.6343 13.2938 17.245C13.2677 16.8624 13.2189 16.6396 13.1484 16.4695C12.9186 15.9147 12.4778 15.4739 11.9231 15.2441C11.753 15.1737 11.5302 15.1249 11.1476 15.0988C10.7582 15.0722 10.2608 15.0718 9.54717 15.0718H7.28302C6.56939 15.0718 6.07196 15.0722 5.6826 15.0988C5.30003 15.1249 5.07724 15.1737 4.90713 15.2441C4.35235 15.4739 3.91158 15.9147 3.68178 16.4695C3.61132 16.6396 3.56251 16.8624 3.53641 17.245C3.50984 17.6343 3.50943 18.1317 3.50943 18.8454C3.50943 19.2622 3.17154 19.6001 2.75472 19.6001C2.3379 19.6001 2 19.2622 2 18.8454V18.8186C2 18.138 1.99999 17.589 2.03048 17.1422C2.06182 16.6828 2.12791 16.2765 2.28725 15.8918C2.67024 14.9672 3.40486 14.2326 4.3295 13.8496C4.71418 13.6903 5.12043 13.6242 5.57986 13.5928C6.02664 13.5623 6.57561 13.5623 7.25615 13.5623H9.57404Z" />
        <path d="M16.6228 4.78029C16.2364 4.62389 15.7964 4.81032 15.64 5.19669C15.4836 5.58305 15.6701 6.02305 16.0564 6.17944C16.8876 6.51589 17.4717 7.33028 17.4717 8.27929C17.4717 9.22831 16.8876 10.0427 16.0564 10.3791C15.6701 10.5355 15.4836 10.9755 15.64 11.3619C15.7964 11.7483 16.2364 11.9347 16.6228 11.7783C18.0044 11.2191 18.9811 9.86415 18.9811 8.27929C18.9811 6.69444 18.0044 5.33953 16.6228 4.78029Z" />
      </svg>
    );
  },
);

NoTeamIcon.displayName = 'NoTeamIcon';
