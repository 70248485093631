import { Link } from 'react-router-dom';

import { SideNavSettingsIcon } from '@pointfive/assets';

import { TopNavButton } from './TopNavButton';

type SettingsButtonProps = {
  isSelected: boolean;
};
export const SettingsButton = ({ isSelected }: SettingsButtonProps) => {
  return (
    <Link to={'/settings'}>
      <TopNavButton
        label="Settings"
        isSelected={isSelected}
        icon={<SideNavSettingsIcon size="sm" />}
        dataCy="top-bar-btn-settings"
      />
    </Link>
  );
};
