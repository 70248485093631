import {
  Navigate,
  Route,
  createRoutesFromElements,
  useLocation,
  useRouteError,
} from 'react-router-dom';

import { ErrorBoundaryFallback } from 'components/ErrorBoundary/ErrorBoundaryFallBack';
import { IS_PROD } from 'constants/env';
import { CLOUD_INTELLIGENCE_PAGE_TITLE } from 'pages/CloudIntelligence/constants';
import { ROUTE_CONSTANTS } from 'router/constants';

import { AUTOMATIONS_PAGE_TITLE } from '../pages/Automations';
import { automationsElements } from '../pages/Automations/router.elements';
import { COMMITMENTS_TITLE } from '../pages/Commitments/components/CommitmentsLayout/constants';
import Content from '../pages/Content';
import { settingsElements } from '../pages/Settings/router.elements';
import { ERR_IMPORT_MESSAGE, lazyWithRetries } from './lazyWithRetries';
import { DefaultRoute } from './utils/defaultRouter';

const Resources = lazyWithRetries(() => import('../pages/Resources/Resources'));
const Resource = lazyWithRetries(() => import('../pages/Resource/Resource'));
const ResourceOverviewTab = lazyWithRetries(
  () => import('../pages/Resource/components/ResourceOverview/index'),
);
const ResourceOpportunitiesTab = lazyWithRetries(
  () => import('../pages/Resource/components/ResourceOpportunities/index'),
);
const Opportunities = lazyWithRetries(
  () => import('../pages/Opportunities/Opportunities'),
);
const OpenTab = lazyWithRetries(
  () =>
    import('../pages/Opportunities/OpportunitiesTabsContent/OpenTabContent'),
);
const AllOpportunitiesTab = lazyWithRetries(
  () =>
    import(
      '../pages/Opportunities/OpportunitiesTabsContent/AllOpportunitiesTab'
    ),
);
const ClosedTab = lazyWithRetries(
  () =>
    import('../pages/Opportunities/OpportunitiesTabsContent/ClosedTabContent'),
);
const GroupsTab = lazyWithRetries(
  () =>
    import('../pages/Opportunities/OpportunitiesTabsContent/GroupsTabContent'),
);
const Opportunity = lazyWithRetries(() => import('../pages/Opportunity'));
const OpportunityOverviewTab = lazyWithRetries(
  () => import('../pages/Opportunity/components/OpportunityOverview/index'),
);
const OpportunityActivityLogTab = lazyWithRetries(
  () => import('../pages/Opportunity/components/OpportunityActivityLog/index'),
);
const AnalysisTabPlaceholder = lazyWithRetries(
  () => import('domains/resource/components/ResourceAnalysis'),
);

const ExpertInsights = lazyWithRetries(() => import('../pages/Insights'));
const Dashboard = lazyWithRetries(() => import('../pages/Dashboard'));
const Commitments = lazyWithRetries(() => import('../pages/Commitments'));

const CloudIntelligenceLayout = lazyWithRetries(
  () => import('../pages/CloudIntelligence/components/CloudIntelligenceLayout'),
);
const CloudIntelligence = lazyWithRetries(
  () => import('../pages/CloudIntelligence'),
);
const CloudIntelligenceDashboardView = lazyWithRetries(
  () => import('../pages/CloudIntelligence/components/DashboardView'),
);

const ErrorPage = lazyWithRetries(() => import('../pages/States/ErrorPage'));

// Construct a path that will handle both /teams and any number of nested routes for team: /teams/<team id>, /teams/<team id>/<team id> etc.
const TeamsRecursivePath = ROUTE_CONSTANTS.teams + '/*';

export const routes = createRoutesFromElements([
  // eslint-disable-next-line react/jsx-key
  <Route
    path={ROUTE_CONSTANTS.content}
    element={<Content />}
    errorElement={<ErrorBoundary />}
  >
    {/* The default page */}
    <Route path={ROUTE_CONSTANTS.default} element={<DefaultRoute />} />
    <Route
      path={ROUTE_CONSTANTS.overview}
      element={<Dashboard />}
      handle={{ title: 'Home' }}
    />

    {/* Resources */}
    {/* TODO: Remove after solving resource page with invalid UUID
    This is here for backwards compatibility, as the old resource page was using the `resources/all` path. */}
    <Route
      path={`${ROUTE_CONSTANTS.resources}/all`}
      element={<Navigate to={`/${ROUTE_CONSTANTS.resources}`} replace />}
    />
    <Route
      path={ROUTE_CONSTANTS.resources}
      element={<Resources />}
      handle={{ title: 'Inventory' }}
    />

    {/* Resource */}
    <Route
      path={ROUTE_CONSTANTS.resource}
      element={<Resource />}
      handle={{ title: 'Inventory' }}
    >
      <Route
        index
        element={
          <Navigate to={`${ROUTE_CONSTANTS.resourceOverview}`} replace />
        }
      />
      <Route
        path={ROUTE_CONSTANTS.resourceOverview}
        element={<ResourceOverviewTab />}
        errorElement={
          <ErrorBoundaryFallback source="Resource page tabs - overview" />
        }
      />
      <Route
        path={ROUTE_CONSTANTS.resourceAnalysis}
        element={<AnalysisTabPlaceholder />}
        errorElement={
          <ErrorBoundaryFallback source="Resource page tabs - analysis" />
        }
      />
      <Route
        path={ROUTE_CONSTANTS.resourceOpportunities}
        element={<ResourceOpportunitiesTab />}
        errorElement={
          <ErrorBoundaryFallback source="Resource page tabs - opportunities" />
        }
      />
    </Route>

    {/* The Opportunities page will always redirect to the Open tab by default if attempting to enter "/opportunities" */}
    <Route
      path={ROUTE_CONSTANTS.opportunities}
      element={<Navigate to={ROUTE_CONSTANTS.opportunitiesOpen} replace />}
    />
    <Route
      path={ROUTE_CONSTANTS.opportunities}
      element={<Opportunities />}
      handle={{ title: 'Opportunities' }}
    >
      <Route
        path={ROUTE_CONSTANTS.opportunitiesAll}
        element={<AllOpportunitiesTab />}
      />
      <Route
        path={ROUTE_CONSTANTS.opportunitiesGroups}
        element={<GroupsTab />}
      />
      <Route path={ROUTE_CONSTANTS.opportunitiesOpen} element={<OpenTab />} />

      <Route
        path={ROUTE_CONSTANTS.opportunitiesClosed}
        element={<ClosedTab />}
      />
    </Route>

    <Route
      path={ROUTE_CONSTANTS.specificOpportunity}
      element={<Opportunity />}
      handle={{ title: 'Opportunities' }}
    >
      <Route
        index
        element={
          <Navigate to={`${ROUTE_CONSTANTS.opportunityOverview}`} replace />
        }
      />
      <Route
        path={ROUTE_CONSTANTS.opportunityOverview}
        element={<OpportunityOverviewTab />}
        errorElement={
          <ErrorBoundaryFallback source="Opportunity page tabs - overview" />
        }
      />
      <Route
        path={ROUTE_CONSTANTS.opportunityActivityLog}
        element={<OpportunityActivityLogTab />}
        errorElement={
          <ErrorBoundaryFallback source="Opportunity page tabs - activity log" />
        }
      />
      <Route
        path={ROUTE_CONSTANTS.opportunityAnalysis}
        element={<AnalysisTabPlaceholder />}
        errorElement={
          <ErrorBoundaryFallback source="Opportunity page tabs - analysis" />
        }
      />
    </Route>

    {/* Commitments */}
    <Route
      path={ROUTE_CONSTANTS.commitments}
      element={<Commitments />}
      handle={{ title: COMMITMENTS_TITLE }}
    />

    {/* Expert Insights */}
    <Route
      path={ROUTE_CONSTANTS.expertInsights}
      element={<ExpertInsights />}
      handle={{ title: 'Expert Insights' }}
    />

    {/* Settings */}
    <Route
      path={ROUTE_CONSTANTS.settings}
      element={<settingsElements.Settings />}
      handle={{ title: 'Settings' }}
    >
      <Route index element={<Navigate to={ROUTE_CONSTANTS.users} replace />} />
      <Route
        path={ROUTE_CONSTANTS.users}
        element={<settingsElements.Users />}
      />
      <Route
        path={ROUTE_CONSTANTS.teams}
        element={<settingsElements.TeamsPage />}
      />
      <Route path={TeamsRecursivePath} element={<settingsElements.Team />} />
      <Route
        path={ROUTE_CONSTANTS.integration}
        element={<Navigate to={ROUTE_CONSTANTS.aws} replace />}
      />
      <Route
        path={ROUTE_CONSTANTS.integrationType}
        element={<settingsElements.Integrations />}
      />
      <Route
        path={ROUTE_CONSTANTS.notifications}
        element={<settingsElements.NotificationsPage />}
      />
      <Route
        path={ROUTE_CONSTANTS.preferences}
        element={<settingsElements.Preferences />}
      >
        <Route
          path={ROUTE_CONSTANTS.preferencesDetections}
          element={<settingsElements.Detection />}
        />
      </Route>
    </Route>

    {/* Cloud Intelligence */}
    <Route
      path={ROUTE_CONSTANTS.cloudIntelligence}
      handle={{ title: CLOUD_INTELLIGENCE_PAGE_TITLE }}
    >
      <Route element={<CloudIntelligenceLayout />}>
        <Route
          index
          element={
            <Navigate
              to={ROUTE_CONSTANTS.cloudIntelligenceDashboards}
              replace
            />
          }
        />
        <Route
          index
          path={ROUTE_CONSTANTS.cloudIntelligenceDashboards}
          element={<CloudIntelligence />}
          errorElement={
            <ErrorBoundaryFallback source="Analytics page tabs - overview" />
          }
        />
        <Route
          path={ROUTE_CONSTANTS[404]}
          element={
            <Navigate
              to={ROUTE_CONSTANTS.cloudIntelligenceDashboards}
              replace
            />
          }
        />
      </Route>
      <Route
        path={ROUTE_CONSTANTS.cloudIntelligenceDashboardView}
        element={<CloudIntelligenceDashboardView />}
        errorElement={<ErrorBoundaryFallback source="Analytics View Mode" />}
      />
    </Route>

    {/* New Rule */}
    <Route
      path={ROUTE_CONSTANTS.newRule}
      element={<settingsElements.NewRulePage />}
      handle={{ title: 'Settings' }}
    />

    {/* Automations */}
    <Route
      path={ROUTE_CONSTANTS.automations}
      element={<automationsElements.Automations />}
      handle={{ title: AUTOMATIONS_PAGE_TITLE }}
    />
    <Route
      path={ROUTE_CONSTANTS.newAutomation}
      element={<automationsElements.NewAutomation />}
      handle={{ title: AUTOMATIONS_PAGE_TITLE }}
    />
    <Route
      path={ROUTE_CONSTANTS.editAutomation}
      element={<automationsElements.EditAutomation />}
      handle={{ title: AUTOMATIONS_PAGE_TITLE }}
    />

    {/*
        TODO: https://linear.app/pointfive/issue/PLAT-1797/router-or-fix-settings-sub-pages
        Settings - new and setup are out side of `/settings` main route.
        Why? These pages should not display the tabs.
        Solution: <Settings hideTabs/>
    */}
    <Route
      path={ROUTE_CONSTANTS.editRule}
      element={<settingsElements.EditRulePage />}
      handle={{ title: 'Settings' }}
    />
    <Route
      path={ROUTE_CONSTANTS.newAwsAccount}
      element={<settingsElements.NewAwsAccount />}
      handle={{ title: 'Settings' }}
    />
    <Route
      path={ROUTE_CONSTANTS.newSlackAccount}
      element={<settingsElements.NewSlackAccount />}
      handle={{ title: 'Settings' }}
    />
    <Route
      path={ROUTE_CONSTANTS.newJiraAccount}
      element={<settingsElements.NewJiraAccount />}
      handle={{ title: 'Settings' }}
    />
    <Route
      path={ROUTE_CONSTANTS.setupSlackAccount}
      element={<settingsElements.SetupIntegration />}
      handle={{ title: 'Settings' }}
    />
    <Route
      path={ROUTE_CONSTANTS.newAwsMultiAccounts}
      element={<settingsElements.NewAwsMultipleAccounts />}
      handle={{ title: 'Settings' }}
    />

    {/* Error pages */}
    <Route path={ROUTE_CONSTANTS.error} element={<ErrorPage />} />

    <Route
      path={ROUTE_CONSTANTS[404]}
      element={<Navigate to={ROUTE_CONSTANTS.default} replace />}
    />
  </Route>,
]);

function ErrorBoundary() {
  const location = useLocation();
  const error = useRouteError() as Error;

  if (!IS_PROD) {
    const errorStyle = [
      'color: white',
      'background-color: red',
      'font-weight: bold',
      'padding: 8px 8px',
      'border-radius: 4px',
      'border: 4px solid black',
    ].join(';');

    // eslint-disable-next-line
    console.error(
      `%c(!) Router ErrorBoundary reached: ${error?.message}`,
      errorStyle,
    );
    return;
  }

  // First, try to resolve quietly if it's a manner of a new available version!
  const searchParams = new URLSearchParams(location.search);
  const refreshCount = parseInt(searchParams.get('refresh') ?? '0');

  if (error.message.includes(ERR_IMPORT_MESSAGE) && refreshCount < 2) {
    searchParams.set('refresh', String(refreshCount + 1));
    window.location.href = location.pathname + '?' + searchParams.toString();

    return null;
  }

  // Otherwise, report the error to Sentry, redirect to error page
  return <Navigate to="error" state={error} />;
}
