import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const EyeOffIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="EyeOff"
      >
        <path d="M17.6063 16.2168C17.5602 16.1538 17.4956 16.0997 17.4403 16.0547L7.85062 6.68648C7.80452 6.63243 7.75841 6.58739 7.70309 6.54235L4.3467 3.26348C3.98709 2.91217 3.40618 2.91217 3.04657 3.26348C2.68695 3.61479 2.68695 4.18228 3.04657 4.53359L5.72061 7.14588C4.0793 8.45202 2.95436 9.99237 2.38266 10.8751C2.23513 11.1003 2.10604 11.2985 2.04149 11.6048C1.98617 11.848 1.98617 12.1633 2.04149 12.4065C2.11526 12.7037 2.23513 12.9019 2.38266 13.1271C3.56293 14.9557 6.86399 19.2074 12 19.2074C13.6321 19.2074 15.2273 18.766 16.7395 17.9013L19.6441 20.7388C19.8285 20.9189 20.059 21 20.2988 21C20.5385 21 20.769 20.9099 20.9534 20.7388C21.313 20.3875 21.313 19.82 20.9534 19.4687L17.6063 16.1988V16.2168ZM10.2112 11.5417L12.4703 13.7487C12.3135 13.7847 12.1568 13.8117 11.9908 13.8117C10.9765 13.8117 10.1466 13.001 10.1466 12.0101C10.1466 11.848 10.1651 11.6949 10.2112 11.5417ZM11.9908 17.4149C7.77686 17.4149 4.95528 13.7487 3.93177 12.1723C3.88566 12.1002 3.84878 12.0462 3.82112 12.0281C3.84878 11.9741 3.88566 11.9291 3.93177 11.848C4.45735 11.0373 5.50853 9.60503 7.02075 8.434L8.80959 10.1815C8.47764 10.731 8.30244 11.3616 8.30244 12.0101C8.30244 14.0009 9.95297 15.6133 11.9908 15.6133C12.6639 15.6133 13.3001 15.4421 13.8626 15.1179L15.3656 16.5861C14.2683 17.1356 13.1342 17.4149 11.9816 17.4149H11.9908Z" />
        <path d="M21.9493 11.6048C21.8755 11.3075 21.7556 11.1093 21.6081 10.8841C20.4278 9.05555 17.1268 4.80383 11.9908 4.80383C11.5574 4.80383 11.124 4.83085 10.6999 4.89391C10.1927 4.96597 9.85155 5.42537 9.92531 5.92081C9.99908 6.41624 10.4693 6.75854 10.9765 6.67747C11.3177 6.63243 11.6588 6.6054 12 6.6054C16.2139 6.6054 19.0355 10.2716 20.059 11.848C20.1051 11.9201 20.142 11.9741 20.1697 11.9921C20.142 12.0462 20.1051 12.0912 20.059 12.1723C19.8193 12.5506 19.4136 13.1271 18.8787 13.7577C18.556 14.136 18.6021 14.7035 18.9894 15.0278C19.1646 15.1719 19.3767 15.235 19.5795 15.235C19.8469 15.235 20.1051 15.1269 20.2895 14.9107C20.8981 14.2081 21.3499 13.5505 21.6173 13.1271C21.7649 12.9019 21.8847 12.7037 21.9585 12.4065C22.0138 12.1633 22.0138 11.848 21.9585 11.6048H21.9493Z" />
      </svg>
    );
  },
);

EyeOffIcon.displayName = 'EyeOffIcon';
