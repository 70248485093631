import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const StarFullIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="StarFull"
      >
        <path d="M16.8605 20.2562C16.5289 20.2562 16.2235 20.0991 15.7959 19.8723L12.0175 17.8914L8.23897 19.8723C7.73285 20.1428 7.40125 20.3173 6.97366 20.2388C6.61589 20.169 6.30174 19.9421 6.12721 19.6192C5.92651 19.2527 5.98759 18.8775 6.08358 18.3103L6.80786 14.1042L3.75367 11.1285C3.34353 10.7271 3.07302 10.4653 3.01193 10.0465C2.95957 9.67997 3.08174 9.31346 3.3348 9.04295C3.62277 8.73753 3.998 8.68517 4.56521 8.59791L8.78873 7.97834L10.6736 4.15623C10.9267 3.64138 11.0925 3.30106 11.4764 3.1178C11.808 2.96073 12.192 2.96073 12.5236 3.1178C12.9075 3.30106 13.0733 3.64138 13.3264 4.15623L15.2113 7.97834L19.4348 8.59791C20.002 8.68517 20.3772 8.73753 20.6652 9.04295C20.9183 9.31346 21.0404 9.67997 20.9881 10.0465C20.927 10.4653 20.6565 10.7271 20.2551 11.1285L17.2009 14.1042L17.9251 18.3103C18.0211 18.8775 18.0822 19.2527 17.8815 19.6192C17.707 19.9421 17.3928 20.169 17.0351 20.2388C16.9653 20.2475 16.9042 20.2562 16.8344 20.2562H16.8605Z" />
      </svg>
    );
  },
);

StarFullIcon.displayName = 'StarFullIcon';
