import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Status01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Status01"
      >
        <path d="M12 6C11.4478 6 11 5.55225 11 5V3C11 2.44775 11.4478 2 12 2C12.5522 2 13 2.44775 13 3V5C13 5.55225 12.5522 6 12 6Z" />
        <path d="M12 22C11.4478 22 11 21.5522 11 21V19C11 18.4478 11.4478 18 12 18C12.5522 18 13 18.4478 13 19V21C13 21.5522 12.5522 22 12 22Z" />
        <path d="M21 13H19C18.4478 13 18 12.5522 18 12C18 11.4478 18.4478 11 19 11H21C21.5522 11 22 11.4478 22 12C22 12.5522 21.5522 13 21 13Z" />
        <path d="M5 13H3C2.44775 13 2 12.5522 2 12C2 11.4478 2.44775 11 3 11H5C5.55225 11 6 11.4478 6 12C6 12.5522 5.55225 13 5 13Z" />
        <path d="M16.9497 8.05029C16.6938 8.05029 16.438 7.95264 16.2427 7.75732C15.8521 7.3667 15.8521 6.73389 16.2427 6.34326L17.6567 4.9292C18.0474 4.53857 18.6802 4.53857 19.0708 4.9292C19.4614 5.31982 19.4614 5.95264 19.0708 6.34326L17.6567 7.75732C17.4614 7.95264 17.2056 8.05029 16.9497 8.05029Z" />
        <path d="M5.63623 19.3638C5.38037 19.3638 5.12451 19.2661 4.9292 19.0708C4.53857 18.6802 4.53857 18.0474 4.9292 17.6567L6.34326 16.2427C6.73389 15.8521 7.3667 15.8521 7.75732 16.2427C8.14795 16.6333 8.14795 17.2661 7.75732 17.6567L6.34326 19.0708C6.14795 19.2661 5.89209 19.3638 5.63623 19.3638Z" />
        <path d="M18.3638 19.3638C18.1079 19.3638 17.8521 19.2661 17.6567 19.0708L16.2427 17.6567C15.8521 17.2661 15.8521 16.6333 16.2427 16.2427C16.6333 15.8521 17.2661 15.8521 17.6567 16.2427L19.0708 17.6567C19.4614 18.0474 19.4614 18.6802 19.0708 19.0708C18.8755 19.2661 18.6196 19.3638 18.3638 19.3638Z" />
        <path d="M7.05029 8.05029C6.79443 8.05029 6.53857 7.95264 6.34326 7.75732L4.9292 6.34326C4.53857 5.95264 4.53857 5.31982 4.9292 4.9292C5.31982 4.53857 5.95264 4.53857 6.34326 4.9292L7.75732 6.34326C8.14795 6.73389 8.14795 7.3667 7.75732 7.75732C7.56201 7.95264 7.30615 8.05029 7.05029 8.05029Z" />
      </svg>
    );
  },
);

Status01Icon.displayName = 'Status01Icon';
