import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

type TanstackReactQueryProviderProps = {
  children: React.ReactNode;
};

const queryClient = new QueryClient();

export const TanstackReactQueryProvider = ({
  children,
}: TanstackReactQueryProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
