import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavSearchIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavSearch"
      >
        <g clipPath="url(#clip0_1589_37893)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.4355 2.92742C6.73661 2.92742 2.92742 6.73661 2.92742 11.4355C2.92742 16.1344 6.73661 19.9435 11.4355 19.9435C13.7205 19.9435 15.7952 19.0427 17.3237 17.5769C17.358 17.5288 17.3968 17.483 17.44 17.4398C17.4831 17.3967 17.529 17.3579 17.577 17.3236C19.0428 15.7951 19.9435 13.7205 19.9435 11.4355C19.9435 6.73661 16.1344 2.92742 11.4355 2.92742ZM19.7221 18.1823C21.2217 16.3426 22.121 13.9941 22.121 11.4355C22.121 5.53405 17.3369 0.75 11.4355 0.75C5.53405 0.75 0.75 5.53405 0.75 11.4355C0.75 17.3369 5.53405 22.121 11.4355 22.121C13.9941 22.121 16.3427 21.2217 18.1824 19.722L21.3914 22.9311C21.8166 23.3563 22.5059 23.3563 22.9311 22.9311C23.3563 22.506 23.3563 21.8166 22.9311 21.3915L19.7221 18.1823Z"
          />
        </g>
        <defs>
          <clipPath id="clip0_1589_37893">
            <rect width="24" height="24" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

SideNavSearchIcon.displayName = 'SideNavSearchIcon';
