import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Contrast02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Contrast02"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM11.0909 6.09091C11.0909 5.58883 11.4979 5.18182 12 5.18182C15.7656 5.18182 18.8182 8.23442 18.8182 12C18.8182 15.7656 15.7656 18.8182 12 18.8182C11.4979 18.8182 11.0909 18.4112 11.0909 17.9091V6.09091ZM12.9091 7.08244V16.9176C15.2365 16.4901 17 14.451 17 12C17 9.54903 15.2365 7.50995 12.9091 7.08244Z"
        />
      </svg>
    );
  },
);

Contrast02Icon.displayName = 'Contrast02Icon';
