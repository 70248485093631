import { useEffect } from 'react';

import Sentry from 'services/sentry';

import { ICON_NAME_TO_COLOR_MAP } from '../iconNameToColorMap';
import { Icon } from '../index';
import { getIconName } from '../utils';

type ServiceResourceTypeIconProps = {
  service?: string;
  resourceType?: string;
  size?: number;
  outlineIcon?: boolean;
  className?: string;
  color?: string;
};

const DEFAULT_ICON_SIZE = 16;

export const ServiceResourceTypeIcon = ({
  service,
  resourceType,
  size,
  outlineIcon,
  className,
  color,
}: ServiceResourceTypeIconProps) => {
  const iconName = getIconName({ service, resourceType, outlineIcon });
  const defaultColor = ICON_NAME_TO_COLOR_MAP[iconName];

  useEffect(() => {
    if (!iconName) {
      Sentry.captureException(
        new Error(
          `Unrecognized icon - service ${service}, resourceType ${resourceType}, outlineIcon ${outlineIcon}`,
        ),
      );
    }
  }, [iconName]);

  if (!iconName) {
    return null;
  }

  return (
    <Icon
      size={size ?? DEFAULT_ICON_SIZE}
      name={iconName}
      className={className}
      color={color ?? defaultColor}
    />
  );
};
