import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as CloudfrontColorIcon } from './variant-svgs/cloudfront-color.svg';
import { ReactComponent as CloudfrontDisabledIcon } from './variant-svgs/cloudfront-disabled.svg';
import { ReactComponent as CloudfrontBlackIcon } from './variant-svgs/cloudfront-regular.svg';

const VARIANT_ICON_MAP = {
  default: CloudfrontColorIcon,
  black: CloudfrontBlackIcon,
  disabled: CloudfrontDisabledIcon,
};

export const CloudfrontIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="cloudfront-icon"
      />
    );
  },
);

CloudfrontIcon.displayName = 'CloudfrontIcon';
