import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavHelpIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavHelp"
      >
        <g clipPath="url(#clip0_908_522522)">
          <path d="M12 23.625C5.595 23.625 0.375 18.405 0.375 12C0.375 5.595 5.595 0.375 12 0.375C18.405 0.375 23.625 5.595 23.625 12C23.625 18.405 18.405 23.625 12 23.625ZM12 2.625C6.825 2.625 2.625 6.825 2.625 12C2.625 17.175 6.825 21.375 12 21.375C17.175 21.375 21.375 17.175 21.375 12C21.375 6.825 17.175 2.625 12 2.625ZM12.015 18.375C11.4 18.375 10.89 17.865 10.89 17.25C10.89 16.635 11.385 16.125 12.015 16.125C12.63 16.125 13.14 16.635 13.14 17.25C13.14 17.865 12.63 18.375 12.015 18.375ZM11.91 14.175C11.445 14.175 10.995 13.875 10.845 13.41C10.65 12.825 10.965 12.18 11.55 11.985C12.33 11.73 13.935 10.86 13.935 9.90001C13.935 9.42001 13.77 8.955 13.455 8.58C13.155 8.22 12.72 7.96499 12.255 7.88999C11.79 7.81499 11.295 7.88999 10.89 8.14499C10.485 8.38499 10.17 8.77499 10.005 9.22499C9.795 9.80999 9.165 10.125 8.565 9.915C7.98 9.705 7.665 9.05999 7.875 8.47499C8.205 7.52999 8.865 6.72 9.735 6.21C10.605 5.7 11.64 5.51999 12.63 5.68499C13.62 5.84999 14.52 6.37499 15.18 7.13999C15.825 7.91999 16.185 8.89501 16.185 9.90001C16.185 12.72 12.66 13.98 12.27 14.115C12.15 14.16 12.03 14.175 11.91 14.175Z" />
        </g>
        <defs>
          <clipPath id="clip0_908_522522">
            <rect width="24" height="24" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

SideNavHelpIcon.displayName = 'SideNavHelpIcon';
