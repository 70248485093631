import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Commitments02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Commitments02"
      >
        <path d="M14.57 3.8H9.42003C8.92003 3.8 8.52002 3.4 8.52002 2.9C8.52002 2.4 8.92003 2 9.42003 2H14.57C15.07 2 15.47 2.4 15.47 2.9C15.47 3.4 15.07 3.8 14.57 3.8Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.9208 21.2249L20.2929 22.597C20.6834 22.9875 21.3166 22.9875 21.7071 22.597C22.0976 22.2065 22.0976 21.5733 21.7071 21.1828L3.70711 3.18279C3.31658 2.79226 2.68342 2.79226 2.29289 3.18279C1.90237 3.57331 1.90237 4.20648 2.29289 4.597L4.77108 7.07519C4.72824 7.18725 4.69109 7.30227 4.65993 7.41999C4.53993 7.85997 4.53993 8.29996 4.53993 9.17989V17.08C4.53993 18.62 4.53994 19.46 4.91994 20.2C5.25994 20.86 5.77994 21.38 6.43994 21.72C7.17994 22.1 8.02992 22.1 9.56991 22.1H15.0699C16.6099 22.1 17.4499 22.1 18.1899 21.72C18.4574 21.5862 18.7019 21.4197 18.9208 21.2249ZM11.4399 16.94C11.1999 16.94 10.9699 16.85 10.7999 16.68H10.8199L9.09993 14.96C8.74993 14.61 8.74993 14.04 9.09993 13.69C9.44993 13.34 10.0199 13.34 10.3699 13.69L11.4499 14.77L11.9579 14.262L6.33143 8.63553C6.32994 8.78893 6.32994 8.96582 6.32994 9.16991V17.07C6.32994 18.3299 6.32994 19.02 6.50993 19.38C6.66993 19.69 6.91993 19.95 7.23993 20.11C7.59993 20.29 8.28993 20.29 9.54993 20.29H15.0499C16.3099 20.29 16.9999 20.29 17.3599 20.11C17.4559 20.0605 17.5471 20.0023 17.632 19.9361L13.2279 15.532L12.0799 16.68C11.9099 16.84 11.6799 16.94 11.4399 16.94Z"
        />
        <path d="M9.49994 6.63H15.7299C16.4299 6.63 16.8099 6.63 17.0199 6.68999C17.5999 6.83999 18.0499 7.29999 18.2099 7.87C18.2699 8.07999 18.2699 8.46997 18.2699 9.15993V15.39L20.0699 17.19V9.16C20.0699 8.28008 20.0699 7.83997 19.9499 7.39999C19.6299 6.19999 18.6899 5.25999 17.4899 4.93999C17.0499 4.81999 16.6099 4.81999 15.7299 4.81999H8.85994C8.36994 4.81999 8.01993 4.82 7.72993 4.84L9.50993 6.62L9.49994 6.63Z" />
        <path d="M15.9399 11.54C15.5899 11.19 15.0199 11.19 14.6699 11.54L14.5399 11.67L15.8099 12.94L15.9399 12.81C16.2899 12.46 16.2899 11.89 15.9399 11.54Z" />
      </svg>
    );
  },
);

Commitments02Icon.displayName = 'Commitments02Icon';
