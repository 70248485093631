import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Share01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Share01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.3636 3.2636C11.7151 2.91213 12.2849 2.91213 12.6364 3.2636L16.2364 6.8636C16.5879 7.21508 16.5879 7.78492 16.2364 8.1364C15.8849 8.48787 15.3151 8.48787 14.9636 8.1364L12.9 6.07279V14.7C12.9 15.1971 12.4971 15.6 12 15.6C11.5029 15.6 11.1 15.1971 11.1 14.7V6.07279L9.0364 8.1364C8.68493 8.48787 8.11508 8.48787 7.76361 8.1364C7.41213 7.78492 7.41213 7.21508 7.76361 6.8636L11.3636 3.2636ZM3.9 11.1C4.39706 11.1 4.8 11.5029 4.8 12V15.78C4.8 16.5509 4.8007 17.075 4.8338 17.4801C4.86604 17.8746 4.92447 18.0764 4.99619 18.2172C5.16876 18.5559 5.44413 18.8312 5.78282 19.0038C5.92358 19.0755 6.12536 19.134 6.51993 19.1662C6.92502 19.1993 7.44908 19.2 8.22 19.2H15.78C16.5509 19.2 17.075 19.1993 17.4801 19.1662C17.8746 19.134 18.0764 19.0755 18.2172 19.0038C18.5559 18.8312 18.8312 18.5559 19.0038 18.2172C19.0755 18.0764 19.134 17.8746 19.1662 17.4801C19.1993 17.075 19.2 16.5509 19.2 15.78V12C19.2 11.5029 19.6029 11.1 20.1 11.1C20.5971 11.1 21 11.5029 21 12V15.8172C21 16.5417 21 17.1396 20.9602 17.6266C20.9189 18.1325 20.8302 18.5976 20.6076 19.0344C20.2625 19.7117 19.7118 20.2625 19.0344 20.6076C18.5976 20.8302 18.1325 20.9189 17.6266 20.9602C17.1396 21 16.5417 21 15.8172 21H8.18281C7.45835 21 6.86043 21 6.37336 20.9602C5.86747 20.9189 5.40244 20.8302 4.96564 20.6076C4.28825 20.2625 3.73752 19.7117 3.39238 19.0344C3.16982 18.5976 3.08111 18.1325 3.03978 17.6266C2.99998 17.1396 2.99999 16.5416 3 15.8172L3 12C3 11.5029 3.40294 11.1 3.9 11.1Z"
        />
      </svg>
    );
  },
);

Share01Icon.displayName = 'Share01Icon';
