import { gql } from '@apollo/client';

export const CREATE_TICKET_MUTATION = gql`
  mutation CreateJiraTicketV3(
    $integrationId: UUID!
    $shareableId: UUID!
    $shareableType: ShareableType!
    $projectKey: String!
    $issueType: String!
    $title: String!
    $body: String!
    $reporterId: String
    $assigneeId: String
    $priority: JiraTicketPriority
    $type: TicketType!
    $customFields: [CustomField!]
  ) {
    createTicketV3(
      integrationId: $integrationId
      input: {
        shareableId: $shareableId
        shareableType: $shareableType
        projectKey: $projectKey
        issueType: $issueType
        title: $title
        body: $body
        reporterId: $reporterId
        assigneeId: $assigneeId
        priority: $priority
        type: $type
        customFields: $customFields
      }
    ) {
      success
      ticket {
        link
        id
        status
      }
    }
  }
`;
