import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Resources02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Resources02"
      >
        <path d="M8.0199 6.10993L12.2999 3.95993L19.2999 7.47993L12.7099 10.7999L14.0499 12.1399L21.7099 8.28993C22.0099 8.13993 22.2099 7.82993 22.2099 7.48993C22.2099 7.14993 22.0199 6.83993 21.7099 6.68993L13.0299 2.31993C12.8799 2.23993 12.7399 2.17993 12.5599 2.13993C12.3899 2.10993 12.2299 2.10993 12.0599 2.13993C11.8699 2.17993 11.7399 2.23993 11.5799 2.31993L6.67989 4.77993L8.0199 6.11993V6.10993Z" />
        <path d="M3.24251 6.50682L0.942796 4.20711C0.552271 3.81658 0.552271 3.18342 0.942796 2.79289C1.33332 2.40237 1.96648 2.40237 2.35701 2.79289L20.357 20.7929C20.7475 21.1834 20.7475 21.8166 20.357 22.2071C19.9665 22.5976 19.3333 22.5976 18.9428 22.2071L16.6257 19.89L13.0199 21.6999C12.9949 21.7116 12.9702 21.7235 12.9455 21.7355C12.8218 21.7952 12.6982 21.8549 12.5399 21.8799C12.4599 21.8999 12.3899 21.8999 12.2999 21.8999C12.2099 21.8999 12.1299 21.8899 12.0399 21.8699C11.8599 21.8399 11.7199 21.7699 11.5699 21.6899L2.88989 17.3299C2.44989 17.0999 2.26989 16.5599 2.48989 16.1199C2.71989 15.6799 3.25988 15.4999 3.69988 15.7199L12.2999 20.0399L15.2793 18.5436L13.6161 16.8805L13.0399 17.1699L13.0037 17.1869C12.867 17.251 12.7345 17.3132 12.5599 17.3499C12.4799 17.3699 12.3899 17.3699 12.3099 17.3699C12.2299 17.3699 12.1399 17.3699 12.0599 17.3499C11.8699 17.3199 11.7299 17.2499 11.5799 17.1699L2.89988 12.8099C2.45988 12.5799 2.27989 12.0399 2.49989 11.5999H2.50988C2.73988 11.1599 3.27989 10.9799 3.71989 11.1999L12.1907 15.4551L7.1608 10.4251L2.89989 8.27993C2.58989 8.12993 2.39989 7.81993 2.39989 7.47993C2.39989 7.13993 2.59989 6.82993 2.89989 6.67993L3.24251 6.50682Z" />
        <path d="M20.8999 11.1999L15.7199 13.8099L17.0599 15.1499L21.7099 12.8099C22.1499 12.5899 22.3299 12.0499 22.1099 11.5999C21.8899 11.1599 21.3399 10.9799 20.8999 11.1999Z" />
        <path d="M18.7299 16.8099L20.8999 15.7199C21.3399 15.4999 21.8899 15.6799 22.1099 16.1199C22.3299 16.5699 22.1499 17.1099 21.7099 17.3299L20.0699 18.1499L18.7299 16.8099Z" />
      </svg>
    );
  },
);

Resources02Icon.displayName = 'Resources02Icon';
