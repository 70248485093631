import {
  SUPPORTED_SERVICES,
  SUPPORTED_TYPES,
} from 'domains/resource/resourceDetails/constants';

import {
  GENERIC_GRAPH_ICON_MAP,
  SERVICE_RESOURCE_TYPE_GRAPH_ICONS,
  SERVICE_TO_ICON_NAME_MAP,
  serviceToOutlineIconNameMap,
} from './constants';

type IconNameArgs = {
  service?: string;
  resourceType?: string;

  // TODO: remove if not needed
  outlineIcon?: boolean;
};

export const getIconName = ({
  service,
  resourceType,
  outlineIcon,
}: IconNameArgs) => {
  const normalizedServiceName = Object.values(SUPPORTED_SERVICES).find(
    (supportedService) =>
      supportedService.toLowerCase() === service?.toLowerCase(),
  );

  if (outlineIcon) {
    return serviceToOutlineIconNameMap[
      normalizedServiceName as keyof typeof serviceToOutlineIconNameMap
    ];
  }

  const genericIcon =
    GENERIC_GRAPH_ICON_MAP[
      resourceType?.toLowerCase() as keyof typeof GENERIC_GRAPH_ICON_MAP
    ];

  if (genericIcon) {
    return genericIcon;
  }

  const graphIcon =
    SERVICE_RESOURCE_TYPE_GRAPH_ICONS[
      normalizedServiceName as keyof typeof SERVICE_RESOURCE_TYPE_GRAPH_ICONS
    ]?.[resourceType as keyof typeof SUPPORTED_TYPES];

  if (graphIcon) {
    return graphIcon;
  }

  return SERVICE_TO_ICON_NAME_MAP[
    normalizedServiceName as keyof typeof SERVICE_TO_ICON_NAME_MAP
  ];
};
