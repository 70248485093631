import { gql } from '@apollo/client';

import { ACCOUNTS_LIMIT } from 'domains/account/constants';

export const GET_DISTINCT_OWNERS_VALUES = gql`
  query getDistinctOwnersValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      owners(distinct: ALL)
    }
  }
`;

export const GET_DISTINCT_RISK_VALUES = gql`
  query getDistinctRiskValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      risk
    }
  }
`;

export const GET_DISTINCT_EFFORT_VALUES = gql`
  query getDistinctEffortValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      effort
    }
  }
`;

export const GET_DISTINCT_PROJECTS_VALUES = gql`
  query getDistinctProjectsValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: ${ACCOUNTS_LIMIT}) {
      _id
      projects {
        id
        name
        provider
      }
    }
  }
`;

export const GET_DISTINCT_MANAGEMENT_ACCOUNTS_VALUES = gql`
  query getDistinctManagementAccountsValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      managementAccounts {
        id
        name
      }
    }
  }
`;

export const GET_DISTINCT_OPPORTUNITY_TYPE_VALUES = gql`
  query getDistinctOpportunityTypeValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      opportunityType
    }
  }
`;

export const GET_DISTINCT_SERVICE_VALUES = gql`
  query getDistinctServiceValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      service
    }
  }
`;

export const GET_DISTINCT_ASSIGNED_USER_VALUES = gql`
  query getDistinctAssignedUserValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      assignedUser {
        email
      }
    }
  }
`;

export const GET_DISTINCT_RESOURCE_TYPE_NAME_VALUES = gql`
  query getDistinctResourceTypeNameValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      resourceTypeName
    }
  }
`;

export const GET_DISTINCT_SAVINGS_VALUES = gql`
  query getDistinctSavingsValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      savings {
        max
      }
    }
  }
`;

export const GET_DISTINCT_TAG_KEYS_VALUES = gql`
  query getDistinctTagKeysValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      tagKeys
    }
  }
`;

export const GET_DISTINCT_TEAMS_VALUES = gql`
  query getDistinctTeamsValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      teams {
        id
        name
      }
    }
  }
`;

export const GET_DISTINCT_PROVIDER_VALUES = gql`
  query getDistinctProviderValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      resourceProviders
    }
  }
`;

export const GET_DISTINCT_ACTIVITY_VALUES = gql`
  query getDistinctActivityValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      activityLogType
    }
  }
`;

export const GET_DISTINCT_IS_STILL_DETECTED_VALUES = gql`
  query getDistinctIsStillDetectedValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      isStillDetected
    }
  }
`;

export const GET_DISTINCT_CLOSED_BY_VALUES = gql`
  query getDistinctClosedByValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      state {
        changedByType
        changedByUser {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_DISTINCT_CLOSING_REASON_VALUES = gql`
  query getDistinctClosingReasonValues($where: OpportunitiesFilterInput) {
    opportunityFilters(where: $where, offset: 0, limit: 0) {
      _id
      stateChangeReason
    }
  }
`;
