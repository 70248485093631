import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  MouseEvent,
  ReactNode,
  RefObject,
  forwardRef,
  useEffect,
  useMemo,
} from 'react';

import { randomId } from 'utils/strings';

import { Icon } from './Icon';

type InputOptions = {
  startIconName?: string;
  endIconName?: string;
  endIconOnClick?: () => void;
  label?: ReactNode;
  helperText?: ReactNode;
  disabled?: boolean;
  errorMessage?: string;
  success?: boolean;
  onClick?: (e: MouseEvent<HTMLInputElement, MouseEvent>) => void;
  inputClassName?: string;
  paddingY?: string; // TODO: align all platform to support 36px height
  height?: string; // TODO: align all platform to support 36px height
  // manualAutoFocus solves the following problem:
  // regular autoFocus prop won't work 100% of the time.
  // If you render a React component into a detached element(e.g adding it to the DOM later),
  // React will call focus() too soon.
  // This will result in the input not focusing when your React tree gets added to the DOM.
  manualAutoFocus?: boolean;
};

type HtmlInputProps = Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'children' | 'onClick'
>;

type InputOptionsWithoutChildren = Omit<InputOptions, 'children' | 'onClick'>;

export type InputProps = HtmlInputProps & InputOptionsWithoutChildren;

// states
const disabledClasses =
  'text-text-tertiary pointer-events-none cursor-not-allowed select-none border-gray-200 bg-gray-50';
const successClasses = 'border-green-500 bg-white focus:border-green-500';
const errorClasses = 'border-red-500 bg-white focus:border-red-500';

const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  // TODO: this is a bad pattern, prop change will not always trigger re-render
  const {
    startIconName,
    endIconName,
    success,
    disabled,
    label,
    type = 'text',
    errorMessage,
    helperText,
    className = '',
    endIconOnClick,
    inputClassName = '',
    manualAutoFocus,
    paddingY = '',
    height = 'h-9',
    ...rest
  } = props;

  // TODO: remove "children",present error message somewhere? revision colors. wrap helper text instead of exceeding.

  const id = randomId();

  useEffect(() => {
    // TODO: fix type assertion
    const typedRef = ref as unknown as RefObject<HTMLInputElement>;

    if (manualAutoFocus && typedRef?.current) {
      typedRef?.current?.focus?.();
    }
  }, [manualAutoFocus, ref]);

  const stateClassname = useMemo(() => {
    if (disabled) {
      return disabledClasses;
    }
    if (success) {
      return successClasses;
    }
    if (errorMessage) {
      return errorClasses;
    }
    return 'hover:border-primary-200 focus:border-primary-600 active:border-primary-600 border-gray-200';
  }, [props.disabled, props.success, props.errorMessage]);

  const labelTextClassname = useMemo(() => {
    if (errorMessage) {
      return 'text-rose-600';
    }
    return '';
  }, [label, errorMessage]);

  const helperTextClassname = useMemo(() => {
    if (errorMessage) {
      return 'text-rose-600';
    }
    return 'text-text-tertiary';
  }, [helperText, errorMessage]);

  const leftPadding = useMemo(() => {
    if (startIconName) {
      return 'pl-9';
    }
    return 'pl-4';
  }, [startIconName]);

  const rightPadding = useMemo(() => {
    if (endIconName) {
      return 'pr-9';
    }
    return 'pr-4';
  }, [endIconName]);

  return (
    <fieldset
      className={`${className} text-body-2 flex flex-col items-start gap-1 text-text-primary`}
    >
      {label && (
        <label
          className={`${labelTextClassname} text-overline w-full`}
          htmlFor={id}
        >
          {label}
        </label>
      )}
      <div className="relative w-full">
        {startIconName && (
          <Icon
            name={startIconName}
            size={16}
            className="absolute bottom-0 left-0 top-0 m-auto ml-3 text-gray-600"
          />
        )}

        <input
          className={`${inputClassName} ${stateClassname} ${leftPadding} ${rightPadding} ${paddingY} ${height} inline-flex w-full min-w-[80px] flex-1 items-center justify-center rounded border leading-none outline-none focus:ring-0`}
          id={id}
          data-cy={`input-${type}`}
          ref={ref}
          type={type}
          disabled={disabled}
          autoFocus={props.autoFocus}
          {...rest}
        />
        {endIconName && (
          <>
            <div className="absolute bottom-0 right-0 top-0 m-auto mr-9 h-5 w-[1px] bg-gray-200">
              {' '}
            </div>
            <Icon
              name={endIconName}
              data-cy="clear-search-icon"
              size={16}
              className={`absolute bottom-0 right-0 top-0 m-auto mr-3 ${
                endIconOnClick ? 'cursor-pointer' : ''
              }`}
              onClick={endIconOnClick}
            />
          </>
        )}
      </div>
      {(helperText || errorMessage) && (
        <span data-cy="input-error-message" className={helperTextClassname}>
          {errorMessage || helperText}
        </span>
      )}
    </fieldset>
  );
});

Input.displayName = 'Input';
export default Input;
