import { useEffect } from 'react';

import LogRocket from 'logrocket';

import { IS_PROD } from 'constants/env';
import { useAuth } from 'services/auth';

const ANONYMOUS = 'Anonymous';

export const LogRocketIdentify = () => {
  const user = useAuth.use.user();

  useEffect(() => {
    if (!user || !IS_PROD) {
      return;
    }

    const { email, firstName } = user || {};
    const isSu = user?.isSuperUser;
    const suPrefix = isSu ? 'su-' : '';
    const suSuffix = isSu ? ` (SU)` : '';
    const name = firstName ?? ANONYMOUS;
    const validEmail = email ?? ANONYMOUS;

    LogRocket.identify(suPrefix + validEmail, {
      name: name + suSuffix,
      email: validEmail,
      su: !!user?.isSuperUser,
      oid: user?.originalUserId ?? '',
    });
  }, [user]);

  return null;
};
