import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const CheckCircleIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="CheckCircle"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 3.81818C7.48131 3.81818 3.81818 7.48131 3.81818 12C3.81818 16.5187 7.48131 20.1818 12 20.1818C16.5187 20.1818 20.1818 16.5187 20.1818 12C20.1818 7.48131 16.5187 3.81818 12 3.81818ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM16.7337 8.6299C17.0888 8.98493 17.0888 9.56053 16.7337 9.91555L11.2792 15.3701C10.9242 15.7251 10.3486 15.7251 9.99354 15.3701L7.26627 12.6428C6.91124 12.2878 6.91124 11.7122 7.26627 11.3572C7.62129 11.0022 8.19689 11.0022 8.55192 11.3572L10.6364 13.4416L15.4481 8.6299C15.8031 8.27488 16.3787 8.27488 16.7337 8.6299Z"
        />
      </svg>
    );
  },
);

CheckCircleIcon.displayName = 'CheckCircleIcon';
