import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Edit02Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Edit02"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.7929 2.12132C17.288 0.626226 19.712 0.626228 21.2071 2.12132C22.7022 3.61642 22.7022 6.04044 21.2071 7.53554L18.2098 10.5329L18.2071 10.5355L18.2045 10.5382L8.00084 20.7418C7.98408 20.7586 7.96747 20.7752 7.95099 20.7917C7.70609 21.037 7.49007 21.2534 7.2334 21.4226C7.00774 21.5714 6.76301 21.6891 6.50584 21.7723C6.21333 21.867 5.90942 21.9005 5.56489 21.9385C5.54171 21.9411 5.51836 21.9437 5.49481 21.9463L2.11044 22.3223C1.80851 22.3559 1.50771 22.2503 1.2929 22.0355C1.07809 21.8207 0.972576 21.5199 1.00612 21.218L1.38216 17.8336C1.38478 17.8101 1.38736 17.7867 1.38992 17.7635C1.42792 17.419 1.46144 17.1151 1.55613 16.8226C1.63938 16.5654 1.75701 16.3207 1.90582 16.095C2.07507 15.8384 2.29144 15.6223 2.53673 15.3774C2.55323 15.361 2.56987 15.3444 2.58663 15.3276L15.7929 2.12132ZM13.5 7.24264L4.00084 16.7418C3.67834 17.0643 3.61846 17.1309 3.57547 17.1961C3.52587 17.2713 3.48666 17.3529 3.45891 17.4386C3.43486 17.5129 3.4203 17.6012 3.36993 18.0545L3.13193 20.1965L5.27394 19.9585C5.72724 19.9081 5.81556 19.8936 5.88986 19.8695C5.97559 19.8418 6.05717 19.8026 6.13238 19.753C6.19758 19.71 6.26412 19.6501 6.58663 19.3276L16.0858 9.82842L13.5 7.24264ZM17.5 8.41421L14.9142 5.82843L17.2071 3.53553C17.9212 2.82149 19.0789 2.82149 19.7929 3.53553C20.507 4.24958 20.507 5.40728 19.7929 6.12132L17.5 8.41421Z"
        />
      </svg>
    );
  },
);

Edit02Icon.displayName = 'Edit02Icon';
