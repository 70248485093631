import { ReactNode, useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

export interface SideNavButtonProps {
  id?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  selected?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
  showBorder?: boolean;
  dataCy: string;
}

export const SideNavButton = ({
  id,
  disabled,
  selected,
  icon: ButtonIcon,
  onClick,
  className,
  label,
  showBorder = true,
  dataCy,
}: SideNavButtonProps) => {
  const classes = useMemo(() => {
    let result = '';

    const disabledClasses = 'cursor-default text-disabled';
    const normalClasses = selected
      ? 'text-ds-navigation-icon-active'
      : 'text-neutral-400';

    result += disabled ? disabledClasses : normalClasses;

    return result;
  }, [disabled, selected]);

  return (
    <button
      id={id}
      className={`${className} ${classes} group/button text-body-1 relative flex w-full items-center justify-start gap-2 border-none px-4 py-2 font-semibold ring-0 transition-[background-color] duration-300 focus:outline-none ${selected ? 'bg-ds-navigation-side-bg-selected' : 'hover:bg-ds-navigation-side-bg-hover'}`}
      onClick={onClick}
      data-cy={dataCy}
    >
      <div
        className={twMerge(
          'absolute left-0 h-full w-[2px] bg-ds-navigation-icon-active transition-[transform] delay-100 duration-300 ease-in-out',
          showBorder && selected ? 'scale-y-100' : 'scale-y-0',
        )}
      />
      <div>{ButtonIcon}</div>
      <div
        className={`truncate text-clip text-sm leading-6 ${
          selected ? 'text-ds-text-primary' : 'text-ds-text-tertiary'
        } group-hover/button:text-ds-text-primary`}
      >
        {label}
      </div>
    </button>
  );
};
