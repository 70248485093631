import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as PointfiveColorIcon } from './variant-svgs/pointfive-color.svg';
import { ReactComponent as PointfiveDisabledIcon } from './variant-svgs/pointfive-disabled.svg';
import { ReactComponent as PointfiveBlackIcon } from './variant-svgs/pointfive-regular.svg';

const VARIANT_ICON_MAP = {
  default: PointfiveColorIcon,
  black: PointfiveBlackIcon,
  disabled: PointfiveDisabledIcon,
};

export const PointfiveIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="pointfive-icon"
      />
    );
  },
);

PointfiveIcon.displayName = 'PointfiveIcon';
