import { graphql } from '../generated';

export const CLOUD_INTELLIGENCE_DASHBOARDS = graphql(`
  query cloudIntelligenceDashboards(
    $where: CloudIntelligenceDashboardsFilterInput
  ) {
    cloudIntelligenceDashboards(where: $where) {
      id
      presetDashboardId
      embeddedId
      name
      updatedAt
      isSystem
      createdBy
      workspaceSlug
      workspaceRegion
      isFavorite
      providers
      permissions {
        everyoneCanView
        hasViewPermission
        usersViewPermissions {
          id
          name
        }
        teamsViewPermissions {
          id
          name
        }
      }
    }
  }
`);

export const CLOUD_INTELLIGENCE_DASHBOARDS_CREATED_BY_FILTER = graphql(`
  query cloudIntelligenceDashboardsCreatedByFilter(
    $where: CloudIntelligenceDashboardsFilterInput
  ) {
    cloudIntelligenceDashboardsFilters(where: $where) {
      createdBy {
        id
        fullName
      }
    }
  }
`);

export const CLOUD_INTELLIGENCE_DASHBOARDS_PROVIDER_FILTER = graphql(`
  query cloudIntelligenceDashboardsProvidersFilter(
    $where: CloudIntelligenceDashboardsFilterInput
  ) {
    cloudIntelligenceDashboardsFilters(where: $where) {
      providers
    }
  }
`);

export const CLOUD_INTELLIGENCE_DASHBOARDS_USER_PERMISSIONS_FILTER = graphql(`
  query cloudIntelligenceDashboardsUserPermissionsFilter(
    $where: CloudIntelligenceDashboardsFilterInput
  ) {
    cloudIntelligenceDashboardsFilters(where: $where) {
      usersPermissions {
        id
        fullName
      }
    }
  }
`);

export const CLOUD_INTELLIGENCE_DASHBOARD_COUNT = graphql(`
  query cloudIntelligenceDashboardCount {
    cloudIntelligenceDashboardCount
  }
`);

export const CLOUD_INTELLIGENCE_DASHBOARD_TOKEN = graphql(`
  query cloudIntelligenceDashboardToken {
    cloudIntelligenceDashboardTokens {
      id
      tokens
    }
  }
`);

export const CLOUD_INTELLIGENCE_MY_PERMISSIONS = graphql(`
  query cloudIntelligenceMyPermissions {
    cloudIntelligenceMyPermissions {
      isCloudIntelligenceCreator
    }
  }
`);

export const CREATE_CLOUD_INTELLIGENCE_DASHBOARD = graphql(`
  mutation createCloudIntelligenceDashboard(
    $name: String!
    $providers: [CloudIntelligenceProvider!]!
  ) {
    createCloudIntelligenceDashboard(name: $name, providers: $providers) {
      id
      presetDashboardId
      embeddedId
      name
      updatedAt
      isSystem
      createdBy
      providers
      workspaceSlug
      workspaceRegion
      isFavorite
      permissions {
        everyoneCanView
        hasViewPermission
        usersViewPermissions {
          id
          name
        }
        teamsViewPermissions {
          id
          name
        }
      }
    }
  }
`);

export const UPDATE_CLOUD_INTELLIGENCE_DASHBOARD = graphql(`
  mutation updateCloudIntelligenceDashboard(
    $input: UpdateCloudIntelligenceDashboardInput!
  ) {
    updateCloudIntelligenceDashboard(input: $input) {
      id
      presetDashboardId
      embeddedId
      name
      updatedAt
      isSystem
      createdBy
      providers
      workspaceSlug
      workspaceRegion
      isFavorite
      permissions {
        everyoneCanView
        hasViewPermission
        usersViewPermissions {
          id
          name
        }
        teamsViewPermissions {
          id
          name
        }
      }
    }
  }
`);

export const DELETE_CLOUD_INTELLIGENCE_DASHBOARD = graphql(`
  mutation deleteCloudIntelligenceDashboard($id: UUID!) {
    deleteCloudIntelligenceDashboard(id: $id) {
      success
    }
  }
`);

export const CLOUD_INTELLIGENCE_WORKSPACE_ENABLE = graphql(`
  query cloudIntelligenceWorkspaceEnable {
    cloudIntelligenceState {
      enabled
    }
  }
`);

export const UPDATE_CLOUD_INTELLIGENCE_CREATORS = graphql(`
  mutation UpdateCloudIntelligenceCreators(
    $usersToAdd: [UUID!]
    $usersToRemove: [UUID!]
  ) {
    updateCloudIntelligenceCreators(
      usersToAdd: $usersToAdd
      usersToRemove: $usersToRemove
    ) {
      success
    }
  }
`);

export const GET_TEAM_NAME_LIST = graphql(`
  query getTeamList {
    listTeams {
      id
      name
      childTeams {
        id
        name
      }
    }
  }
`);

export const GET_MEMBERS_LIST = graphql(`
  query getMembersList($where: UsersFilterInput, $limit: Int, $offset: Int) {
    users(where: $where, limit: $limit, offset: $offset) {
      id
      fullName
      role
      cloudIntelligenceCreator
    }
  }
`);

export const UPDATE_CLOUD_INTELLIGENCE_PERMISSIONS = graphql(`
  mutation updateCloudIntelligencePermissions(
    $input: UpdateCloudIntelligencePermissionsInput!
  ) {
    updateCloudIntelligencePermissions(input: $input) {
      success
      __typename
    }
  }
`);

export const DELETE_CLOUD_INTELLIGENCE_PERMISSIONS = graphql(`
  mutation deleteCloudIntelligencePermissions(
    $input: DeleteCloudIntelligencePermissionsInput!
  ) {
    deleteCloudIntelligencePermissions(input: $input) {
      success
      __typename
    }
  }
`);

export const CLOUD_INTELLIGENCE_CREATORS = graphql(`
  query cloudIntelligenceCreators {
    cloudIntelligenceCreators {
      creators {
        id
        name
      }
      usersQuota
    }
  }
`);

export const UPDATE_CLOUD_INTELLIGENCE_FAVORITE = graphql(`
  mutation updateCloudIntelligenceUserIsFavorite(
    $input: CloudIntelligenceUserIsFavoriteInput!
  ) {
    updateCloudIntelligenceUserIsFavorite(input: $input) {
      success
    }
  }
`);

export const CLOUD_INTELLIGENCE_DASHBOARD_EXPORT = graphql(`
  query CloudIntelligenceDashboardExport($id: UUID!) {
    cloudIntelligenceDashboardExport(id: $id)
  }
`);

export const DUPLICATE_CLOUD_INTELLIGENCE_DASHBOARD = graphql(`
  mutation duplicateCloudIntelligenceDashboard(
    $input: DuplicateCloudIntelligenceDashboardInput!
  ) {
    duplicateCloudIntelligenceDashboard(input: $input) {
      success
    }
  }
`);
