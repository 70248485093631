import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as DisksColorIcon } from './variant-svgs/disks-color.svg';
import { ReactComponent as DisksDisabledIcon } from './variant-svgs/disks-disabled.svg';
import { ReactComponent as DisksBlackIcon } from './variant-svgs/disks-regular.svg';

const VARIANT_ICON_MAP = {
  default: DisksColorIcon,
  black: DisksBlackIcon,
  disabled: DisksDisabledIcon,
};

export const DisksIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="disks-icon"
      />
    );
  },
);

DisksIcon.displayName = 'DisksIcon';
