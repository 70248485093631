import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const HighRiskEffortIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="HighRiskEffort"
      >
        <path d="M1.5 11.55C1.5 10.9701 1.9701 10.5 2.55 10.5H5.55C6.1299 10.5 6.6 10.9701 6.6 11.55V14.55C6.6 15.1299 6.1299 15.6 5.55 15.6H2.55C1.9701 15.6 1.5 15.1299 1.5 14.55V11.55Z" />
        <path d="M9.3 11.55C9.3 10.9701 9.7701 10.5 10.35 10.5H13.35C13.9299 10.5 14.4 10.9701 14.4 11.55V14.55C14.4 15.1299 13.9299 15.6 13.35 15.6H10.35C9.7701 15.6 9.3 15.1299 9.3 14.55V11.55Z" />
        <path d="M17.1 11.55C17.1 10.9701 17.5701 10.5 18.15 10.5H21.15C21.7299 10.5 22.2 10.9701 22.2 11.55V14.55C22.2 15.1299 21.7299 15.6 21.15 15.6H18.15C17.5701 15.6 17.1 15.1299 17.1 14.55V11.55Z" />
      </svg>
    );
  },
);

HighRiskEffortIcon.displayName = 'HighRiskEffortIcon';
