import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const LinkExternal01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="LinkExternal01"
      >
        <path d="M13.96 21H8.27C6.67 21 5.79 21 5 20.6C4.31 20.25 3.75 19.69 3.4 19C3 18.22 3 17.33 3 15.74V10.05C3 8.45001 3 7.57001 3.4 6.79001C3.75 6.09001 4.31 5.54001 5 5.19001C5.78 4.79001 6.67 4.79001 8.26 4.79001H10.22C10.77 4.79001 11.22 5.24001 11.22 5.79001C11.22 6.34001 10.77 6.79001 10.22 6.79001H8.26C6.97 6.79001 6.26 6.79001 5.9 6.97001C5.58 7.13001 5.33 7.38001 5.17 7.70001C4.99 8.06001 4.99 8.77001 4.99 10.06V15.75C4.99 17.04 4.99 17.75 5.17 18.11C5.33 18.43 5.58 18.68 5.9 18.84C6.26 19.02 6.97 19.02 8.26 19.02H13.95C15.24 19.02 15.95 19.02 16.31 18.84C16.63 18.68 16.88 18.43 17.04 18.11C17.22 17.75 17.22 17.04 17.22 15.75V13.79C17.22 13.24 17.67 12.79 18.22 12.79C18.77 12.79 19.22 13.24 19.22 13.79V15.75C19.22 17.35 19.22 18.23 18.82 19.01C18.47 19.7 17.91 20.26 17.22 20.61C16.43 21.01 15.55 21.01 13.95 21.01L13.96 21ZM12.89 12.11C12.63 12.11 12.38 12.01 12.18 11.82C11.79 11.43 11.79 10.8 12.18 10.41L17.58 5.01001H14.66C14.11 5.01001 13.66 4.56001 13.66 4.01001C13.66 3.46001 14.11 3.01001 14.66 3.01001H19.99C20.12 3.01001 20.25 3.04001 20.37 3.08001C20.49 3.13001 20.6 3.20001 20.69 3.29001C20.78 3.38001 20.85 3.49001 20.9 3.60001C20.95 3.72001 20.98 3.85001 20.98 3.98001V9.31001C20.98 9.86001 20.53 10.31 19.98 10.31C19.43 10.31 18.98 9.86001 18.98 9.31001V6.39001L13.58 11.79C13.38 11.99 13.13 12.08 12.87 12.08L12.89 12.11Z" />
      </svg>
    );
  },
);

LinkExternal01Icon.displayName = 'LinkExternal01Icon';
