import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const ExpertInsightsIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="ExpertInsights"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.02287 21.0717C7.02287 20.5498 7.44594 20.1267 7.96782 20.1267H16.0314C16.5533 20.1267 16.9764 20.5498 16.9764 21.0717C16.9764 21.5935 16.5533 22.0166 16.0314 22.0166H7.96782C7.44594 22.0166 7.02287 21.5935 7.02287 21.0717Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4656 4.91928C14.4066 4.21186 13.143 3.84547 11.8541 3.87522C8.52176 3.95 5.87388 6.64762 5.88899 9.84049L5.889 9.84179C5.89198 10.7305 6.10257 11.6079 6.50597 12.4077C6.90942 13.2076 7.49569 13.9099 8.22216 14.46L8.2269 14.4636C8.59237 14.744 8.89042 15.1025 9.09717 15.5139C9.30398 15.9254 9.41363 16.3782 9.41687 16.8387L9.41691 16.8453L9.41689 17.1029H14.5828L14.5828 16.8434C14.5837 16.3783 14.6939 15.9205 14.9037 15.5053C15.1128 15.0914 15.415 14.7317 15.7852 14.4522C16.7928 13.6809 17.5218 12.6237 17.8738 11.4329C18.2259 10.2415 18.184 8.97271 17.7537 7.80518C17.3232 6.6373 16.5243 5.62647 15.4656 4.91928ZM11.8111 1.98581C13.4848 1.9473 15.1306 2.42269 16.5154 3.34776C17.9008 4.27317 18.9561 5.60287 19.5269 7.15152C20.0979 8.70051 20.1538 10.3864 19.6862 11.9686C19.2186 13.5505 18.2529 14.9444 16.9308 15.9554L16.9251 15.9597C16.7819 16.0675 16.6681 16.2041 16.5904 16.3577C16.513 16.511 16.4731 16.6781 16.4727 16.8466V17.3607C16.4727 17.8017 16.2925 18.2195 15.9803 18.5235C15.669 18.8267 15.2519 18.9928 14.8221 18.9928H9.17752C8.74772 18.9928 8.33065 18.8267 8.01931 18.5235C7.70713 18.2195 7.52699 17.8017 7.52699 17.3607V16.8491C7.52538 16.6814 7.4853 16.5153 7.40855 16.3626C7.33169 16.2097 7.2195 16.0732 7.0785 15.9646C6.12624 15.2428 5.35282 14.318 4.81855 13.2587C4.28371 12.1983 4.00308 11.0319 3.9991 9.84814L4.94405 9.84496L3.99911 9.84944L3.9991 9.84814C3.97965 5.58623 7.48855 2.08313 11.8111 1.98581Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9366 6.83439C12.3541 7.14752 12.4387 7.73982 12.1256 8.15732L11.3696 9.16527H13.4695C14.6808 9.16527 15.3722 10.5481 14.6455 11.5171L13.3855 13.1971C13.0724 13.6146 12.4801 13.6992 12.0626 13.3861C11.6451 13.0729 11.5605 12.4806 11.8736 12.0631L12.6296 11.0552H10.5297C9.3184 11.0552 8.62698 9.67232 9.35375 8.70329L10.6137 7.02338C10.9268 6.60588 11.5191 6.52126 11.9366 6.83439Z"
        />
      </svg>
    );
  },
);

ExpertInsightsIcon.displayName = 'ExpertInsightsIcon';
