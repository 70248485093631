/* eslint-disable @typescript-eslint/naming-convention */
export const ICON_SIZE_OPTIONS = {
  xs: 12,
  sm: 16,
  md: 20,
  lg: 24,
  xl: 28,
  '2xl': 32,
  '3xl': 36,
  '4xl': 40,
  '5xl': 52,
  '6xl': 64,
};

export const ICON_COLOR_OPTIONS = {
  default: '#525866',
  active: '#525866',
  disabled: '#a0a0ab',
  primary: '#667085',
  secondary: '#667085',
  green: '#052e1c',
  yellow: '#542c0d',
  white: '#ffffff',
  'inline-add-on': '#475467',
  hover: '#9494a8',
  tertiary: '#9494a8',
  black: '#1f242f',
  'primary-on-dark': '#ffffff',
  'secondary-on-dark': '#9494a8',
  'error-primary': '#d92d20',
  'warning-primary': '#dc6803',
  'success-primary': '#059669',
  'info-primary': '#155eef',
  'brand-primary': '#0018ed',
  'brand-primary-invert': '#ffffff',
  favorite: '#fac515',
  'border-focus': '#a8b8f7',
  disable: '#a0a0ab',
};
