import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SlashCircle01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SlashCircle01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.54155 6.70913C4.36031 8.14937 3.65138 9.99183 3.65138 11.9999C3.65138 16.6107 7.38918 20.3485 12 20.3485C14.008 20.3485 15.8505 19.6396 17.2907 18.4583L5.54155 6.70913ZM6.70925 5.54143L18.4584 17.2906C19.6397 15.8504 20.3486 14.0079 20.3486 11.9999C20.3486 7.38906 16.6108 3.65125 12 3.65125C9.99196 3.65125 8.14949 4.36019 6.70925 5.54143ZM2 11.9999C2 6.47703 6.47715 1.99988 12 1.99988C17.5228 1.99988 22 6.47703 22 11.9999C22 17.5227 17.5228 21.9999 12 21.9999C6.47715 21.9999 2 17.5227 2 11.9999Z"
        />
      </svg>
    );
  },
);

SlashCircle01Icon.displayName = 'SlashCircle01Icon';
