import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as ClusterColorIcon } from './variant-svgs/cluster-color.svg';
import { ReactComponent as ClusterDisabledIcon } from './variant-svgs/cluster-disabled.svg';
import { ReactComponent as ClusterBlackIcon } from './variant-svgs/cluster-regular.svg';

const VARIANT_ICON_MAP = {
  default: ClusterColorIcon,
  black: ClusterBlackIcon,
  disabled: ClusterDisabledIcon,
};

export const ClusterIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="cluster-icon"
      />
    );
  },
);

ClusterIcon.displayName = 'ClusterIcon';
