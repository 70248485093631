import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as GoogleColorIcon } from './variant-svgs/google-color.svg';
import { ReactComponent as GoogleDisabledIcon } from './variant-svgs/google-disabled.svg';
import { ReactComponent as GoogleBlackIcon } from './variant-svgs/google-regular.svg';

const VARIANT_ICON_MAP = {
  default: GoogleColorIcon,
  black: GoogleBlackIcon,
  disabled: GoogleDisabledIcon,
};

export const GoogleIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="google-icon"
      />
    );
  },
);

GoogleIcon.displayName = 'GoogleIcon';
