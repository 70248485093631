import { forwardRef } from 'react';

import { Icon, ToolTip } from '@pointfive/ui';
import Button, {
  ButtonSizes,
  ButtonVariants,
} from '@pointfive/ui/components/base/Button';

export const Trigger = forwardRef<HTMLButtonElement>(({ ...props }, ref) => {
  return (
    <ToolTip message="More actions" side="top">
      <Button
        ref={ref}
        className="px-1.5 transition-all duration-300 hover:text-gray-500"
        size={ButtonSizes.Small}
        variant={ButtonVariants.Ghost}
        data-cy="action-dropdown-menu-trigger"
        {...props}
      >
        <Icon data-cy="actions-menu-icon" name="dots-vertical" size={16} />
      </Button>
    </ToolTip>
  );
});
Trigger.displayName = 'Trigger';
