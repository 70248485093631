import {
  SideNavAnalyticsIcon,
  SideNavCommitmentsIcon,
  SideNavDashboardIcon,
  SideNavExpertInsightsIcon,
  SideNavOpportunityIcon,
  SideNavResourcesIcon,
} from '@pointfive/assets';

import { ROUTE_CONSTANTS } from 'router/constants';
import { USER_ROLES } from 'services/auth';

import { NavBarItems } from './types';

export const NAV_BAR_ITEMS: NavBarItems[] = [
  {
    label: 'Home',
    icon: SideNavDashboardIcon,
    path: ROUTE_CONSTANTS.overview,
    roleAccess: [USER_ROLES.ADMIN],
  },
  {
    label: 'Opportunities',
    icon: SideNavOpportunityIcon,
    path: ROUTE_CONSTANTS.opportunities,
  },
  {
    label: 'Inventory',
    icon: SideNavResourcesIcon,
    path: ROUTE_CONSTANTS.resources,
  },
  {
    label: 'Analytics',
    icon: SideNavAnalyticsIcon,
    path: ROUTE_CONSTANTS.cloudIntelligence,
  },
  {
    label: 'Commitments',
    icon: SideNavCommitmentsIcon,
    path: ROUTE_CONSTANTS.commitments,
    roleAccess: [USER_ROLES.ADMIN],
  },
  {
    label: 'Expert Insights',
    icon: SideNavExpertInsightsIcon,
    path: ROUTE_CONSTANTS.expertInsights,
    roleAccess: [USER_ROLES.ADMIN],
  },
];
