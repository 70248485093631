import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const NoRulesIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="NoRules"
      >
        <path d="M4.86884 4.83326C4.68403 5.21514 4.60726 5.62352 4.57056 6.07394C4.53377 6.52547 4.53378 7.08106 4.53379 7.76041V16.5673C4.53378 17.2466 4.53377 17.8022 4.57056 18.2538C4.60864 18.7211 4.68987 19.1432 4.89016 19.5375C5.20364 20.1544 5.70384 20.656 6.31907 20.9703C6.71218 21.1712 7.13311 21.2527 7.59918 21.2908C8.04945 21.3277 8.60349 21.3277 9.28094 21.3277H14.7098C15.3873 21.3277 15.9413 21.3277 16.3916 21.2908C16.8577 21.2527 17.2786 21.1712 17.6717 20.9703C18.1442 20.7289 18.5489 20.377 18.8532 19.9472L17.7836 18.7912C17.7747 18.812 17.7655 18.8317 17.756 18.8504C17.5872 19.1826 17.3179 19.4527 16.9866 19.622C16.8417 19.696 16.6414 19.752 16.2687 19.7826C15.8872 19.8138 15.395 19.8144 14.6782 19.8144H9.31256C8.59581 19.8144 8.10362 19.8138 7.72207 19.7826C7.34932 19.752 7.14909 19.696 7.00418 19.622C6.6729 19.4527 6.40357 19.1826 6.23477 18.8504C6.16093 18.7051 6.10509 18.5043 6.07464 18.1305C6.04346 17.7479 6.04288 17.2544 6.04288 16.5356V7.79211C6.04288 7.07335 6.04346 6.57979 6.07464 6.19717C6.07618 6.17823 6.07779 6.15973 6.07946 6.14166L4.86884 4.83326Z" />
        <path d="M8.43582 8.68835C8.16674 8.61193 7.95881 8.38955 7.90248 8.11193L8.43582 8.68835Z" />
        <path d="M10.522 8.7169L9.12184 7.2036H15.3489C15.7656 7.2036 16.1035 7.54237 16.1035 7.96025C16.1035 8.37814 15.7656 8.7169 15.3489 8.7169H10.522Z" />
        <path d="M10.1736 10.5665H8.64186C8.22514 10.5665 7.88732 10.9053 7.88732 11.3231C7.88732 11.741 8.22514 12.0798 8.64186 12.0798H11.5738L10.1736 10.5665Z" />
        <path d="M13.6336 12.0798L12.2334 10.5665H13.6721C14.0889 10.5665 14.4267 10.9053 14.4267 11.3231C14.4267 11.741 14.0889 12.0798 13.6721 12.0798H13.6336Z" />
        <path d="M17.9478 16.7425C17.9479 16.6757 17.9479 16.6067 17.9479 16.5356V7.79211C17.9479 7.07335 17.9473 6.57979 17.9161 6.19717C17.8857 5.82339 17.8298 5.6226 17.756 5.47728C17.5872 5.14508 17.3179 4.87499 16.9866 4.70573C16.8417 4.63169 16.6414 4.57569 16.2687 4.54515C15.8872 4.51389 15.395 4.5133 14.6782 4.5133H9.31256C8.59581 4.5133 8.10362 4.51389 7.72207 4.54515C7.34932 4.57569 7.14909 4.63169 7.00418 4.70573C6.96071 4.72794 6.9183 4.75189 6.87704 4.77749L5.84018 3.65688C5.99017 3.54394 6.15029 3.44361 6.31907 3.35737C6.71218 3.15651 7.13311 3.07506 7.59918 3.03688C8.04945 2.99998 8.6035 2.99999 9.28096 3H14.7098C15.3873 2.99999 15.9413 2.99998 16.3916 3.03688C16.8577 3.07506 17.2786 3.15651 17.6717 3.35737C18.2869 3.67172 18.7871 4.17331 19.1006 4.79026C19.3009 5.18447 19.3821 5.60657 19.4202 6.07394C19.457 6.52546 19.457 7.08104 19.457 7.76036V16.5673C19.457 17.2466 19.457 17.8023 19.4202 18.2538C19.4182 18.2783 19.4161 18.3027 19.4139 18.3269L17.9478 16.7425Z" />
        <path d="M8.64186 13.9294C8.22514 13.9294 7.88732 14.2681 7.88732 14.686C7.88732 15.1039 8.22514 15.4427 8.64186 15.4427H10.3186C10.7353 15.4427 11.0732 15.1039 11.0732 14.686C11.0732 14.2681 10.7353 13.9294 10.3186 13.9294H8.64186Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.53341 4.16724C3.83717 3.8808 4.31466 3.89528 4.59992 4.19959L20.0909 20.7247C20.3762 21.029 20.3612 21.5079 20.0574 21.7943C19.7537 22.0807 19.2762 22.0663 18.9909 21.762L3.49992 5.23686C3.21466 4.93256 3.22965 4.45367 3.53341 4.16724Z"
        />
      </svg>
    );
  },
);

NoRulesIcon.displayName = 'NoRulesIcon';
