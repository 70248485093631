import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as RdsClusterColorIcon } from './variant-svgs/rds-cluster-color.svg';
import { ReactComponent as RdsClusterDisabledIcon } from './variant-svgs/rds-cluster-disabled.svg';
import { ReactComponent as RdsClusterBlackIcon } from './variant-svgs/rds-cluster-regular.svg';

const VARIANT_ICON_MAP = {
  default: RdsClusterColorIcon,
  black: RdsClusterBlackIcon,
  disabled: RdsClusterDisabledIcon,
};

export const RdsClusterIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="rds-cluster-icon"
      />
    );
  },
);

RdsClusterIcon.displayName = 'RdsClusterIcon';
