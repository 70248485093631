import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as RedshiftColorIcon } from './variant-svgs/redshift-color.svg';
import { ReactComponent as RedshiftDisabledIcon } from './variant-svgs/redshift-disabled.svg';
import { ReactComponent as RedshiftBlackIcon } from './variant-svgs/redshift-regular.svg';

const VARIANT_ICON_MAP = {
  default: RedshiftColorIcon,
  black: RedshiftBlackIcon,
  disabled: RedshiftDisabledIcon,
};

export const RedshiftIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="redshift-icon"
      />
    );
  },
);

RedshiftIcon.displayName = 'RedshiftIcon';
