import {
  ComponentPropsWithoutRef,
  ElementRef,
  ReactNode,
  forwardRef,
} from 'react';

import * as PopoverPrimitive from '@radix-ui/react-popover';

import { cn } from '../../../../utils/utils';

const PopoverContainer = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

/**
 * When using PopoverPrimitive.Portal inside modal, it's causing sibling portal elements under body, and the 2 siblings are racing for focus endlessly.
 * (Console shows errors of "Uncaught RangeError: Maximum call stack size exceeded.")
 * See https://github.com/radix-ui/primitives/issues/1159.
 *
 * Rendering with Portal when outside of a modal (and thus needs the portal, otherwise it renders off position).
 * If inside modal, the Portal is not needed.
 */
const PopoverWrapper = ({
  isRenderedInModal,
  children,
}: {
  isRenderedInModal: boolean;
  children: ReactNode;
}) =>
  isRenderedInModal ? (
    <>{children}</>
  ) : (
    <PopoverPrimitive.Portal>{children}</PopoverPrimitive.Portal>
  );

export type PopoverExtraProps = {
  isRenderedInModal?: boolean;
};

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & PopoverExtraProps
>(
  (
    {
      className,
      align = 'center',
      sideOffset = 4,
      side = 'bottom',
      isRenderedInModal = false,
      ...props
    },
    ref,
  ) => (
    <PopoverWrapper isRenderedInModal={isRenderedInModal}>
      <PopoverPrimitive.Content
        side={side}
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={cn(
          'z-50 w-72 rounded-md border bg-popover text-popover-foreground shadow-md outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
          className,
        )}
        {...props}
      />
    </PopoverWrapper>
  ),
);
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { PopoverContainer, PopoverContent, PopoverTrigger };
