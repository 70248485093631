import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const SideNavAnalyticsIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="SideNavAnalytics"
      >
        <g clipPath="url(#clip0_485_39244)">
          <path d="M17.0397 23.9252H6.95971C4.76971 23.9252 3.65971 23.9252 2.65471 23.4152C1.75471 22.9652 1.04971 22.2452 0.584707 21.3452C0.0747073 20.3252 0.074707 19.2302 0.074707 17.0402V6.96019C0.074707 4.77019 0.0747073 3.66019 0.584707 2.65519C1.03471 1.75519 1.75471 1.05019 2.65471 0.58519C3.65971 0.0751898 4.76971 0.0751953 6.95971 0.0751953H17.0397C19.2297 0.0751953 20.3397 0.0751898 21.3447 0.58519C22.2447 1.03519 22.9497 1.75519 23.4147 2.65519C23.9247 3.66019 23.9247 4.77019 23.9247 6.96019V17.0402C23.9247 19.2302 23.9247 20.3402 23.4147 21.3452C22.9647 22.2452 22.2447 22.9502 21.3447 23.4152C20.3397 23.9252 19.2297 23.9252 17.0397 23.9252ZM6.95971 2.3252C5.18971 2.3252 4.19971 2.3252 3.67471 2.5952C3.20971 2.8352 2.83471 3.21019 2.59471 3.67519C2.32471 4.20019 2.32471 5.17519 2.32471 6.96019V17.0402C2.32471 18.8102 2.32471 19.8002 2.59471 20.3252C2.83471 20.7902 3.20971 21.1652 3.67471 21.4052C4.19971 21.6752 5.18971 21.6752 6.95971 21.6752H17.0397C18.8097 21.6752 19.7997 21.6752 20.3247 21.4052C20.7897 21.1652 21.1647 20.7902 21.4047 20.3252C21.6747 19.8002 21.6747 18.8102 21.6747 17.0402V6.96019C21.6747 5.19019 21.6747 4.20019 21.4047 3.67519C21.1647 3.21019 20.7897 2.8352 20.3247 2.5952C19.7997 2.3252 18.8097 2.3252 17.0397 2.3252H6.95971ZM16.7997 19.1252C16.1847 19.1252 15.6747 18.6152 15.6747 18.0002V10.8002C15.6747 10.1852 16.1847 9.67519 16.7997 9.67519C17.4147 9.67519 17.9247 10.1852 17.9247 10.8002V18.0002C17.9247 18.6152 17.4147 19.1252 16.7997 19.1252ZM11.9997 19.1252C11.3847 19.1252 10.8747 18.6152 10.8747 18.0002V6.00019C10.8747 5.38519 11.3847 4.87519 11.9997 4.87519C12.6147 4.87519 13.1247 5.38519 13.1247 6.00019V18.0002C13.1247 18.6152 12.6147 19.1252 11.9997 19.1252ZM7.19971 19.1252C6.58471 19.1252 6.07471 18.6152 6.07471 18.0002V13.2002C6.07471 12.5852 6.58471 12.0752 7.19971 12.0752C7.81471 12.0752 8.32471 12.5852 8.32471 13.2002V18.0002C8.32471 18.6152 7.81471 19.1252 7.19971 19.1252Z" />
        </g>
        <defs>
          <clipPath id="clip0_485_39244">
            <rect width="24" height="24" />
          </clipPath>
        </defs>
      </svg>
    );
  },
);

SideNavAnalyticsIcon.displayName = 'SideNavAnalyticsIcon';
