import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const File03Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="File03"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.19971 4.00007C3.19971 2.45368 4.45331 1.20007 5.99971 1.20007H17.9997C19.5461 1.20007 20.7997 2.45368 20.7997 4.00007V20.0001C20.7997 21.5465 19.5461 22.8001 17.9997 22.8001H5.99971C4.45331 22.8001 3.19971 21.5465 3.19971 20.0001V4.00007ZM5.99971 2.80007C5.33697 2.80007 4.79971 3.33733 4.79971 4.00007V20.0001C4.79971 20.6628 5.33697 21.2001 5.99971 21.2001H17.9997C18.6624 21.2001 19.1997 20.6628 19.1997 20.0001V4.00007C19.1997 3.33733 18.6624 2.80007 17.9997 2.80007H5.99971Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.19971 7.00007C7.19971 6.55825 7.55788 6.20007 7.99971 6.20007H11.9997C12.4415 6.20007 12.7997 6.55825 12.7997 7.00007C12.7997 7.4419 12.4415 7.80007 11.9997 7.80007H7.99971C7.55788 7.80007 7.19971 7.4419 7.19971 7.00007Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.19971 11.0001C7.19971 10.5582 7.55788 10.2001 7.99971 10.2001H13.9997C14.4415 10.2001 14.7997 10.5582 14.7997 11.0001C14.7997 11.4419 14.4415 11.8001 13.9997 11.8001H7.99971C7.55788 11.8001 7.19971 11.4419 7.19971 11.0001Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.19971 15.0001C7.19971 14.5582 7.55788 14.2001 7.99971 14.2001H10.9997C11.4415 14.2001 11.7997 14.5582 11.7997 15.0001C11.7997 15.4419 11.4415 15.8001 10.9997 15.8001H7.99971C7.55788 15.8001 7.19971 15.4419 7.19971 15.0001Z"
        />
      </svg>
    );
  },
);

File03Icon.displayName = 'File03Icon';
