import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const EditPlusIcon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="EditPlus"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.178 2.99878C16.5098 1.66691 18.6692 1.66691 20.0011 2.99878C21.333 4.33066 21.333 6.49005 20.0011 7.82193L17.3309 10.4921L17.3286 10.4944L17.3263 10.4967L8.23655 19.5865C8.22162 19.6014 8.20682 19.6162 8.19214 19.6309C7.97398 19.8494 7.78154 20.0422 7.55289 20.193C7.35187 20.3255 7.13385 20.4303 6.90476 20.5045C6.64419 20.5888 6.37345 20.6187 6.06653 20.6525C6.04589 20.6548 6.02508 20.6571 6.0041 20.6594L2.98921 20.9944C2.72025 21.0243 2.45228 20.9303 2.26092 20.739C2.06957 20.5476 1.97557 20.2796 2.00545 20.0107L2.34044 16.9958C2.34277 16.9748 2.34507 16.954 2.34735 16.9333C2.3812 16.6264 2.41106 16.3557 2.49542 16.0951C2.56958 15.866 2.67437 15.648 2.80693 15.447C2.9577 15.2183 3.15045 15.0259 3.36896 14.8077C3.38366 14.793 3.39848 14.7783 3.41341 14.7633L15.178 2.99878ZM13.1354 7.561L4.67324 16.0231C4.38594 16.3104 4.3326 16.3697 4.2943 16.4278C4.25012 16.4948 4.21519 16.5675 4.19047 16.6438C4.16904 16.71 4.15607 16.7887 4.1112 17.1925L3.89918 19.1007L5.80735 18.8887C6.21116 18.8438 6.28984 18.8308 6.35603 18.8094C6.4324 18.7847 6.50507 18.7498 6.57207 18.7056C6.63015 18.6673 6.68943 18.6139 6.97673 18.3266L15.4389 9.8645L13.1354 7.561ZM16.6987 8.60468L14.3952 6.30118L16.4378 4.25861C17.0739 3.62251 18.1052 3.62251 18.7413 4.25861C19.3774 4.8947 19.3774 5.92601 18.7413 6.5621L16.6987 8.60468Z"
        />
        <path d="M18 13.9999C18.5523 13.9999 19 14.4476 19 14.9999V16.9999H21C21.5523 16.9999 22 17.4476 22 17.9999C22 18.5521 21.5523 18.9999 21 18.9999H19V20.9999C19 21.5521 18.5523 21.9998 18 21.9998C17.4477 21.9998 17 21.5521 17 20.9999V18.9999H15C14.4477 18.9999 14 18.5521 14 17.9999C14 17.4476 14.4477 16.9999 15 16.9999H17V14.9999C17 14.4476 17.4477 13.9999 18 13.9999Z" />
      </svg>
    );
  },
);

EditPlusIcon.displayName = 'EditPlusIcon';
