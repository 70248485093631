import { Link } from 'react-router-dom';

import { ButtonsGroup, CollapseToggle, SideNavButton } from './components';
import { NavigationLink } from './types';

export interface SideNavProps {
  currentLocation: string;
  links?: NavigationLink[];
}

export const SideNav = ({ currentLocation, links = [] }: SideNavProps) => {
  return (
    <div
      className={`border-r-border-minimal relative flex h-full w-[200px] flex-col overflow-hidden pt-2 shadow-[4px_0px_30px_0px_rgba(18,33,58,0.10)] transition-[width] duration-200 group-hover/sidenav:!w-[200px] group-[.is-navbar-collapsed]/root:w-12`}
    >
      <ButtonsGroup>
        {links.map((link) => (
          <Link to={link.path} key={link.path} className="w-full">
            <SideNavButton
              selected={currentLocation?.startsWith(link.path)}
              label={link.label}
              icon={link.icon}
              dataCy={`side-bar-btn-${link.label.toLowerCase()}`}
              className="scale-100 transition-[margin,transform] duration-200 ease-in-out active:scale-95"
            />
          </Link>
        ))}
      </ButtonsGroup>
      <CollapseToggle />
    </div>
  );
};
