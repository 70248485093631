import { useEffect } from 'react';

import Icons from './icons.svg?raw';

export const IconProvider = () => {
  useEffect(() => {
    document.body.insertAdjacentHTML('beforeend', Icons);
  }, []);

  return null;
};
