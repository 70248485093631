import { lazyWithRetries } from '../../router/lazyWithRetries';

const importRouter = () => import('./router.index');

export const settingsElements = {
  Settings: lazyWithRetries(async () => (await importRouter()).Settings),
  Users: lazyWithRetries(async () => (await importRouter()).Users),
  TeamsPage: lazyWithRetries(async () => (await importRouter()).TeamPage),
  Team: lazyWithRetries(async () => (await importRouter()).Team),
  Integrations: lazyWithRetries(
    async () => (await importRouter()).Integrations,
  ),
  Preferences: lazyWithRetries(async () => (await importRouter()).Preferences),
  Detection: lazyWithRetries(async () => (await importRouter()).Detection),
  NotificationsPage: lazyWithRetries(
    async () => (await importRouter()).NotificationsPage,
  ),
  NewRulePage: lazyWithRetries(async () => (await importRouter()).NewRulePage),
  EditRulePage: lazyWithRetries(
    async () => (await importRouter()).EditRulePage,
  ),
  NewAwsAccount: lazyWithRetries(
    async () => (await importRouter()).NewAccountForm,
  ),
  NewAwsMultipleAccounts: lazyWithRetries(
    async () => (await importRouter()).NewMultiAccountForm,
  ),
  NewSlackAccount: lazyWithRetries(
    async () => (await importRouter()).NewSlackIntegration,
  ),
  SetupIntegration: lazyWithRetries(
    async () => (await importRouter()).SetupIntegration,
  ),
  NewJiraAccount: lazyWithRetries(
    async () => (await importRouter()).NewJiraIntegration,
  ),
};
