import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Grid01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Grid01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.125 4C4.09185 4 4.06005 4.01317 4.03661 4.03661C4.01317 4.06005 4 4.09185 4 4.125V8.625C4 8.65815 4.01317 8.68995 4.03661 8.71339C4.06005 8.73683 4.09185 8.75 4.125 8.75H8.625C8.65815 8.75 8.68995 8.73683 8.71339 8.71339C8.73683 8.68995 8.75 8.65815 8.75 8.625V4.125C8.75 4.09185 8.73683 4.06005 8.71339 4.03661C8.68995 4.01317 8.65815 4 8.625 4H4.125ZM2.6224 2.6224C3.02091 2.22388 3.56141 2 4.125 2H8.625C9.18859 2 9.72909 2.22388 10.1276 2.6224C10.5261 3.02091 10.75 3.56141 10.75 4.125V8.625C10.75 9.18859 10.5261 9.72909 10.1276 10.1276C9.72909 10.5261 9.18859 10.75 8.625 10.75H4.125C3.56141 10.75 3.02091 10.5261 2.6224 10.1276C2.22388 9.72909 2 9.18859 2 8.625V4.125C2 3.56141 2.22388 3.02091 2.6224 2.6224Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.375 4C15.3418 4 15.3101 4.01317 15.2866 4.03661C15.2632 4.06005 15.25 4.09185 15.25 4.125V8.625C15.25 8.65815 15.2632 8.68995 15.2866 8.71339C15.3101 8.73683 15.3418 8.75 15.375 8.75H19.875C19.9082 8.75 19.9399 8.73683 19.9634 8.71339C19.9868 8.68995 20 8.65815 20 8.625V4.125C20 4.09185 19.9868 4.06005 19.9634 4.03661C19.9399 4.01317 19.9082 4 19.875 4H15.375ZM13.8724 2.6224C14.2709 2.22388 14.8114 2 15.375 2H19.875C20.4386 2 20.9791 2.22388 21.3776 2.6224C21.7761 3.02091 22 3.56142 22 4.125V8.625C22 9.18858 21.7761 9.72909 21.3776 10.1276C20.9791 10.5261 20.4386 10.75 19.875 10.75H15.375C14.8114 10.75 14.2709 10.5261 13.8724 10.1276C13.4739 9.72909 13.25 9.18859 13.25 8.625V4.125C13.25 3.56141 13.4739 3.02091 13.8724 2.6224Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.125 15.25C4.09185 15.25 4.06005 15.2632 4.03661 15.2866C4.01317 15.3101 4 15.3418 4 15.375V19.875C4 19.9082 4.01317 19.9399 4.03661 19.9634C4.06005 19.9868 4.09185 20 4.125 20H8.625C8.65815 20 8.68995 19.9868 8.71339 19.9634C8.73683 19.9399 8.75 19.9082 8.75 19.875V15.375C8.75 15.3418 8.73683 15.3101 8.71339 15.2866C8.68995 15.2632 8.65815 15.25 8.625 15.25H4.125ZM2.6224 13.8724C3.02091 13.4739 3.56141 13.25 4.125 13.25H8.625C9.18859 13.25 9.72909 13.4739 10.1276 13.8724C10.5261 14.2709 10.75 14.8114 10.75 15.375V19.875C10.75 20.4386 10.5261 20.9791 10.1276 21.3776C9.72909 21.7761 9.18858 22 8.625 22H4.125C3.56142 22 3.02091 21.7761 2.6224 21.3776C2.22388 20.9791 2 20.4386 2 19.875V15.375C2 14.8114 2.22388 14.2709 2.6224 13.8724Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.375 15.25C15.3418 15.25 15.3101 15.2632 15.2866 15.2866C15.2632 15.3101 15.25 15.3418 15.25 15.375V19.875C15.25 19.9082 15.2632 19.9399 15.2866 19.9634C15.3101 19.9868 15.3418 20 15.375 20H19.875C19.9082 20 19.9399 19.9868 19.9634 19.9634C19.9868 19.9399 20 19.9082 20 19.875V15.375C20 15.3418 19.9868 15.3101 19.9634 15.2866C19.9399 15.2632 19.9082 15.25 19.875 15.25H15.375ZM13.8724 13.8724C14.2709 13.4739 14.8114 13.25 15.375 13.25H19.875C20.4386 13.25 20.9791 13.4739 21.3776 13.8724C21.7761 14.2709 22 14.8114 22 15.375V19.875C22 20.4386 21.7761 20.9791 21.3776 21.3776C20.9791 21.7761 20.4386 22 19.875 22H15.375C14.8114 22 14.2709 21.7761 13.8724 21.3776C13.4739 20.9791 13.25 20.4386 13.25 19.875V15.375C13.25 14.8114 13.4739 14.2709 13.8724 13.8724Z"
        />
      </svg>
    );
  },
);

Grid01Icon.displayName = 'Grid01Icon';
