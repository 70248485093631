import { ReactNode } from 'react';

import { cva } from 'class-variance-authority';
import { ToastContentProps } from 'react-toastify';

import { Icon } from '../Icon';

export const ToastColors = {
  green: 'green',
  blue: 'blue',
  red: 'red',
  purple: 'purple',
} as const;
export type ToastColorsType = (typeof ToastColors)[keyof typeof ToastColors];

export enum ToastTypes {
  default = 'default',
  positive = 'positive',
  negative = 'negative',
  warning = 'warning',
}

const toastVariants = cva('rounded-lg shadow-sm px-4 py-3', {
  variants: {
    type: {
      [ToastTypes.default]: 'bg-primary-900 text-text-inverted',
      [ToastTypes.positive]: 'bg-green-600 text-text-inverted',
      [ToastTypes.negative]: 'bg-severity-900 text-text-inverted',
      [ToastTypes.warning]: 'bg-severity-700 text-gray-900',
    },
  },
  defaultVariants: {
    type: ToastTypes.default,
  },
});

export type ToastProps = Partial<
  ToastContentProps & {
    type?: ToastTypes;
    message?: ReactNode;
    defaultMessageFormat?: boolean;
    toastIcon?: string | boolean;
    withBorder?: boolean;
    withCloseButton?: boolean;
    actionComponent?: ReactNode;
    dataCy?: string;
  }
>;

const toSentenceCase = (input: ReactNode): ReactNode => {
  if (!input || typeof input !== 'string') {
    return input;
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const Toast = ({
  type = ToastTypes.default,
  closeToast,
  message,
  dataCy,
  defaultMessageFormat = true,
  toastIcon = 'check-circle',
  withBorder = false,
  withCloseButton = true,
  actionComponent = null,
}: ToastProps) => {
  return (
    <div data-cy={dataCy} className={toastVariants({ type })}>
      <div className="flex items-center gap-[20px]">
        <div className="flex items-center gap-3">
          {toastIcon && typeof toastIcon === 'string' ? (
            <Icon name={toastIcon} size={24} />
          ) : null}
          <div className="flex gap-4">
            {withBorder ? (
              <div className="border-border-minimal border-l" />
            ) : null}
            <div className="text-body-2">
              {defaultMessageFormat ? toSentenceCase(message) : message}
            </div>
          </div>
        </div>
        {actionComponent}
        {withCloseButton ? (
          <div className="cursor-pointer" onClick={() => closeToast?.()}>
            <Icon name="x" size={18} />
          </div>
        ) : null}
      </div>
    </div>
  );
};
