import { gql } from '@apollo/client';

export const ANALYTICS_DASHBOARD_TOKEN = gql`
  query analyticsDashboardToken($input: AnalyticsDashboardTokenInput) {
    external {
      analyticsDashboardToken(input: $input) {
        id
        tokens
      }
    }
  }
`;

export const ANALYTICS_DASHBOARDS = gql`
  query analyticsDashboards {
    analyticsDashboards {
      dashboardId
      name
    }
  }
`;
