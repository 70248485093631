import { forwardRef } from 'react';

import { ICON_SIZE_OPTIONS } from './constants';
import { VariantIconProps } from './types';
import { ReactComponent as Ec2InstanceColorIcon } from './variant-svgs/ec2-instance-color.svg';
import { ReactComponent as Ec2InstanceDisabledIcon } from './variant-svgs/ec2-instance-disabled.svg';
import { ReactComponent as Ec2InstanceBlackIcon } from './variant-svgs/ec2-instance-regular.svg';

const VARIANT_ICON_MAP = {
  default: Ec2InstanceColorIcon,
  black: Ec2InstanceBlackIcon,
  disabled: Ec2InstanceDisabledIcon,
};

export const Ec2InstanceIcon = forwardRef<SVGSVGElement, VariantIconProps>(
  ({ size = 'sm', variant, onClick }, ref) => {
    const VariantIcon = VARIANT_ICON_MAP[variant];

    return (
      <VariantIcon
        onClick={onClick}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        ref={ref}
        aria-label="ec2-instance-icon"
      />
    );
  },
);

Ec2InstanceIcon.displayName = 'Ec2InstanceIcon';
