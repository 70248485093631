import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Expend06Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Expend06"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 5.41421L4 8C4 8.55228 3.55228 9 3 9C2.44772 9 2 8.55228 2 8L2 3C2 2.44772 2.44772 2 3 2L8 2C8.55228 2 9 2.44772 9 3C9 3.55228 8.55228 4 8 4L5.41421 4L8.70711 7.29289C9.09763 7.68342 9.09763 8.31658 8.70711 8.70711C8.31658 9.09763 7.68342 9.09763 7.29289 8.70711L4 5.41421ZM16 4C15.4477 4 15 3.55229 15 3C15 2.44772 15.4477 2 16 2H21C21.5523 2 22 2.44772 22 3V8C22 8.55229 21.5523 9 21 9C20.4477 9 20 8.55229 20 8V5.41421L16.7071 8.70711C16.3166 9.09763 15.6834 9.09763 15.2929 8.70711C14.9024 8.31658 14.9024 7.68342 15.2929 7.29289L18.5858 4H16ZM3 15C3.55229 15 4 15.4477 4 16L4 18.5858L7.29289 15.2929C7.68342 14.9024 8.31658 14.9024 8.70711 15.2929C9.09763 15.6834 9.09763 16.3166 8.70711 16.7071L5.41421 20H8C8.55229 20 9 20.4477 9 21C9 21.5523 8.55229 22 8 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21L2 16C2 15.4477 2.44772 15 3 15ZM18.5858 20L15.2929 16.7071C14.9024 16.3166 14.9024 15.6834 15.2929 15.2929C15.6834 14.9024 16.3166 14.9024 16.7071 15.2929L20 18.5858V16C20 15.4477 20.4477 15 21 15C21.5523 15 22 15.4477 22 16V21C22 21.5523 21.5523 22 21 22H16C15.4477 22 15 21.5523 15 21C15 20.4477 15.4477 20 16 20H18.5858Z"
        />
      </svg>
    );
  },
);

Expend06Icon.displayName = 'Expend06Icon';
