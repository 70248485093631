import { forwardRef } from 'react';

import { ICON_COLOR_OPTIONS, ICON_SIZE_OPTIONS } from './constants';
import { IconProps } from './types';

export const Target01Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ size = 'sm', color, onClick }, ref) => {
    const iconColor = color ? ICON_COLOR_OPTIONS[color] : 'currentColor';

    return (
      <svg
        ref={ref}
        width={ICON_SIZE_OPTIONS[size]}
        height={ICON_SIZE_OPTIONS[size]}
        color={iconColor}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill={iconColor}
        onClick={onClick}
        aria-label="Target01"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.86812 11.0909H6.54545C7.04753 11.0909 7.45455 11.4979 7.45455 12C7.45455 12.5021 7.04753 12.9091 6.54545 12.9091H3.86812C4.28734 16.7015 7.29853 19.7127 11.0909 20.1319V17.4545C11.0909 16.9525 11.4979 16.5455 12 16.5455C12.5021 16.5455 12.9091 16.9525 12.9091 17.4545V20.1319C16.7015 19.7127 19.7127 16.7015 20.1319 12.9091H17.4545C16.9525 12.9091 16.5455 12.5021 16.5455 12C16.5455 11.4979 16.9525 11.0909 17.4545 11.0909H20.1319C19.7127 7.29853 16.7015 4.28734 12.9091 3.86812V6.54545C12.9091 7.04753 12.5021 7.45455 12 7.45455C11.4979 7.45455 11.0909 7.04753 11.0909 6.54545V3.86812C7.29853 4.28734 4.28734 7.29853 3.86812 11.0909ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
        />
      </svg>
    );
  },
);

Target01Icon.displayName = 'Target01Icon';
